import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const FilterControlsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin: 8px 0 10px 0;
  justify-content: flex-end;

  @media ${DEVICE.formResponsive} {
    gap: auto;
    justify-content: space-between;
  }
`;

export const ClearFiltersButton = styled.button`
  background: transparent;
  border: 1px solid var(--GRAY_80_OR_RAISIN_BLACK_ALPHA_03);
  border-radius: 4px;
  cursor: pointer;
  color: var(--GRAY_80_OR_DAVY_GRAY);
  font-size: 12px;
  font-weight: 600;
  height: 35px;
  padding: 0 12px;
`;

export const ToggleFiltersButton = styled.button`
  align-items: center;
  background: transparent;
  border: 1px solid var(--GRAY_80_OR_RAISIN_BLACK_ALPHA_03);
  border-radius: 4px;
  cursor: pointer;
  color: var(--GRAY_80_OR_DAVY_GRAY);
  display: flex;
  font-size: 12px;
  font-weight: 600;
  gap: 8px;
  height: 40px;
  justify-content: center;
  width: 120px;

  svg > path {
    fill: var(--GRAY_80_OR_DAVY_GRAY);
  }
`;
