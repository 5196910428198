import { PrimaryButton } from 'components/common/Button/Button.styled';
import { useAppContext } from 'contexts/AppContext';
import { useState } from 'react';
import RESET_PASSWORD_STATIC_DATA from 'pages/ResetPasswordPage/constants';
import {
  SIGN_IN,
  SUBMIT_BUTTON_CSS,
} from 'components/Forms/SignForm/constants';
import { MobileBottomSpacer } from 'components/common/Modal/Modal.styled';
import {
  ButtonWrapper,
  IconWrapper,
  ResetPasswordWrapper,
  SubTitle,
  Title,
  TopContentWrapper,
} from './ResetPasswordPage.styled';
import ResetPassword from '../../components/Forms/ResetPassword/ResetPassword';

const ResetPasswordPage = () => {
  const [isSuccess, setSuccess] = useState(false);

  const { setActiveForm } = useAppContext();

  const handleClick = () => setActiveForm(SIGN_IN);

  return (
    <ResetPasswordWrapper>
      <TopContentWrapper>
        <IconWrapper>{RESET_PASSWORD_STATIC_DATA[isSuccess].icon}</IconWrapper>
        <Title>{RESET_PASSWORD_STATIC_DATA[isSuccess].title}</Title>
        <SubTitle>{RESET_PASSWORD_STATIC_DATA[isSuccess].sub_title}</SubTitle>
        {isSuccess && (
          <ButtonWrapper>
            <PrimaryButton onClick={handleClick} {...SUBMIT_BUTTON_CSS}>
              Go to Sign in
            </PrimaryButton>
          </ButtonWrapper>
        )}
        {!isSuccess && <ResetPassword handleSuccess={setSuccess} />}
        <MobileBottomSpacer />
      </TopContentWrapper>
    </ResetPasswordWrapper>
  );
};

export default ResetPasswordPage;
