import { CONTACT_EMAIL } from 'constants/other';

const PARAGRAPHS = {
  ABOUT: [
    [
      'We are an independent and community-driven community of entrepreneurship at HBS across graduation years, industries and geographies. We love hearing what works best for all of the company-builders out there. HBSBuilds is for any HBS student, drop out, or alumni building or joining a startup, be it 0-1 entrepreneurship or entrepreneurship through acquisition (aka Search). Inspired by our friends at ',
      ', our goal is to level up the HBS entrepreneurship community by pooling our resources, networks, and knowledge. ',
    ],
    'If you’re an HBS founder, please add your company ',
    'We also host regular events with various HBS alumni, including notable founders, investors, and operators. For more information on these events and other HBSBuilds initiatives, be sure to sign up for our quarterly newsletter.',
    'If you know of other resources, events, or content you’d like to see here, or you have exclusive deals to offer our community, you can add them directly on this site, or reach out.',
  ],
};

export const LINK_KEYS = {
  GSBUILDS: 'GSBuilds.co',
  HERE: 'here',
  REACH_OUT: 'please reach out',
};

export const LINKS = {
  [LINK_KEYS.GSBUILDS]: 'https://gsbuilds.co/',
  [LINK_KEYS.HERE]: 'https://www.hbsbuilds.com',
  [LINK_KEYS.REACH_OUT]: CONTACT_EMAIL,
};

export default PARAGRAPHS;
