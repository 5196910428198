import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Spacer from 'components/common/Spacer/Spacer.styled';
import RadioButtonGroup from 'components/common/RadioButtonGroup/RadioButtonGroup';
import DropdownHide from 'assets/icons/DropdownHide';
import DropdownShow from 'assets/icons/DropdownShow';
import useClickOutside from 'hooks/useClickOutside';

import {
  DropdownMenu,
  SortField,
  SortFieldWrapper,
  SortWrapper,
  DropdownMenuContainer,
} from 'pages/AdminPage/components/Filters/FilterSort/FilterSort.styled';
import { TEXT_SIZES } from 'components/common/RadioButtonGroup/constants';

const FilterSort = ({
  radioGroupLabel,
  options,
  setSortType,
  sortedBy,
  orderedBy,
  sortParams,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const contentRef = useRef(null);

  useClickOutside(contentRef, () => setShowDropdown(false));

  const onSortTypeChange = ({ target: { value } }) =>
    setSortType(sortParams[value]);

  const sortedByLabel = Object.keys(sortParams).find(
    (key) =>
      sortParams[key].sortBy === sortedBy &&
      sortParams[key].orderBy === orderedBy,
  );

  return (
    <SortWrapper>
      <SortFieldWrapper>
        <SortField id={sortedBy} onClick={() => setShowDropdown(!showDropdown)}>
          Sort by {sortedByLabel}
          {showDropdown ? <DropdownHide /> : <DropdownShow />}
        </SortField>
        <Spacer Height="4px" />
      </SortFieldWrapper>
      {showDropdown && (
        <DropdownMenuContainer ref={contentRef}>
          <DropdownMenu>
            <RadioButtonGroup
              radioButtonFillColor="var(--BRIGHT_GRAY_OR_DAVY_GRAY)"
              radioButtonLabelColor="var(--BRIGHT_GRAY_OR_DAVY_GRAY)"
              radioButtonOutlineColor="var(--DAVY_GRAY_OR_BRIGHT_GRAY)"
              key={sortedByLabel}
              textSize={TEXT_SIZES.MEDIUM}
              flexDirection="column"
              groupLabel={radioGroupLabel}
              options={options}
              selectedLabel={sortedByLabel}
              onChange={onSortTypeChange}
            />
          </DropdownMenu>
        </DropdownMenuContainer>
      )}
    </SortWrapper>
  );
};

FilterSort.propTypes = {
  radioGroupLabel: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setSortType: PropTypes.func.isRequired,
  sortedBy: PropTypes.string.isRequired,
  orderedBy: PropTypes.string.isRequired,
  sortParams: PropTypes.shape({
    orderBy: PropTypes.string,
    sortBy: PropTypes.string,
  }).isRequired,
};

export default FilterSort;
