import { Divider } from 'components/common/Divider/Divider.styled';
import { CRIMSON } from 'constants/colors';
import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const FounderCardContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  min-width: 260px;

  @media ${DEVICE.tablet} {
    margin-top: 20px;
  }
`;

export const FounderInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FounderName = styled.p`
  font-weight: 540;
  font-size: 18px;
  margin: 16px 0 20px 0;
  text-align: center;
  color: var(--WHITE_OR_DAVY_GRAY);
`;

export const FounderCardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const FounderCardDivider = styled(Divider)`
  margin: 10px 0;
  width: 60%;
`;

export const FounderJobInfo = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
`;

export const FounderPhoto = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 100%;
`;

export const FounderCardInfoParagraph = styled.p`
  font-size: 12px;
  margin: 0;
  text-align: center;

  a {
    color: ${CRIMSON};
    font-weight: 540;
  }
`;

export const FounderLinks = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: 12px;
  gap: 20px;
`;

export const LinkedInLink = styled.a`
  display: flex;

  & > svg > path {
    fill: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  }
`;

export const HbsClasscardLink = styled.a`
  background-color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  color: var(--RAISING_BLACK_OR_WHITE);
  height: 16px;
  display: flex;
  align-items: center;
  padding: 0 2px;
  padding-top: 2px;
  font-weight: 600;
  font-size: 12px;
  border-radius: 4px;
`;
