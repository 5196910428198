import PropTypes from 'prop-types';
import { DAVY_GRAY } from 'constants/colors';

const DropdownHide = ({ fill = DAVY_GRAY }) => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.41 7.41L6 2.83L10.59 7.41L12 6L6 0L0 6L1.41 7.41Z"
      fill={fill}
    />
  </svg>
);

DropdownHide.defaultProps = {
  fill: DAVY_GRAY,
};

DropdownHide.propTypes = {
  fill: PropTypes.string,
};

export default DropdownHide;
