import { investorParamsActions } from 'pages/AdminPage/TabControl/InvestorTab/InvestorFilters/constants';

export const changeFilterOrPaginationParam = (field, data) => ({
  type: investorParamsActions.SET_FILTER_OR_PAGINATION_PARAM,
  payload: { field, data },
});

export const changeSortType = (sortType) => ({
  type: investorParamsActions.SET_SORT_PARAM,
  payload: { sortType },
});

export const setInitialQueryParams = () => ({
  type: investorParamsActions.SET_INITIAL_QUERY_PARAMS,
});
