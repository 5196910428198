import { useReducer } from 'react';
import useCompanyValidationErrors from 'pages/AdminPage/components/Forms/Company/EditCompany/EditCombinedSteps/hooks/useCompanyValidationErrors';
import {
  inputSchema,
  multiSelectSchema,
  singleSelectSchema,
} from 'pages/AdminPage/components/Forms/Company/EditCompany/schema';
import {
  changeFieldValue,
  companyStateReducer,
  setFounders,
} from 'pages/AdminPage/components/Forms/Company/EditCompany/EditCombinedSteps/hooks/companyStateReducer';

const useCompanyState = (initialCompanyData) => {
  const [companyData, dispatch] = useReducer(companyStateReducer, {
    ...initialCompanyData,
  });

  const { validationErrors, setValidationError } = useCompanyValidationErrors();

  const inputOnChangeHandler =
    (key) =>
    ({ target: { value } }) => {
      setValidationError(
        key,
        validationErrors[key] && !inputSchema[key].validator(value),
      );
      dispatch(changeFieldValue(key, value));
    };

  const inputOnBlurHandler =
    (key) =>
    ({ target: { value } }) => {
      dispatch(changeFieldValue(key, value));

      setValidationError(key, !inputSchema[key].validator(value));
    };

  const singleSelectionHandler = (key, collection) => (option) => {
    dispatch(
      changeFieldValue(
        key,
        option && collection.find((object) => object.label === option.label),
      ),
    );

    setValidationError(key, !singleSelectSchema[key].validator(option));
  };

  const multiSelectionHandler = (key, collection) => (options) => {
    dispatch(
      changeFieldValue(
        key,
        options &&
          options.map((option) =>
            collection.find((object) => object.id === option.id),
          ),
      ),
    );

    setValidationError(key, !multiSelectSchema[key].validator(options));
  };

  const radioButtonGroupHandler =
    (key) =>
    ({ target: { value } }) =>
      dispatch(changeFieldValue(key, value));

  const foundersHandler = (founders) => dispatch(setFounders(founders));

  return {
    companyData,
    validationErrors,
    inputOnChangeHandler,
    inputOnBlurHandler,
    singleSelectionHandler,
    multiSelectionHandler,
    radioButtonGroupHandler,
    setValidationError,
    foundersHandler,
  };
};

export default useCompanyState;
