import Container from 'pages/HomePage/components/BackToTop/BackToTop.styled';
import BackToTopArrow from 'assets/icons/BackToTopArrow';
import { useEffect, useState } from 'react';

const BackToTop = () => {
  const [backtoTop, setBackToTop] = useState(false);

  useEffect(() => {
    // 2244px is the height of the page on Figma
    const handleScrollButtonVisibility = () =>
      window.scrollY > 2244 ? setBackToTop(true) : setBackToTop(false);

    window.addEventListener('scroll', handleScrollButtonVisibility);

    return () => {
      window.removeEventListener('scroll', handleScrollButtonVisibility);
    };
  }, []);

  const handleScroll = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <Container>
      {backtoTop && <BackToTopArrow handleScroll={handleScroll} />}
    </Container>
  );
};

export default BackToTop;
