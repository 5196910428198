const NewCompanyIcon = () => (
  <svg
    width="74"
    height="74"
    viewBox="0 0 74 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="37" cy="37" r="32" fill="white" />
    <circle cx="37" cy="37" r="36.5" stroke="white" />
    <path
      d="M43 40H41V42H43V40ZM43 36H41V38H43V36ZM45 44H37V42H39V40H37V38H39V36H37V34H45V44ZM35 32H33V30H35V32ZM35 36H33V34H35V36ZM35 40H33V38H35V40ZM35 44H33V42H35V44ZM31 32H29V30H31V32ZM31 36H29V34H31V36ZM31 40H29V38H31V40ZM31 44H29V42H31V44ZM37 32V28H27V46H47V32H37Z"
      fill="#A51C30"
    />
  </svg>
);

export default NewCompanyIcon;
