import styled, { keyframes } from 'styled-components';
import DEVICE from 'constants/deviceSizes';
import { Link } from 'react-router-dom';
import { Divider } from 'components/common/Divider/Divider.styled';
import { DAVY_GRAY } from 'constants/colors';

const SideMenuSlideIn = keyframes`
    from {
      width: 65vw;
    }

    to {
      width: 100vw;
    }
`;

export const Sidemenu = styled.div`
  display: none;

  @media ${DEVICE.laptop} {
    animation: 0.5s ${SideMenuSlideIn};
    background-color: var(--RAISING_BLACK_OR_WHITE);
    transition: opacity 0.3s;
    opacity: ${(props) => (props.show ? '1' : '0')};
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    margin: 0;
    justify-content: space-between;
    position: fixed;
    left: 0;
    z-index: 100;
    width: 100vw;
  }
`;

export const ListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 60px 40px 0 20px;
  padding: 0;
`;

export const ListItem = styled(Link)`
  color: var(--WHITE_OR_RAISING_BLACK);
  cursor: pointer;
  list-style-type: none;
  padding: 10px 0;
`;

export const SideMenuDivider = styled(Divider)`
  margin: 20px 0;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Paragraph = styled.p`
  margin: 0;
  padding: 10px 0;
`;

export const HamburgerTooltipContainer = styled.div`
  margin-left: 10px;

  svg > path {
    fill: ${(props) =>
      props.disabledDarkMode ? DAVY_GRAY : 'var(--BRIGHT_GRAY_OR_DAVY_GRAY)'};
  }
`;

export const AnchorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
`;

export const ProfileWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
