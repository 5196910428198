import isURL from 'validator/lib/isURL';

import isEmail from 'validator/lib/isEmail';

export const isTruthy = (value) => !!value;

export const isEmptyOrTruthy = (value) => !value || !!value;

export const isEmptyFieldValidator = (value) => !!value.length;

export const hbsEmailPattern = /^[A-Z0-9._%+-]+@mba[0-9]{4}\.hbs.edu/i;

export const hbsEmailValidator = (value) => hbsEmailPattern.test(value);

export const emailValidator = (value) => isEmail(value);

export const isEmptyOrValidEmail = (value) => !value || isEmail(value);

export const isUrlValid = (str) => isURL(str, { require_protocol: true });

export const isEmptyOrValidUrl = (str) => !str || isUrlValid(str);

export const isValidDate = (date) => date instanceof Date;

const phonePattern = /^[0-9]+$/;

export const isPhoneValid = (phone) => phonePattern.test(phone);

export const isPhoneEmptyOrValid = (phone) => !phone || isPhoneValid(phone);

export const isNumeric = (value) => !Number.isNaN(value);
