import { CRIMSON } from 'constants/colors';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import DEVICE from 'constants/deviceSizes';

export const NotFoundContainer = styled.div`
  display: flex;
  margin-top: 9vh;
  flex-direction: column;
  min-height: calc(100vh - 96px - 155px);
  align-items: center;
  position: relative;
  width: 100%;
`;

export const NotFoundTitle = styled.h1`
  color: var(--WHITE_OR_DAVY_GRAY);
  font-size: 48px;
  line-height: 72px;
  margin: 0;
  text-align: center;

  @media ${DEVICE.tablet} {
    font-size: 32px;
    line-height: 48px;
  }

  @media ${DEVICE.formResponsive} {
    font-size: 22px;
    line-height: 33px;
  }
`;

export const NotFoundParagraph = styled.p`
  color: var(--WHITE_OR_DAVY_GRAY);
  font-size: 16px;
  line-height: 24px;
  margin: 16px 0 60px;

  a {
    color: ${CRIMSON};
    cursor: pointer;
    font-weight: 600;
    text-decoration: underline !important;
  }

  @media ${DEVICE.tablet} {
    font-size: 15px;
    line-height: 21px;
    margin-bottom: 32px;
    text-align: center;
  }

  @media ${DEVICE.formResponsive} {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 32px;
    width: 335px;
  }
`;

export const HomeLink = styled(Link)`
  color: ${CRIMSON};
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline !important;
`;

export const DotsNotFound = styled.div`
  display: flex;
  left: -25%;
  position: absolute;
  top: 7%;
  z-index: -1;

  @media ${DEVICE.tablet} {
    display: flex;
    justify-content: center;
    left: 54%;
    scale: 140%;
    top: 60%;
  }

  @media ${DEVICE.formResponsive} {
    left: 50%;
    scale: 130%;
    top: 70%;
  }
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const ImageWrapper = styled.div`
  @media ${DEVICE.tablet} {
    svg {
      max-height: 179px;
      max-width: 500px;
    }
  }

  @media ${DEVICE.formResponsive} {
    svg {
      max-height: 113px;
      max-width: 310px;
    }
  }
`;
