import { useEffect, useRef, useState } from 'react';
import Modal from 'components/common/Modal/Modal';
import CompanyPopup from 'pages/HomePage/components/CompaniesContainer/CompanyPopup/CompanyPopup';
import {
  LoadMoreContainer,
  CompanyPreview,
  FiltersSection,
  ClearFilters,
  ClearFiltersButton,
} from 'pages/HomePage/components/CompaniesContainer/CompaniesContainer.styled';
import copyDefinedProps from 'utils/copyDefinedProps';
import { REQUEST_DEBOUNCE_DURATION } from 'constants/input_debounce_duration';
import { getAllCompanies } from 'services/requests';
import useRequest from 'hooks/useRequest';
import NoResults from 'components/common/NoResults/NoResults';
import BackToTop from 'pages/HomePage/components/BackToTop/BackToTop';
import {
  LoadingSpinner,
  LoadingSpinnerContainer,
} from 'components/common/LoadingSpinner/LoadingSpinner.styled';
import Filters from './Filters/Filters';
import FilterControls from './FilterControls/FilterControls';
import CompanyList from './CompanyList';
import {
  DEFAULT_PAGINATION_LIMIT,
  DEFAULT_PAGINATION_OFFSET,
  DEFAULT_SORT_TYPE,
  INITIAL_FILTERS,
  sortQueryParams,
} from './constants';
import FloatingAnchorPartner from './FloatingAnchorPartner/FloatingAnchorPartner';

const CompaniesContainer = () => {
  const [data, setData] = useState([]);
  const [previewCompany, setPreviewCompany] = useState(null);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [filtersKey, setFiltersKey] = useState(false);
  const [displayFilters, setDisplayFilters] = useState(true);
  const fetching = useRef(false);
  const setFetching = (val) => {
    fetching.current = val;
  };
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [sortType, setSortType] = useState(DEFAULT_SORT_TYPE);
  const [loadMoreConfig, setLoadMoreConfig] = useState({
    offset: DEFAULT_PAGINATION_OFFSET,
    limit: DEFAULT_PAGINATION_LIMIT,
  });
  const [prevConfig, setPrevConfig] = useState({
    offset: -1,
    limit: -1,
  });
  const [offsetThresholdElementVisible, setOffsetThresholdElementVisible] =
    useState(false);
  const [noMore, setNoMore] = useState(false);
  const loadMoreRef = useRef(null);
  const resetFetchedData = () => {
    setData([]);
    setTotalRowCount(0);
    setLoadMoreConfig({
      offset: DEFAULT_PAGINATION_OFFSET,
      limit: DEFAULT_PAGINATION_LIMIT,
    });
    setPrevConfig({
      offset: -1,
      limit: -1,
    });
  };

  const handleSortTypeChange = (sortTypeValue) => {
    resetFetchedData();
    setSortType(sortTypeValue);
  };

  const handleFiltersChange = (filterKey, value) => {
    resetFetchedData();
    setFilters((prevState) => ({
      ...prevState,
      [filterKey]: value,
    }));
  };

  const isFiltersApplied = () =>
    Boolean(
      filters.companyName ||
        filters.founderName ||
        filters.foundingYear ||
        filters.stage ||
        filters.category,
    );

  const clearFilters = () => {
    if (isFiltersApplied()) {
      resetFetchedData();
      setFiltersKey(!filtersKey);
      setFilters(INITIAL_FILTERS);
    }
  };

  const updateLoadMoreConfig = () => {
    setLoadMoreConfig((prevState) => ({
      ...prevState,
      offset: prevState.offset + prevState.limit,
    }));
  };

  const { request } = useRequest();
  const loadMoreObserver = new IntersectionObserver((entries) => {
    const entry = entries[0];
    setOffsetThresholdElementVisible(entry.isIntersecting);
    if (entry.isIntersecting) {
      if (!fetching.current) {
        updateLoadMoreConfig();
      }
    }
  });
  useEffect(() => {
    loadMoreObserver.observe(loadMoreRef.current);
  }, []);

  useEffect(() => {
    const getCompanies = async () => {
      if (prevConfig?.offset !== loadMoreConfig.offset) {
        const filterParams = copyDefinedProps(filters, Object.keys(filters));
        setFetching(true);
        setLoading(true);
        const responseData = await request(() =>
          getAllCompanies({
            offset: loadMoreConfig.offset,
            limit: loadMoreConfig.limit,
            ...filterParams,
            ...sortQueryParams[sortType],
          }),
        );

        if (responseData) {
          setTotalRowCount(responseData.data.total);
          setData((prevState) => prevState.concat(responseData.data.data));
          setPrevConfig({
            offset: loadMoreConfig.offset,
            limit: loadMoreConfig.limit,
          });
          if (responseData.data.data.length === 0) {
            setNoMore(true);
          }
        }

        setFetching(false);
        setLoading(false);
      }
    };

    const getCompaniesDebounced = setTimeout(
      getCompanies,
      REQUEST_DEBOUNCE_DURATION,
    );

    return () => {
      clearTimeout(getCompaniesDebounced);
    };
  }, [request, loadMoreConfig]);

  const closePopup = () => setPreviewCompany(null);

  const displayCompanies = () => {
    if (!data.length) return <NoResults />;

    return (
      <CompanyList
        fetching={fetching}
        data={data}
        setPreviewCompany={setPreviewCompany}
      />
    );
  };

  return (
    <>
      {!!previewCompany && (
        <Modal closeOnEscape closeForm={closePopup}>
          <CompanyPopup {...previewCompany} />
        </Modal>
      )}
      <FiltersSection>
        <FilterControls
          sortType={sortType}
          handleSortTypeChange={handleSortTypeChange}
          toggleFilters={() => setDisplayFilters(!displayFilters)}
          isShowFilters={displayFilters}
        />
        {displayFilters && (
          <ClearFilters>
            <ClearFiltersButton onClick={() => clearFilters()}>
              Clear filters
            </ClearFiltersButton>
          </ClearFilters>
        )}
        {displayFilters && (
          <Filters
            isFiltersApplied={filtersKey}
            filters={filters}
            handleFiltersChange={handleFiltersChange}
          />
        )}
      </FiltersSection>
      {displayCompanies()}
      {!noMore && <div ref={loadMoreRef} />}
      <LoadMoreContainer>
        {loading && offsetThresholdElementVisible && (
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        )}
        <CompanyPreview>
          {`${data.length}
          of ${totalRowCount} entries`}
          {loadMoreConfig.offset ? <BackToTop /> : null}
        </CompanyPreview>
      </LoadMoreContainer>
      <FloatingAnchorPartner />
    </>
  );
};

export default CompaniesContainer;
