import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FiltersContainer } from 'pages/AdminPage/components/Filters/Filters.styled';
import FilterInput from 'pages/AdminPage/components/Filters/FilterInput/FileInput';
import FilterSelect from 'pages/AdminPage/components/Filters/FilterSelect/FilterSelect';
import {
  generateYearOptions,
  mapCategories,
  mapFundingStages,
} from 'pages/AdminPage/TabControl/CompanyTab/CompanyFilters/utils';
import useRequest from 'hooks/useRequest';
import { getAllCategories, getAllFundingStages } from 'services/requests';
import useParamsProps from 'pages/AdminPage/TabControl/CompanyTab/CompanyFilters/useCompanyParams/propTypes';
import { COMPANY_PARAMS } from 'pages/AdminPage/TabControl/CompanyTab/CompanyFilters/constants';
import companyFiltersSchema from 'pages/AdminPage/components/Filters/schema';

const Filters = ({
  companyQueryParams,
  setCategory,
  setCompanyName,
  setFounder,
  setFoundingYear,
  setFundingStage,
}) => {
  const [categories, setCategories] = useState([]);
  const [fundingStages, setFundingStages] = useState([]);
  const yearOptions = generateYearOptions();

  const { request } = useRequest();

  useEffect(() => {
    let ignore = false;

    const fetchCategories = async () => {
      const response = await request(() => getAllCategories());

      if (!ignore && response) setCategories(mapCategories(response.data));
    };

    const fetchFundingStages = async () => {
      const response = await request(() => getAllFundingStages());

      if (!ignore && response)
        setFundingStages(mapFundingStages(response.data));
    };

    fetchFundingStages();

    fetchCategories();

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <FiltersContainer>
      <FilterSelect
        {...companyFiltersSchema.CATEGORY}
        options={categories}
        value={companyQueryParams[COMPANY_PARAMS.CATEGORY]}
        setValue={setCategory}
      />
      <FilterInput
        {...companyFiltersSchema.COMPANY_NAME}
        value={companyQueryParams[COMPANY_PARAMS.COMPANY_NAME]}
        setValue={setCompanyName}
      />
      <FilterInput
        {...companyFiltersSchema.FOUNDER}
        value={companyQueryParams[COMPANY_PARAMS.FOUNDER]}
        setValue={setFounder}
      />
      <FilterSelect
        {...companyFiltersSchema.FOUNDING_YEAR}
        options={yearOptions}
        value={companyQueryParams[COMPANY_PARAMS.FOUNDING_YEAR]}
        setValue={setFoundingYear}
      />
      <FilterSelect
        {...companyFiltersSchema.FUNDING_STAGE}
        options={fundingStages}
        value={companyQueryParams[COMPANY_PARAMS.FUNDING_STAGE]}
        setValue={setFundingStage}
      />
    </FiltersContainer>
  );
};

Filters.propTypes = {
  companyQueryParams: useParamsProps().isRequired,
  setCategory: PropTypes.func.isRequired,
  setCompanyName: PropTypes.func.isRequired,
  setFounder: PropTypes.func.isRequired,
  setFoundingYear: PropTypes.func.isRequired,
  setFundingStage: PropTypes.func.isRequired,
};

export default Filters;
