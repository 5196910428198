export const BULK_IMPORT_COMPANY_STEPS = {
  CSV: 'CSV',
  RESPONSE: 'RESPONSE',
};

export const PLEASE_TRY_AGAIN = 'Please check the file and try again.';

export const NOT_400_ERROR_MESSAGE =
  'Please check the file and try again. If the problem persists,\nplease contact us!';

export const STATUS = {
  REQUEST_ON_GOING: 'REQUEST_ON_GOING',
  VALIDATING_FILE_SUCCESS: 'validatingFileSuccess',
  VALIDATING_FILE_FAILURE: 'validatingFileFailure',
  IMPORT_SUCCESS: 'importSuccess',
};
export const isValidatingFileSuccess = (status) =>
  status === STATUS.VALIDATING_FILE_SUCCESS;
export const isValidatingFileFailure = (status) =>
  status === STATUS.VALIDATING_FILE_FAILURE;

export const isRequestOnGoing = (status) => status === STATUS.REQUEST_ON_GOING;

export const isImportSuccess = (status) => status === STATUS.IMPORT_SUCCESS;
