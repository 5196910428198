import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StatusCodes } from 'http-status-codes';

import ResponsePopUp from 'components/common/ResponsePopUp/ResponsePopUp';
import { InvestorFormStateProps } from 'components/Forms/Investor/propTypes';
import repackInvestorFormState from 'components/Forms/Investor/utils';
import {
  ERROR_CONFIG,
  THANK_YOU_CONFIG,
} from 'components/Forms/Company/Response/constants';

const Response = ({ formState, request }) => {
  const [success, setSuccess] = useState(true);
  const [errorConfig, setErrorConfig] = useState(ERROR_CONFIG);

  let ignore = false;

  useEffect(() => {
    const submitInvestor = async () => {
      try {
        if (!ignore) {
          // supports patch in the future
          const requestBody = [
            repackInvestorFormState(formState),
            ...(formState.id ? [formState.id] : []),
          ];

          const responseData = await request(...requestBody);

          if (responseData.status !== StatusCodes.OK)
            throw new Error(`Failed: ${responseData.response}`);
        }

        setSuccess(true);
      } catch (error) {
        if (error.response.data?.message) {
          setErrorConfig({
            ...ERROR_CONFIG,
            descriptionText: error.response.data.message,
          });
        }

        setSuccess(false);
      }
    };

    submitInvestor();

    return () => {
      ignore = true;
    };
  }, []);

  return <ResponsePopUp {...(success ? THANK_YOU_CONFIG : errorConfig)} />;
};

Response.propTypes = {
  formState: InvestorFormStateProps().isRequired,
  request: PropTypes.func.isRequired,
};

export default Response;
