import {
  passwordValidator,
  repeatPasswordValidator,
} from '../SignForm/passwordValidator';
import { RESET_PASSWORD_FIELD_NAME_KEYS } from './constants';

const RESET_PASSWORD_FORM_INITIAL_STATE = {
  [RESET_PASSWORD_FIELD_NAME_KEYS.PASSWORD]: {
    label: 'New password*',
    inputType: 'password',
    value: '',
    isError: false,
    isDisabled: false,
    warnMsg: 'Minimum 7 characters required',
    validator: passwordValidator,
  },
  [RESET_PASSWORD_FIELD_NAME_KEYS.CONFIRM_PASSWORD]: {
    label: 'Repeat password*',
    inputType: 'password',
    value: '',
    isError: false,
    isDisabled: false,
    warnMsg: `Passwords don't match`,
    validator: repeatPasswordValidator,
  },
};

export default RESET_PASSWORD_FORM_INITIAL_STATE;
