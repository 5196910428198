/* eslint-disable react/prop-types */
import CheckBox from 'pages/AdminPage/components/AdminTable/AdditionalElements/CheckBox/CheckBox';
import { TDdescription } from 'pages/AdminPage/components/AdminTable/TableElements/TableElements.styled';
import ControlCell from 'pages/AdminPage/TabControl/ResourceTabs/PublicResourcesTab/PublicTable/PublicResourceControlCell';

const PUBLIC_RESOURCES_COLUMNS = [
  {
    id: 'selection',
    width: '5%',
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <CheckBox {...getToggleAllRowsSelectedProps()} />
    ),
    Cell: ({ row }) => <CheckBox {...row.getToggleRowSelectedProps()} />,
  },
  {
    Header: 'Resource Name',
    accessor: 'dealName',
    width: '15%',
  },
  {
    Header: 'Resource URL',
    accessor: 'dealUrl',
    width: '22%',
    Cell: ({
      row: {
        original: { dealUrl },
      },
    }) => <TDdescription>{dealUrl}</TDdescription>,
  },
  {
    Header: 'Description',
    accessor: 'dealDescription',
    width: '22%',
    Cell: ({
      row: {
        original: { dealDescription },
      },
    }) => <TDdescription>{dealDescription}</TDdescription>,
  },
  {
    Header: 'Expiring Date',
    accessor: 'expiringDate',
    width: '18%',
    Cell: ({
      row: {
        original: { expiringDate },
      },
    }) => (
      <TDdescription>
        {expiringDate
          ? new Date(expiringDate).toLocaleDateString('en-US')
          : '-'}
      </TDdescription>
    ),
  },
  {
    id: 'controls',
    Header: '',
    width: '8%',
    Cell: ({
      row: {
        original: { id, deletedAt },
      },
    }) => (
      <ControlCell
        isDeleted={!!deletedAt}
        id={id}
        deleteWithIdPath="/administration/public-resource/delete"
        editWithIdPath="/administration/public-resource/edit"
        restoreWithIdPath="/administration/public-resource/restore"
      />
    ),
  },
];

export default PUBLIC_RESOURCES_COLUMNS;
