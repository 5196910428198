import { forwardRef, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CheckBoxInput from 'pages/AdminPage/components/AdminTable/AdditionalElements/CheckBox/CheckBox.styled';

const CheckBox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <CheckBoxInput
      ref={resolvedRef}
      onClick={(e) => e.stopPropagation()}
      {...rest}
    />
  );
});

CheckBox.defaultProps = {
  indeterminate: false,
};

CheckBox.propTypes = {
  indeterminate: PropTypes.bool,
};

export default CheckBox;
