import styled from 'styled-components';
import DEVICE from 'constants/deviceSizes';
import { CRIMSON } from 'constants/colors';

export const Container = styled.div`
  display: none;

  @media ${DEVICE.laptop} {
    display: flex;
    align-items: center;
    gap: 0 10px;
    z-index: 150;
  }
`;

export const HamburgerContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  svg > path {
    fill: ${CRIMSON};
  }
`;
