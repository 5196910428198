import ResponseThankYou from 'assets/icons/ResponseThankYou';
import ResponseError from 'assets/icons/ResponseError';

export const THANK_YOU_CONFIG = {
  title: 'Success!',
  descriptionText: 'Public resources have been successfully deleted.',
  SVG: <ResponseThankYou />,
};

export const ERROR_CONFIG = {
  title: 'Something went wrong',
  descriptionText:
    'The server encountered a temporary error and could not complete your request. Please go back to admin panel or try again.',
  SVG: <ResponseError />,
};
