import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import AlertDanger from 'components/common/Alert/Alert';
import Spacer from 'components/common/Spacer/Spacer.styled';
import {
  Wrapper,
  Logo,
  Title,
  FormContainer,
  FormBottomContainer,
  AlreadyHaveDontHave,
  SignUpInButton,
  ForgotPasswordContainer,
  ForgotPasswordText,
  FormRowContainer,
} from 'components/Forms/SignForm/SignForm.styled';
import {
  SIGN_IN,
  SIGN_UP,
  SIGN_UP_ALREADY_HAVE,
  SIGN_IN_DONT_HAVE,
  SIGN_UP_FIELD_NAME_KEYS,
  SIGN_IN_FIELD_NAME_KEYS,
  SUBMIT_BUTTON_CSS,
  INITIAL_ALERT_STATE,
} from 'components/Forms/SignForm/constants';
import { PrimaryButton } from 'components/common/Button/Button.styled';
import Modal from 'components/common/Modal/Modal';
import {
  SIGN_IN_FORM_INITIAL_STATE,
  SIGN_UP_FORM_INITIAL_STATE,
  SUBMIT_FUNCS,
} from 'components/Forms/SignForm/schemas';
import { useAppContext } from 'contexts/AppContext';
import FormInput from 'components/common/FormComponents/Input/FormInput/FormInput';
import repackState, {
  toggleTextPasswordTypes,
} from 'components/Forms/SignForm/utils';
import useFormInput from 'components/common/FormComponents/Input/FormInput/useFormInput';
import Recaptcha from 'components/common/Recaptcha/Recaptcha';
import PasswordEyeToggle from 'components/common/PasswordEyeToggle/PasswordEyeToggle';
import WelcomeScreen from './WelcomeScreen/WelcomeScreen';
import { FORGOT_PASSWORD } from '../ForgotPassword/constants';

const SignForm = ({ activeForm, setActiveForm }) => {
  const { addUser } = useAppContext();

  const [captchaState, setCaptchaState] = useState(
    activeForm === SIGN_IN || false,
  );

  const closeForm = () => setActiveForm(null);

  const [alert, setAlert] = useState(INITIAL_ALERT_STATE);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);

  const [formState, setFormState] = useState({
    ...(activeForm === SIGN_IN
      ? SIGN_IN_FORM_INITIAL_STATE
      : SIGN_UP_FORM_INITIAL_STATE),
  });

  const keys =
    activeForm === SIGN_IN
      ? Object.values(SIGN_IN_FIELD_NAME_KEYS)
      : Object.values(SIGN_UP_FIELD_NAME_KEYS);

  const { onChangeHandler, onBlurHandler, triggerError } = useFormInput();

  const handleEyeToggle = () =>
    setFormState((prevState) => ({
      ...prevState,
      [SIGN_IN_FIELD_NAME_KEYS.PASSWORD]: {
        ...prevState[SIGN_IN_FIELD_NAME_KEYS.PASSWORD],
        inputType: toggleTextPasswordTypes(
          prevState[SIGN_IN_FIELD_NAME_KEYS.PASSWORD].inputType,
        ),
      },
    }));

  const submitHandler = async (e) => {
    e.preventDefault();

    const fieldWithErrors = keys.filter(
      (key) => !formState[key].validator(formState[key].value),
    );

    fieldWithErrors.forEach((key) => triggerError(setFormState, key));

    if (fieldWithErrors.length) {
      setAlert(INITIAL_ALERT_STATE);
      return;
    }

    try {
      const { data } = await SUBMIT_FUNCS[activeForm](
        repackState(formState, keys),
      );

      addUser({
        ...data,
      });

      if (activeForm === SIGN_UP) {
        setShowWelcomeScreen(true);

        return;
      }

      setActiveForm(null);
    } catch (error) {
      setAlert({
        shouldLoadAlert: true,
        alertText: error.response.data.message,
      });
    }
  };

  useEffect(() => {
    let timeout;

    if (showWelcomeScreen)
      timeout = setTimeout(() => setActiveForm(null), 3000);

    return () => clearTimeout(timeout);
  }, [showWelcomeScreen]);

  return showWelcomeScreen ? (
    <WelcomeScreen />
  ) : (
    <Modal closeForm={closeForm}>
      <Wrapper>
        <Logo>HBSBuilds</Logo>
        <Title>Welcome to HBSBuilds!</Title>
        {alert.shouldLoadAlert ? (
          <AlertDanger alertText={alert.alertText} />
        ) : (
          <Spacer Height="40px" Margin="0px 0px 10px 0px" />
        )}
        <FormContainer onSubmit={submitHandler}>
          {keys.map((key) => (
            <FormRowContainer key={key}>
              <FormInput
                {...formState[key]}
                key={key}
                name={key}
                onChangeHandler={onChangeHandler(setFormState, key)}
                onBlurHandler={onBlurHandler(setFormState, key)}
              />
              {key === SIGN_IN_FIELD_NAME_KEYS.PASSWORD && (
                <PasswordEyeToggle
                  inputType={
                    formState[SIGN_IN_FIELD_NAME_KEYS.PASSWORD].inputType
                  }
                  isError={formState[SIGN_IN_FIELD_NAME_KEYS.PASSWORD].isError}
                  handleEyeToggle={handleEyeToggle}
                />
              )}
            </FormRowContainer>
          ))}
          {activeForm === SIGN_UP && (
            <Recaptcha setCaptchaState={setCaptchaState} />
          )}
          {activeForm === SIGN_IN && (
            <ForgotPasswordContainer>
              <ForgotPasswordText
                onClick={() => setActiveForm(FORGOT_PASSWORD)}
              >
                Forgot password?
              </ForgotPasswordText>
            </ForgotPasswordContainer>
          )}
          <Spacer Height="20px" />
          <FormBottomContainer>
            <PrimaryButton {...SUBMIT_BUTTON_CSS} disabled={!captchaState}>
              {activeForm}
            </PrimaryButton>

            <AlreadyHaveDontHave>
              <span>
                {activeForm === SIGN_IN
                  ? SIGN_IN_DONT_HAVE
                  : SIGN_UP_ALREADY_HAVE}
                <SignUpInButton
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveForm(activeForm === SIGN_IN ? SIGN_UP : SIGN_IN);
                  }}
                >
                  {activeForm === SIGN_IN ? SIGN_UP : SIGN_IN}
                </SignUpInButton>
              </span>
            </AlreadyHaveDontHave>
          </FormBottomContainer>
        </FormContainer>
      </Wrapper>
    </Modal>
  );
};

SignForm.propTypes = {
  activeForm: PropTypes.string.isRequired,
  setActiveForm: PropTypes.func.isRequired,
};

export default SignForm;
