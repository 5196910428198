import { useEffect, useState } from 'react';
import useRequest from 'hooks/useRequest';
import { getStatisticsByPeriod } from 'services/requests';
import PropTypes from 'prop-types';
import StatusBox from '../../../components/StatusBox/StatusBox';
import CompanyStatisticsContainer from './CompanyStatistics.styled';
import {
  statisticsProperties,
  STATISTICS_KEYS,
  INITIAL_STATISTICS,
  STATISTICS_PARAMS,
  NUM_DAYS_FOR_STATISTICS,
} from './constants';
import useParamsProps from '../CompanyFilters/useCompanyParams/propTypes';
import { COMPANY_STATISTIC_PARAMS } from '../CompanyFilters/constants';

const CompanyStatistics = ({
  setStatisticParam,
  resetFilters,
  companyQueryParams,
}) => {
  const [statistics, setStatistics] = useState(INITIAL_STATISTICS);

  const { request } = useRequest();

  const setQueryParams = (statisticKey) => {
    Object.entries(STATISTICS_PARAMS[statisticKey]).forEach(
      ([queryParam, queryCondition]) =>
        setStatisticParam(queryParam, queryCondition),
    );
  };

  const isCardActive = (statisticKey) => {
    if (statisticKey === COMPANY_STATISTIC_PARAMS.CREATED) {
      return companyQueryParams.created && !companyQueryParams.hiring;
    }

    return !!companyQueryParams[statisticKey];
  };

  const handleOnClick = (statisticKey) => {
    resetFilters();

    if (!isCardActive(statisticKey)) {
      setQueryParams(statisticKey);
    }
  };

  useEffect(() => {
    let ignore = false;

    const fetchOptions = async () => {
      const response = await request(() =>
        getStatisticsByPeriod({ days: NUM_DAYS_FOR_STATISTICS }),
      );

      if (!ignore) {
        setStatistics(response.data);
      }
    };

    fetchOptions();

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <CompanyStatisticsContainer>
      {Object.values(STATISTICS_KEYS).map((key) => (
        <StatusBox
          key={key}
          onClick={() => handleOnClick(key)}
          svg={statisticsProperties[key].svg}
          value={statistics[key]}
          description={statisticsProperties[key].description}
          isActive={isCardActive(key)}
        />
      ))}
    </CompanyStatisticsContainer>
  );
};

CompanyStatistics.propTypes = {
  companyQueryParams: useParamsProps().isRequired,
  setStatisticParam: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
};

export default CompanyStatistics;
