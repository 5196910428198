const { useState } = require('react');

const useInvestorPreview = () => {
  const [previewInvestor, setPreviewInvestor] = useState(null);
  const closePopup = () => setPreviewInvestor(null);

  return { previewInvestor, closePopup, setPreviewInvestor };
};

export default useInvestorPreview;
