import PropTypes from 'prop-types';
import {
  ResponsePopUpWrapper,
  Title,
  DescriptionParagraph,
} from 'components/common/ResponsePopUp/ResponsePopUp.styled';

const ResponsePopUp = ({ title, descriptionText, SVG }) => (
  <ResponsePopUpWrapper>
    <Title>{title}</Title>
    <DescriptionParagraph>{descriptionText}</DescriptionParagraph>
    <DescriptionParagraph>{SVG}</DescriptionParagraph>
  </ResponsePopUpWrapper>
);

ResponsePopUp.propTypes = {
  title: PropTypes.string.isRequired,
  descriptionText: PropTypes.string.isRequired,
  SVG: PropTypes.element.isRequired,
};

export default ResponsePopUp;
