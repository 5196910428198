import ResponseError from 'assets/icons/ResponseError';
import ResponseThankYou from 'assets/icons/ResponseThankYou';
import CATEGORY_COLORS from 'constants/category_colors';

export const PUBLIC_KEYS = {
  CATEGORIES: 'categories',
  CONTACT_NAME: 'contactName',
  CONTACT_EMAIL: 'contactEmail',
  DEAL_DESCRIPTION: 'dealDescription',
  DEAL_IMAGE: 'dealImage',
  DEAL_NAME: 'dealName',
  DEAL_URL: 'dealUrl',
  TARGET_AUDIENCES: 'targetAudiences',
  EXPIRING_DATE: 'expiringDate',
};

export const BUTTONS_CSS = {
  primary: {
    Height: '40px',
    FontWeight: '600',
    FontSize: '14px',
    Width: '173px',
    Border: 'none',
    type: 'submit',
  },
  secondary: {
    Border: '1px solid #545556',
    Width: '83px',
    Height: '40px',
    FontWeight: '600',
    FontSize: '14px',
    type: 'button',
    noDark: true,
  },
};

export const PUBLIC_RESOURCE_CATEGORIES = {
  [CATEGORY_COLORS.BLUE]: ['Student Group / Club', 'Conference'],
  [CATEGORY_COLORS.SKY_BLUE]: ['VC with Campus Focus', 'Social Impact Fund'],
  [CATEGORY_COLORS.TURQUOISE]: [
    'VC with Student Representatives',
    'Community Groups',
  ],
  [CATEGORY_COLORS.GREEN]: ['University Run / Sponsored Program', 'I-Lab'],
  [CATEGORY_COLORS.YELLOW]: ['Accelerator / Incubator', 'Rock Center'],
  [CATEGORY_COLORS.WARM_YELLOW]: ['Hackathon'],
  [CATEGORY_COLORS.SALMON]: ['Pitch / Business Competition'],
  [CATEGORY_COLORS.LAVANDER]: ['Founder / Entrepreneurship Fellowship'],
  [CATEGORY_COLORS.PURPLE]: ['VC / Investor Fellowship'],
  [CATEGORY_COLORS.GRAY]: ['Grant'],
};

export const PUBLIC_RESOURCE_TARGET_AUDIENCES = {
  [CATEGORY_COLORS.BLUE]: ['All Current Students', 'Female Founders'],
  [CATEGORY_COLORS.SKY_BLUE]: ['MBA Students', 'MA Startups'],
  [CATEGORY_COLORS.TURQUOISE]: ['Undergrad', 'Grad Students'],
  [CATEGORY_COLORS.GREEN]: ['Alumni'],
  [CATEGORY_COLORS.YELLOW]: ['i-lab members'],
  [CATEGORY_COLORS.WARM_YELLOW]: ['Everyone'],
  [CATEGORY_COLORS.SALMON]: ['St. Louis Area'],
  [CATEGORY_COLORS.LAVANDER]: ['DEI Founders'],
  [CATEGORY_COLORS.PURPLE]: ['Black Founders'],
  [CATEGORY_COLORS.GRAY]: ['LatinX Founders'],
};

export const ERROR_CONFIG = {
  title: 'Something went wrong',
  descriptionText:
    'The server encountered a temporary error and could not complete your request. Please go back to homepage or try again.',
  SVG: <ResponseError />,
};

export const THANK_YOU_CONFIG = {
  title: 'Thank you!',
  descriptionText: 'Your resource has been updated',
  SVG: <ResponseThankYou />,
};
