/* eslint-disable react/prop-types */
import CheckBox from 'pages/AdminPage/components/AdminTable/AdditionalElements/CheckBox/CheckBox';
import CompanyControlCell from 'pages/AdminPage/TabControl/CompanyTab/CompanyTable/CompanyControlCell';
import { TDdescription } from 'pages/AdminPage/components/AdminTable/TableElements/TableElements.styled';

const COMPANY_COLUMNS = [
  {
    id: 'selection',
    width: '5%',
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <CheckBox {...getToggleAllRowsSelectedProps()} />
    ),
    Cell: ({ row }) => <CheckBox {...row.getToggleRowSelectedProps()} />,
  },
  {
    Header: 'Company Name',
    accessor: 'companyName',
    width: '15%',
  },
  {
    Header: 'Founding year',
    accessor: 'foundingYear',
    width: '10%',
  },
  {
    Header: 'Founder',
    accessor: 'founders',
    width: '17%',
    Cell: ({
      row: {
        original: { founders },
      },
    }) => (
      <TDdescription>
        {founders.filter((founder) => founder.position === 1)[0]?.fullName}
      </TDdescription>
    ),
  },
  {
    Header: 'Description',
    accessor: 'companyDescription',
    width: '28%',
    Cell: ({
      row: {
        original: { companyDescription },
      },
    }) => <TDdescription>{companyDescription}</TDdescription>,
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
    width: '18%',
    Cell: ({
      row: {
        original: { createdAt },
      },
    }) => (
      <TDdescription>
        {createdAt && new Date(createdAt).toLocaleDateString('en-US')}
      </TDdescription>
    ),
  },
  {
    id: 'controls',
    Header: '',
    width: '8%',
    Cell: ({
      row: {
        original: { id, deletedAt },
      },
    }) => <CompanyControlCell id={id} isDeleted={!!deletedAt} />,
  },
];

export default COMPANY_COLUMNS;
