export const DELETE_COMPANY = 'Delete company';

export const DELETE_COMPANY_STEPS = {
  PROMPT: 'PROMPT',
  RESPONSE: 'RESPONSE',
};

export const DELETE_COMPANY_KEYS = {
  COMPANY_IDS: 'companyIds',
};
