import { useAppContext } from 'contexts/AppContext';
import PlusIcon from 'assets/icons/PlusIcon';
import AddButton from 'pages/AdminPage/components/Filters/AddACompanyButton/AddACompanyButton.styled';
import { ADD_AN_INVESTOR } from 'components/Forms/Investor/constants';

const AddAInvestorButton = () => {
  const { setActiveForm } = useAppContext();

  return (
    <AddButton onClick={() => setActiveForm(ADD_AN_INVESTOR)}>
      <PlusIcon />
      <p>Add an investor</p>
    </AddButton>
  );
};

export default AddAInvestorButton;
