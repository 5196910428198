import BRANDS from 'constants/brands';
import { useEffect, useState } from 'react';
import {
  BrandName,
  BrandNameWrapper,
  BrandsWrapper,
} from './BrandsShowcase.styled';

const BrandsShowcase = () => {
  const [brandIndex, setBrandIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setBrandIndex((prevState) =>
        prevState === BRANDS.length - 1 ? 0 : prevState + 1,
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const [firstBrand, secondBrand] = BRANDS[brandIndex];
  return (
    <BrandsWrapper>
      <BrandNameWrapper>
        From <BrandName>{firstBrand}</BrandName>
      </BrandNameWrapper>
      <BrandNameWrapper>
        To <BrandName>{secondBrand}</BrandName>
      </BrandNameWrapper>
    </BrandsWrapper>
  );
};

export default BrandsShowcase;
