import { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/common/Modal/Modal';
import EntryModalAdd from 'components/Forms/Investor/EntryModal/EntryModalAdd';
import StepOne from 'components/Forms/Investor/StepOne/StepOne';
import StepTwo from 'components/Forms/Investor/StepTwo/StepTwo';
import Response from 'components/Forms/Investor/Response/Response';
import { createInvestor } from 'services/requests';
import { defaultInvestorFormState } from 'components/Forms/Investor/propTypes';

import { ADD_PROGRESS, STEPS } from 'components/Forms/Investor/constants';

const AddAnInvestor = ({ closeForm }) => {
  const [step, setStep] = useState(STEPS.ENTRY_MODAL);
  const [formState, setFormState] = useState({
    ...defaultInvestorFormState(),
  });

  return (
    <Modal closeForm={closeForm} progress={ADD_PROGRESS[step]}>
      {step === STEPS.ENTRY_MODAL && (
        <EntryModalAdd setStep={setStep} closeForm={closeForm} />
      )}
      {step === STEPS.STEP_ONE && (
        <StepOne
          nextStep={() => setStep(STEPS.STEP_TWO)}
          currentStep="1/2"
          closeForm={closeForm}
          formState={formState}
          setFormState={setFormState}
        />
      )}
      {step === STEPS.STEP_TWO && (
        <StepTwo
          backStep={() => setStep(STEPS.STEP_ONE)}
          nextStep={() => setStep(STEPS.RESPONSE)}
          currentStep="2/2"
          closeForm={closeForm}
          formState={formState}
          setFormState={setFormState}
        />
      )}
      {step === STEPS.RESPONSE && (
        <Response formState={formState} request={createInvestor} />
      )}
    </Modal>
  );
};

AddAnInvestor.propTypes = {
  closeForm: PropTypes.func.isRequired,
};

export default AddAnInvestor;
