import PropTypes from 'prop-types';
import {
  ArrowWrapper,
  PageNavigatorParagraph,
  PageNavigatorWrapper,
} from 'pages/AdminPage/components/AdminTable/AdditionalElements/Pagination/PageNavigator/PageNavigator.styled';
import PageNavigationBack from 'assets/icons/PageNavigationBack';
import PageNavigationForward from 'assets/icons/PageNavigationForward';

const PageNavigator = ({
  currentPage,
  limit,
  totalRows,
  onPreviousPage,
  onNextPage,
}) => (
  <PageNavigatorWrapper>
    <PageNavigatorParagraph>
      {currentPage + 1}-
      {currentPage + limit < totalRows ? currentPage + limit : totalRows} of{' '}
      {totalRows}
    </PageNavigatorParagraph>
    {currentPage !== 0 && (
      <ArrowWrapper onClick={onPreviousPage}>
        <PageNavigationBack />
      </ArrowWrapper>
    )}
    {currentPage + limit < totalRows && (
      <ArrowWrapper onClick={onNextPage}>
        <PageNavigationForward onClick={onNextPage} />
      </ArrowWrapper>
    )}
  </PageNavigatorWrapper>
);

PageNavigator.propTypes = {
  currentPage: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  totalRows: PropTypes.number.isRequired,
  onPreviousPage: PropTypes.func.isRequired,
  onNextPage: PropTypes.func.isRequired,
};

export default PageNavigator;
