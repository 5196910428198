import PropTypes from 'prop-types';
import {
  PopupContainer,
  PopupContentContainer,
  PopupTitle,
  PopupName,
  PopupContent,
  PopupColumn,
  PopupSection,
  PopupSubSectionName,
  PopupExternalLink,
  PopupCategory,
  PopupDescriptionParagraph,
  PopupButtonContainer,
  PopupDate,
  PopupDescriptionSection,
  PopupSectionParagraph,
  PopupTitleContainer,
} from 'components/common/Popup/Popup.styled';

import PopupHowToRedeem from 'pages/ResourcePage/components/ResourcePopup/ResourcePopup.styled';
import { PrimaryButton } from 'components/common/Button/Button.styled';
import {
  getDaysDiff,
  getDateFromIsoString,
  getCurrentDate,
  formatDate,
} from 'pages/ResourcePage/utils';
import { CRIMSON, RAISIN_BLACK } from 'constants/colors';
import Spacer from 'components/common/Spacer/Spacer.styled';
import { NOT_AVAILABLE } from 'pages/HomePage/components/CompaniesContainer/constants';
import validateURL from 'utils/linkValidator';
import isURL from 'validator/lib/isURL';
import {
  EXCLUSIVE_RESOURCE_CATEGORIES,
  PUBLIC_RESOURCE_CATEGORIES,
  PUBLIC_RESOURCE_TARGET_AUDIENCES,
} from 'pages/ResourcePage/constants';
import PopupMultiCategories from 'components/common/Popup/PopupMultiCategories';

const ResourcePopup = ({
  name,
  description,
  url,
  expiringDate,
  categories,
  targetAudiences,
  discountPricing,
  promotion,
  isExclusive,
  isUserFounder,
}) => {
  const handleGoToLink = (urlPromotion) =>
    window.open(validateURL(urlPromotion), '_blank');

  return (
    <PopupContainer>
      <PopupContentContainer>
        <PopupTitleContainer>
          <PopupTitle>Resource Information</PopupTitle>
          <PopupName>{name}</PopupName>
        </PopupTitleContainer>
        <PopupContent>
          <PopupColumn>
            <PopupSection>
              <PopupSubSectionName>Resource type</PopupSubSectionName>
              <PopupSectionParagraph>
                {`${!isExclusive ? 'Public' : 'Exclusive'} Deal`}
              </PopupSectionParagraph>
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Expiration Date</PopupSubSectionName>
              {expiringDate ? (
                <PopupDate
                  Color={
                    getDaysDiff(
                      getDateFromIsoString(expiringDate),
                      getCurrentDate(),
                    ) < 7
                      ? `${CRIMSON}`
                      : `${RAISIN_BLACK}`
                  }
                >
                  {formatDate(expiringDate)}
                </PopupDate>
              ) : (
                <PopupDate>{NOT_AVAILABLE}</PopupDate>
              )}
            </PopupSection>
            <PopupSection>
              {!isExclusive ? (
                <PopupHowToRedeem>How to Redeem</PopupHowToRedeem>
              ) : (
                <PopupSubSectionName>Company Url</PopupSubSectionName>
              )}
              {isURL(url) ? (
                <PopupExternalLink href={url} target="_blank" rel="noreferrer">
                  {url}
                </PopupExternalLink>
              ) : (
                <PopupSectionParagraph>
                  {url || NOT_AVAILABLE}
                </PopupSectionParagraph>
              )}
            </PopupSection>

            {!isExclusive ? null : (
              <PopupSection>
                <PopupHowToRedeem>How to Redeem</PopupHowToRedeem>
                {promotion && !promotion.includes('@') && isURL(promotion) ? (
                  <PopupExternalLink
                    href={promotion}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {promotion}
                  </PopupExternalLink>
                ) : (
                  <PopupSectionParagraph>
                    {promotion || NOT_AVAILABLE}
                  </PopupSectionParagraph>
                )}
              </PopupSection>
            )}
          </PopupColumn>
          <PopupColumn>
            <PopupSection>
              <PopupSubSectionName>Category</PopupSubSectionName>
              <PopupMultiCategories
                categories={categories}
                categoriesColors={
                  isExclusive
                    ? EXCLUSIVE_RESOURCE_CATEGORIES
                    : PUBLIC_RESOURCE_CATEGORIES
                }
              />
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>{`${
                !isExclusive ? 'Target Audience' : 'Deal Description'
              }`}</PopupSubSectionName>
              {!isExclusive ? (
                <PopupMultiCategories
                  categories={targetAudiences}
                  categoriesColors={PUBLIC_RESOURCE_TARGET_AUDIENCES}
                />
              ) : (
                <PopupCategory>
                  {discountPricing || NOT_AVAILABLE}
                </PopupCategory>
              )}
            </PopupSection>
          </PopupColumn>
        </PopupContent>
      </PopupContentContainer>
      <Spacer Height="15px" />
      <PopupDescriptionSection>
        <PopupSubSectionName>
          {isExclusive ? 'Company Description' : 'Deal description'}
        </PopupSubSectionName>
        <PopupDescriptionParagraph>
          {description || NOT_AVAILABLE}
        </PopupDescriptionParagraph>
      </PopupDescriptionSection>
      <PopupButtonContainer>
        {isURL(promotion) && (
          <PrimaryButton
            Width="145px"
            Height="40px"
            Border="none"
            disabled={!isUserFounder}
            onClick={() => handleGoToLink(promotion)}
          >
            Claim Resource
          </PrimaryButton>
        )}
        <Spacer Height="40px" />
      </PopupButtonContainer>
    </PopupContainer>
  );
};

ResourcePopup.defaultProps = {
  targetAudiences: [],
  discountPricing: '',
  expiringDate: null,
  url: '',
  description: null,
  promotion: '',
  isUserFounder: true,
};

ResourcePopup.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  url: PropTypes.string,
  expiringDate: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  targetAudiences: PropTypes.arrayOf(PropTypes.string),
  discountPricing: PropTypes.string,
  promotion: PropTypes.string,
  isExclusive: PropTypes.bool.isRequired,
  isUserFounder: PropTypes.bool,
};

export default ResourcePopup;
