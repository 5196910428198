import styled from 'styled-components';
import { PrimaryButton } from 'components/common/Button/Button.styled';
import DEVICE from 'constants/deviceSizes';

export const ButtonWrapper = styled.div`
  padding-top: 2%;
`;

export const AddResourceButton = styled(PrimaryButton)`
  border: none;
  height: 48px;
  width: 200px;
  font-size: 16px;
  font-weight: 600;

  @media ${DEVICE.laptopL} {
    font-size: 14px;
  }

  @media ${DEVICE.formResponsive} {
    font-size: 12px;
    padding: 8px 12px;
  }
`;
