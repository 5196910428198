import styled from 'styled-components';
import DEVICE from 'constants/deviceSizes';
import { CRIMSON, LIGHT_GRAY, RAISIN_BLACK } from 'constants/colors';

export const Wrapper = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 72px;
  width: 780px;

  @media ${DEVICE.tablet} {
    margin: auto;
    margin: 0 20px;
    width: 450px;
  }

  @media ${DEVICE.formResponsive} {
    width: 335px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Step = styled.p`
  color: ${CRIMSON};
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
  text-align: left;
`;

export const Title = styled.p`
  color: ${RAISIN_BLACK};
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  margin: 0;
  text-align: left;
`;

export const TitleParagraph = styled.p`
  color: ${RAISIN_BLACK};
  font-size: 14px;
  line-height: 21px;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

export const ClickableDiv = styled.div`
  align-items: center;
  background-color: ${LIGHT_GRAY};
  border: none;
  border-radius: 4px;
  display: flex;
  height: 40px;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-left: 12px;

  p {
    color: ${CRIMSON};
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 12px;
    margin: 0;
  }

  button {
    background: transparent;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const RowContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;

  @media ${DEVICE.formResponsive} {
    flex-direction: column;
    gap: 10px;
  }
`;

export const FieldContainer = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
`;

export const TooltipContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 5px;
  z-index: 10;
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  height: 142px;
  justify-content: flex-end;
  justify-self: flex-end;
  margin-bottom: 20px;

  @media ${DEVICE.formResponsive} {
    justify-content: center;
    margin-bottom: 10px;
  }
`;
