import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import {
  getExclusiveDealCategories,
  getExclusiveDeals,
} from 'services/requests';
import Modal from 'components/common/Modal/Modal';
import { REQUEST_DEBOUNCE_DURATION } from 'constants/input_debounce_duration';
import { useAppContext } from 'contexts/AppContext';
import { mapCategoriesToOptions } from 'components/Forms/Resources/StepTwo/utils';
import useExclusiveResourcesQueryParams from 'pages/AdminPage/TabControl/ResourceTabs/ExclusiveResourcesTab/useExclusiveResourcesParams';
import EXCLUSIVE_RESOURCES_PARAMS from 'pages/AdminPage/TabControl/ResourceTabs/ExclusiveResourcesTab/constants';
import { EXCLUSIVE_RESOURCE_CATEGORIES } from 'pages/AdminPage/TabControl/ResourceTabs/constants';
import FilterControls from 'components/common/Filters/FilterControls/FilterControls';
import ExclusiveResourcesFilters from 'pages/AdminPage/TabControl/ResourceTabs/ExclusiveResourcesTab/ExclusiveResourcesFilters';
import ResourcesContainer from 'pages/AdminPage/TabControl/ResourceTabs/Resources.styled';
import ResourcePopup from 'pages/AdminPage/TabControl/ResourceTabs/components/ResourcePopup/ResourcePopup';
import { mapExclusiveData } from 'pages/AdminPage/TabControl/ResourceTabs/utils';
import Spacer from 'components/common/Spacer/Spacer.styled';
import useAdminExclusiveResourceStore from 'store/exclusiveResource/useAdminExclusiveResourceStore';
import ExclusiveResourceTable from 'pages/AdminPage/TabControl/ResourceTabs/ExclusiveResourcesTab/ExclusiveResourceTable';

const ExclusiveResourcesTab = () => {
  const {
    exclusiveResourcesQueryParams,
    resetFilters,
    setCategory,
    setCompanyName,
    setDealDescription,
    setSortType,
    setPaginationOffset,
    setPaginationLimit,
  } = useExclusiveResourcesQueryParams();
  const [previewResource, setPreviewResource] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isFiltersCleared, setIsFiltersCleared] = useState(false);
  const [displayFilters, setDisplayFilters] = useState(false);
  const [fetching, setFetching] = useState(true);
  const exclusiveResources = useAdminExclusiveResourceStore(
    (state) => state.exclusiveResources,
  );
  const totalNumOfExclusiveResources = useAdminExclusiveResourceStore(
    (state) => state.totalNumOfExclusiveResources,
  );
  const setExclusiveResources = useAdminExclusiveResourceStore(
    (state) => state.setExclusiveResources,
  );
  const [isUserFounder, setIsUserFounder] = useState(false);

  const { user } = useAppContext();

  const { request } = useRequest();

  const closePopup = () => setPreviewResource(null);
  const handleRowClick = (resourceData) => setPreviewResource(resourceData);

  const isFiltersApplied = Boolean(
    exclusiveResourcesQueryParams[EXCLUSIVE_RESOURCES_PARAMS.COMPANY_NAME] ||
      exclusiveResourcesQueryParams[
        EXCLUSIVE_RESOURCES_PARAMS.DEAL_DESCRIPTION
      ] ||
      exclusiveResourcesQueryParams[EXCLUSIVE_RESOURCES_PARAMS.CATEGORY],
  );

  const clearFilters = () => {
    if (isFiltersApplied) {
      setFetching(true);
      resetFilters();
      setIsFiltersCleared(!isFiltersCleared);
    }
  };

  const handlePageClick = (value) => {
    setPaginationOffset(value);
    document.getElementById('scrollInto').scrollIntoView();
  };

  const handleItemsPerPageChange = ({ target: { value } }) => {
    setPaginationLimit(+value);
    document.getElementById('scrollInto').scrollIntoView();
  };

  useEffect(() => {
    let ignore = false;

    const fetchCategories = async () => {
      const fetchedCategories = await request(() =>
        getExclusiveDealCategories(),
      );

      if (!ignore && fetchedCategories)
        setCategories(
          mapCategoriesToOptions(
            fetchedCategories.data,
            EXCLUSIVE_RESOURCE_CATEGORIES,
          ),
        );
    };

    fetchCategories();

    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    let ignore = false;

    const fetchExclusiveResources = async () => {
      const fetchedResources = await request(() =>
        getExclusiveDeals(exclusiveResourcesQueryParams),
      );

      if (!ignore) {
        if (fetchedResources) {
          setExclusiveResources(
            fetchedResources.data.data,
            fetchedResources.data.total,
          );

          setIsUserFounder(fetchedResources.data.isAuthorized);
        }

        setFetching(false);
      }
    };

    if (!ignore) setFetching(true);
    const fetchExclusiveResourcesDebounced = setTimeout(
      fetchExclusiveResources,
      REQUEST_DEBOUNCE_DURATION,
    );

    return () => {
      ignore = true;

      clearTimeout(fetchExclusiveResourcesDebounced);
    };
  }, [request, exclusiveResourcesQueryParams, user]);

  return (
    <ResourcesContainer id="scrollInto">
      <Outlet />
      <FilterControls
        toggleFilters={() => setDisplayFilters(!displayFilters)}
        clearFilters={clearFilters}
      />
      {displayFilters && (
        <ExclusiveResourcesFilters
          exclusiveResourcesQueryParams={exclusiveResourcesQueryParams}
          companyName={
            exclusiveResourcesQueryParams[
              EXCLUSIVE_RESOURCES_PARAMS.COMPANY_NAME
            ]
          }
          dealDescription={
            exclusiveResourcesQueryParams[
              EXCLUSIVE_RESOURCES_PARAMS.DEAL_DESCRIPTION
            ]
          }
          sortByParam={
            exclusiveResourcesQueryParams[EXCLUSIVE_RESOURCES_PARAMS.SORT_BY]
          }
          orderByParam={
            exclusiveResourcesQueryParams[EXCLUSIVE_RESOURCES_PARAMS.ORDER_BY]
          }
          categories={categories}
          isFiltersCleared={isFiltersCleared}
          setDealDescription={setDealDescription}
          setCategory={setCategory}
          setSortType={setSortType}
          setCompanyName={setCompanyName}
        />
      )}
      <Spacer Height="10px" />
      {!!previewResource && (
        <Modal closeOnEscape closeForm={closePopup}>
          <ResourcePopup
            {...mapExclusiveData(previewResource)}
            isUserFounder={isUserFounder}
          />
        </Modal>
      )}
      <ExclusiveResourceTable
        exclusiveResourcesQueryParams={exclusiveResourcesQueryParams}
        handleRowClick={handleRowClick}
        handlePageClick={handlePageClick}
        handleItemsPerPageChange={handleItemsPerPageChange}
        fetching={fetching}
        totalNumOfExclusiveResources={totalNumOfExclusiveResources}
        exclusiveResources={exclusiveResources}
      />
    </ResourcesContainer>
  );
};
export default ExclusiveResourcesTab;
