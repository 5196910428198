export const preparePublicResourceData = (formState) => ({
  dealName: formState.dealName,
  dealUrl: formState.dealUrl,
  dealImage: formState.dealImage && formState.dealImage.base64,
  targetAudiences: formState.targetAudiences.map((category) => category.id),
  categories: formState.categories.map((category) => category.id),
  dealDescription: formState.dealDescription,
  contactName: formState.contactName,
  contactEmail: formState.contactEmail,
  expiringDate: formState.expiringDate
    ? formState.expiringDate.toISOString()
    : null,
});

export const prepareExclusiveResourceData = (formState) => ({
  companyName: formState.companyName,
  promotion: formState.promotion,
  companyWebsite: formState.companyUrl,
  companyLogo: formState.companyLogo && formState.companyLogo.base64,
  categories: formState.categories.map((category) => category.id),
  dealDescription: formState.dealDescription,
  companyDescription: formState.companyDescription,
  contactName: formState.contactName,
  contactEmail: formState.contactEmail,
  expiringDate: formState.expiringDate
    ? formState.expiringDate.toISOString()
    : null,
});
