import PropTypes from 'prop-types';
import {
  FounderCardContainer,
  FounderCardInfoContainer,
  FounderCardInfoParagraph,
  FounderName,
  FounderInfo,
  FounderJobInfo,
  FounderPhoto,
  FounderCardDivider,
  FounderLinks,
  LinkedInLink,
  HbsClasscardLink,
} from 'pages/AboutPage/FoundersSection/FounderCard/FounderCard.styled';
import LinkedInIcon from 'assets/icons/LinkedInIcon';

const FounderCard = ({ name, mba, jobs, personalLinks }) => (
  <FounderCardContainer>
    <FounderInfo>
      <FounderPhoto
        src={`${process.env.PUBLIC_URL}/static/about-page-images/${name}.jpeg`}
      />
      <FounderName>{name}</FounderName>
    </FounderInfo>
    <FounderCardInfoParagraph>{mba}</FounderCardInfoParagraph>
    <FounderCardDivider />
    <FounderCardInfoContainer>
      {jobs?.map((job) => (
        <FounderJobInfo key={job.position + job.companyName}>
          <FounderCardInfoParagraph>{job.position}</FounderCardInfoParagraph>
          <FounderCardInfoParagraph>
            @<a href={job.companyWebsite}>{job.companyName}</a>
          </FounderCardInfoParagraph>
        </FounderJobInfo>
      ))}
    </FounderCardInfoContainer>
    <FounderLinks>
      {personalLinks.linkedIn ? (
        <LinkedInLink href={personalLinks.linkedIn}>
          <LinkedInIcon />
        </LinkedInLink>
      ) : null}
      {personalLinks.classcardLink ? (
        <HbsClasscardLink href={personalLinks.classcardLink}>
          HBS
        </HbsClasscardLink>
      ) : null}
    </FounderLinks>
  </FounderCardContainer>
);

FounderCard.defaultProps = {
  jobs: null,
};

FounderCard.propTypes = {
  name: PropTypes.string.isRequired,
  mba: PropTypes.string.isRequired,
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.string,
      companyName: PropTypes.string,
      companyWebsite: PropTypes.string,
    }),
  ),
  personalLinks: PropTypes.shape({
    linkedIn: PropTypes.string,
    classcardLink: PropTypes.string,
  }).isRequired,
};

export default FounderCard;
