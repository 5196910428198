import PropTypes from 'prop-types';
import {
  LoadingSpinner,
  LoadingSpinnerContainer,
} from 'components/common/LoadingSpinner/LoadingSpinner.styled';
import ResourcesNotFound from 'pages/AdminPage/TabControl/ResourceTabs/components/ResourcesNotFound/ResourcesNotFound';
import AdminTable from 'pages/AdminPage/components/AdminTable/AdminTable';
import PUBLIC_RESOURCES_COLUMNS from 'pages/AdminPage/TabControl/ResourceTabs/PublicResourcesTab/PublicTable/publicResourceTableSchema';
import PublicResourceControlSelected from 'pages/AdminPage/TabControl/ResourceTabs/PublicResourcesTab/PublicTable/PublicResourceControlSelected';
import Pagination from 'pages/AdminPage/components/AdminTable/AdditionalElements/Pagination/Pagination';
import { LIMIT_OPTIONS } from 'pages/AdminPage/TabControl/constants';

const PublicResourceTable = ({
  publicResources,
  totalNumOfPublicResources,
  publicResourcesQueryParams,
  handlePageClick,
  handleItemsPerPageChange,
  fetching,
  handleRowClick,
}) => {
  if (fetching)
    return (
      <LoadingSpinnerContainer>
        <LoadingSpinner />
      </LoadingSpinnerContainer>
    );

  if (!publicResources.length) return <ResourcesNotFound />;

  return (
    <>
      <AdminTable
        columns={PUBLIC_RESOURCES_COLUMNS}
        data={publicResources}
        ControlSelected={PublicResourceControlSelected}
        handleRowClick={handleRowClick}
      />
      <Pagination
        totalRows={totalNumOfPublicResources}
        offset={publicResourcesQueryParams.offset}
        limit={publicResourcesQueryParams.limit}
        limitOptions={LIMIT_OPTIONS}
        handlePageClick={handlePageClick}
        handleItemsPerPageChange={handleItemsPerPageChange}
      />
    </>
  );
};

PublicResourceTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  publicResources: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalNumOfPublicResources: PropTypes.number.isRequired,
  publicResourcesQueryParams: PropTypes.shape({
    offset: PropTypes.number,
    limit: PropTypes.number,
  }).isRequired,
  handlePageClick: PropTypes.func.isRequired,
  handleItemsPerPageChange: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  handleRowClick: PropTypes.func.isRequired,
};

export default PublicResourceTable;
