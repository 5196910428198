import { useAppContext } from 'contexts/AppContext';
import {
  ADD_A_COMPANY,
  UPDATE_A_COMPANY,
} from 'components/Forms/Company/constants';
import AddCompany from 'components/Forms/Company/AddCompany';
import UpdateCompany from 'components/Forms/Company/UpdateCompany';
import UpdateEvent from 'components/Forms/Events/UpdateEvent';
import SignForm from 'components/Forms/SignForm/SignForm';
import { SIGN_IN, SIGN_UP } from 'components/Forms/SignForm/constants';
import AddResource from 'components/Forms/Resources/AddResources';
import { ADD_A_RESOURCE } from 'components/Forms/Resources/constants';
import { ADD_NEW_EVENT, UPDATE_EVENT } from 'components/Forms/Events/constants';
import { ADD_AN_INVESTOR } from 'components/Forms/Investor/constants';
import AddEvents from 'components/Forms/Events/AddEvents';
import AddAnInvestor from 'components/Forms/Investor/AddAnInvestor';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import { FORGOT_PASSWORD } from './ForgotPassword/constants';

const FormContainer = () => {
  const { activeForm, setActiveForm } = useAppContext();

  const closeForm = () => setActiveForm(null);

  return (
    !!activeForm && (
      <>
        {activeForm === ADD_A_COMPANY && (
          <AddCompany closeForm={closeForm} key={activeForm} />
        )}
        {activeForm === UPDATE_A_COMPANY && (
          <UpdateCompany closeForm={closeForm} />
        )}
        {activeForm === UPDATE_EVENT && <UpdateEvent closeForm={closeForm} />}
        {activeForm === ADD_A_RESOURCE && (
          <AddResource closeForm={closeForm} key={activeForm} />
        )}
        {activeForm === ADD_NEW_EVENT && (
          <AddEvents closeForm={closeForm} key={activeForm} />
        )}
        {activeForm === SIGN_IN && (
          <SignForm
            activeForm={SIGN_IN}
            setActiveForm={setActiveForm}
            key={activeForm}
          />
        )}
        {activeForm === SIGN_UP && (
          <SignForm
            activeForm={SIGN_UP}
            setActiveForm={setActiveForm}
            key={activeForm}
          />
        )}
        {activeForm === FORGOT_PASSWORD && (
          <ForgotPassword setActiveForm={setActiveForm} />
        )}
        {activeForm === ADD_AN_INVESTOR && (
          <AddAnInvestor closeForm={closeForm} key={activeForm} />
        )}
      </>
    )
  );
};

export default FormContainer;
