import {
  PUBLIC_RESOURCE_CATEGORIES,
  PUBLIC_RESOURCE_TARGET_AUDIENCES,
} from 'pages/AdminPage/TabControl/ResourceTabs/constants';

export const getOptionColor = (categoryName, list) => {
  const listOfKeys = Object.keys(list);
  const matchColor = listOfKeys.find((color) =>
    list[color].includes(categoryName),
  );

  return matchColor || null;
};

export const mapCategoriesToOptions = (iterableObject, categoryName) =>
  iterableObject.map((value) => ({
    id: value.id,
    label: value.name,
    bgColor: getOptionColor(value.name, categoryName),
  }));

export const defaultPublicFormStateProps = () => ({
  contactName: '',
  contactEmail: '',
  dealName: '',
  dealDescription: '',
  dealUrl: '',
  dealImage: null,
  isExclusive: false,
  targetAudiences: [],
  categories: [],
  expiringDate: null,
});

export const preparePublicResourceData = (formState) => ({
  dealName: formState.dealName,
  dealUrl: formState.dealUrl,
  dealImage: formState.dealImage && formState.dealImage.base64,
  targetAudiences: formState.targetAudiences.map((category) => category.id),
  categories: formState.categories.map((category) => category.id),
  dealDescription: formState.dealDescription,
  contactName: formState.contactName,
  contactEmail: formState.contactEmail,
  expiringDate: formState.expiringDate
    ? formState.expiringDate.toISOString()
    : null,
});

export const unpackPublicResource = (data) => ({
  id: data.id,
  dealName: data.name,
  dealUrl: data.dealUrl,
  dealImage: data.dealImage,
  targetAudiences: mapCategoriesToOptions(
    data.targetAudiences,
    PUBLIC_RESOURCE_TARGET_AUDIENCES,
  ),
  categories: mapCategoriesToOptions(
    data.categories,
    PUBLIC_RESOURCE_CATEGORIES,
  ),
  dealDescription: data.description,
  contactName: data.contactName ?? '',
  contactEmail: data.contactEmail ?? '',
  expiringDate: data.expiringDate ? new Date(data.expiringDate) : null,
});

export const packPublicResource = (data) => ({
  id: data.id,
  name: data.dealName,
  dealUrl: data.dealUrl,
  dealImage: data.dealImage,
  targetAudiences: data.targetAudiences.map((category) => category.label),
  categories: data.categories.map((category) => category.label),
  description: data.dealDescription,
  contactName: data.contactName,
  contactEmail: data.contactEmail,
  expiringDate: data.expiringDate ? data.expiringDate.toISOString() : null,
});
