export const CATEGORIES = {
  TEAM: 'Team',
  OPERATING_ADVISORY_BOARD: 'Operating Advisory Board',
  VC_ADVISORY_BOARD: 'VC Advisory Board',
  CLASS_REPS: 'Class Reps',
};

export const CLASS_REP_DESC = [
  'Class reps are an integral part of the HBSBuilds community, responsible for staying updated on the entrepreneurial endeavors of their classmates and being a liaison to their class for HBSBuilds events. If you’re an alum whose class is not yet represented and you are interested in becoming a class rep, please ',
  'reach out to us',
  '.',
];
