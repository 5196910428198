import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

const PaginationWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  width: 100%;

  @media ${DEVICE.formResponsive} {
    flex-direction: column;
  }
`;

export default PaginationWrapper;
