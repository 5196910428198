import STEP_ONE_KEYS from 'components/Forms/Company/StepOne/constants';
import {
  hbsEmailValidator,
  isEmptyFieldValidator,
  isUrlValid,
} from 'utils/inputValidators';

export const ContactInfoSchema = {
  [STEP_ONE_KEYS.CREATOR_FULL_NAME]: {
    label: 'Your full name*',
    inputType: 'text',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your full name',
    validator: isEmptyFieldValidator,
  },
  [STEP_ONE_KEYS.CREATOR_HBS_EMAIL]: {
    label: 'HBS email*',
    inputType: 'email',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your HBS email',
    validator: hbsEmailValidator,
  },
  [STEP_ONE_KEYS.CREATOR_HBS_CLASSCARD_LINK]: {
    label: 'HBS Classcard Link*',
    inputType: 'text',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your HBS Classcard Link',
    validator: isUrlValid,
  },
};

export const isHiringOptions = [
  {
    label: 'Yes',
    name: 'isHiring',
  },
  {
    label: 'No',
    name: 'isHiring',
  },
];
