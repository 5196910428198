import AnchorPartner from 'components/common/AnchorPartner/AnchorPartner';
import Dots from 'components/common/Dots/Dots';
import AnchorPartnerContainer from './AnchorPartnerSection.styled';

const AnchorPartnerSection = () => (
  <AnchorPartnerContainer>
    <Dots />
    <AnchorPartner />
  </AnchorPartnerContainer>
);

export default AnchorPartnerSection;
