import ResponseError from 'assets/icons/ResponseError';
import ResponseThankYou from 'assets/icons/ResponseThankYou';
import CATEGORY_COLORS from 'constants/category_colors';

export const EXCLUSIVE_KEYS = {
  CATEGORIES: 'categories',
  COMPANY_DESCRIPTION: 'companyDescription',
  COMPANY_LOGO: 'companyLogo',
  CONTACT_NAME: 'contactName',
  CONTACT_EMAIL: 'contactEmail',
  COMPANY_WEBSITE: 'companyUrl',
  COMPANY_NAME: 'companyName',
  DEAL_DESCRIPTION: 'dealDescription',
  EXPIRING_DATE: 'expiringDate',
  PROMOTION: 'promotion',
};

export const BUTTONS_CSS = {
  primary: {
    Height: '40px',
    FontWeight: '600',
    FontSize: '14px',
    Width: '173px',
    Border: 'none',
    type: 'submit',
  },
  secondary: {
    Border: '1px solid #545556',
    Width: '83px',
    Height: '40px',
    FontWeight: '600',
    FontSize: '14px',
    type: 'button',
    noDark: true,
  },
};

export const EXCLUSIVE_RESOURCE_CATEGORIES = {
  [CATEGORY_COLORS.BLUE]: ['Banking', 'Sales', 'Swag', 'Email API'],
  [CATEGORY_COLORS.SKY_BLUE]: [
    'Business Intelligence',
    'CRM',
    'Naming',
    'Cloud Communications',
  ],
  [CATEGORY_COLORS.TURQUOISE]: [
    'Chat',
    'HR & Payroll',
    'Analytics / Data Services',
    'Influence Marketing',
  ],
  [CATEGORY_COLORS.GREEN]: [
    'Cloud Services',
    'Legal',
    'Insurance',
    'Accounting and Finance',
  ],
  [CATEGORY_COLORS.YELLOW]: [
    'Design',
    'Financial Services',
    'Website Design',
    'PR',
  ],
  [CATEGORY_COLORS.WARM_YELLOW]: [
    'Security',
    'Housing/Coworking',
    'Website Building',
    'Coaching',
  ],
  [CATEGORY_COLORS.SALMON]: ['Software', 'Logistics', 'ATS', 'R&D Tax Credit'],
  [CATEGORY_COLORS.LAVANDER]: [
    'Finance',
    'Project/Workforce Management & Tools',
    'OKR Tracking',
    'Office Supplies',
  ],
  [CATEGORY_COLORS.PURPLE]: [
    'Marketing',
    'Mental Health',
    'Benchmarking',
    'Messaging Platform',
    'Payment Services',
  ],
  [CATEGORY_COLORS.GRAY]: [
    'Content Marketing',
    'Hiring / Recruiting',
    'Cap Table Management',
  ],
};

export const ERROR_CONFIG = {
  title: 'Something went wrong',
  descriptionText:
    'The server encountered a temporary error and could not complete your request. Please go back to homepage or try again.',
  SVG: <ResponseError />,
};

export const THANK_YOU_CONFIG = {
  title: 'Thank you!',
  descriptionText: 'Your resource has been updated',
  SVG: <ResponseThankYou />,
};
