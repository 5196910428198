export const STEP_TWO_KEYS = {
  CATEGORIES: 'categories',
  COMPANY_CARD_COLOR: 'companyCardColor',
  COMPANY_NAME: 'companyName',
  COMPANY_DESCRIPTION: 'companyDescription',
  COMPANY_STAGE: 'companyStage',
  COMPANY_WEBSITE: 'companyWebsite',
  FOUNDING_YEAR: 'foundingYear',
  FUNDING_STAGE: 'fundingStage',
  GEOGRAPHIC_FOCUS: 'geographicFocus',
};

export const TOOLTIP_CSS = {
  text: 'You can select multiple categories',
  Heigth: '50px',
  Width: '120px',
  Side: 'Top',
  LeftOffSet: '-90px',
  ArrowLeftOffSet: '95px',
};

export const BUTTONS_CSS = {
  secondary: {
    Border: '1px solid #545556',
    Width: '83px',
    Height: '40px',
    FontWeight: '600',
    FontSize: '14px',
    type: 'button',
    noDark: true,
  },
  primary: {
    Height: '40px',
    FontWeight: '600',
    FontSize: '14px',
    Width: '173px',
    Border: 'none',
    type: 'submit',
  },
};
