import styled from 'styled-components';

const Spacer = styled.div`
  margin: ${(props) => (props.Margin ? props.Margin : '0px')};
  padding: ${(props) => (props.Padding ? props.Padding : '0px')};
  min-width: ${(props) => (props.Width ? props.Width : '1px')};
  min-height: ${(props) => (props.Height ? props.Height : '1px')};
  flex: 0 1 auto;
`;

export default Spacer;
