import { useState, useEffect } from 'react';
import useRequest from 'hooks/useRequest';
import {
  getPublicResourceOptions,
  getPublicResources,
} from 'services/requests';
import Modal from 'components/common/Modal/Modal';
import ResourcePopup from 'pages/ResourcePage/components/ResourcePopup/ResourcePopup';
import ResourceCard from 'pages/ResourcePage/components/ResourceCard/ResourceCard';
import Carousel from 'components/common/Carousel/Carousel.styled';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { REQUEST_DEBOUNCE_DURATION } from 'constants/input_debounce_duration';
import {
  PUBLIC_RESOURCE_CATEGORIES,
  PUBLIC_RESOURCE_TARGET_AUDIENCES,
  RESOURCES_CAROUSEL_SETTINGS,
  STARTUP_GUIDE_SITE_LINK,
  KIRSCH_EC_GUIDE_LINK,
} from 'pages/ResourcePage/constants';
import { mapCategoriesToOptions } from 'components/Forms/Resources/StepTwo/utils';
import { mapPublicData } from 'pages/ResourcePage/utils';
import {
  LoadingSpinner,
  LoadingSpinnerContainer,
} from 'components/common/LoadingSpinner/LoadingSpinner.styled';
import WarnIcon from 'assets/icons/InputErrorIcon';
import usePublicResourcesQueryParams from './usePublicResourcesParams';
import FilterControls from '../FilterControls/FilterControls';
import PublicResourcesFilters from './components/PublicResourcesFilters/PublicResourcesFilters';
import {
  ResourcesContainer,
  ResourcesHeading,
  StartupLink,
} from '../../ResourcePage.styled';
import { PUBLIC_RESOURCES_PARAMS } from './constants';
import ResourcesNotFound from '../ResourcesNotFound/ResourcesNotFound';

const PublicResourcesContainer = () => {
  const {
    publicResourcesQueryParams,
    setCategory,
    setResourceName,
    setSortType,
    resetFilters,
    setTargetAudience,
  } = usePublicResourcesQueryParams();
  const [previewResource, setPreviewResource] = useState(null);
  const [categories, setCategories] = useState([]);
  const [targetAudiences, setTargetAudiences] = useState([]);
  const [isPublicFiltersCleared, setIsPublicFiltersCleared] = useState(false);
  const [displayFilters, setDisplayFilters] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [publicResourcesData, setPublicResourcesData] = useState({
    data: [],
    total: 0,
  });

  const { request } = useRequest();

  const closePopup = () => setPreviewResource(null);

  const isFiltersApplied = Boolean(
    publicResourcesQueryParams[PUBLIC_RESOURCES_PARAMS.RESOURCE_NAME] ||
      publicResourcesQueryParams[PUBLIC_RESOURCES_PARAMS.TARGET_AUDIENCE] ||
      publicResourcesQueryParams[PUBLIC_RESOURCES_PARAMS.CATEGORY],
  );

  const clearFilters = () => {
    if (isFiltersApplied) {
      setFetching(true);
      resetFilters();
      setIsPublicFiltersCleared(!isPublicFiltersCleared);
    }
  };

  useEffect(() => {
    let ignore = false;
    const fetchOptions = async () => {
      const allData = await request(() => getPublicResourceOptions());
      if (!ignore && allData) {
        setTargetAudiences(
          mapCategoriesToOptions(
            allData[0].data,
            PUBLIC_RESOURCE_TARGET_AUDIENCES,
          ),
        );
        setCategories(
          mapCategoriesToOptions(allData[1].data, PUBLIC_RESOURCE_CATEGORIES),
        );
      }
    };

    fetchOptions();

    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    let ignore = false;

    const fetchPublicResources = async () => {
      const fetchedResources = await request(() =>
        getPublicResources(publicResourcesQueryParams),
      );

      if (!ignore && fetchedResources) {
        setPublicResourcesData({
          data: fetchedResources.data.data,
          total: fetchedResources.data.total,
        });

        setFetching(false);
      }
    };

    if (!ignore) setFetching(true);

    const fetchPublicResourcesDebounced = setTimeout(
      fetchPublicResources,
      REQUEST_DEBOUNCE_DURATION,
    );

    return () => {
      ignore = true;

      clearTimeout(fetchPublicResourcesDebounced);
    };
  }, [request, publicResourcesQueryParams]);

  const getDisplay = () => {
    if (fetching)
      return (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      );

    if (!publicResourcesData.data.length) return <ResourcesNotFound />;

    return (
      <Carousel {...RESOURCES_CAROUSEL_SETTINGS}>
        {publicResourcesData.data.map((publicResource) => (
          <ResourceCard
            key={publicResource.dealName}
            {...mapPublicData(publicResource)}
            previewResourceDetails={() => setPreviewResource(publicResource)}
          />
        ))}
      </Carousel>
    );
  };

  return (
    <ResourcesContainer>
      <p>
        <WarnIcon />
        <strong> Please Note: </strong>
        This page is undergoing maintenance, and some resources and discounts
        may not be working. If you come across issues, please report them to{' '}
        <a href="mailto:wpsternlicht@gmail.com">Will</a> or{' '}
        <a href="mailto:mshah@mba2024.hbs.edu">Malay</a>. Please consider
        contributing your own discount codes / contacts if you have them! We
        appreciate your understanding and your help improving this community
        project.
      </p>
      <ResourcesHeading>
        Student Created Resources & Opportunities
      </ResourcesHeading>
      <StartupLink
        href={KIRSCH_EC_GUIDE_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        *Entrepreneurship for ECs Class Guide by Katie Kirsch MBA 2023
      </StartupLink>
      <ResourcesHeading>Marquee Resources & Opportunities</ResourcesHeading>
      <StartupLink
        href={STARTUP_GUIDE_SITE_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        *The Rock Center&apos;s Startup Guide
      </StartupLink>
      <ResourcesHeading>
        General Student & Alumni Opportunities & Resources (
        {publicResourcesData.total})
      </ResourcesHeading>
      <FilterControls
        toggleFilters={() => setDisplayFilters(!displayFilters)}
        clearFilters={clearFilters}
      />
      {displayFilters && (
        <PublicResourcesFilters
          publicResourcesQueryParams={publicResourcesQueryParams}
          categories={categories}
          orderByParam={
            publicResourcesQueryParams[PUBLIC_RESOURCES_PARAMS.ORDER_BY]
          }
          resourceName={
            publicResourcesQueryParams[PUBLIC_RESOURCES_PARAMS.RESOURCE_NAME]
          }
          sortByParam={
            publicResourcesQueryParams[PUBLIC_RESOURCES_PARAMS.SORT_BY]
          }
          setCategory={setCategory}
          setResourceName={setResourceName}
          setSortType={setSortType}
          setTargetAudience={setTargetAudience}
          targetAudiences={targetAudiences}
          isFiltersCleared={isPublicFiltersCleared}
        />
      )}
      {!!previewResource && (
        <Modal closeOnEscape closeForm={closePopup}>
          <ResourcePopup {...mapPublicData(previewResource)} />
        </Modal>
      )}
      {getDisplay()}
    </ResourcesContainer>
  );
};
export default PublicResourcesContainer;
