import { useReducer } from 'react';
import {
  publicResourceStateReducer,
  changeFieldValue,
} from 'pages/AdminPage/components/Forms/PublicResource/EditPublicResource/hooks/publicResourceStateReducer';
import {
  publicResourceInputSchema,
  publicResourceSelectSchema,
  publicResourceDescriptionSchema,
} from 'pages/AdminPage/components/Forms/PublicResource/EditPublicResource/schemas';
import usePublicResourceValidationErrors from './usePublicResourceValidationErrors';

const usePublicResourceState = (initialPublicResourceData) => {
  const [publicResourceData, dispatch] = useReducer(
    publicResourceStateReducer,
    {
      ...initialPublicResourceData,
    },
  );

  const { validationErrors, setValidationError } =
    usePublicResourceValidationErrors();

  const inputOnChangeHandler =
    (key) =>
    ({ target: { value } }) => {
      setValidationError(
        key,
        validationErrors[key] &&
          !publicResourceInputSchema[key].validator(value),
      );
      dispatch(changeFieldValue(key, value));
    };

  const inputOnBlurHandler =
    (key) =>
    ({ target: { value } }) => {
      dispatch(changeFieldValue(key, value));

      setValidationError(key, !publicResourceInputSchema[key].validator(value));
    };

  const descriptionOnChangeHandler =
    (key) =>
    ({ target: { value } }) => {
      setValidationError(
        key,
        validationErrors[key] &&
          !publicResourceDescriptionSchema[key].validator(value),
      );
      dispatch(changeFieldValue(key, value));
    };

  const descriptionOnBlurHandler =
    (key) =>
    ({ target: { value } }) => {
      dispatch(changeFieldValue(key, value));

      setValidationError(
        key,
        !publicResourceDescriptionSchema[key].validator(value),
      );
    };

  const multiSelectionHandler = (key, collection) => (options) => {
    dispatch(
      changeFieldValue(
        key,
        options &&
          options.map((option) =>
            collection.find((object) => object.id === option.id),
          ),
      ),
    );

    setValidationError(
      key,
      !publicResourceSelectSchema[key].validator(options),
    );
  };

  const selectDateHandler = (key) => (date) =>
    dispatch(changeFieldValue(key, date));

  const setUploadedFile = (key) => (data) =>
    dispatch(changeFieldValue(key, data));

  return {
    publicResourceData,
    validationErrors,
    inputOnChangeHandler,
    inputOnBlurHandler,
    descriptionOnBlurHandler,
    descriptionOnChangeHandler,
    multiSelectionHandler,
    selectDateHandler,
    setUploadedFile,
    setValidationError,
  };
};

export default usePublicResourceState;
