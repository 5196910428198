import {
  CRIMSON,
  DARK_CHARCOAL,
  DAVY_GRAY,
  RAISIN_BLACK_ALPHA_03,
  WHITE,
} from 'constants/colors';
import styled from 'styled-components';

export const DatePickerContainer = styled.div`
  background-color: ${WHITE};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: ${WHITE};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: ${(props) => props.Width};
  min-width: ${(props) => props.Width};

  .react-datepicker {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.12);
    border: none;
    display: ${(props) => props.showTime && 'flex'};
  }
  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-weight: 500;
    color: #000;
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .react-datepicker__header {
    text-align: center;
    border: none;
    background-color: #fff;
    padding: 8px 0;
    position: relative;
    font-size: 14px;
  }
  .react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .react-datepicker__header--time:not(.react-datepicker__header--time--only) {
    border-top-left-radius: 0;
  }
  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-top-right-radius: 0.3rem;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: ${CRIMSON};
    color: #fff;
  }
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    background-color: ${CRIMSON};
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background-color: ${CRIMSON};
    color: #fff;
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background-color: ${CRIMSON};
  }
  .react-datepicker__tab-loop {
    position: relative;
  }
  .react-datepicker-popper {
    width: 100%;
    position: absolute;
  }
  .react-datepicker__day--selected::after {
    content: '.';
    font-size: 24px;
    position: absolute;
    display: block;
    margin: -23px 0px;
    width: 27px;
  }
  .react-datepicker,
  .react-datepicker__month-container {
    width: ${(props) => props.Width};
    font-family: Poppins;
    margin-bottom: 10px;
  }
  .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
    justify-content: space-evenly;
    font-size: 12px;
  }
  .react-datepicker__navigation {
    margin-top: 15px;
  }
  .react-datepicker__navigation--previous {
    left: ${(props) => (props.showTime ? 'none' : '6%')};
  }
  .react-datepicker__navigation--next {
    right: ${(props) => (props.showTime ? 'none' : '6%')};
  }
  .react-datepicker__navigation-icon::before {
    border-color: ${DARK_CHARCOAL};
    top: 4px;
    border-width: 2px 2px 0 0;
    height: 6px;
    width: 6px;
  }

  .react-datepicker__close-icon {
    top: 25%;
    right: 7px;
    width: 16px;
    height: 16px;
  }
  .react-datepicker__close-icon::after {
    right: 0;
    background-color: ${WHITE};
    color: ${DAVY_GRAY};
    font-size: 14px;
    content: 'x';
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${CRIMSON};
  }
`;

export const DatePickerLabel = styled.label`
  color: ${DARK_CHARCOAL};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 10px;
`;

export const InputFieldWrapper = styled.div`
  align-items: center;
  background: none;
  border: 1px solid
    ${(props) => (props.isError ? CRIMSON : RAISIN_BLACK_ALPHA_03)};
  border-radius: 4px;
  display: flex;
  padding: 0 12px;
  gap: 10px;
  height: 42px;
  position: relative;
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const DateText = styled.p`
  color: ${DAVY_GRAY};
  font-size: 14px;
  margin: 0;
`;

export const CalendarIconContainer = styled.div`
  display: flex;
  justify-content: center;
  justify-self: flex-end;
  align-items: center;
  top: -2px;
  position: relative;
`;

export const ErrorMessage = styled.p`
  color: ${CRIMSON};
  font-size: 10px;
  line-height: 24px;
  margin: 0;
  top: 0;
  position: relative;
`;
