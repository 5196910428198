import {
  hbsEmailValidator,
  isEmptyFieldValidator,
  isEmptyOrValidEmail,
  isPhoneEmptyOrValid,
  isNumeric,
  isUrlValid,
} from 'utils/inputValidators';

import STEP_THREE_KEYS from 'components/Forms/Company/StepThree/constants';

const FOUNDER_INITIAL_STATE = {
  [STEP_THREE_KEYS.FULL_NAME]: {
    label: 'Full name*',
    inputType: 'text',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your full name',
    validator: isEmptyFieldValidator,
  },
  [STEP_THREE_KEYS.EMAIL]: {
    label: 'Email*',
    inputType: 'email',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter a valid email',
    validator: hbsEmailValidator,
  },
  [STEP_THREE_KEYS.ALTERNATIVE_EMAIL]: {
    label: 'Public Email (Preferred email of communication)',
    inputType: 'email',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter a valid email',
    validator: (value) => isEmptyOrValidEmail(value),
  },
  [STEP_THREE_KEYS.DISPLAY_ALTERNATIVE_EMAIL]: {
    label: 'E-mail will be visible to all hbs founders',
    checked: false,
    disabledDarkMode: true,
    validator: () => true,
  },
  [STEP_THREE_KEYS.FOUNDER_PHONE]: {
    label: 'Contact Phone',
    inputType: 'text',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Phone number can contain only numbers',
    validator: (value) => isPhoneEmptyOrValid(value),
  },
  [STEP_THREE_KEYS.DISPLAY_FOUNDER_PHONE]: {
    label: 'Contact phone will be visible to all hbs founders',
    checked: false,
    disabledDarkMode: true,
    validator: () => true,
  },
  [STEP_THREE_KEYS.LINKEDIN]: {
    label: 'LinkedIn account*',
    inputType: 'text',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter a LinkedIn account link',
    validator: isUrlValid,
  },
  [STEP_THREE_KEYS.CLASS_YEAR]: {
    label: 'Class year*',
    placeHolder: 'Select a year',
    MaxHeight: '110px',
    isError: false,
    selectedValue: null,
    validator: isNumeric,
  },
};

export const FoundersSelectSchema = {
  [STEP_THREE_KEYS.FOUNDER_ATTRIBUTES]: {
    label: 'Founder(s) attributes',
    placeHolder: 'Pick up to 3 attributes',
    MaxHeight: '170px',
    isError: false,
    disabledDarkMode: true,
    validator: () => true,
  },
};

export default FOUNDER_INITIAL_STATE;
