import PropTypes from 'prop-types';
import {
  Dot,
  DotsContainer,
  DotsRow,
} from 'components/common/Dots/Dots.styled';

const Dots = ({ rows, cols }) => (
  <DotsContainer>
    {[...Array(rows).keys()].map((e) => (
      <DotsRow key={e}>
        {[...Array(cols).keys()].map((el, index) => (
          <Dot key={`${el * 16 + index}`} />
        ))}
      </DotsRow>
    ))}
  </DotsContainer>
);

Dots.defaultProps = {
  rows: 16,
  cols: 16,
};

Dots.propTypes = {
  rows: PropTypes.number,
  cols: PropTypes.number,
};

export default Dots;
