import PropTypes from 'prop-types';
import { Button } from 'components/common/Button/Button.styled';
import {
  CompanyCardContainer,
  IsHiringWrapper,
  CompanyName,
  CompanyDetails,
  CompanyCategories,
  SingleCategory,
  CompanyDetailsTitle,
  StageContainer,
  CompanyDescription,
  FounderInitials,
  WebsiteLink,
  CompanyCardDivider,
  Stage,
  NotAvailableParagraph,
} from 'pages/HomePage/components/CompaniesContainer/CompanyCard/CompanyCard.styled';
import getCategoryColor, { getInitials } from 'pages/HomePage/utils';
import {
  CATEGORIES,
  OPERATING_STAGE,
  FUNDING_STAGE,
} from 'pages/HomePage/constants';
import HiringIcon from 'assets/icons/HiringIcon';
import validateURL from 'utils/linkValidator';
import { NOT_AVAILABLE } from '../constants';

const CompanyCard = ({
  id,
  companyName,
  categories,
  foundingYear,
  fundingStage,
  companyStage,
  creatorHbsClasscardLink,
  companyCardColor,
  companyDescription,
  founders,
  previewCompanyDetails,
  isHiring,
}) => (
  <CompanyCardContainer
    bgColor={companyCardColor}
    key={`${id}-${companyName}`}
    onClick={previewCompanyDetails}
  >
    {isHiring && (
      <IsHiringWrapper>
        <HiringIcon />
      </IsHiringWrapper>
    )}
    <CompanyName>{companyName}</CompanyName>
    <CompanyDetails>
      <CompanyCategories>
        {categories.map((category) => (
          <SingleCategory
            bgColor={getCategoryColor(category, CATEGORIES)}
            key={`${category}`}
          >
            {category}
          </SingleCategory>
        ))}
      </CompanyCategories>
      <CompanyDetailsTitle>
        {foundingYear ? `${foundingYear}, ` : null}
        {founders?.map((founder) => (
          <div key={`${founder.fullName}${founder.classYear}}`}>
            {founder.fullName}
          </div>
        ))}
      </CompanyDetailsTitle>
      <StageContainer>
        <span>Funding Stage</span>
        {fundingStage ? (
          <Stage stageColor={getCategoryColor(fundingStage, FUNDING_STAGE)}>
            {fundingStage}
          </Stage>
        ) : (
          <NotAvailableParagraph>{NOT_AVAILABLE}</NotAvailableParagraph>
        )}
      </StageContainer>
      <StageContainer>
        <span>Operating Stage</span>
        <Stage stageColor={getCategoryColor(companyStage, OPERATING_STAGE)}>
          {companyStage}
        </Stage>
      </StageContainer>
      <CompanyCardDivider />
      <CompanyDescription>{companyDescription}</CompanyDescription>
      {creatorHbsClasscardLink && (
        <WebsiteLink
          href={validateURL(creatorHbsClasscardLink)}
          target="_blank"
        >
          <Button
            Width="100%"
            Height="40px"
            Display="flex"
            JustifyContent="center"
            AlignItems="center"
            Border="none"
            BgColor={companyCardColor}
            Color="white"
            FontSize="12px"
            FontWeight="600"
            onClick={(e) => e.stopPropagation()}
          >
            <FounderInitials Color={companyCardColor}>
              {getInitials(
                founders.find((founder) => founder.position === 1)?.fullName ||
                  '',
              )}
            </FounderInitials>
            Contact founder
          </Button>
        </WebsiteLink>
      )}
    </CompanyDetails>
  </CompanyCardContainer>
);

CompanyCard.propTypes = {
  id: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  companyStage: PropTypes.string.isRequired,
  foundingYear: PropTypes.number,
  fundingStage: PropTypes.string,
  creatorHbsClasscardLink: PropTypes.string,
  companyCardColor: PropTypes.string.isRequired,
  companyDescription: PropTypes.string.isRequired,
  founders: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      classYear: PropTypes.number,
      position: PropTypes.number.isRequired,
    }),
  ),
  previewCompanyDetails: PropTypes.func.isRequired,
  isHiring: PropTypes.bool.isRequired,
};

CompanyCard.defaultProps = {
  creatorHbsClasscardLink: null,
  foundingYear: null,
  fundingStage: null,
  founders: {
    classYear: null,
  },
};

export default CompanyCard;
