import { EXCLUSIVE_RESOURCE_CATEGORIES } from 'pages/AdminPage/components/Forms/ExclusiveResource/EditExclusiveResource/constants';

export const getOptionColor = (categoryName, list) => {
  const listOfKeys = Object.keys(list);
  const matchColor = listOfKeys.find((color) =>
    list[color].includes(categoryName),
  );

  return matchColor || null;
};

export const mapCategoriesToOptions = (iterableObject, categoryName) =>
  iterableObject.map((value) => ({
    id: value.id,
    label: value.name,
    bgColor: getOptionColor(value.name, categoryName),
  }));

export const defaultExclusiveFormStateProps = () => ({
  companyName: '',
  promotion: '',
  companyUrl: '',
  companyLogo: null,
  dealDescription: '',
  companyDescription: '',
  contactName: '',
  contactEmail: '',
  categories: [],
  expiringDate: null,
});

export const prepareExclusiveResourceData = (formState) => ({
  companyName: formState.companyName,
  promotion: formState.promotion,
  companyWebsite: formState.companyUrl,
  companyLogo: formState.companyLogo && formState.companyLogo.base64,
  categories: formState.categories.map((category) => category.id),
  description: formState.dealDescription,
  companyDescription: formState.companyDescription,
  contactName: formState.contactName,
  contactEmail: formState.contactEmail,
  expiringDate: formState.expiringDate
    ? formState.expiringDate.toISOString()
    : null,
});

export const unpackExclusiveResource = (data) => ({
  id: data.id,
  companyName: data.companyName ?? '',
  promotion: data.promotion ?? '',
  companyUrl: data.companyWebsite ?? '',
  companyLogo: null,
  categories: mapCategoriesToOptions(
    data.categories,
    EXCLUSIVE_RESOURCE_CATEGORIES,
  ),
  dealDescription: data.description ?? '',
  companyDescription: data.companyDescription ?? '',
  contactName: data.contactName ?? '',
  contactEmail: data.contactEmail ?? '',
  expiringDate: data.expiringDate ? new Date(data.expiringDate) : null,
});

export const packExclusiveResource = (data) => ({
  id: data.id,
  companyName: data.companyName,
  promotion: data.promotion,
  companyWebsite: data.companyUrl,
  companyLogo: data.companyLogo,
  categories: data.categories.map((category) => category.label),
  description: data.dealDescription,
  companyDescription: data.companyDescription,
  contactName: data.contactName,
  contactEmail: data.contactEmail,
  expiringDate: data.expiringDate ? data.expiringDate.toISOString() : null,
});
