import {
  emailValidator,
  isEmptyFieldValidator,
  isUrlValid,
} from 'utils/inputValidators';
import { EXCLUSIVE_KEYS } from 'pages/AdminPage/components/Forms/ExclusiveResource/EditExclusiveResource/constants';

export const exclusiveResourceInputSchema = {
  [EXCLUSIVE_KEYS.COMPANY_NAME]: {
    label: 'Company name*',
    inputType: 'text',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your company name',
    validator: isEmptyFieldValidator,
  },
  [EXCLUSIVE_KEYS.COMPANY_WEBSITE]: {
    label: 'Company URL*',
    inputType: 'text',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: "Enter your company's URL",
    validator: isUrlValid,
  },
  [EXCLUSIVE_KEYS.CONTACT_NAME]: {
    label: 'Contact name*',
    inputType: 'text',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter contact name',
    validator: isEmptyFieldValidator,
  },
  [EXCLUSIVE_KEYS.CONTACT_EMAIL]: {
    label: 'Email address*',
    inputType: 'email',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter a valid email address',
    validator: emailValidator,
  },
  [EXCLUSIVE_KEYS.PROMOTION]: {
    label: 'Tailored link to promotion/Discount code/Email*',
    inputType: 'text',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: "Enter your company's website",
    validator: isEmptyFieldValidator,
  },
};

export const exclusiveResourceSelectSchema = {
  [EXCLUSIVE_KEYS.CATEGORIES]: {
    label: 'Category*',
    placeHolder: 'Select the category',
    MaxHeight: '170px',
    isError: false,
    validator: isEmptyFieldValidator,
  },
};

export const exclusiveResourceDescriptionSchema = {
  [EXCLUSIVE_KEYS.DEAL_DESCRIPTION]: {
    label: 'Describe the deal and how to redeem it*',
    placeholder: 'Enter your deal description...',
    warnMsg: 'Please enter description',
    validator: isEmptyFieldValidator,
    isError: false,
    disabledDarkMode: true,
    isDisabled: false,
    RowNum: 6,
  },
  [EXCLUSIVE_KEYS.COMPANY_DESCRIPTION]: {
    label: 'Company description*',
    placeholder: 'Enter your company description...',
    warnMsg: 'Please enter description',
    validator: isEmptyFieldValidator,
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    RowNum: 6,
  },
};
