import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import {
  getPublicResourceOptions,
  getPublicResources,
} from 'services/requests';
import Modal from 'components/common/Modal/Modal';
import { REQUEST_DEBOUNCE_DURATION } from 'constants/input_debounce_duration';
import { mapCategoriesToOptions } from 'components/Forms/Resources/StepTwo/utils';
import FilterControls from 'components/common/Filters/FilterControls/FilterControls';
import { mapPublicData } from 'pages/AdminPage/TabControl/ResourceTabs/utils';
import ResourcesContainer from 'pages/AdminPage/TabControl/ResourceTabs/Resources.styled';
import ResourcePopup from 'pages/AdminPage/TabControl/ResourceTabs/components/ResourcePopup/ResourcePopup';
import {
  PUBLIC_RESOURCE_CATEGORIES,
  PUBLIC_RESOURCE_TARGET_AUDIENCES,
} from 'pages/AdminPage/TabControl/ResourceTabs/constants';
import usePublicResourcesQueryParams from 'pages/AdminPage/TabControl/ResourceTabs/PublicResourcesTab/usePublicResourcesParams';
import PUBLIC_RESOURCES_PARAMS from 'pages/AdminPage/TabControl/ResourceTabs/PublicResourcesTab/constants';
import PublicResourcesFilters from 'pages/AdminPage/TabControl/ResourceTabs/PublicResourcesTab/PublicResourcesFilters';
import Spacer from 'components/common/Spacer/Spacer.styled';
import useAdminPublicResourceStore from 'store/publicResource/useAdminPublicResourceStore';
import PublicResourceTable from 'pages/AdminPage/TabControl/ResourceTabs/PublicResourcesTab/PublicResourceTable';

const PublicResourcesTab = () => {
  const {
    publicResourcesQueryParams,
    setCategory,
    setResourceName,
    setSortType,
    resetFilters,
    setTargetAudience,
    setPaginationLimit,
    setPaginationOffset,
  } = usePublicResourcesQueryParams();
  const [previewResource, setPreviewResource] = useState(null);
  const [categories, setCategories] = useState([]);
  const [targetAudiences, setTargetAudiences] = useState([]);
  const [isPublicFiltersCleared, setIsPublicFiltersCleared] = useState(false);
  const [displayFilters, setDisplayFilters] = useState(false);
  const [fetching, setFetching] = useState(true);
  const publicResources = useAdminPublicResourceStore(
    (state) => state.publicResources,
  );
  const totalNumOfPublicResources = useAdminPublicResourceStore(
    (state) => state.totalNumOfPublicResources,
  );
  const setPublicResources = useAdminPublicResourceStore(
    (state) => state.setPublicResources,
  );

  const { request } = useRequest();

  const closePopup = () => setPreviewResource(null);
  const handleRowClick = (resourceData) => setPreviewResource(resourceData);

  const isFiltersApplied = Boolean(
    publicResourcesQueryParams[PUBLIC_RESOURCES_PARAMS.RESOURCE_NAME] ||
      publicResourcesQueryParams[PUBLIC_RESOURCES_PARAMS.TARGET_AUDIENCE] ||
      publicResourcesQueryParams[PUBLIC_RESOURCES_PARAMS.CATEGORY],
  );

  const clearFilters = () => {
    if (isFiltersApplied) {
      setFetching(true);
      resetFilters();
      setIsPublicFiltersCleared(!isPublicFiltersCleared);
    }
  };

  const handlePageClick = (value) => {
    setPaginationOffset(value);
    document.getElementById('scrollInto').scrollIntoView();
  };

  const handleItemsPerPageChange = ({ target: { value } }) => {
    setPaginationLimit(+value);
    document.getElementById('scrollInto').scrollIntoView();
  };

  useEffect(() => {
    let ignore = false;
    const fetchOptions = async () => {
      const allData = await request(() => getPublicResourceOptions());
      if (!ignore && allData) {
        setTargetAudiences(
          mapCategoriesToOptions(
            allData[0].data,
            PUBLIC_RESOURCE_TARGET_AUDIENCES,
          ),
        );
        setCategories(
          mapCategoriesToOptions(allData[1].data, PUBLIC_RESOURCE_CATEGORIES),
        );
      }
    };

    fetchOptions();

    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    let ignore = false;

    const fetchPublicResources = async () => {
      const fetchedResources = await request(() =>
        getPublicResources(publicResourcesQueryParams),
      );

      if (!ignore && fetchedResources) {
        setPublicResources(
          fetchedResources.data.data,
          fetchedResources.data.total,
        );

        setFetching(false);
      }
    };

    if (!ignore) setFetching(true);

    const fetchPublicResourcesDebounced = setTimeout(
      fetchPublicResources,
      REQUEST_DEBOUNCE_DURATION,
    );

    return () => {
      ignore = true;

      clearTimeout(fetchPublicResourcesDebounced);
    };
  }, [request, publicResourcesQueryParams]);

  return (
    <ResourcesContainer id="scrollInto">
      <Outlet />
      <FilterControls
        toggleFilters={() => setDisplayFilters(!displayFilters)}
        clearFilters={clearFilters}
      />
      {displayFilters && (
        <PublicResourcesFilters
          publicResourcesQueryParams={publicResourcesQueryParams}
          categories={categories}
          orderByParam={
            publicResourcesQueryParams[PUBLIC_RESOURCES_PARAMS.ORDER_BY]
          }
          resourceName={
            publicResourcesQueryParams[PUBLIC_RESOURCES_PARAMS.RESOURCE_NAME]
          }
          sortByParam={
            publicResourcesQueryParams[PUBLIC_RESOURCES_PARAMS.SORT_BY]
          }
          setCategory={setCategory}
          setResourceName={setResourceName}
          setSortType={setSortType}
          setTargetAudience={setTargetAudience}
          targetAudiences={targetAudiences}
          isFiltersCleared={isPublicFiltersCleared}
        />
      )}
      <Spacer Height="10px" />
      {!!previewResource && (
        <Modal closeOnEscape closeForm={closePopup}>
          <ResourcePopup {...mapPublicData(previewResource)} />
        </Modal>
      )}
      <PublicResourceTable
        publicResources={publicResources}
        totalNumOfPublicResources={totalNumOfPublicResources}
        publicResourcesQueryParams={publicResourcesQueryParams}
        handlePageClick={handlePageClick}
        handleItemsPerPageChange={handleItemsPerPageChange}
        fetching={fetching}
        handleRowClick={handleRowClick}
      />
    </ResourcesContainer>
  );
};
export default PublicResourcesTab;
