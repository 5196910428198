import { PUBLIC_KEYS } from 'pages/AdminPage/components/Forms/PublicResource/EditPublicResource/constants';
import { useState } from 'react';

const INITIAL_PUBLIC_RESOURCE_VALIDATION_ERRORS = {
  [PUBLIC_KEYS.CONTACT_NAME]: false,
  [PUBLIC_KEYS.CONTACT_EMAIL]: false,
  [PUBLIC_KEYS.DEAL_URL]: false,
  [PUBLIC_KEYS.TARGET_AUDIENCES]: false,
  [PUBLIC_KEYS.CATEGORIES]: false,
  [PUBLIC_KEYS.DEAL_DESCRIPTION]: false,
  [PUBLIC_KEYS.DEAL_IMAGE]: false,
  [PUBLIC_KEYS.DEAL_NAME]: false,
  [PUBLIC_KEYS.EXPIRING_DATE]: false,
};

const usePublicResourceValidationErrors = () => {
  const [validationErrors, setValidationErrors] = useState(
    INITIAL_PUBLIC_RESOURCE_VALIDATION_ERRORS,
  );

  const setValidationError = (key, value) =>
    setValidationErrors((prevState) => ({ ...prevState, [key]: value }));

  return { validationErrors, setValidationError };
};

export default usePublicResourceValidationErrors;
