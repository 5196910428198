import PropTypes from 'prop-types';

const CloseIcon = ({ Size = '24px' }) => (
  <svg
    width={Size}
    height={Size}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
      fill="var(--WHITE_OR_RAISING_BLACK)"
    />
  </svg>
);

CloseIcon.defaultProps = {
  Size: '24',
};

CloseIcon.propTypes = {
  Size: PropTypes.string,
};

export default CloseIcon;
