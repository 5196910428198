import { useReducer } from 'react';
import {
  SORT_KEYS,
  INVESTOR_PARAMS,
  SORT_PARAMS,
  investorParamsActions,
} from 'pages/AdminPage/TabControl/InvestorTab/InvestorFilters/constants';
import {
  changeFilterOrPaginationParam,
  changeSortType,
  setInitialQueryParams,
} from './actions';

const initialQueryParams = {
  [INVESTOR_PARAMS.FULL_NAME]: '',
  [INVESTOR_PARAMS.CATEGORY]: null,
  [INVESTOR_PARAMS.CHECK_SIZE]: null,
  [INVESTOR_PARAMS.STAGE]: null,
  [INVESTOR_PARAMS.STATUS]: null,
  [INVESTOR_PARAMS.LIMIT]: 10,
  [INVESTOR_PARAMS.OFFSET]: 0,
  ...SORT_PARAMS[SORT_KEYS.NEWEST],
};

const investorQueryParamsReducer = (state, action) => {
  switch (action.type) {
    case investorParamsActions.SET_SORT_PARAM:
      return {
        ...state,
        ...action.payload.sortType,
      };

    case investorParamsActions.SET_FILTER_OR_PAGINATION_PARAM:
      return {
        ...state,
        [action.payload.field]: action.payload.data,
        [INVESTOR_PARAMS.OFFSET]:
          action.payload.field === INVESTOR_PARAMS.OFFSET
            ? action.payload.data
            : 0,
      };

    case investorParamsActions.SET_INITIAL_QUERY_PARAMS:
      return { ...initialQueryParams };

    default:
      throw new Error(`no such action: ${action.type}`);
  }
};

const useInvestorParams = () => {
  const [investorQueryParams, dispatch] = useReducer(
    investorQueryParamsReducer,
    {
      ...initialQueryParams,
    },
  );

  const setPaginationLimit = (limit) =>
    dispatch(changeFilterOrPaginationParam(INVESTOR_PARAMS.LIMIT, limit));

  const setPaginationOffset = (offset) =>
    dispatch(changeFilterOrPaginationParam(INVESTOR_PARAMS.OFFSET, offset));

  const setCategory = (option) =>
    dispatch(
      changeFilterOrPaginationParam(
        INVESTOR_PARAMS.CATEGORY,
        option ? option.id : null,
      ),
    );

  const setClassYear = (option) =>
    dispatch(
      changeFilterOrPaginationParam(
        INVESTOR_PARAMS.CLASS_YEAR,
        option ? option.id : null,
      ),
    );

  const setStage = (option) =>
    dispatch(
      changeFilterOrPaginationParam(
        INVESTOR_PARAMS.STAGE,
        option ? option.id : null,
      ),
    );

  const setStatus = (option) =>
    dispatch(
      changeFilterOrPaginationParam(
        INVESTOR_PARAMS.STATUS,
        option ? option.id : null,
      ),
    );

  const setCheckSize = (option) =>
    dispatch(
      changeFilterOrPaginationParam(
        INVESTOR_PARAMS.CHECK_SIZE,
        option ? option.id : null,
      ),
    );

  const setSortType = (type) => dispatch(changeSortType(type));

  const resetFilters = () => dispatch(setInitialQueryParams());

  return {
    investorQueryParams,
    setStatus,
    setCategory,
    setClassYear,
    setCheckSize,
    setStage,
    setSortType,
    setPaginationLimit,
    setPaginationOffset,
    resetFilters,
  };
};

export default useInvestorParams;
