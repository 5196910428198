import PropTypes from 'prop-types';
import {
  Wrapper,
  CheckBoxWrapper,
  CheckBox,
  CheckBoxLabel,
  Paragraph,
} from 'components/Header/ToggleSwitch/ToggleSwitch.styled';

const ToggleSwitch = ({ isDark, toggleSwitch }) => (
  <Wrapper>
    <Paragraph spacingTop>Light theme</Paragraph>
    <CheckBoxWrapper>
      <CheckBox
        id="checkbox"
        type="checkbox"
        onChange={toggleSwitch}
        checked={isDark}
      />
      <CheckBoxLabel htmlFor="checkbox" />
    </CheckBoxWrapper>
    <div>
      <Paragraph spacingTop>Dark theme</Paragraph>
      <Paragraph small>We&apos;ve got cookies :)</Paragraph>
    </div>
  </Wrapper>
);

ToggleSwitch.propTypes = {
  isDark: PropTypes.bool.isRequired,
  toggleSwitch: PropTypes.func.isRequired,
};

export default ToggleSwitch;
