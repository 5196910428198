import { useReducer } from 'react';
import {
  resourcesParamsActions,
  sortConfig,
} from 'pages/AdminPage/TabControl/ResourceTabs/constants';
import EXCLUSIVE_RESOURCES_PARAMS from 'pages/AdminPage/TabControl/ResourceTabs/ExclusiveResourcesTab/constants';
import {
  changeFilterOrPaginationParam,
  changeSortType,
  setInitialQueryParams,
} from '../actions';

const initialQueryParams = {
  ...sortConfig.NEWEST,
  [EXCLUSIVE_RESOURCES_PARAMS.COMPANY_NAME]: '',
  [EXCLUSIVE_RESOURCES_PARAMS.DEAL_DESCRIPTION]: '',
  [EXCLUSIVE_RESOURCES_PARAMS.CATEGORY]: null,
  [EXCLUSIVE_RESOURCES_PARAMS.LIMIT]: 10,
  [EXCLUSIVE_RESOURCES_PARAMS.OFFSET]: 0,
  [EXCLUSIVE_RESOURCES_PARAMS.WITH_DELETED]: true,
};

const exclusiveResourceParamsReducer = (state, action) => {
  switch (action.type) {
    case resourcesParamsActions.SET_FILTER_OR_PAGINATION_PARAM:
      return {
        ...state,
        [action.payload.field]: action.payload.data,
      };

    case resourcesParamsActions.SET_SORT_PARAM:
      return {
        ...state,
        ...sortConfig[action.payload.sortType],
      };

    case resourcesParamsActions.SET_INITIAL_QUERY_PARAMS:
      return { ...initialQueryParams };

    default:
      throw new Error(`no such action: ${action.type}`);
  }
};

const useExclusiveResourcesQueryParams = () => {
  const [exclusiveResourcesQueryParams, dispatch] = useReducer(
    exclusiveResourceParamsReducer,
    {
      ...initialQueryParams,
    },
  );

  const setCompanyName = ({ target: { value } }) =>
    dispatch(
      changeFilterOrPaginationParam(
        EXCLUSIVE_RESOURCES_PARAMS.COMPANY_NAME,
        value,
      ),
    );

  const setDealDescription = ({ target: { value } }) =>
    dispatch(
      changeFilterOrPaginationParam(
        EXCLUSIVE_RESOURCES_PARAMS.DEAL_DESCRIPTION,
        value,
      ),
    );

  const setCategory = (option) =>
    dispatch(
      changeFilterOrPaginationParam(
        EXCLUSIVE_RESOURCES_PARAMS.CATEGORY,
        option ? option.id : null,
      ),
    );

  const setSortType = (type) => dispatch(changeSortType(type));

  const setPaginationLimit = (limit) =>
    dispatch(
      changeFilterOrPaginationParam(EXCLUSIVE_RESOURCES_PARAMS.LIMIT, limit),
    );

  const setPaginationOffset = (offset) =>
    dispatch(
      changeFilterOrPaginationParam(EXCLUSIVE_RESOURCES_PARAMS.OFFSET, offset),
    );

  const resetFilters = () => dispatch(setInitialQueryParams());

  return {
    exclusiveResourcesQueryParams,
    setCompanyName,
    setDealDescription,
    setCategory,
    setSortType,
    setPaginationLimit,
    setPaginationOffset,
    resetFilters,
  };
};

export default useExclusiveResourcesQueryParams;
