const emptyStringToNull = (str) => (str === '' ? null : str);

const repackFormState = (formState) => ({
  creatorFullName: formState.creatorFullName,
  creatorHbsEmail: formState.creatorHbsEmail,
  creatorHbsClasscardLink: formState.creatorHbsClasscardLink,
  isHiring: formState.isHiring === 'Yes',
  companyName: formState.companyName,
  companyWebsite: emptyStringToNull(formState.companyWebsite),
  foundingYear: +formState.foundingYear.label,
  companyDescription: formState.companyDescription,
  fundingStageId: formState.fundingStage.id,
  companyStageId: formState.companyStage.id,
  companyCardColorId: formState.companyCardColor.id,
  founderAttributes: formState.founderAttributes.length
    ? formState.founderAttributes.map((founderAttribute) => founderAttribute.id)
    : null,
  geographicFocus: formState.geographicFocus.length
    ? formState.geographicFocus.map(
        (geographicLocation) => geographicLocation.id,
      )
    : null,
  categories: formState.categories.map((category) => category.id),
  founders: formState.founders.map((founder) => ({
    fullName: founder.fullName.value,
    classYear: +founder.classYear.selectedValue.label,
    founderEmail: founder.founderEmail.value || null,
    founderLinkedin: emptyStringToNull(founder.founderLinkedin.value),
    position: founder.position,
    alternativeEmail: founder.alternativeEmail.value || null,
    displayAlternativeEmail:
      !!founder.alternativeEmail.value &&
      founder.displayAlternativeEmail.checked,
    phone: founder.phone.value || null,
    displayPhone: !!founder.phone.value && founder.displayPhone.checked,
  })),
});

export const packFormState = (data) => ({
  creatorFullName: data.creatorFullName,
  creatorHbsEmail: data.creatorHbsEmail,
  creatorHbsClasscardLink: data.creatorHbsClasscardLink,
  isHiring: data.isHiring === 'Yes',
  companyName: data.companyName,
  companyWebsite: data.companyWebsite || '',
  foundingYear: +data.foundingYear.label,
  companyDescription: data.companyDescription,
  fundingStage: data.fundingStage.label,
  companyStage: data.companyStage.label,
  companyCardColor: data.companyCardColor.label,
  founderAttributes: data.founderAttributes.map(
    (founderAttribute) => founderAttribute.label,
  ),
  geographicFocus: data.geographicFocus.map(
    (geographicLocation) => geographicLocation.label,
  ),
  categories: data.categories.map((category) => category.label),
  founders: data.founders.map((founder) => ({
    fullName: founder.fullName.value,
    classYear: +founder.classYear.selectedValue.label,
    founderEmail: founder.founderEmail.value || null,
    founderLinkedin: emptyStringToNull(founder.founderLinkedin.value),
    position: founder.position,
    alternativeEmail: founder.alternativeEmail.value || null,
    displayAlternativeEmail:
      !!founder.alternativeEmail.value &&
      founder.displayAlternativeEmail.checked,
    phone: founder.phone.value || null,
    displayPhone: !!founder.phone.value && founder.displayPhone.checked,
  })),
});

export default repackFormState;
