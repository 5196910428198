import {
  resourcesParamsActions,
  sortConfig,
} from 'pages/ResourcePage/constants';
import { useReducer } from 'react';
import PUBLIC_RESOURCES_PARAMS from 'pages/AdminPage/TabControl/ResourceTabs/PublicResourcesTab/constants';
import {
  changeFilterOrPaginationParam,
  changeSortType,
  setInitialQueryParams,
} from 'pages/AdminPage/TabControl/ResourceTabs/actions';

const initialQueryParams = {
  [PUBLIC_RESOURCES_PARAMS.RESOURCE_NAME]: '',
  [PUBLIC_RESOURCES_PARAMS.CATEGORY]: null,
  [PUBLIC_RESOURCES_PARAMS.TARGET_AUDIENCE]: null,
  ...sortConfig.NEWEST,
  [PUBLIC_RESOURCES_PARAMS.LIMIT]: 10,
  [PUBLIC_RESOURCES_PARAMS.OFFSET]: 0,
  [PUBLIC_RESOURCES_PARAMS.WITH_DELETED]: true,
};

const publicResourcesQueryParamsReducer = (state, action) => {
  switch (action.type) {
    case resourcesParamsActions.SET_FILTER_OR_PAGINATION_PARAM:
      return {
        ...state,
        [action.payload.field]: action.payload.data,
      };

    case resourcesParamsActions.SET_SORT_PARAM:
      return {
        ...state,
        ...sortConfig[action.payload.sortType],
      };

    case resourcesParamsActions.SET_INITIAL_QUERY_PARAMS:
      return { ...initialQueryParams };

    default:
      throw new Error(`no such action: ${action.type}`);
  }
};

const usePublicResourcesQueryParams = () => {
  const [publicResourcesQueryParams, dispatch] = useReducer(
    publicResourcesQueryParamsReducer,
    {
      ...initialQueryParams,
    },
  );

  const setResourceName = ({ target: { value } }) =>
    dispatch(
      changeFilterOrPaginationParam(
        PUBLIC_RESOURCES_PARAMS.RESOURCE_NAME,
        value,
      ),
    );

  const setTargetAudience = (option) =>
    dispatch(
      changeFilterOrPaginationParam(
        PUBLIC_RESOURCES_PARAMS.TARGET_AUDIENCE,
        option ? option.id : null,
      ),
    );

  const setCategory = (option) =>
    dispatch(
      changeFilterOrPaginationParam(
        PUBLIC_RESOURCES_PARAMS.CATEGORY,
        option ? option.id : null,
      ),
    );

  const setSortType = (type) => dispatch(changeSortType(type));

  const setPaginationLimit = (limit) =>
    dispatch(
      changeFilterOrPaginationParam(PUBLIC_RESOURCES_PARAMS.LIMIT, limit),
    );

  const setPaginationOffset = (offset) =>
    dispatch(
      changeFilterOrPaginationParam(PUBLIC_RESOURCES_PARAMS.OFFSET, offset),
    );

  const resetFilters = () => dispatch(setInitialQueryParams());

  return {
    publicResourcesQueryParams,
    setResourceName,
    setTargetAudience,
    setCategory,
    setSortType,
    setPaginationLimit,
    setPaginationOffset,
    resetFilters,
  };
};

export default usePublicResourcesQueryParams;
