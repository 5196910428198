import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'components/common/Modal/Modal';
import ConfirmPrompt from 'pages/AdminPage/components/Forms/ExclusiveResource/DeleteExclusiveResource/ConfirmPrompt/ConfirmPrompt';
import Response from 'pages/AdminPage/components/Forms/ExclusiveResource/DeleteExclusiveResource/Response/Response';
import DELETE_EXCLUSIVE_RESOURCE_STEPS from 'pages/AdminPage/components/Forms/ExclusiveResource/DeleteExclusiveResource/constants';
import { deleteExclusiveResources } from 'services/requests';

const DeleteExclusiveResource = () => {
  const [activeForm, setActiveForm] = useState(
    DELETE_EXCLUSIVE_RESOURCE_STEPS.PROMPT,
  );
  const {
    state: { ids },
  } = useLocation();

  const navigate = useNavigate();
  const closeForm = () =>
    navigate({ pathname: '/administration/exclusive-resource' });

  return (
    <Modal closeForm={closeForm}>
      {activeForm === DELETE_EXCLUSIVE_RESOURCE_STEPS.PROMPT && (
        <ConfirmPrompt
          numberOfExclusiveResources={ids.length}
          closeForm={closeForm}
          nextStep={() =>
            setActiveForm(DELETE_EXCLUSIVE_RESOURCE_STEPS.RESPONSE)
          }
        />
      )}
      {activeForm === DELETE_EXCLUSIVE_RESOURCE_STEPS.RESPONSE && (
        <Response
          resourceIds={ids}
          req={deleteExclusiveResources}
          closeForm={closeForm}
        />
      )}
    </Modal>
  );
};
export default DeleteExclusiveResource;
