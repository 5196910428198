const SIZE = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  formResponsive: '520px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

const DEVICE = {
  mobileS: `(max-width: ${SIZE.mobileS})`,
  mobileM: `(max-width: ${SIZE.mobileM})`,
  mobileL: `(max-width: ${SIZE.mobileL})`,
  formResponsive: `(max-width: ${SIZE.formResponsive})`,
  tablet: `(max-width: ${SIZE.tablet})`,
  laptop: `(max-width: ${SIZE.laptop})`,
  laptopL: `(max-width: ${SIZE.laptopL})`,
  desktop: `(max-width: ${SIZE.desktop})`,
  desktopL: `(max-width: ${SIZE.desktop})`,
};

export default DEVICE;
