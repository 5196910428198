// eslint-disable-next-line import/prefer-default-export
export const publicResourcesFiltersSchema = {
  RESOURCE_NAME: {
    label: 'Resource name',
  },
  TARGET_AUDIENCE: {
    label: 'Target audience',
    placeHolder: 'Select',
  },
  CATEGORY: {
    label: 'Category',
    placeHolder: 'Select',
  },
  SORT: {
    sortFieldLabel: 'Sort',
    radioGroupLabel: 'Date or Alphabetical',
  },
};
