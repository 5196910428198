import PropTypes from 'prop-types';
import { LinkItUrl } from 'react-linkify-it';

import {
  ButtonsContainer,
  DescriptionContainer,
  DescriptionParagraph,
  Title,
  Wrapper,
} from 'components/Forms/Investor/EntryModal/EntryModal.styled';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import { EmailLink } from 'components/Footer/Footer.styled';

import BUTTONS_CSS from 'components/Forms/Company/EntryModal/constants';
import { STEPS } from 'components/Forms/Investor/constants';
import { STATIC_TEXT } from 'components/Forms/Investor/EntryModal/constants';
import { CONTACT_EMAIL } from 'constants/other';

const EntryModalAdd = ({ setStep, closeForm }) => {
  const nextStep = () => setStep(STEPS.STEP_ONE);

  return (
    <Wrapper>
      <DescriptionContainer>
        <Title>{STATIC_TEXT.START_TITLE}</Title>
        <LinkItUrl>
          <DescriptionParagraph>
            {STATIC_TEXT.START_DESCRIPTION}
          </DescriptionParagraph>
        </LinkItUrl>
        <DescriptionParagraph>
          {STATIC_TEXT.CONTACT_INFO}{' '}
          <EmailLink href={`mailto:${CONTACT_EMAIL}`}>
            {CONTACT_EMAIL}
          </EmailLink>
        </DescriptionParagraph>
      </DescriptionContainer>
      <ButtonsContainer>
        <PrimaryButton {...BUTTONS_CSS} onClick={nextStep}>
          Start
        </PrimaryButton>
        <SecondaryButton {...BUTTONS_CSS} noDark onClick={closeForm}>
          Cancel
        </SecondaryButton>
      </ButtonsContainer>
    </Wrapper>
  );
};

EntryModalAdd.propTypes = {
  setStep: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
};

export default EntryModalAdd;
