import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const FilterControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
  width: 100%;
`;

export const FilterControlsWrapper = styled.div`
  display: flex;

  @media ${DEVICE.formResponsive} {
    width: 100%;
  }
`;

export const SortDropdownField = styled.div`
  align-items: center;
  border: 1px solid var(--GRAY_80_OR_RAISIN_BLACK_ALPHA_03);
  border-radius: 4px;
  display: flex;
  padding: 0 8px 0 12px;
  min-height: 42px;
  justify-content: space-between;
  min-width: 142px;
  flex: 1;
  cursor: pointer;

  p {
    font-size: 12px;
    line-height: 18px;
    color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);

    span {
      font-weight: 600;
      color: var(--BRIGHT_GRAY_OR_DARK_CHARCOAL);
    }
  }

  svg > path {
    fill: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  }
`;

export const SortControlContainer = styled.div`
  position: relative;
`;

export const SortDropdown = styled.div`
  width: calc(calc(100% - 17px));
  background-color: var(--RAISING_BLACK_OR_WHITE);
  border: 1px solid var(--GRAY_80_OR_BRIGHT_GRAY);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  left: 0;
  max-height: ${(props) => props.MaxHeight};
  padding: 20px 0 18px 15px;
  overflow: auto;
  overflow-x: hidden;
  position: absolute;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  top: 10;
  z-index: 30;
  margin-top: 4px;
`;

export const FilterButton = styled.div`
  display: flex;
  align-items: center;
  min-width: 94px;
  flex: 1;
  justify-content: center;
  gap: 8px;
  font-size: 12px;
  border: 1px solid var(--GRAY_80_OR_RAISIN_BLACK_ALPHA_03);
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  border-radius: 4px;
  cursor: pointer;
  padding: 0 10px;

  svg > path {
    fill: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  }
`;
