import { CRIMSON } from 'constants/colors';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const AnchorPartnerContainer = styled.div`
  border: 1px solid var(--DARK_CHARCOAL_OR_BRIGHT_GRAY);
  background-color: var(--RAISING_BLACK_OR_WHITE);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 380px;
  gap: 10px;
  z-index: 1;
`;

export const AnchorTextWrapper = styled.a`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
  color: inherit;
`;

export const AnchorText = styled.span`
  font-size: 14px;
  line-height: 20px;
`;

export const LearnMore = styled(Link)`
  color: ${CRIMSON};
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline !important;
`;
