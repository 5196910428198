import ResponseError from 'assets/icons/ResponseError';
import ResponseThankYou from 'assets/icons/ResponseThankYou';
import { ADD_NEW_EVENT, UPDATE_EVENT } from 'components/Forms/Events/constants';
import EVENTS_STATIC_TEXT from '../EntryModal/constants';

export const THANK_YOU_CONFIG = {
  title: EVENTS_STATIC_TEXT[ADD_NEW_EVENT].END_TITLE,
  descriptionText: EVENTS_STATIC_TEXT[ADD_NEW_EVENT].END_DESCRIPTION,
  SVG: <ResponseThankYou />,
};

export const THANK_YOU_UPDATE_CONFIG = {
  title: EVENTS_STATIC_TEXT[UPDATE_EVENT].END_TITLE,
  descriptionText: EVENTS_STATIC_TEXT[UPDATE_EVENT].END_DESCRIPTION,
  SVG: <ResponseThankYou />,
};

export const ERROR_CONFIG = {
  title: 'Something went wrong',
  descriptionText:
    'The server encountered a temporary error and could not complete your request. Please go back to homepage or try again.',
  SVG: <ResponseError />,
};
