import PageNavigator from 'pages/AdminPage/components/AdminTable/AdditionalElements/Pagination/PageNavigator/PageNavigator';
import PageSelector from 'pages/AdminPage/components/AdminTable/AdditionalElements/Pagination/PageSelector/PageSelector';
import PaginationWrapper from 'pages/AdminPage/components/AdminTable/AdditionalElements/Pagination/Pagination.styled';
import PropTypes from 'prop-types';

const Pagination = ({
  totalRows,
  offset,
  limit,
  limitOptions,
  handlePageClick,
  handleItemsPerPageChange,
}) => {
  const handlePreviousPageClick = () => {
    if (offset > 0) handlePageClick(offset - limit);
  };

  const handleNextPageClick = () => {
    if (offset < totalRows - 1) handlePageClick(offset + limit);
  };

  return (
    <PaginationWrapper>
      <PageSelector
        limit={limit}
        limitOptions={limitOptions}
        onChangeHandler={handleItemsPerPageChange}
      />
      <PageNavigator
        currentPage={offset}
        limit={limit}
        totalRows={totalRows}
        onPreviousPage={handlePreviousPageClick}
        onNextPage={handleNextPageClick}
      />
    </PaginationWrapper>
  );
};

Pagination.propTypes = {
  totalRows: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  limitOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  handlePageClick: PropTypes.func.isRequired,
  handleItemsPerPageChange: PropTypes.func.isRequired,
};

export default Pagination;
