import { useState } from 'react';
import PropTypes from 'prop-types';

import FilterControls from 'components/common/Filters/FilterControls/FilterControls';
import useParamsProps from 'pages/FundingPage/components/InvestorFilters/useInvestorParams/propTypes';
import Filters from 'pages/FundingPage/components/InvestorFilters/Filters';
import { FiltersContainer } from 'components/common/Filters/Filters.styled';

const InvestorFilters = ({
  investorQueryParams,
  setFullName,
  setCompanyName,
  setCategory,
  setCheckSize,
  setGeoFocus,
  resetFilters,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const toggleFilters = () => setShowFilters(!showFilters);

  return (
    <>
      <FilterControls
        toggleFilters={toggleFilters}
        clearFilters={resetFilters}
      />
      <FiltersContainer id="scrollInto">
        {showFilters && (
          <Filters
            investorQueryParams={investorQueryParams}
            setFullName={setFullName}
            setCompanyName={setCompanyName}
            setCategory={setCategory}
            setCheckSize={setCheckSize}
            setGeoFocus={setGeoFocus}
          />
        )}
      </FiltersContainer>
    </>
  );
};

InvestorFilters.propTypes = {
  investorQueryParams: useParamsProps().isRequired,
  setFullName: PropTypes.func.isRequired,
  setCompanyName: PropTypes.func.isRequired,
  setCategory: PropTypes.func.isRequired,
  setCheckSize: PropTypes.func.isRequired,
  setGeoFocus: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
};

export default InvestorFilters;
