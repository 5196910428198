import PropTypes from 'prop-types';
import {
  exclusiveFormStateProps,
  publicFormStateProps,
} from 'components/Forms/Resources/propTypes';
import {
  FormContainer,
  Step,
  Title,
  ButtonsContainer,
} from 'components/Forms/Resources/AddResources.styled';
import {
  ExclusiveDealsNote,
  FieldContainer,
  Wrapper,
  TitleContainer,
} from 'components/Forms/Resources/StepThree/StepThree.styled';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import BackArrow from 'assets/icons/BackArrow';
import Spacer from 'components/common/Spacer/Spacer.styled';
import ContactInputSchema from 'components/Forms/Resources/StepThree/schemas';
import { BUTTONS_CSS, PUBLIC_KEYS } from 'components/Forms/Resources/constants';
import { useState } from 'react';
import useFormInput from 'components/common/FormComponents/Input/FormInput/useFormInput';
import FormInput from 'components/common/FormComponents/Input/FormInput/FormInput';

const StepThree = ({
  backStep,
  nextStep,
  currentStep,
  closeForm,
  setFormState,
  formState,
}) => {
  const [inputsState, setInputsState] = useState(ContactInputSchema);

  const onChangeHandler =
    (stateHandler, key) =>
    ({ target: { value } }) => {
      setFormState((prevState) => ({ ...prevState, [key]: value }));
      stateHandler((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          isError: prevState[key].isError && !prevState[key].validator(value),
        },
      }));
    };
  const { onBlurHandler, triggerError } = useFormInput();

  const onClickSubmitButton = (e) => {
    e.preventDefault();

    const keysInput = Object.keys(inputsState);

    const inputsWithErrors = keysInput.filter(
      (key) => !inputsState[key].validator(formState[key]),
    );

    inputsWithErrors.forEach((key) => triggerError(setInputsState, key));

    if (inputsWithErrors.length) return;

    nextStep();
  };

  return (
    <Wrapper Height="420px">
      <TitleContainer>
        <Step>
          <SecondaryButton
            Border="none"
            noDark
            type="button"
            onClick={backStep}
          >
            <BackArrow />
          </SecondaryButton>
          {`Step ${currentStep}`}
        </Step>
        <Title>Enter email address where we can contact you</Title>
      </TitleContainer>
      <ExclusiveDealsNote>
        Exclusive deals can be accessed only if you have registered account on
        HBS Builds website.
      </ExclusiveDealsNote>
      <Spacer Height="20px" />
      <FormContainer>
        <FieldContainer>
          <FormInput
            key={PUBLIC_KEYS.CONTACT_NAME}
            name={PUBLIC_KEYS.CONTACT_NAME}
            {...inputsState[PUBLIC_KEYS.CONTACT_NAME]}
            value={formState[PUBLIC_KEYS.CONTACT_NAME]}
            onChangeHandler={onChangeHandler(
              setInputsState,
              PUBLIC_KEYS.CONTACT_NAME,
            )}
            onBlurHandler={onBlurHandler(
              setInputsState,
              PUBLIC_KEYS.CONTACT_NAME,
            )}
          />
        </FieldContainer>
        <FieldContainer>
          <FormInput
            key={PUBLIC_KEYS.CONTACT_EMAIL}
            name={PUBLIC_KEYS.CONTACT_EMAIL}
            {...inputsState[PUBLIC_KEYS.CONTACT_EMAIL]}
            value={formState[PUBLIC_KEYS.CONTACT_EMAIL]}
            onChangeHandler={onChangeHandler(
              setInputsState,
              PUBLIC_KEYS.CONTACT_EMAIL,
            )}
            onBlurHandler={onBlurHandler(
              setInputsState,
              PUBLIC_KEYS.CONTACT_EMAIL,
            )}
          />
        </FieldContainer>
        <ButtonsContainer>
          <SecondaryButton {...BUTTONS_CSS.secondary} onClick={closeForm}>
            Cancel
          </SecondaryButton>
          <Spacer Width="16px" />
          <PrimaryButton {...BUTTONS_CSS.primary} onClick={onClickSubmitButton}>
            Submit
          </PrimaryButton>
        </ButtonsContainer>
      </FormContainer>
    </Wrapper>
  );
};

StepThree.propTypes = {
  currentStep: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  formState: PropTypes.oneOfType([
    publicFormStateProps(),
    exclusiveFormStateProps(),
  ]).isRequired,
};

export default StepThree;
