import PropTypes from 'prop-types';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import {
  FormContainer,
  Step,
  Title,
  TitleContainer,
  Wrapper,
  InputsContainer,
  ButtonsContainer,
  DescriptionContainer,
} from 'components/Forms/Company/StepOne/StepOne.styled';
import Spacer from 'components/common/Spacer/Spacer.styled';
import RadioButtonGroup from 'components/common/RadioButtonGroup/RadioButtonGroup';
import useFormInput from 'components/common/FormComponents/Input/FormInput/useFormInput';
import FormInput from 'components/common/FormComponents/Input/FormInput/FormInput';
import { useAppContext } from 'contexts/AppContext';
import STEP_ONE_KEYS from 'components/Forms/Company/StepOne/constants';
import {
  ContactInfoSchema,
  isHiringOptions,
} from 'components/Forms/Company/StepOne/schema';
import { useState } from 'react';
import { formStateProp } from 'components/Forms/Company/propTypes';
import { WILLS_EMAIL } from 'constants/other';

const keys = Object.values(STEP_ONE_KEYS);

const StepOne = ({
  nextStep,
  currentStep,
  closeForm,
  formState,
  setFormState,
}) => {
  const [inputsState, setInputsState] = useState(ContactInfoSchema);

  const { user } = useAppContext();

  const onChangeHandler =
    (key) =>
    ({ target: { value } }) => {
      setFormState((prevState) => ({ ...prevState, [key]: value }));
      setInputsState((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          isError: prevState[key].isError && !prevState[key].validator(value),
        },
      }));
    };

  const { onBlurHandler, triggerError } = useFormInput();

  const isHiringSelectionHandler = ({ target: { value } }) =>
    setFormState((prevState) => ({ ...prevState, isHiring: value }));

  const onClickSubmitButton = (e) => {
    e.preventDefault();
    const fieldWithErrors = keys.filter(
      (key) => !inputsState[key].validator(formState[key]),
    );

    fieldWithErrors.forEach((key) => triggerError(setInputsState, key));

    if (fieldWithErrors.length) return;

    setFormState((prevState) => {
      const foundersCopy = prevState.founders;
      const indexOfPrimaryFounders = foundersCopy.findIndex(
        (founder) => founder.position === 1,
      );

      foundersCopy[indexOfPrimaryFounders] = {
        ...foundersCopy[indexOfPrimaryFounders],
        fullName: {
          ...foundersCopy[indexOfPrimaryFounders].fullName,
          value: prevState.creatorFullName,
        },
        founderEmail: {
          ...foundersCopy[indexOfPrimaryFounders].founderEmail,
          value: prevState.creatorHbsEmail,
        },
      };

      return { ...prevState, founders: foundersCopy };
    });
    nextStep();
  };

  return (
    <Wrapper>
      <TitleContainer>
        <Step>{`Step ${currentStep}`}</Step>
        <Title>General Info</Title>
      </TitleContainer>
      <Spacer Height="20px" />
      <FormContainer onSubmit={onClickSubmitButton}>
        <InputsContainer>
          {keys.map((key) => (
            <div key={`${key}: ${formState[key].label}`}>
              <FormInput
                {...inputsState[key]}
                value={formState[key]}
                onChangeHandler={onChangeHandler(key)}
                onBlurHandler={onBlurHandler(setInputsState, key)}
                name={key}
                isDisabled={
                  key !== STEP_ONE_KEYS.CREATOR_HBS_CLASSCARD_LINK &&
                  !!user &&
                  user.hbsEmail !== WILLS_EMAIL
                }
              />
            </div>
          ))}
        </InputsContainer>
        <DescriptionContainer>
          <p>
            So that only verified members of the HBS community can contact you,
            please provide the following: <br />
            Alumni students please search for your profiles URL{' '}
            <a
              href="https://www.alumni.hbs.edu/community/Pages/directory-search.aspx?"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            <br />
            Current Students please search for your profile’s URL{' '}
            <a
              href="https://secure.hbs.edu/classcards/search.do"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
          </p>
        </DescriptionContainer>
        <Spacer Height="32px" />
        <RadioButtonGroup
          groupLabel="Is this company currently hiring?*"
          options={isHiringOptions}
          selectedLabel={formState.isHiring}
          onChange={isHiringSelectionHandler}
        />
        <ButtonsContainer>
          <SecondaryButton
            Border="1px solid #545556"
            Width="83px"
            Height="40px"
            FontWeight="600"
            FontSize="14px"
            type="button"
            noDark
            onClick={closeForm}
          >
            Cancel
          </SecondaryButton>
          <Spacer Width="16px" />
          <PrimaryButton
            Height="40px"
            FontWeight="600"
            FontSize="14px"
            Width="173px"
            Border="none"
            type="submit"
          >
            Next: Company Info
          </PrimaryButton>
        </ButtonsContainer>
      </FormContainer>
    </Wrapper>
  );
};

StepOne.propTypes = {
  nextStep: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  formState: formStateProp().isRequired,
};

export default StepOne;
