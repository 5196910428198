import { PUBLIC_KEYS } from 'pages/AdminPage/components/Forms/PublicResource/EditPublicResource/constants';
import {
  emailValidator,
  isEmptyFieldValidator,
  isUrlValid,
} from 'utils/inputValidators';

export const publicResourceInputSchema = {
  [PUBLIC_KEYS.DEAL_NAME]: {
    label: 'Resource name*',
    inputType: 'text',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your resource name',
    validator: isEmptyFieldValidator,
  },
  [PUBLIC_KEYS.DEAL_URL]: {
    label: 'Deal URL*',
    inputType: 'text',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: "Enter your deal's URL",
    validator: isUrlValid,
  },
  [PUBLIC_KEYS.CONTACT_NAME]: {
    label: 'Your name*',
    inputType: 'text',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your name',
    validator: isEmptyFieldValidator,
  },
  [PUBLIC_KEYS.CONTACT_EMAIL]: {
    label: 'Email address*',
    inputType: 'email',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter a valid email address',
    validator: emailValidator,
  },
};

export const publicResourceSelectSchema = {
  [PUBLIC_KEYS.TARGET_AUDIENCES]: {
    label: 'Target audience*',
    placeHolder: 'Select the audience',
    MaxHeight: '130px',
    isError: false,
    validator: isEmptyFieldValidator,
  },
  [PUBLIC_KEYS.CATEGORIES]: {
    label: 'Category*',
    placeHolder: 'Select the category',
    MaxHeight: '170px',
    isError: false,
    validator: isEmptyFieldValidator,
  },
};

export const publicResourceDescriptionSchema = {
  [PUBLIC_KEYS.DEAL_DESCRIPTION]: {
    label: 'Describe the deal and how to redeem it*',
    placeholder: 'Enter your deal description...',
    warnMsg: 'Please enter description',
    validator: isEmptyFieldValidator,
    isError: false,
    disabledDarkMode: true,
    isDisabled: false,
    RowNum: 6,
  },
};
