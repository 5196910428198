import styled from 'styled-components';
import { SecondaryButton } from 'components/common/Button/Button.styled';

export const ClearFiltersContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-start;
`;

export const ClearFiltersButton = styled(SecondaryButton)`
  border: 1px solid var(--GRAY_80_OR_RAISIN_BLACK_ALPHA_03);
  color: var(--GRAY_80_OR_DAVY_GRAY);
  padding: 6px;
  width: 120px;
`;
