import PropTypes from 'prop-types';
import PROTECTED_ROUTE_FALLBACKS from './constants';

const ProtectedRoute = ({ isAuthorized, children, fallback }) => {
  if (!isAuthorized) {
    return fallback;
  }

  return children;
};

ProtectedRoute.defaultProps = {
  fallback: PROTECTED_ROUTE_FALLBACKS.NOT_FOUND,
};

ProtectedRoute.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  fallback: PropTypes.element,
};

export default ProtectedRoute;
