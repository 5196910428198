import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import useDarkMode from 'hooks/useDarkMode';
import { useAppContext } from 'contexts/AppContext';
import isAuth from 'utils/isAuth';
import GlobalStyle from 'components/GlobalStyle/GlobalStyle';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import FormContainer from 'components/Forms/FormContainer';
import HomePage from 'pages/HomePage/HomePage';
import ResourcePage from 'pages/ResourcePage/ResourcePage';
import AdminPage from 'pages/AdminPage/AdminPage';
import AboutPage from 'pages/AboutPage/AboutPage';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import ResetPasswordPage from 'pages/ResetPasswordPage/ResetPasswordPage';
import DeleteCompany from 'pages/AdminPage/components/Forms/Company/DeleteCompany/DeleteCompany';
import EditCompany from 'pages/AdminPage/components/Forms/Company/EditCompany/EditCompany';
import RestoreCompany from 'pages/AdminPage/components/Forms/Company/RestoreCompany/RestoreCompany';
import EditPublicResource from 'pages/AdminPage/components/Forms/PublicResource/EditPublicResource/EditPublicResource';
import ExclusiveResourcesTab from 'pages/AdminPage/TabControl/ResourceTabs/ExclusiveResourcesTab/ExclusiveResourcesTab';
import ProtectedRoute from 'routes/ProtectedRoutes/ProtectedRoute';
import FundingPage from 'pages/FundingPage/FundingPage';
import RestorePublicResource from 'pages/AdminPage/components/Forms/PublicResource/RestorePublicResource/RestorePublicResource';
import DeletePublicResource from 'pages/AdminPage/components/Forms/PublicResource/DeletePublicResource/DeletePublicResource';
import CompanyTab from 'pages/AdminPage/TabControl/CompanyTab/CompanyTab';
import InvestorTab from 'pages/AdminPage/TabControl/InvestorTab/InvestorTab';
import PublicResourcesTab from 'pages/AdminPage/TabControl/ResourceTabs/PublicResourcesTab/PublicResourcesTab';
import DeleteExclusiveResource from 'pages/AdminPage/components/Forms/ExclusiveResource/DeleteExclusiveResource/DeleteExclusiveResource';
import EditExclusiveResource from 'pages/AdminPage/components/Forms/ExclusiveResource/EditExclusiveResource/EditExclusiveResource';
import RestoreExclusiveResource from 'pages/AdminPage/components/Forms/ExclusiveResource/RestoreExclusiveResource/RestoreExclusiveResource';
import AddAnInvestor from 'components/Forms/Investor/AddAnInvestor';
import BulkImportCompanies from 'pages/AdminPage/components/Forms/Company/BulkImportCompany/BulkImportCompanies';

import USER_ROLES from 'constants/user_roles';

import './App.css';

const App = () => {
  const navigate = useNavigate();
  const [isDark, themeToggler] = useDarkMode();

  const { removeUser, getUserRole } = useAppContext();

  const location = useLocation();

  useEffect(() => {
    if (!isAuth()) removeUser();
  }, [location]);

  return (
    <HelmetProvider>
      <GlobalStyle
        isDark={location.pathname.startsWith('/administration') || isDark}
      />
      <FormContainer />
      <Header toggleSwitch={themeToggler} isDark={isDark} />
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="resources" element={<ResourcePage />} />
        <Route path="funding" element={<FundingPage />}>
          <Route
            path="add"
            element={<AddAnInvestor closeForm={() => navigate('/funding')} />}
          />
        </Route>
        <Route path="reset-password" element={<ResetPasswordPage />} />
        <Route
          path="administration"
          element={
            <ProtectedRoute isAuthorized={getUserRole() === USER_ROLES.ADMIN}>
              <AdminPage />
            </ProtectedRoute>
          }
        >
          <Route path="companies" element={<CompanyTab />}>
            <Route path="delete" element={<DeleteCompany />} />
            <Route path="edit" element={<EditCompany />} />
            <Route path="restore" element={<RestoreCompany />} />
            <Route path="bulk-import" element={<BulkImportCompanies />} />
          </Route>
          <Route path="public-resource" element={<PublicResourcesTab />}>
            <Route path="delete" element={<DeletePublicResource />} />
            <Route path="edit" element={<EditPublicResource />} />
            <Route path="restore" element={<RestorePublicResource />} />
          </Route>
          <Route path="exclusive-resource" element={<ExclusiveResourcesTab />}>
            <Route path="delete" element={<DeleteExclusiveResource />} />
            <Route path="edit" element={<EditExclusiveResource />} />
            <Route path="restore" element={<RestoreExclusiveResource />} />
          </Route>
          <Route path="investor" element={<InvestorTab />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </HelmetProvider>
  );
};

export default App;
