import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useRequest from 'hooks/useRequest';
import { getExclusiveDealCategories } from 'services/requests';
import {
  FormContainer,
  Step,
  Title,
  TitleContainer,
  ButtonsContainer,
} from 'components/Forms/Resources/AddResources.styled';
import {
  ExclusiveRowContainer,
  FieldContainer,
  RowContainer,
  Wrapper,
} from 'components/Forms/Resources/StepTwo/StepTwo.styled';
import Spacer from 'components/common/Spacer/Spacer.styled';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import MultiSelect from 'components/common/Dropdown/MultiSelect';
import BackArrow from 'assets/icons/BackArrow';
import DragNDrop from 'components/common/DragNDrop/DragNDrop';
import HbsDatePicker from 'components/common/HbsDatePicker/HbsDatePicker';
import {
  BUTTONS_CSS,
  EXCLUSIVE_KEYS,
} from 'components/Forms/Resources/constants';
import {
  ExclusiveResourceInfoDescriptionSchema,
  ExclusiveResourceInfoInputSchema,
  ExclusiveResourceInfoSelectSchema,
} from 'components/Forms/Resources/StepTwo/ExclusiveResource/schemas';
import { mapCategoriesToOptions } from 'components/Forms/Resources/StepTwo/utils';
import { exclusiveFormStateProps } from 'components/Forms/Resources/propTypes';
import useFormInput from 'components/common/FormComponents/Input/FormInput/useFormInput';
import FormInput from 'components/common/FormComponents/Input/FormInput/FormInput';
import FormDescription from 'components/common/FormComponents/Input/FormDescription/FormDescription';
import { EXCLUSIVE_RESOURCE_CATEGORIES } from 'pages/ResourcePage/constants';

const StepTwoExclusive = ({
  currentStep,
  closeForm,
  backStep,
  nextStep,
  formState,
  setFormState,
}) => {
  const [inputsState, setInputsState] = useState(
    ExclusiveResourceInfoInputSchema,
  );
  const [selectsState, setSelectsState] = useState(
    ExclusiveResourceInfoSelectSchema,
  );
  const [descriptionState, setDescriptionState] = useState(
    ExclusiveResourceInfoDescriptionSchema,
  );

  const { request } = useRequest();
  const currentDate = new Date();
  const [exclusiveDealCategories, setExclusiveDealCategories] = useState([]);

  const onChangeHandler =
    (stateHandler, key) =>
    ({ target: { value } }) => {
      setFormState((prevState) => ({ ...prevState, [key]: value }));
      stateHandler((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          isError: prevState[key].isError && !prevState[key].validator(value),
        },
      }));
    };
  const { onBlurHandler, triggerError } = useFormInput();

  const selectDateHandler = (date) =>
    setFormState((prevState) => ({
      ...prevState,
      [EXCLUSIVE_KEYS.EXPIRING_DATE]: date,
    }));

  const setUploadedFile = (data) =>
    setFormState((prevState) => ({
      ...prevState,
      [EXCLUSIVE_KEYS.COMPANY_LOGO]: data,
    }));

  useEffect(() => {
    let ignore = false;

    const fetchOptions = async () => {
      if (!ignore) {
        const response = await request(() => getExclusiveDealCategories());

        if (response)
          setExclusiveDealCategories(
            mapCategoriesToOptions(
              response.data,
              EXCLUSIVE_RESOURCE_CATEGORIES,
            ),
          );
      }
    };

    fetchOptions();

    return () => {
      ignore = true;
    };
  }, []);

  const multiSelectionHandler = (key, collection) => (options) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]:
        options &&
        options.map((option) =>
          collection.find((object) => object.id === option.id),
        ),
    }));
    setSelectsState((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        isError: false,
      },
    }));
  };

  const onClickSubmitButton = (e) => {
    e.preventDefault();

    const keysInput = Object.keys(inputsState);
    const keysSelect = Object.keys(selectsState);
    const keysDescription = Object.keys(descriptionState);

    const inputsWithErrors = keysInput.filter(
      (key) => !inputsState[key].validator(formState[key]),
    );
    const selectsWithErrors = keysSelect.filter(
      (key) => !selectsState[key].validator(formState[key]),
    );
    const descriptionWithError = keysDescription.filter(
      (key) => !descriptionState[key].validator(formState[key]),
    );

    inputsWithErrors.forEach((key) => triggerError(setInputsState, key));
    selectsWithErrors.forEach((key) => triggerError(setSelectsState, key));
    descriptionWithError.forEach((key) =>
      triggerError(setDescriptionState, key),
    );

    if (
      inputsWithErrors.length ||
      selectsWithErrors.length ||
      descriptionWithError.length
    )
      return;

    nextStep();
  };

  return (
    <Wrapper>
      <TitleContainer>
        <Step>
          <SecondaryButton
            Border="none"
            noDark
            type="button"
            onClick={backStep}
          >
            <BackArrow />
          </SecondaryButton>
          {`Step ${currentStep}`}
        </Step>
        <Title>General Info</Title>
      </TitleContainer>
      <Spacer Height="20px" />
      <FormContainer>
        <RowContainer>
          <FieldContainer>
            <FormInput
              {...inputsState[EXCLUSIVE_KEYS.COMPANY_WEBSITE]}
              name={EXCLUSIVE_KEYS.COMPANY_WEBSITE}
              value={formState[EXCLUSIVE_KEYS.COMPANY_WEBSITE]}
              onChangeHandler={onChangeHandler(
                setInputsState,
                EXCLUSIVE_KEYS.COMPANY_WEBSITE,
              )}
              onBlurHandler={onBlurHandler(
                setInputsState,
                EXCLUSIVE_KEYS.COMPANY_WEBSITE,
              )}
            />
          </FieldContainer>
          <FieldContainer>
            <FormInput
              {...inputsState[EXCLUSIVE_KEYS.PROMOTION]}
              name={EXCLUSIVE_KEYS.PROMOTION}
              value={formState[EXCLUSIVE_KEYS.PROMOTION]}
              onChangeHandler={onChangeHandler(
                setInputsState,
                EXCLUSIVE_KEYS.PROMOTION,
              )}
              onBlurHandler={onBlurHandler(
                setInputsState,
                EXCLUSIVE_KEYS.PROMOTION,
              )}
            />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <FieldContainer>
            <MultiSelect
              key={exclusiveDealCategories.length}
              {...selectsState[EXCLUSIVE_KEYS.CATEGORIES]}
              selectedValue={formState[EXCLUSIVE_KEYS.CATEGORIES]}
              options={exclusiveDealCategories}
              onChangeHandler={multiSelectionHandler(
                EXCLUSIVE_KEYS.CATEGORIES,
                exclusiveDealCategories,
              )}
              disabledDarkMode
            />
            <Spacer Height="20px" />
          </FieldContainer>
          <FieldContainer>
            <HbsDatePicker
              selectedDate={formState[EXCLUSIVE_KEYS.EXPIRING_DATE]}
              minDate={currentDate}
              setSelectedDate={selectDateHandler}
            />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <FieldContainer>
            <FormDescription
              {...descriptionState[EXCLUSIVE_KEYS.DEAL_DESCRIPTION]}
              value={formState[EXCLUSIVE_KEYS.DEAL_DESCRIPTION]}
              onChangeHandler={onChangeHandler(
                setDescriptionState,
                EXCLUSIVE_KEYS.DEAL_DESCRIPTION,
              )}
              onBlurHandler={onBlurHandler(
                setDescriptionState,
                EXCLUSIVE_KEYS.DEAL_DESCRIPTION,
              )}
              name={EXCLUSIVE_KEYS.DEAL_DESCRIPTION}
            />
          </FieldContainer>
          <FieldContainer>
            <FormDescription
              {...descriptionState[EXCLUSIVE_KEYS.COMPANY_DESCRIPTION]}
              value={formState[EXCLUSIVE_KEYS.COMPANY_DESCRIPTION]}
              onChangeHandler={onChangeHandler(
                setDescriptionState,
                EXCLUSIVE_KEYS.COMPANY_DESCRIPTION,
              )}
              onBlurHandler={onBlurHandler(
                setDescriptionState,
                EXCLUSIVE_KEYS.COMPANY_DESCRIPTION,
              )}
              name={EXCLUSIVE_KEYS.COMPANY_DESCRIPTION}
            />
          </FieldContainer>
        </RowContainer>
        <ExclusiveRowContainer>
          <FieldContainer>
            <DragNDrop
              uploadedFile={formState[EXCLUSIVE_KEYS.COMPANY_LOGO]}
              setUploadedFile={setUploadedFile}
              label="Deal logo"
              dndParagraph="Drag and drop or upload a company logo"
            />
          </FieldContainer>
        </ExclusiveRowContainer>
        <ButtonsContainer>
          <SecondaryButton {...BUTTONS_CSS.secondary} onClick={closeForm}>
            Cancel
          </SecondaryButton>
          <Spacer Width="16px" />
          <PrimaryButton {...BUTTONS_CSS.primary} onClick={onClickSubmitButton}>
            Next: Contact
          </PrimaryButton>
        </ButtonsContainer>
      </FormContainer>
    </Wrapper>
  );
};

StepTwoExclusive.propTypes = {
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  formState: exclusiveFormStateProps().isRequired,
};

export default StepTwoExclusive;
