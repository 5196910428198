const NotFound = () => (
  <svg
    width="681"
    height="247"
    viewBox="0 0 681 247"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 197V198H2H98.2V233V234H99.2H158.6H159.6V233V198H181.7H182.7V197V146V145H181.7H159.6V17V16H158.6H94.1H93.5797L93.2811 16.426L1.18112 147.826L1 148.084V148.4V197ZM102.7 145H64.1586L102.7 86.6293V145Z"
      fill="var(--RAISING_BLACK_OR_WHITE)"
      stroke="#A51C30"
      strokeWidth="2"
    />
    <path
      d="M497.699 197V198H498.699H594.899V233V234H595.899H655.299H656.299V233V198H678.399H679.399V197V146V145H678.399H656.299V17V16H655.299H590.799H590.279L589.98 16.426L497.88 147.826L497.699 148.084V148.4V197ZM599.399 145H560.858L599.399 86.6293V145Z"
      fill="var(--RAISING_BLACK_OR_WHITE)"
      stroke="#A51C30"
      strokeWidth="2"
    />
    <circle
      cx="340.199"
      cy="130.5"
      r="108.5"
      fill="var(--RAISING_BLACK_OR_WHITE)"
      stroke="#A51C30"
      strokeWidth="2"
    />
    <path
      d="M341.199 39C343.132 39 344.699 37.433 344.699 35.5C344.699 33.567 343.132 32 341.199 32C339.266 32 337.699 33.567 337.699 35.5C337.699 37.433 339.266 39 341.199 39Z"
      fill="#A51C30"
    />
    <path
      d="M288.199 49C289.58 49 290.699 47.8807 290.699 46.5C290.699 45.1193 289.58 44 288.199 44C286.818 44 285.699 45.1193 285.699 46.5C285.699 47.8807 286.818 49 288.199 49Z"
      fill="var(--DAVY_GRAY_OR_BRIGHT_GRAY)"
    />
    <path
      d="M429.199 170C430.58 170 431.699 168.881 431.699 167.5C431.699 166.119 430.58 165 429.199 165C427.818 165 426.699 166.119 426.699 167.5C426.699 168.881 427.818 170 429.199 170Z"
      fill="var(--DIM_GRAY_OR_BRIGHT_GRAY)"
    />
    <path
      d="M296.199 76C297.028 76 297.699 75.3284 297.699 74.5C297.699 73.6716 297.028 73 296.199 73C295.371 73 294.699 73.6716 294.699 74.5C294.699 75.3284 295.371 76 296.199 76Z"
      fill="var(--DIM_GRAY_OR_BRIGHT_GRAY)"
    />
    <path
      d="M358.199 63C359.028 63 359.699 62.3284 359.699 61.5C359.699 60.6716 359.028 60 358.199 60C357.371 60 356.699 60.6716 356.699 61.5C356.699 62.3284 357.371 63 358.199 63Z"
      fill="var(--DIM_GRAY_OR_BRIGHT_GRAY)"
    />
    <path
      d="M435.199 102C436.028 102 436.699 101.328 436.699 100.5C436.699 99.6716 436.028 99 435.199 99C434.371 99 433.699 99.6716 433.699 100.5C433.699 101.328 434.371 102 435.199 102Z"
      fill="var(--DIM_GRAY_OR_BRIGHT_GRAY)"
    />
    <path
      d="M275.506 65.0122L273.703 66.8025L271.913 65L270.711 66.1936L272.501 67.9956L270.699 69.7859L271.892 70.9878L273.695 69.1975L275.486 71L276.687 69.8064L274.897 68.0039L276.699 66.2141L275.506 65.0122Z"
      fill="var(--DIM_GRAY_OR_BRIGHT_GRAY)"
    />
    <path
      d="M378.506 62.0122L376.703 63.8025L374.913 62L373.711 63.1936L375.501 64.9956L373.699 66.7859L374.892 67.9878L376.695 66.1975L378.486 68L379.687 66.8064L377.897 65.0039L379.699 63.2141L378.506 62.0122Z"
      fill="var(--DIM_GRAY_OR_BRIGHT_GRAY)"
    />
    <path
      d="M434.506 125.012L432.703 126.803L430.913 125L429.711 126.194L431.501 127.996L429.699 129.786L430.892 130.988L432.695 129.197L434.486 131L435.687 129.806L433.897 128.004L435.699 126.214L434.506 125.012Z"
      fill="var(--DIM_GRAY_OR_BRIGHT_GRAY)"
    />
    <path
      opacity="0.8"
      d="M348.079 56.93L361.098 51.7669L374.117 46.6039L381.466 43.6893C381.873 43.528 381.725 42.8649 381.319 43.0262L368.3 48.1892L355.281 53.3522L347.932 56.2668C347.525 56.4281 347.673 57.0912 348.079 56.93Z"
      fill="var(--DIM_GRAY_OR_BRIGHT_GRAY)"
    />
    <path
      opacity="0.8"
      d="M316.22 83.1028L329.239 77.9398L342.257 72.7768L349.607 69.8622C350.013 69.7009 349.866 69.0378 349.459 69.199L336.441 74.362L323.422 79.5251L316.072 82.4397C315.666 82.6009 315.813 83.2641 316.22 83.1028Z"
      fill="var(--DIM_GRAY_OR_BRIGHT_GRAY)"
    />
    <path
      d="M313.301 70.2282C320.26 70.2282 325.902 64.5807 325.902 57.6141C325.902 50.6475 320.26 45 313.301 45C306.341 45 300.699 50.6475 300.699 57.6141C300.699 64.5807 306.341 70.2282 313.301 70.2282Z"
      fill="var(--DIM_GRAY_OR_BRIGHT_GRAY)"
    />
    <path
      d="M312.061 50.3765C312.86 50.3765 313.507 49.7284 313.507 48.929C313.507 48.1295 312.86 47.4814 312.061 47.4814C311.263 47.4814 310.615 48.1295 310.615 48.929C310.615 49.7284 311.263 50.3765 312.061 50.3765Z"
      fill="#D5D5D4"
    />
    <path
      d="M314.54 64.8516C315.339 64.8516 315.986 64.2035 315.986 63.4041C315.986 62.6046 315.339 61.9565 314.54 61.9565C313.742 61.9565 313.094 62.6046 313.094 63.4041C313.094 64.2035 313.742 64.8516 314.54 64.8516Z"
      fill="#D5D5D4"
    />
    <path
      d="M320.118 56.3731C320.688 56.3731 321.151 55.9102 321.151 55.3391C321.151 54.7681 320.688 54.3052 320.118 54.3052C319.547 54.3052 319.085 54.7681 319.085 55.3391C319.085 55.9102 319.547 56.3731 320.118 56.3731Z"
      fill="#D5D5D4"
    />
    <path
      d="M308.342 61.9568C310.168 61.9568 311.648 60.4755 311.648 58.6482C311.648 56.8209 310.168 55.3396 308.342 55.3396C306.517 55.3396 305.037 56.8209 305.037 58.6482C305.037 60.4755 306.517 61.9568 308.342 61.9568Z"
      fill="#D5D5D4"
    />
    <path
      d="M285.199 124.999C258.954 126.464 235.199 149.999 235.199 149.999L233.699 151.499L235.199 158.999L239.699 168.999L243.199 177.499L247.199 184.499L252.199 193.499L256.199 199.499L262.199 205.999L268.199 211.499L275.699 217.999L282.699 222.499L292.699 227.999L300.699 231.999L307.199 233.999L317.199 236.499L329.199 238.499H340.699H355.699L366.199 236.499L382.199 230.999L395.699 224.499L408.199 215.499L420.699 203.999L428.199 194.999L433.699 182.999C433.699 182.999 411.199 189.64 378.699 178.499C342.396 166.054 323.516 122.862 285.199 124.999Z"
      fill="var(--DIM_GRAY_OR_BRIGHT_GRAY)"
    />
    <path
      d="M285.199 136.069C258.954 137.384 235.199 158.521 235.199 158.521V159.5L236.699 166.236L240.699 175.5L244.699 182.5L248.699 189L254.199 197.5L258.699 202.5L264.699 208.5L270.199 214L278.699 220L284.199 223.5L292.699 228.57L300.699 232.163L307.199 233.959L317.199 236.204L329.199 238H340.699H355.699L366.199 236.204L382.199 231.264L394.699 224L407.699 216L418.699 205L425.699 197.5L432.199 188C432.199 188 411.199 194.121 378.699 184.115C342.396 172.939 323.516 134.149 285.199 136.069Z"
      fill="var(--DAVY_GRAY_OR_LIGHT_GRAY)"
    />
    <circle
      cx="340.199"
      cy="130.5"
      r="108.5"
      stroke="#A51C30"
      strokeWidth="2"
    />
    <circle cx="340.199" cy="130.5" r="44.5" stroke="#A51C30" strokeWidth="2" />
    <path
      d="M371.614 210.769H343.495L295.602 215.398L247.338 210.769V97.3628L295.402 100.834L344.504 97.3628L371.699 100.834C371.699 100.834 375.699 104 380.699 114.5C385.699 125 391.305 191.009 391.305 191.009L371.614 210.769Z"
      fill="url(#paint0_linear_86_6222)"
    />
    <path
      d="M370.698 209.192H343.194L296.349 213.696L249.141 209.192V98.8513L296.153 102.229L344.181 98.8513L373.699 103C373.699 103 377.073 107.06 378.699 110C380.912 113.999 381.72 116.535 382.699 121C383.373 124.075 383.699 129 383.699 129L389.958 189.966L370.698 209.192Z"
      fill="white"
    />
    <path
      d="M370.853 206.189H341.886L297.7 210.437L253.172 206.189V102.111L297.515 105.297L342.817 102.111L377.699 105.297L380.699 131L387.133 189.905L370.853 206.189Z"
      fill="#A51C30"
    />
    <path
      d="M383.714 193.216L309.204 154.024L325.593 102.569L317.117 101.598L312.732 116.129L281.397 101.598H267.038L310.347 124.197L294.798 178.046L302.601 177.437L306.819 162.092L376.449 200.468L383.714 193.216Z"
      fill="white"
    />
    <path
      d="M368.802 209.02L369.142 187.537L389.958 188.372L368.802 209.02Z"
      fill="url(#paint1_linear_86_6222)"
    />
    <path
      d="M253.172 147.089L317.016 175.202L321.259 183.949L317.035 188.325L253.172 158.203V147.089Z"
      fill="white"
    />
    <path
      d="M370.698 209.021L371.008 189.197L389.958 189.967L370.698 209.021Z"
      fill="white"
    />
    <path
      d="M338.914 190.351L365.553 103.335H370.699L375.199 104.5L346.306 194.902L338.914 190.351Z"
      fill="white"
    />
    <path
      opacity="0.1"
      d="M297.515 210.437L341.859 206.189V102.111L297.515 105.297"
      fill="black"
    />
    <path
      d="M338.128 139.99C338.128 148.397 322.822 172.204 322.822 172.204C322.822 172.204 307.516 148.397 307.516 139.99C307.515 137.99 307.911 136.01 308.68 134.163C309.449 132.315 310.576 130.637 311.997 129.223C313.419 127.809 315.106 126.687 316.963 125.922C318.821 125.156 320.811 124.762 322.822 124.762C324.832 124.762 326.823 125.156 328.68 125.922C330.538 126.687 332.225 127.809 333.647 129.223C335.068 130.637 336.195 132.315 336.964 134.163C337.733 136.01 338.128 137.99 338.128 139.99Z"
      fill="#FF5252"
    />
    <path
      d="M322.822 143.53C325.377 143.53 327.449 141.469 327.449 138.928C327.449 136.386 325.377 134.326 322.822 134.326C320.266 134.326 318.194 136.386 318.194 138.928C318.194 141.469 320.266 143.53 322.822 143.53Z"
      fill="white"
    />
    <path
      d="M360.699 146L385.199 153L387.133 164.157L358.57 154.017L360.699 146Z"
      fill="white"
    />
    <path
      d="M274.88 168.442L268.987 208.979L275.675 210.38L284.485 172.972L284.475 167.67L274.88 168.442Z"
      fill="white"
    />
    <path
      d="M274.642 115.521L268.029 153.632L273.149 156.381L285.354 115.509L274.642 115.521Z"
      fill="white"
    />
    <path
      d="M287.29 109.383C288.067 109.383 288.698 108.756 288.698 107.982C288.698 107.209 288.067 106.582 287.29 106.582C286.512 106.582 285.882 107.209 285.882 107.982C285.882 108.756 286.512 109.383 287.29 109.383Z"
      fill="#FF5252"
    />
    <path
      d="M270.395 181.848C271.172 181.848 271.803 181.221 271.803 180.448C271.803 179.675 271.172 179.048 270.395 179.048C269.617 179.048 268.987 179.675 268.987 180.448C268.987 181.221 269.617 181.848 270.395 181.848Z"
      fill="#FF5252"
    />
    <path
      d="M268.986 192.176C269.764 192.176 270.394 191.549 270.394 190.775C270.394 190.002 269.764 189.375 268.986 189.375C268.209 189.375 267.578 190.002 267.578 190.775C267.578 191.549 268.209 192.176 268.986 192.176Z"
      fill="#FF5252"
    />
    <path
      d="M283.947 189.725C284.724 189.725 285.355 189.098 285.355 188.325C285.355 187.552 284.724 186.925 283.947 186.925C283.169 186.925 282.539 187.552 282.539 188.325C282.539 189.098 283.169 189.725 283.947 189.725Z"
      fill="#FF5252"
    />
    <path
      d="M259.835 147.716C260.612 147.716 261.243 147.089 261.243 146.316C261.243 145.542 260.612 144.915 259.835 144.915C259.057 144.915 258.427 145.542 258.427 146.316C258.427 147.089 259.057 147.716 259.835 147.716Z"
      fill="#FF5252"
    />
    <path
      d="M370.714 146.316C371.492 146.316 372.122 145.689 372.122 144.915C372.122 144.142 371.492 143.515 370.714 143.515C369.937 143.515 369.306 144.142 369.306 144.915C369.306 145.689 369.937 146.316 370.714 146.316Z"
      fill="#FF5252"
    />
    <path
      d="M358.571 194.977C359.348 194.977 359.979 194.35 359.979 193.576C359.979 192.803 359.348 192.176 358.571 192.176C357.793 192.176 357.163 192.803 357.163 193.576C357.163 194.35 357.793 194.977 358.571 194.977Z"
      fill="#FF5252"
    />
    <path
      opacity="0.1"
      d="M362.199 118C368.352 128.325 384.699 139 384.699 139C384.699 139 384.441 129.453 383.199 123.5L383.171 123.367C382.444 119.878 382.021 117.851 380.699 114.5C379.577 111.657 378.74 110.14 377.199 107.5C375.78 105.068 372.199 101 372.199 101L368.699 100.5L355.199 99C355.199 99 358.151 111.207 362.199 118Z"
      fill="#2E2D29"
    />
    <path
      d="M266.699 91C267.804 91 268.699 90.1046 268.699 89C268.699 87.8954 267.804 87 266.699 87C265.595 87 264.699 87.8954 264.699 89C264.699 90.1046 265.595 91 266.699 91Z"
      fill="#A51C30"
    />
    <path
      d="M397.699 109C398.804 109 399.699 108.105 399.699 107C399.699 105.895 398.804 105 397.699 105C396.595 105 395.699 105.895 395.699 107C395.699 108.105 396.595 109 397.699 109Z"
      fill="#A51C30"
    />
    <path
      d="M368.199 85C369.58 85 370.699 83.8807 370.699 82.5C370.699 81.1193 369.58 80 368.199 80C366.818 80 365.699 81.1193 365.699 82.5C365.699 83.8807 366.818 85 368.199 85Z"
      fill="var(--DIM_GRAY_OR_BRIGHT_GRAY)"
    />
    <path
      d="M399.199 136C401.132 136 402.699 134.433 402.699 132.5C402.699 130.567 401.132 129 399.199 129C397.266 129 395.699 130.567 395.699 132.5C395.699 134.433 397.266 136 399.199 136Z"
      fill="var(--DIM_GRAY_OR_BRIGHT_GRAY)"
    />
    <path
      d="M407.199 159C408.028 159 408.699 158.328 408.699 157.5C408.699 156.672 408.028 156 407.199 156C406.371 156 405.699 156.672 405.699 157.5C405.699 158.328 406.371 159 407.199 159Z"
      fill="var(--DIM_GRAY_OR_BRIGHT_GRAY)"
    />
    <path
      d="M418.699 200H414.699V1.69173C414.699 1.24305 414.91 0.812758 415.285 0.495498C415.66 0.178237 416.169 0 416.699 0C417.23 0 417.738 0.178237 418.113 0.495498C418.489 0.812758 418.699 1.24305 418.699 1.69173V200Z"
      fill="var(--DIM_GRAY_OR_BRIGHT_GRAY)"
    />
    <path
      d="M389.22 26.1102H438.772C439.224 26.1097 439.657 25.9301 439.976 25.6109C440.295 25.2916 440.475 24.8588 440.475 24.4074V10.028C440.475 9.57655 440.295 9.14373 439.976 8.8245C439.657 8.50527 439.224 8.3257 438.772 8.3252H389.217C388.926 8.32539 388.641 8.39974 388.387 8.54119L375.572 15.6926C375.308 15.84 375.088 16.0551 374.935 16.3158C374.781 16.5764 374.7 16.8733 374.699 17.1758C374.699 17.4782 374.779 17.7754 374.931 18.0368C375.083 18.2981 375.302 18.5142 375.566 18.6628L388.384 25.8907C388.639 26.0344 388.927 26.11 389.22 26.1102Z"
      fill="#A51C30"
    />
    <path
      d="M402.569 13.95V21H400.609V18.17H398.219V21H396.259V13.95H398.219V16.6H400.609V13.95H402.569ZM409.071 21.07C408.411 21.07 407.804 20.9167 407.251 20.61C406.698 20.2967 406.258 19.8667 405.931 19.32C405.611 18.7667 405.451 18.1433 405.451 17.45C405.451 16.7567 405.611 16.1367 405.931 15.59C406.258 15.0367 406.698 14.6067 407.251 14.3C407.804 13.9933 408.411 13.84 409.071 13.84C409.738 13.84 410.344 13.9933 410.891 14.3C411.444 14.6067 411.881 15.0367 412.201 15.59C412.521 16.1367 412.681 16.7567 412.681 17.45C412.681 18.1433 412.521 18.7667 412.201 19.32C411.881 19.8667 411.444 20.2967 410.891 20.61C410.338 20.9167 409.731 21.07 409.071 21.07ZM409.071 19.25C409.571 19.25 409.964 19.0867 410.251 18.76C410.544 18.4333 410.691 17.9967 410.691 17.45C410.691 16.89 410.544 16.45 410.251 16.13C409.964 15.8033 409.571 15.64 409.071 15.64C408.564 15.64 408.168 15.8033 407.881 16.13C407.594 16.45 407.451 16.89 407.451 17.45C407.451 18.0033 407.594 18.4433 407.881 18.77C408.168 19.09 408.564 19.25 409.071 19.25ZM423.792 13.95V21H421.832V17.11L420.502 21H418.862L417.522 17.08V21H415.562V13.95H417.932L419.702 18.53L421.432 13.95H423.792ZM428.868 15.52V16.67H431.118V18.16H428.868V19.43H431.418V21H426.908V13.95H431.418V15.52H428.868Z"
      fill="white"
    />
    <path
      d="M392.591 58.1385H392.59C391.874 58.1377 391.187 57.8528 390.68 57.3463C390.174 56.8398 389.889 56.1531 389.888 55.4368V55.4357V41.0563V41.0552C389.889 40.339 390.174 39.6522 390.68 39.1457C391.187 38.6392 391.874 38.3543 392.59 38.3535H392.591L442.147 38.3535L442.147 38.3535C442.608 38.3538 443.061 38.4717 443.463 38.6961L443.464 38.6963L456.278 45.8477C456.278 45.8477 456.278 45.8477 456.278 45.8477C456.698 46.0817 457.047 46.4231 457.29 46.8368C457.534 47.2506 457.663 47.7217 457.664 48.2019C457.665 48.682 457.538 49.1537 457.297 49.5686C457.055 49.9834 456.707 50.3263 456.289 50.5621C456.289 50.5621 456.289 50.5621 456.289 50.5622L443.471 57.7901L443.47 57.7904C443.065 58.0183 442.608 58.1382 442.144 58.1385H442.143H392.591Z"
      fill="var(--DIM_GRAY_OR_BRIGHT_GRAY)"
      stroke="var(--RAISING_BLACK_OR_WHITE)"
      strokeWidth="2"
    />
    <path
      d="M392.402 88.6004H392.401C391.684 88.5996 390.998 88.3147 390.491 87.8082C389.985 87.3017 389.7 86.615 389.699 85.8987V85.8976V71.5182V71.5171C389.7 70.8009 389.985 70.1141 390.491 69.6076C390.998 69.1011 391.684 68.8162 392.401 68.8154H392.402L441.957 68.8154L441.958 68.8154C442.419 68.8157 442.872 68.9337 443.274 69.158L443.274 69.1582L456.089 76.3096C456.089 76.3096 456.089 76.3096 456.089 76.3096C456.508 76.5436 456.858 76.885 457.101 77.2987C457.345 77.7125 457.474 78.1837 457.475 78.6638C457.476 79.1439 457.349 79.6156 457.107 80.0305C456.865 80.4453 456.518 80.7882 456.099 81.024C456.099 81.0241 456.099 81.0241 456.099 81.0241L443.281 88.252L443.28 88.2523C442.876 88.4802 442.419 88.6001 441.954 88.6004H441.954H392.402Z"
      fill="var(--DIM_GRAY_OR_BRIGHT_GRAY)"
      stroke="var(--RAISING_BLACK_OR_WHITE)"
      strokeWidth="2"
    />
    <defs>
      <linearGradient
        id="paint0_linear_86_6222"
        x1="319.938"
        y1="215.398"
        x2="319.938"
        y2="97.3628"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#808080" stopOpacity="0.25" />
        <stop offset="0.53514" stopColor="#808080" stopOpacity="0.12" />
        <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_86_6222"
        x1="379.38"
        y1="209.02"
        x2="379.38"
        y2="187.537"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.12" />
        <stop offset="0.55135" stopOpacity="0.09" />
        <stop offset="1" stopOpacity="0.02" />
      </linearGradient>
    </defs>
  </svg>
);

export default NotFound;
