import PropTypes from 'prop-types';
import {
  WrapperSingle,
  Radio,
  Label,
} from 'components/common/RadioButtonGroup/Radio.styled';

const RadioButton = ({
  value,
  label,
  id,
  name,
  defaultChecked,
  onChange,
  fontSize,
  fillColor,
  labelColor,
  outlineColor,
}) => (
  <WrapperSingle>
    <Radio
      outlineColor={outlineColor}
      id={id}
      value={value}
      name={name}
      fillColor={fillColor}
      defaultChecked={defaultChecked}
      onChange={onChange}
    />
    <Label htmlFor={id} labelColor={labelColor} fontSize={fontSize}>
      {label}
    </Label>
  </WrapperSingle>
);

RadioButton.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  fontSize: PropTypes.string.isRequired,
  outlineColor: PropTypes.string.isRequired,
  fillColor: PropTypes.string.isRequired,
  labelColor: PropTypes.string.isRequired,
};

export default RadioButton;
