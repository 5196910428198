import styled from 'styled-components';
import { CRIMSON } from 'constants/colors';
import DEVICE from 'constants/deviceSizes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  margin-top: 30px;
`;

export const WelcomeToHbsTitle = styled.p`
  color: ${CRIMSON};
  margin: 0;
  width: 503px;
  font-weight: 700;
  font-size: 32px;
  line-height: 72px;

  @media ${DEVICE.formResponsive} {
    height: auto;
    font-size: 24px;
    line-height: 36px;
    width: 100%;
  }
`;

export const RedLine = styled.div`
  width: 80px;
  height: 8px;
  margin: 8px 0;
  background: ${CRIMSON};

  @media ${DEVICE.formResponsive} {
    width: 60px;
    height: 4px;
  }
`;

export const BottomContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StayInTouch = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StayInTouchParagraph = styled.p`
  width: 120px;
  height: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 24px 0 0;

  @media ${DEVICE.formResponsive} {
    width: 100%;
    height: 18px;
    font-size: 14px;
    line-height: 18px;
    margin-top: 16px;
  }
`;

export const BottomParagraph = styled.p`
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  width: 410px;
  height: 21px;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 24px;

  @media ${DEVICE.formResponsive} {
    width: 100%;
    height: 17px;
    font-size: 12px;
    line-height: 16px;
  }
`;

export const DescriptionParagraph = styled.p`
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0;
  text-align: left;

  a,
  button {
    font-weight: 540;
    color: ${CRIMSON};

    :hover {
      text-decoration: underline;
    }
  }
  button {
    background: transparent;
    outline: none;
    border: none;
    font-size: inherit;
    font-size: 16px;
    line-height: 27px;
    font-family: 'Poppins', sans-serif;
    padding: 0;
    cursor: pointer;
  }
  @media ${DEVICE.formResponsive} {
    font-size: 14px;
  }
`;
