/* eslint-disable react/prop-types */
import { forwardRef } from 'react';
import CalendarIcon from 'assets/icons/CalendarIcon';
import {
  CalendarIconContainer,
  DateContainer,
  DateText,
  InputFieldWrapper,
} from 'components/common/HbsDatePicker/HbsDatePicker.styled';
import { PlaceHolder } from 'components/common/Dropdown/Select.styled';

const DatePickerInput = forwardRef(({ value, onClick, isError }, ref) => (
  <InputFieldWrapper onClick={onClick} ref={ref} isError={isError}>
    <CalendarIconContainer>
      <CalendarIcon />
    </CalendarIconContainer>
    <DateContainer>
      {value ? (
        <DateText>{value}</DateText>
      ) : (
        <PlaceHolder disabledDarkMode>Click to select a date</PlaceHolder>
      )}
    </DateContainer>
  </InputFieldWrapper>
));

export default DatePickerInput;
