import { BRIGHT_GRAY } from 'constants/colors';
import styled from 'styled-components';

export const ThumbsContainer = styled.aside`
  display: flex;
  align-items: center;
`;

export const Thumb = styled.div`
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid ${BRIGHT_GRAY};
  width: 100px;
  height: 100px;
  padding: 4px;
`;

export const ThumbInner = styled.div`
  display: flex;
  align-items: center;
`;

export const Img = styled.img`
  width: 'auto';
  height: '100%';
  overflow: 'hidden';
  max-width: 100px;
  max-height: 100px;
`;
