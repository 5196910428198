import { create } from 'zustand';

const useInvestorStore = create((set) => ({
  investors: [],
  totalNumOfInvestors: 0,
  setInvestors: (investors, totalNumOfInvestors) =>
    set({ investors, totalNumOfInvestors }),
  appendInvestors: (investors) =>
    set((state) => ({
      investors: [...state.investors, ...investors],
      totalNumOfInvestors: state.totalNumOfInvestors,
    })),
  updateInvestor: (investor) =>
    set((state) => ({
      investors: state.investors.map((c) =>
        c.id === investor.id ? { ...c, ...investor } : c,
      ),
      totalNumOfInvestors: state.totalNumOfInvestors,
    })),
  updateInvestorStatus: (id, status) =>
    set((state) => ({
      investors: state.investors.map((c) =>
        c.id === id ? { ...c, status } : c,
      ),
      totalNumOfInvestors: state.totalNumOfInvestors,
    })),
}));

export default useInvestorStore;
