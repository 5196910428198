import styled from 'styled-components';
import DEVICE from 'constants/deviceSizes';
import { CRIMSON, DAVY_GRAY, RAISIN_BLACK } from 'constants/colors';

export const ResponsePopUpWrapper = styled.div`
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 16px 0;
  width: 768px;
  margin: 0 72px 60px;
  justify-content: center;

  @media ${DEVICE.tablet} {
    height: 80vh;
    margin: 0 42px;
    width: 520px;
  }

  @media ${DEVICE.formResponsive} {
    margin: 0 20px;
    width: 335px;
  }
`;

export const Title = styled.h1`
  color: ${DAVY_GRAY};
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
`;

export const DescriptionParagraph = styled.p`
  color: ${RAISIN_BLACK};
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 16px;
  max-width: 700px;
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: flex-end;
  margin-bottom: 20px;
`;

export const EmailLink = styled.a`
  color: ${CRIMSON};
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  &:hover::after {
    text-decoration: none;
  }
`;
