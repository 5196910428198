import PropTypes from 'prop-types';

const { shape, number, string } = PropTypes;

const useParamsProps = () =>
  shape({
    fullName: string,
    companyName: string,
    category: number,
    checkSize: number,
    geoFocus: number,
    status: string,
    limit: number,
    offset: number,
    created: number,
    updated: number,
  });

export default useParamsProps;
