import {
  InputField,
  InputFieldAndButtonWrapper,
  InputLabel,
} from 'components/common/FormComponents/Input/Input.styled';
import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;

  @media ${DEVICE.laptop} {
    flex-wrap: wrap;
  }

  @media ${DEVICE.tablet} {
    flex-direction: column;
    flex-wrap: auto;
  }
`;

export const FilterGroupWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex: 1;

  @media ${DEVICE.formResponsive} {
    flex-direction: column;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  @media ${DEVICE.laptop} {
    min-width: 300px;
  }

  @media ${DEVICE.formResponsive} {
    min-width: auto;
  }
`;

export const FilterInputFieldWrapper = styled(InputFieldAndButtonWrapper)`
  background-color: transparent;
  border: 1px solid var(--GRAY_80_OR_RAISIN_BLACK_ALPHA_03);
  min-height: 42px;
`;

export const FilterInputField = styled(InputField)`
  color: var(--BRIGHT_GRAY_OR_DARK_CHARCOAL);
  font-size: 12px;
`;

export const FilterLabel = styled(InputLabel)`
  color: var(--BRIGHT_GRAY_OR_DARK_CHARCOAL);
`;
