import CompanyHiringIcon from 'assets/icons/CompaniesHiringIcon';
import NewCompanyIcon from 'assets/icons/NewCompanyIcon';
import UpdatedCompanyIcon from 'assets/icons/UpdatedCompanyIcon';

export const NUM_DAYS_FOR_STATISTICS = 14;

export const STATISTICS_KEYS = {
  CREATED: 'created',
  UPDATED: 'updated',
  HIRING: 'hiring',
};

export const STATISTICS_PARAMS = {
  [STATISTICS_KEYS.CREATED]: {
    created: NUM_DAYS_FOR_STATISTICS,
  },
  [STATISTICS_KEYS.UPDATED]: {
    updated: NUM_DAYS_FOR_STATISTICS,
  },
  [STATISTICS_KEYS.HIRING]: {
    created: NUM_DAYS_FOR_STATISTICS,
    hiring: true,
  },
};

export const INITIAL_STATISTICS = {
  [STATISTICS_KEYS.CREATED]: '0',
  [STATISTICS_KEYS.UPDATED]: '0',
  [STATISTICS_KEYS.HIRING]: '0',
};

export const statisticsProperties = {
  [STATISTICS_KEYS.CREATED]: {
    svg: <NewCompanyIcon />,
    description: `New companies in the last ${NUM_DAYS_FOR_STATISTICS} days`,
  },
  [STATISTICS_KEYS.UPDATED]: {
    svg: <UpdatedCompanyIcon />,
    description: `Edited companies in the last ${NUM_DAYS_FOR_STATISTICS} days`,
  },
  [STATISTICS_KEYS.HIRING]: {
    svg: <CompanyHiringIcon />,
    description: `Companies are hiring in the last ${NUM_DAYS_FOR_STATISTICS} days`,
  },
};
