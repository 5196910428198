import PropTypes from 'prop-types';
import {
  Wrapper,
  Title,
  DescriptionParagraph,
  ButtonsContainer,
  BUTTONS_CSS,
} from 'pages/AdminPage/components/Forms/ExclusiveResource/DeleteExclusiveResource/DeleteExclusiveResource.styled';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';

const ConfirmPrompt = ({ numberOfExclusiveResources, closeForm, nextStep }) => (
  <Wrapper>
    <Title>Delete resource</Title>
    <DescriptionParagraph>{`Are you sure you want to delete ${numberOfExclusiveResources} ${
      numberOfExclusiveResources > 1 ? 'resources' : 'resource'
    }?`}</DescriptionParagraph>
    <ButtonsContainer>
      <SecondaryButton $ {...BUTTONS_CSS.secondary} onClick={closeForm}>
        No, Keep
      </SecondaryButton>
      <PrimaryButton $ {...BUTTONS_CSS.primary} onClick={nextStep}>
        Yes, Delete
      </PrimaryButton>
    </ButtonsContainer>
  </Wrapper>
);

ConfirmPrompt.propTypes = {
  numberOfExclusiveResources: PropTypes.number.isRequired,
  closeForm: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default ConfirmPrompt;
