const getCategoryColor = (categoryName, list) => {
  const listOfKeys = Object.keys(list);
  const matchColor = listOfKeys.find((color) =>
    list[color].includes(categoryName),
  );

  return matchColor || null;
};

export default getCategoryColor;

export const getInitials = (string) =>
  string
    .split(' ')
    .map(([firstLetter]) => firstLetter)
    .filter((_, index, array) => index === 0 || index === array.length - 1)
    .join('')
    .toUpperCase();
