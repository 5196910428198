import {
  CaptchaWrapper,
  ErrorMessage,
} from 'components/common/Recaptcha/Recaptcha.styled';
import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { submitCaptcha } from 'services/requests';

const Recaptcha = ({ setCaptchaState }) => {
  const recaptchaRef = useRef(null);
  const [error, setError] = useState(false);

  const handleSubmit = async () => {
    const captchaToken = await recaptchaRef.current.getValue();

    try {
      const { status } = await submitCaptcha(captchaToken);
      if (status === 200) {
        setError(false);
        setCaptchaState(true);
      }
    } catch (e) {
      setError(true);
      recaptchaRef.current.reset();
      setCaptchaState(false);
    }
  };

  return (
    <CaptchaWrapper>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
        onChange={handleSubmit}
      >
        {error && (
          <ErrorMessage>Something went wrong, please try again.</ErrorMessage>
        )}
      </ReCAPTCHA>
    </CaptchaWrapper>
  );
};

Recaptcha.propTypes = {
  setCaptchaState: PropTypes.func.isRequired,
};

export default Recaptcha;
