import SymphonyLetterLogo from 'assets/icons/SymphonyLetterLogo';
import SymphonyLogo from 'assets/icons/SymphonyLogo';
import { SYMPHONY_WEBSITE } from 'constants/other';
import gaSymphonyVisit from 'utils/gaSymphonyVisit';

import {
  FloatingAnchorPartnerContainer,
  AnchorPartnerContent,
  AnchorPartnerDetails,
  LearnMoreLink,
  SymphonyLetterLogoWrapper,
} from './FloatingAnchorPartner.styled';

const FloatingAnchorPartner = () => {
  return (
    <FloatingAnchorPartnerContainer>
      <SymphonyLetterLogoWrapper>
        <SymphonyLetterLogo />
      </SymphonyLetterLogoWrapper>
      <AnchorPartnerContent>
        <AnchorPartnerDetails
          href={SYMPHONY_WEBSITE}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.stopPropagation();
            gaSymphonyVisit('floating_anchor_partner');
          }}
        >
          <p>Anchor partner </p>
          <SymphonyLogo />
        </AnchorPartnerDetails>
        <LearnMoreLink to="/about#anchor-partner">Claim offer</LearnMoreLink>
      </AnchorPartnerContent>
    </FloatingAnchorPartnerContainer>
  );
};

export default FloatingAnchorPartner;
