import {
  Container,
  RedLine,
  StayInTouch,
  WelcomeToHbsTitle,
  StayInTouchParagraph,
  BottomParagraph,
  BottomContentWrapper,
  DescriptionParagraph,
} from 'pages/AboutPage/AboutSection/AboutSection.styled';
import Subscribe from 'components/common/SubscribeInput/SubscribeInput';
import PARAGRAPHS, {
  LINKS,
  LINK_KEYS,
} from 'pages/AboutPage/AboutSection/constants';
import { ADD_A_COMPANY } from 'components/Forms/Company/constants';
import { useAppContext } from 'contexts/AppContext';

const WelcomeToHbs = () => {
  const { setActiveForm } = useAppContext();

  return (
    <Container>
      <WelcomeToHbsTitle>About us</WelcomeToHbsTitle>
      <RedLine />
      <div>
        <DescriptionParagraph>
          {PARAGRAPHS.ABOUT[0][0]}
          <a href={LINKS[LINK_KEYS.GSBUILDS]}>{LINK_KEYS.GSBUILDS}</a>
          {PARAGRAPHS.ABOUT[0][1]}
        </DescriptionParagraph>
        <DescriptionParagraph>
          {PARAGRAPHS.ABOUT[1]}
          <button onClick={() => setActiveForm(ADD_A_COMPANY)} type="button">
            {LINK_KEYS.HERE}
          </button>
          .
        </DescriptionParagraph>
        <DescriptionParagraph>{PARAGRAPHS.ABOUT[2]}</DescriptionParagraph>
        <DescriptionParagraph>{PARAGRAPHS.ABOUT[3]} </DescriptionParagraph>
        <DescriptionParagraph>{PARAGRAPHS.ABOUT[4]}</DescriptionParagraph>
      </div>
      <BottomContentWrapper>
        <StayInTouch>
          <StayInTouchParagraph>Stay in touch!</StayInTouchParagraph>
          <BottomParagraph>
            Receive a Quarterly Roundup of HBS-related startup news
          </BottomParagraph>
        </StayInTouch>
        <Subscribe />
      </BottomContentWrapper>
    </Container>
  );
};

export default WelcomeToHbs;
