import styled from 'styled-components';

const StyledAlert = styled.div`
  display: flex;
  border-color: transparent;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background: ${(props) => props.bgColor};
  padding: 0 2%;
  gap: 10px;
  min-height: 40px;
`;

const StyledAlertText = styled.p`
  color: ${(props) => props.textColor};
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
`;

export { StyledAlert, StyledAlertText };
