import { CRIMSON, DARK_CHARCOAL, SILVER_SAND } from 'constants/colors';
import styled from 'styled-components';

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 15px;
  gap: 5px;
`;

const CheckboxLabel = styled.label`
  color: ${(props) =>
    props.disabledDarkMode
      ? DARK_CHARCOAL
      : 'var(--BRIGHT_GRAY_OR_DARK_CHARCOAL)'};

  font-size: 12px;
  margin-bottom: -2px;
`;

const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  accent-color: ${CRIMSON};
  width: 16px;
  height: 16px;
  background-color: ${CRIMSON};
  border: none;
  margin-right: 5px;
  border: 1px solid ${SILVER_SAND};
  cursor: pointer;
`;

export { CheckboxWrapper, CheckboxLabel, CheckboxInput };
