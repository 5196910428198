import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'components/common/Modal/Modal';
import {
  LoadingSpinner,
  AbsoluteLoadingSpinnerContainer,
} from 'components/common/LoadingSpinner/LoadingSpinner.styled';
import useFetchPublicResourceById from 'pages/AdminPage/components/Forms/PublicResource/EditPublicResource/hooks/useFetchPublicResourceById';
import usePublicResourceOptions from 'pages/AdminPage/components/Forms/PublicResource/EditPublicResource/hooks/usePublicResourceOptions';
import EditPublicCombinedSteps from 'pages/AdminPage/components/Forms/PublicResource/EditPublicResource/EditPublicCombinedSteps';

const EditPublicResource = () => {
  const {
    state: { ids },
  } = useLocation();

  const navigate = useNavigate();
  const closeForm = () =>
    navigate({ pathname: '/administration/public-resource' });

  const { isLoading, initialPublicResourceData } =
    useFetchPublicResourceById(ids);

  const { isFetchingOptions, targetAudiences, publicDealCategories } =
    usePublicResourceOptions();

  if (isLoading || isFetchingOptions)
    return (
      <AbsoluteLoadingSpinnerContainer>
        <LoadingSpinner />
      </AbsoluteLoadingSpinnerContainer>
    );

  if (!initialPublicResourceData) return null;

  return (
    <Modal closeForm={closeForm} closeOnEscape>
      <EditPublicCombinedSteps
        closeForm={closeForm}
        initialPublicResourceData={initialPublicResourceData}
        targetAudiences={targetAudiences}
        publicDealCategories={publicDealCategories}
      />
    </Modal>
  );
};

export default EditPublicResource;
