import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const FilterControlsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin: 20px 0;
  justify-content: space-between;

  @media ${DEVICE.tablet} {
    gap: 20px;
    flex-direction: column-reverse;
  }
`;

export const ToggleCompanyFiltersButton = styled.button`
  align-items: center;
  background: transparent;
  border: 1px solid var(--GRAY_80_OR_RAISIN_BLACK_ALPHA_03);
  border-radius: 4px;
  cursor: pointer;
  color: var(--GRAY_80_OR_DAVY_GRAY);
  display: flex;
  font-size: 12px;
  font-weight: 600;
  gap: 8px;
  justify-content: center;
  width: 120px;

  svg > path {
    fill: var(--GRAY_80_OR_DAVY_GRAY);
  }
`;

export const ToggleWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
