/* eslint-disable no-alert */
import { useCallback } from 'react';

const useRequest = () => {
  const request = useCallback(async (requestFunction) => {
    try {
      const response = await requestFunction();

      return response;
    } catch (error) {
      window.alert(
        `${error.response.status} : ${
          error.response.data
            ? `${Object.values(error.response.data)}`
            : 'Something went wrong. Try again later...'
        }`,
      );

      return null;
    }
  }, []);

  return { request };
};

export default useRequest;
