import PropTypes from 'prop-types';
import {
  ClearResourcesFiltersButton,
  FilterControlsContainer,
} from './FilterControls.styled';
import ToggleFilters from './ToggleFilters';

const FilterControls = ({ clearFilters, toggleFilters }) => (
  <FilterControlsContainer>
    <ToggleFilters toggleFilters={toggleFilters} />
    <ClearResourcesFiltersButton onClick={() => clearFilters()} Border="none">
      Clear filters
    </ClearResourcesFiltersButton>
  </FilterControlsContainer>
);

FilterControls.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  toggleFilters: PropTypes.func.isRequired,
};

export default FilterControls;
