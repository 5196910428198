import {
  SymphonyContactBoldParagraph,
  SymphonyContactContainer,
  SymphonyContactDescription,
  SymphonyContactParagraph,
} from 'pages/AboutPage/FoundersSection/SymphonyContact/SymphonyContact.styled';
import {
  CONTACT_SYMPHONY_BUTTON_CSS,
  DESC_TEXT,
} from 'pages/AboutPage/FoundersSection/SymphonyContact/constants';
import { PrimaryButton } from 'components/common/Button/Button.styled';
import Spacer from 'components/common/Spacer/Spacer.styled';
import SymphonyLogo from 'assets/icons/SymphonyLogo';
import { SYMPHONY_WEBSITE } from 'constants/other';
import gaSymphonyVisit from 'utils/gaSymphonyVisit';

const SymphonyContact = () => (
  <SymphonyContactContainer>
    <SymphonyLogo />
    <SymphonyContactDescription>
      <SymphonyContactParagraph>{DESC_TEXT.normal}</SymphonyContactParagraph>
      <SymphonyContactBoldParagraph>
        {DESC_TEXT.bold}
      </SymphonyContactBoldParagraph>
    </SymphonyContactDescription>
    <a
      onClick={(e) => {
        e.stopPropagation();
        gaSymphonyVisit('about_page');
      }}
      href={SYMPHONY_WEBSITE}
      target="_blank"
      rel="noreferrer"
    >
      <PrimaryButton {...CONTACT_SYMPHONY_BUTTON_CSS}>
        Claim Offer
      </PrimaryButton>
    </a>
    <Spacer Height="40px" />
  </SymphonyContactContainer>
);

export default SymphonyContact;
