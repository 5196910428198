import styled from 'styled-components';
import DEVICE from 'constants/deviceSizes';
import { CRIMSON, RAISIN_BLACK } from 'constants/colors';

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 51px;
  width: 100%;
`;

export const Step = styled.p`
  color: ${CRIMSON};
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
  text-align: left;
`;

export const Title = styled.p`
  color: ${RAISIN_BLACK};
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  margin: 0;
  text-align: left;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  height: 142px;
  justify-content: flex-end;

  @media ${DEVICE.formResponsive} {
    justify-content: center;
  }
`;
