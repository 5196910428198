import { CRIMSON } from 'constants/colors';
import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const WelcomeScreenContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: var(--RAISING_BLACK_OR_WHITE);
  z-index: 1000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.5s ease;
`;

export const WelcomeScreenRedBorder = styled.div`
  width: 100px;
  height: 4px;
  background-color: ${CRIMSON};
`;

export const WelcomeScreenTitle = styled.h1`
  font-size: 36px;
  margin: 24px 0;
  text-align: center;

  @media ${DEVICE.tablet} {
    font-size: 24px;
  }
`;

export const WelcomeScreenSubtitle = styled.p`
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  margin: 0;
  text-align: center;

  @media ${DEVICE.tablet} {
    font-size: 12px;
  }
`;
