import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Controls from 'pages/AdminPage/components/AdminTable/AdditionalElements/Controls/Controls';

const CompanyControlCell = ({ isDeleted, id }) => {
  const navigate = useNavigate();

  const openDeleteModalForId = (e) => {
    e.stopPropagation();

    navigate('/administration/companies/delete', { state: { ids: [id] } });
  };

  const openEditModalForId = (e) => {
    e.stopPropagation();

    navigate('/administration/companies/edit', { state: { ids: [id] } });
  };

  const handleRestore = (e) => {
    e.stopPropagation();

    navigate('/administration/companies/restore', { state: { ids: [id] } });
  };

  return (
    <Controls
      openDeleteModalForId={openDeleteModalForId}
      openEditModalForId={openEditModalForId}
      handleRestore={handleRestore}
      isDeleted={isDeleted}
    />
  );
};

CompanyControlCell.propTypes = {
  id: PropTypes.number.isRequired,
  isDeleted: PropTypes.bool.isRequired,
};

export default CompanyControlCell;
