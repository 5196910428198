export const getCurrentDate = () => new Date();

export const getDateFromIsoString = (dateString) => new Date(dateString);

export const getDaysDiff = (date1, date2) =>
  (date1.getTime() - date2.getTime()) / (1000 * 3600 * 24);

export const formatDate = (isoDate) =>
  new Date(isoDate).toLocaleDateString('en-US');

export const mapPublicData = (publicDeal) => ({
  name: publicDeal.dealName,
  description: publicDeal.dealDescription,
  url: publicDeal.dealUrl || '',
  image: publicDeal.dealImage,
  expiringDate: publicDeal.expiringDate,
  categories: publicDeal.categories,
  targetAudiences: publicDeal.targetAudiences,
  isExclusive: false,
});

export const mapExclusiveData = (exclusiveDeal) => ({
  name: exclusiveDeal.companyName,
  description: exclusiveDeal.companyDescription,
  url: exclusiveDeal.companyWebsite || '',
  image: exclusiveDeal.companyLogo,
  expiringDate: exclusiveDeal.expiringDate,
  categories: exclusiveDeal.categories,
  discountPricing: exclusiveDeal.dealDescription || '',
  promotion: exclusiveDeal.promotion || '',
  isExclusive: true,
});
