import { useEffect, useState } from 'react';
import useRequest from 'hooks/useRequest';
import { useAppContext } from 'contexts/AppContext';
import { REQUEST_DEBOUNCE_DURATION } from 'constants/input_debounce_duration';
import Modal from 'components/common/Modal/Modal';
import NoResults from 'components/common/NoResults/NoResults';
import FloatingAnchorPartner from 'components/common/FloatingAnchorPartner/FloatingAnchorPartner';
import InvestorList from 'pages/FundingPage/components/InvestorsContainer/InvestorList';
import {
  LoadingSpinnerContainer,
  LoadingSpinner,
} from 'components/common/LoadingSpinner/LoadingSpinner.styled';
import InvestorPopup from 'pages/FundingPage/components/InvestorPopup/InvestorPopup';
import { getAllInvestors } from 'services/requests';
import augmentInvestorsWithCardColor from 'pages/FundingPage/utils';
import InvestorFilters from 'pages/FundingPage/components/InvestorFilters/InvestorFilters';
import useInvestorParams, {
  INITIAL_OFF_SET,
} from 'pages/FundingPage/components/InvestorFilters/useInvestorParams/useInvestorParams';
import {
  InvestorCountPreview,
  LoadMoreContainer,
} from 'pages/FundingPage/components/InvestorsContainer/InvestorsContainer.styled';
import BackToTop from 'pages/HomePage/components/BackToTop/BackToTop';
import { SecondaryButton } from 'components/common/Button/Button.styled';

const InvestorsContainer = () => {
  const {
    investorQueryParams,
    setFullName,
    setCompanyName,
    setCategory,
    setCheckSize,
    setGeoFocus,
    setPaginationOffset,
    resetFilters,
  } = useInvestorParams();

  const { user } = useAppContext();

  const [data, setData] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const updateLoadMoreConfig = () =>
    setPaginationOffset(investorQueryParams.offset + investorQueryParams.limit);

  const [previewInvestor, setPreviewInvestor] = useState(null);
  const [fetching, setFetching] = useState(false);

  const { request } = useRequest();

  useEffect(() => {
    let ignore = false;

    const getInvestors = async () => {
      setFetching(true);

      const responseData = await getAllInvestors(investorQueryParams);
      const investors = augmentInvestorsWithCardColor(responseData.data.data);

      if (!ignore && responseData) {
        if (investorQueryParams.offset !== INITIAL_OFF_SET) {
          setData((prevState) => [...prevState, ...investors]);
        } else {
          setData(investors);
        }

        setTotalRowCount(responseData.data.total);
      }

      setFetching(false);
    };

    const getInvestorDebounced = setTimeout(
      getInvestors,
      REQUEST_DEBOUNCE_DURATION,
    );

    return () => {
      clearTimeout(getInvestorDebounced);
      ignore = true;
    };
  }, [request, investorQueryParams]);

  const closePopup = () => setPreviewInvestor(null);

  const displayCompanies = () => {
    if (fetching)
      return (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      );

    if (!data.length) return <NoResults />;

    return (
      <InvestorList
        fetching={fetching}
        data={data}
        setPreviewInvestor={setPreviewInvestor}
        isUserLoggedIn={!!user}
      />
    );
  };

  return (
    <>
      {!!previewInvestor && (
        <Modal closeOnEscape closeForm={closePopup}>
          <InvestorPopup {...previewInvestor} isUserLoggedIn={!!user} />
        </Modal>
      )}
      <InvestorFilters
        investorQueryParams={investorQueryParams}
        setFullName={setFullName}
        setCompanyName={setCompanyName}
        setCategory={setCategory}
        setCheckSize={setCheckSize}
        setGeoFocus={setGeoFocus}
        resetFilters={resetFilters}
      />
      {displayCompanies()}
      <LoadMoreContainer>
        {fetching && (
          <LoadingSpinnerContainer>
            <LoadingSpinner />
          </LoadingSpinnerContainer>
        )}
        {data.length < totalRowCount && (
          <SecondaryButton
            Border="none"
            TextDecoration="underline"
            FontWeight="600"
            FontSize="12px"
            type="button"
            onClick={() => updateLoadMoreConfig()}
          >
            Load more <br />
          </SecondaryButton>
        )}
        <InvestorCountPreview>
          {`${data.length}
          of ${totalRowCount} entries`}
          {investorQueryParams.offset ? <BackToTop /> : null}
        </InvestorCountPreview>
      </LoadMoreContainer>
      <FloatingAnchorPartner />
    </>
  );
};

export default InvestorsContainer;
