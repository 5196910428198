import { SIGN_UP_FIELD_NAME_KEYS } from 'components/Forms/SignForm/constants';

const passwordValidator = (value) => value.length >= 7;

const repeatPasswordValidator = (value) => {
  const passwordElements = document.getElementsByName(
    SIGN_UP_FIELD_NAME_KEYS.PASSWORD,
  );

  return passwordElements.length > 0 && value === passwordElements[0].value;
};

export { passwordValidator, repeatPasswordValidator };
