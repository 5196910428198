import styled from 'styled-components';

export const NoResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50vh;
  margin-top: 120px;
`;

export const NoResultsMessage = styled.p`
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
`;

export const Path = styled.path`
  fill: ${(props) =>
    props.pathVarColor ? `var(${props.pathVarColor})` : props.pathColor};
`;
