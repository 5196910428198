const { useState } = require('react');

const useCompanyPreview = () => {
  const [previewCompany, setPreviewCompany] = useState(null);
  const handleRowClick = (companyData) => setPreviewCompany(companyData);
  const closePopup = () => setPreviewCompany(null);

  return { previewCompany, handleRowClick, closePopup };
};

export default useCompanyPreview;
