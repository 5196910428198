import { resourcesParamsActions } from 'pages/ResourcePage/constants';

export const changeFilterOrPaginationParam = (field, data) => ({
  type: resourcesParamsActions.SET_FILTER_OR_PAGINATION_PARAM,
  payload: { field, data },
});

export const changeSortType = (sortType) => ({
  type: resourcesParamsActions.SET_SORT_PARAM,
  payload: { sortType },
});

export const setInitialQueryParams = () => ({
  type: resourcesParamsActions.SET_INITIAL_QUERY_PARAMS,
});
