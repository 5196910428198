import styled from 'styled-components';

export const PageNavigatorWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 30px;
  gap: 3px;
  padding: 2px 0 0;
`;

export const ArrowWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 30px;
  cursor: pointer;
`;

export const PageNavigatorParagraph = styled.p`
  font-size: 14px;
  margin: 0;
`;
