import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useRequest from 'hooks/useRequest';
import {
  getAllCheckSizes,
  getAllGeologicalLocations,
  getAllInvestorCategories,
} from 'services/requests';
import {
  mapCategories,
  mapCheckSizes,
} from 'pages/FundingPage/components/InvestorFilters/utils';
import investorFiltersSchema from 'pages/FundingPage/components/InvestorFilters/schema';
import { INVESTOR_PARAMS } from 'pages/FundingPage/components/InvestorFilters/constants';
import useParamsProps from 'pages/FundingPage/components/InvestorFilters/useInvestorParams/propTypes';
import FilterInput from 'components/common/Filters/FilterInput/FilterInput';
import { FiltersContainer } from 'components/common/Filters/Filters.styled';
import FilterSelect from 'pages/AdminPage/components/Filters/FilterSelect/FilterSelect';
import { mapGeographicFocus } from 'components/Forms/Investor/StepTwo/utils';

const Filters = ({
  investorQueryParams,
  setFullName,
  setCompanyName,
  setCategory,
  setCheckSize,
  setGeoFocus,
}) => {
  const [categories, setCategories] = useState([]);
  const [checkSizes, setCheckSizes] = useState([]);
  const [geoFocuses, setGeoFocuses] = useState([]);

  const { request } = useRequest();

  useEffect(() => {
    let ignore = false;

    const fetchCategories = async () => {
      const response = await request(() => getAllInvestorCategories());

      if (!ignore && response) setCategories(mapCategories(response.data));
    };

    const fetchCheckSizes = async () => {
      const response = await request(() => getAllCheckSizes());

      if (!ignore && response) setCheckSizes(mapCheckSizes(response.data));
    };

    const fetchGeoFocuses = async () => {
      const response = await request(() => getAllGeologicalLocations());

      if (!ignore && response) setGeoFocuses(mapGeographicFocus(response.data));
    };

    fetchCategories();
    fetchCheckSizes();
    fetchGeoFocuses();

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <FiltersContainer>
      <FilterInput
        {...investorFiltersSchema.FULL_NAME}
        value={investorQueryParams[INVESTOR_PARAMS.FULL_NAME]}
        setValue={setFullName}
      />
      <FilterInput
        {...investorFiltersSchema.COMPANY_NAME}
        value={investorQueryParams[INVESTOR_PARAMS.COMPANY_NAME]}
        setValue={setCompanyName}
      />
      <FilterSelect
        {...investorFiltersSchema.CATEGORY}
        options={categories}
        value={investorQueryParams[INVESTOR_PARAMS.CATEGORY]}
        setValue={setCategory}
      />
      <FilterSelect
        {...investorFiltersSchema.CHECK_SIZES}
        options={checkSizes}
        value={investorQueryParams[INVESTOR_PARAMS.CHECK_SIZE]}
        setValue={setCheckSize}
      />
      <FilterSelect
        {...investorFiltersSchema.GEO_FOCUS}
        options={geoFocuses}
        value={investorQueryParams[INVESTOR_PARAMS.GEO_FOCUS]}
        setValue={setGeoFocus}
      />
    </FiltersContainer>
  );
};

Filters.propTypes = {
  investorQueryParams: useParamsProps().isRequired,
  setFullName: PropTypes.func.isRequired,
  setCompanyName: PropTypes.func.isRequired,
  setCategory: PropTypes.func.isRequired,
  setCheckSize: PropTypes.func.isRequired,
  setGeoFocus: PropTypes.func.isRequired,
};

export default Filters;
