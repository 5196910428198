import PropTypes from 'prop-types';
import {
  ClickableDiv,
  FieldContainer,
  RowContainer,
} from 'components/Forms/Company/StepThree/StepThree.styled';
import STEP_THREE_KEYS from 'components/Forms/Company/StepThree/constants';
import SingleSelect from 'components/common/Dropdown/SingleSelect';
import { generateYearOptions } from 'components/Forms/Company/StepTwo/utils';
import Spacer from 'components/common/Spacer/Spacer.styled';
import BinIcon from 'assets/icons/BinIcon';
import FormInput from 'components/common/FormComponents/Input/FormInput/FormInput';
import {
  founderInputProps,
  founderSelectProps,
} from 'components/Forms/Company/StepThree/Founder/propTypes';
import SingleCheckbox from 'components/common/CheckBox/SingleCheckBox/SingleCheckBox';
import { useAppContext } from 'contexts/AppContext';
import { CONTACT_EMAIL, WILLS_EMAIL } from 'constants/other';

const Founder = ({ founder, setFounder, position, removeFounder }) => {
  const yearOptions = generateYearOptions(4);
  const { user } = useAppContext();

  const onChangeHandler =
    (key) =>
    ({ target: { value } }) =>
      setFounder({
        ...founder,
        [key]: {
          ...founder[key],
          value,
          isError: founder[key].isError && !founder[key].validator(value),
        },
      });

  const onBlurHandler =
    (key) =>
    ({ target: { value } }) => {
      setFounder({
        ...founder,
        [key]: {
          ...founder[key],
          isError: !founder[key].validator(value),
        },
      });
    };

  const selectionHandler = (collection) => (option) =>
    setFounder({
      ...founder,
      [STEP_THREE_KEYS.CLASS_YEAR]: {
        ...founder[STEP_THREE_KEYS.CLASS_YEAR],
        selectedValue:
          option && collection.find((object) => object.label === option.label),
        isError: false,
      },
    });

  const checkboxHandler = (key) =>
    setFounder({
      ...founder,
      [key]: {
        ...founder[key],
        checked: !founder[key].checked,
      },
    });

  return (
    <>
      <RowContainer>
        <FieldContainer>
          <ClickableDiv>
            <p>HBSBer #{position} on Founding Team</p>
            {position !== 1 && (
              <button onClick={(e) => removeFounder(e, position)} type="button">
                <BinIcon />
              </button>
            )}
          </ClickableDiv>
          <Spacer Height="30px" />
        </FieldContainer>
      </RowContainer>

      <RowContainer>
        <FieldContainer>
          <FormInput
            {...founder[STEP_THREE_KEYS.FULL_NAME]}
            name={`${position}: ${STEP_THREE_KEYS.FULL_NAME}`}
            onChangeHandler={onChangeHandler(STEP_THREE_KEYS.FULL_NAME)}
            onBlurHandler={onBlurHandler(STEP_THREE_KEYS.FULL_NAME)}
            isDisabled={
              position === 1 && (user ? user.hbsEmail !== WILLS_EMAIL : true)
            }
          />
        </FieldContainer>
        <FieldContainer>
          <SingleSelect
            {...founder[STEP_THREE_KEYS.CLASS_YEAR]}
            options={yearOptions}
            onChangeHandler={selectionHandler(yearOptions)}
            selectedValue={founder[STEP_THREE_KEYS.CLASS_YEAR].selectedValue}
            disabledDarkMode
          />
          <Spacer Height="20px" />
        </FieldContainer>
      </RowContainer>
      <RowContainer>
        <FormInput
          {...founder[STEP_THREE_KEYS.EMAIL]}
          name={`${position}: ${STEP_THREE_KEYS.EMAIL}`}
          onChangeHandler={onChangeHandler(STEP_THREE_KEYS.EMAIL)}
          onBlurHandler={onBlurHandler(STEP_THREE_KEYS.EMAIL)}
          isDisabled={
            position === 1 && (user ? user.hbsEmail !== CONTACT_EMAIL : true)
          }
        />
      </RowContainer>
      <RowContainer>
        <FormInput
          {...founder[STEP_THREE_KEYS.ALTERNATIVE_EMAIL]}
          name={`${position}: ${STEP_THREE_KEYS.ALTERNATIVE_EMAIL}`}
          onChangeHandler={onChangeHandler(STEP_THREE_KEYS.ALTERNATIVE_EMAIL)}
          onBlurHandler={onBlurHandler(STEP_THREE_KEYS.ALTERNATIVE_EMAIL)}
        />
      </RowContainer>
      <RowContainer>
        <SingleCheckbox
          {...founder[STEP_THREE_KEYS.DISPLAY_ALTERNATIVE_EMAIL]}
          onChangeHandler={() =>
            checkboxHandler(STEP_THREE_KEYS.DISPLAY_ALTERNATIVE_EMAIL)
          }
        />
        <Spacer Height="40px" />
      </RowContainer>
      <RowContainer>
        <FormInput
          {...founder[STEP_THREE_KEYS.FOUNDER_PHONE]}
          name={`${position}: ${STEP_THREE_KEYS.FOUNDER_PHONE}`}
          onChangeHandler={onChangeHandler(STEP_THREE_KEYS.FOUNDER_PHONE)}
          onBlurHandler={onBlurHandler(STEP_THREE_KEYS.FOUNDER_PHONE)}
        />
      </RowContainer>
      <RowContainer>
        <SingleCheckbox
          {...founder[STEP_THREE_KEYS.DISPLAY_FOUNDER_PHONE]}
          onChangeHandler={() =>
            checkboxHandler(STEP_THREE_KEYS.DISPLAY_FOUNDER_PHONE)
          }
        />
        <Spacer Height="40px" />
      </RowContainer>
      <RowContainer>
        <FormInput
          {...founder[STEP_THREE_KEYS.LINKEDIN]}
          name={`${position}: ${STEP_THREE_KEYS.LINKEDIN}`}
          onChangeHandler={onChangeHandler(STEP_THREE_KEYS.LINKEDIN)}
          onBlurHandler={onBlurHandler(STEP_THREE_KEYS.LINKEDIN)}
        />
      </RowContainer>
    </>
  );
};

Founder.propTypes = {
  founder: PropTypes.shape({
    fullName: founderInputProps(),
    classYear: founderInputProps(),
    founderLinkedin: founderSelectProps(),
    founderEmail: founderInputProps(),
    alternativeEmail: founderInputProps(),
    phone: founderInputProps(),
  }).isRequired,
  setFounder: PropTypes.func.isRequired,
  position: PropTypes.number.isRequired,
  removeFounder: PropTypes.func.isRequired,
};

export default Founder;
