const repackInvestorFormState = (formState) => ({
  fullName: formState.investorFullName,
  investorEmail: formState.email,
  vcFirmName: formState.investorVCFirm,
  classYear:
    formState.mbaGradYear === null
      ? formState.mbaGradYear
      : +formState.mbaGradYear.label,
  checkSizes: formState.checkSize.map((checkSize) => checkSize.id),
  fundingStages: formState.fundingStage.map((fundingStage) => fundingStage.id),
  geographicFocus: formState.geographicFocus.map(
    (geographicFocus) => geographicFocus.id,
  ),
  categories: formState.categories.map((category) => category.id),
});

export default repackInvestorFormState;
