import { create } from 'zustand';

const useAdminPublicResourceStore = create((set) => ({
  publicResources: [],
  totalNumOfPublicResources: 0,
  setPublicResources: (publicResources, totalNumOfPublicResources) =>
    set({ publicResources, totalNumOfPublicResources }),
  updatePublicResource: (publicResource) =>
    set((state) => ({
      publicResources: state.publicResources.map((c) =>
        c.id === publicResource.id ? { ...c, ...publicResource } : c,
      ),
      totalNumOfPublicResources: state.totalNumOfPublicResources,
    })),
  deleteMultiplePublicResources: (ids) =>
    set((state) => ({
      publicResources: state.publicResources.map((c) =>
        ids.includes(c.id) ? { ...c, deletedAt: new Date().toISOString() } : c,
      ),
      totalNumOfPublicResources: state.totalNumOfPublicResources,
    })),
  restoreMultiplePublicResources: (ids) =>
    set((state) => ({
      publicResources: state.publicResources.map((c) =>
        ids.includes(c.id) ? { ...c, deletedAt: null } : c,
      ),
      totalNumOfPublicResources: state.totalNumOfPublicResources,
    })),
}));

export default useAdminPublicResourceStore;
