const STEP_THREE_KEYS = {
  FULL_NAME: 'fullName',
  CLASS_YEAR: 'classYear',
  EMAIL: 'founderEmail',
  LINKEDIN: 'founderLinkedin',
  FOUNDER_ATTRIBUTES: 'founderAttributes',
  FOUNDER_PHONE: 'phone',
  DISPLAY_FOUNDER_PHONE: 'displayPhone',
  ALTERNATIVE_EMAIL: 'alternativeEmail',
  DISPLAY_ALTERNATIVE_EMAIL: 'displayAlternativeEmail',
};

export default STEP_THREE_KEYS;
