import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StatusCodes } from 'http-status-codes';
import useAdminPublicResourceStore from 'store/publicResource/useAdminPublicResourceStore';
import ResponsePopUp from 'components/common/ResponsePopUp/ResponsePopUp';
import {
  THANK_YOU_CONFIG,
  ERROR_CONFIG,
} from 'pages/AdminPage/components/Forms/PublicResource/DeletePublicResource/Response/constants';

const Response = ({ resourceIds, req, closeForm }) => {
  const [success, setSuccess] = useState(true);
  const deleteMultiplePublicResources = useAdminPublicResourceStore(
    (state) => state.deleteMultiplePublicResources,
  );

  useEffect(() => {
    let ignore = false;

    const deletePublicResources = async () => {
      try {
        if (!ignore) {
          ignore = true;
          const responseData = await req(resourceIds);

          if (responseData.status !== StatusCodes.OK)
            throw new Error(`Failed: ${responseData.response}`);
        }

        deleteMultiplePublicResources(resourceIds);
        setSuccess(true);

        setTimeout(() => {
          closeForm();
        }, 1200);
      } catch (error) {
        setSuccess(false);
      }
    };

    deletePublicResources();

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <ResponsePopUp
      {...(success
        ? { ...THANK_YOU_CONFIG, descriptionText: 'Successfully deleted' }
        : ERROR_CONFIG)}
    />
  );
};

Response.propTypes = {
  resourceIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  req: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
};

export default Response;
