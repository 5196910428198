import PropTypes from 'prop-types';
import {
  FormContainer,
  Step,
  Title,
  TitleContainer,
  ButtonsContainer,
} from 'components/Forms/Resources/AddResources.styled';
import {
  Wrapper,
  DescriptionContainer,
} from 'components/Forms/Resources/StepOne/StepOne.styled';
import Spacer from 'components/common/Spacer/Spacer.styled';
import generalInfoInputSchema from 'components/Forms/Resources/StepOne/schemas';
import {
  GENERAL_INFO_TEXT,
  resourcesTypeOptions,
} from 'components/Forms/Resources/StepOne/constants';
import RadioButtonGroup from 'components/common/RadioButtonGroup/RadioButtonGroup';
import { useState } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import { CRIMSON } from 'constants/colors';
import {
  defaultExclusiveFormStateProps,
  defaultPublicFormStateProps,
  exclusiveFormStateProps,
  publicFormStateProps,
} from 'components/Forms/Resources/propTypes';
import {
  BUTTONS_CSS,
  EXCLUSIVE_KEYS,
  PUBLIC_KEYS,
} from 'components/Forms/Resources/constants';
import useFormInput from 'components/common/FormComponents/Input/FormInput/useFormInput';
import FormInput from 'components/common/FormComponents/Input/FormInput/FormInput';

const StepOne = ({
  currentStep,
  closeForm,
  nextStep,
  setFormState,
  formState,
}) => {
  const [formInputState, setFormInputState] = useState(generalInfoInputSchema);

  const isExclusiveSelectHandler = () =>
    setFormState((prevState) => ({
      ...(prevState.isExclusive
        ? defaultPublicFormStateProps()
        : defaultExclusiveFormStateProps()),
      isExclusive: !prevState.isExclusive,
    }));

  const { onBlurHandler, triggerError } = useFormInput();

  const onChangeHandler =
    (key) =>
    ({ target: { value } }) => {
      setFormState((prevState) => ({ ...prevState, [key]: value }));
      setFormInputState((prevState) => ({
        ...prevState,
        [formState.isExclusive]: {
          ...prevState[formState.isExclusive],
          isError:
            prevState[formState.isExclusive].isError &&
            !prevState[formState.isExclusive].validator(value),
        },
      }));
    };

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      !formInputState[formState.isExclusive].validator(
        formState[
          formState.isExclusive
            ? EXCLUSIVE_KEYS.COMPANY_NAME
            : PUBLIC_KEYS.DEAL_NAME
        ],
      )
    ) {
      triggerError(setFormInputState, formState.isExclusive);
      return;
    }

    nextStep();
  };

  return (
    <Wrapper>
      <TitleContainer>
        <Step>{`Step ${currentStep}`}</Step>
        <Title>General Info</Title>
      </TitleContainer>
      <Spacer Height="20px" />
      <FormContainer onSubmit={onSubmit}>
        <DescriptionContainer>
          <b>{GENERAL_INFO_TEXT.DESCRIPTION_LABEL}</b>
          <Spacer Height="5px" />
          <p>{GENERAL_INFO_TEXT.DESCRIPTION} </p>
        </DescriptionContainer>
        <Spacer Height="20px" />
        <RadioButtonGroup
          options={resourcesTypeOptions}
          selectedLabel={
            !formState.isExclusive
              ? resourcesTypeOptions[0].label
              : resourcesTypeOptions[1].label
          }
          flexDirection="column"
          radioButtonFillColor={CRIMSON}
          onChange={isExclusiveSelectHandler}
        />
        <Spacer Height="20px" />

        {formState.isExclusive ? (
          <FormInput
            {...formInputState[formState.isExclusive]}
            name="name"
            key={EXCLUSIVE_KEYS.COMPANY_NAME}
            value={formState[EXCLUSIVE_KEYS.COMPANY_NAME]}
            onBlurHandler={onBlurHandler(
              setFormInputState,
              formState.isExclusive,
            )}
            onChangeHandler={onChangeHandler(EXCLUSIVE_KEYS.COMPANY_NAME)}
          />
        ) : (
          <FormInput
            {...formInputState[formState.isExclusive]}
            name="name"
            key={PUBLIC_KEYS.DEAL_NAME}
            value={formState[PUBLIC_KEYS.DEAL_NAME] || ''}
            onBlurHandler={onBlurHandler(
              setFormInputState,
              formState.isExclusive,
            )}
            onChangeHandler={onChangeHandler(PUBLIC_KEYS.DEAL_NAME)}
          />
        )}

        <DescriptionContainer>
          <p>{GENERAL_INFO_TEXT.GRATITUDE} </p>
        </DescriptionContainer>
        <ButtonsContainer>
          <SecondaryButton
            {...BUTTONS_CSS.secondary}
            noDark
            onClick={closeForm}
          >
            Cancel
          </SecondaryButton>
          <Spacer Width="16px" />
          <PrimaryButton {...BUTTONS_CSS.primary}>
            Next: Resource Info
          </PrimaryButton>
        </ButtonsContainer>
      </FormContainer>
    </Wrapper>
  );
};

StepOne.propTypes = {
  currentStep: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  formState: PropTypes.oneOfType([
    publicFormStateProps(),
    exclusiveFormStateProps(),
  ]).isRequired,
};

export default StepOne;
