const exclusiveResourcesFiltersSchema = {
  COMPANY_NAME: {
    label: 'Company name',
  },
  DEAL_DESCRIPTION: {
    label: 'Deal description',
  },
  CATEGORY: {
    label: 'Category',
    placeHolder: 'Select',
  },
  SORT: {
    sortFieldLabel: 'Sort',
    radioGroupLabel: 'Date or Alphabetical',
  },
};

export default exclusiveResourcesFiltersSchema;
