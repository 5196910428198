import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const Wrapper = styled.div`
  align-content: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const FormContainer = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  button {
    align-self: flex-end;

    @media ${DEVICE.formResponsive} {
      align-self: center;
    }
  }
`;
