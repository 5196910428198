import {
  ButtonWrapper,
  AddResourceButton,
} from 'pages/ResourcePage/components/HBSResources/HBSResources.styled';
import Dots from 'components/common/Dots/Dots';
import { useAppContext } from 'contexts/AppContext';
import { ADD_A_RESOURCE } from 'components/Forms/Resources/constants';
import {
  WelcomeToPageContainer,
  WelcomeToPageParagraph,
  WelcomeToPageRedLine,
  WelcomeToPageTitle,
} from 'components/common/WelcomeToPage/WelcomeToPage.styled';
import Spacer from 'components/common/Spacer/Spacer.styled';

const HBSResources = () => {
  const { setActiveForm } = useAppContext();

  return (
    <WelcomeToPageContainer>
      <Dots rows={12} />
      <WelcomeToPageTitle>Resources</WelcomeToPageTitle>
      <WelcomeToPageParagraph>
        An independent set of resources for founders from the HBS community
        built by members of the HBS community
      </WelcomeToPageParagraph>
      <Spacer Height="20px" />
      <ButtonWrapper>
        <AddResourceButton onClick={() => setActiveForm(ADD_A_RESOURCE)}>
          Add a new resource
        </AddResourceButton>
      </ButtonWrapper>
      <Spacer Height="20px" />
      <WelcomeToPageRedLine />
    </WelcomeToPageContainer>
  );
};

export default HBSResources;
