import styled from 'styled-components';

import {
  WHITE,
  DARK_CHARCOAL,
  RAISIN_BLACK_ALPHA_03,
  DAVY_GRAY,
  CRIMSON,
} from 'constants/colors';

export const FormControl = styled.div`
  background-color: ${(props) =>
    props.disabledDarkMode ? WHITE : 'var(--RAISING_BLACK_OR_WHITE)'};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const SelectedValuesContainer = styled.div`
  align-items: center;
  border: 1px solid
    ${(props) => {
      if (props.isError) return CRIMSON;
      return props.disabledDarkMode
        ? RAISIN_BLACK_ALPHA_03
        : 'var(--GRAY_80_OR_RAISIN_BLACK_ALPHA_03)';
    }};
  border-radius: 4px;
  display: flex;
  padding: 0 8px 0 12px;
  min-height: 42px;
  justify-content: space-between;
  position: static;

  & > svg > path {
    fill: ${(props) =>
      props.disabledDarkMode ? DAVY_GRAY : 'var(--BRIGHT_GRAY_OR_DAVY_GRAY)'};
  }
`;

export const SelectErrorIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
`;

export const SelectedValues = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  gap: 2px;
  width: 100%;
`;

export const SelectLabel = styled.label`
  color: ${(props) =>
    props.disabledDarkMode
      ? DARK_CHARCOAL
      : 'var(--BRIGHT_GRAY_OR_DARK_CHARCOAL)'};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 10px 0;
`;

export const DropdownMenuContainer = styled.div`
  position: relative;
  margin-top: 4px;
`;

export const DropdownMenu = styled.div`
  width: calc(100% - 2px);
  background-color: ${(props) =>
    props.disabledDarkMode ? WHITE : 'var(--RAISING_BLACK_OR_WHITE)'};
  border-radius: 4px;
  border: 1px solid #eaeaea;
  display: flex;
  flex-direction: column;
  left: 0;
  justify-content: stretch;
  max-height: ${(props) => props.MaxHeight};
  overflow: auto;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  z-index: 30;
`;

export const SearchBox = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 4px;
  margin: 1% 2% 1% 2%;

  svg > path {
    fill: ${(props) =>
      props.disabledDarkMode ? DAVY_GRAY : 'var(--BRIGHT_GRAY_OR_DAVY_GRAY)'};
  }
`;

export const SearchBoxInput = styled.input`
  color: ${(props) =>
    props.disabledDarkMode
      ? DARK_CHARCOAL
      : 'var(--BRIGHT_GRAY_OR_DARK_CHARCOAL)'};
  background: transparent;
  border: none;
  height: 20px;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export const PlaceHolder = styled.p`
  color: ${(props) =>
    props.disabledDarkMode
      ? DARK_CHARCOAL
      : 'var(--BRIGHT_GRAY_OR_DARK_CHARCOAL)'};
  font-size: 12px;
  line-height: 18px;
  margin: 0px;
`;

export const DropdownItem = styled.div`
  border-radius: 4px;
  padding: 5px 8px 5px 12px;

  &:hover {
    background-color: rgba(242, 242, 242, 0.5);
  }
`;
