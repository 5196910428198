import CompanyTab from 'pages/AdminPage/TabControl/CompanyTab/CompanyTab';
import InvestorTab from 'pages/AdminPage/TabControl/InvestorTab/InvestorTab';
import ExclusiveResourcesTab from 'pages/AdminPage/TabControl/ResourceTabs/ExclusiveResourcesTab/ExclusiveResourcesTab';
import PublicResourcesTab from 'pages/AdminPage/TabControl/ResourceTabs/PublicResourcesTab/PublicResourcesTab';

export const TAB_CONTROL_KEYS = {
  COMPANIES: 'Companies',
  RESOURCES: 'Resources',
  EXCLUSIVE_RESOURCES: 'Exclusive Resources',
  INVESTOR: 'Investors',
};

export const SUB_HEADER_TABS = {
  [TAB_CONTROL_KEYS.COMPANIES]: {
    content: <CompanyTab />,
    url: '/administration/companies',
    width: '100px',
  },
  [TAB_CONTROL_KEYS.RESOURCES]: {
    content: <PublicResourcesTab />,
    url: '/administration/public-resource',
    width: '90px',
  },
  [TAB_CONTROL_KEYS.EXCLUSIVE_RESOURCES]: {
    content: <ExclusiveResourcesTab />,
    url: '/administration/exclusive-resource',
    width: '170px',
  },
  [TAB_CONTROL_KEYS.INVESTOR]: {
    content: <InvestorTab />,
    url: '/administration/investor',
    width: '80px',
  },
};

export const LIMIT_OPTIONS = [10, 20, 50, 100];
