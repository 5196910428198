import useMountTransition from 'hooks/useMountTransition';
import { useEffect, useState } from 'react';
import {
  WelcomeScreenContainer,
  WelcomeScreenRedBorder,
  WelcomeScreenSubtitle,
  WelcomeScreenTitle,
} from './WelcomeScreen.styled';

const WelcomeScreen = () => {
  const [isMounted, setIsMounted] = useState(true);

  const hasTransitionedIn = useMountTransition(isMounted, 500);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(false), 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <WelcomeScreenContainer show={hasTransitionedIn}>
      <WelcomeScreenRedBorder />
      <WelcomeScreenTitle>Welcome to HBS Builds!</WelcomeScreenTitle>
      <WelcomeScreenSubtitle>
        Thank you for contributing to our community.
      </WelcomeScreenSubtitle>
    </WelcomeScreenContainer>
  );
};

export default WelcomeScreen;
