import PropTypes from 'prop-types';
import { LinkItUrl } from 'react-linkify-it';
import { ADD_NEW_EVENT, UPDATE_EVENT } from 'components/Forms/Events/constants';
import EVENTS_STATIC_TEXT from 'components/Forms/Events/EntryModal/constants';
import {
  ButtonsContainer,
  DescriptionContainer,
  DescriptionParagraph,
  EmailLink,
  Title,
  Wrapper,
} from 'components/Forms/Events/EntryModal/EntryModalEvents.styled';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';

const EntryModalEvents = ({ nextStep, closeForm, update }) => {
  const key = update ? UPDATE_EVENT : ADD_NEW_EVENT;

  return (
    <Wrapper>
      <DescriptionContainer>
        <Title>{EVENTS_STATIC_TEXT[key].START_TITLE}</Title>
        <LinkItUrl>
          <DescriptionParagraph>
            {EVENTS_STATIC_TEXT[key].START_DESCRIPTION}
          </DescriptionParagraph>
        </LinkItUrl>
        <DescriptionParagraph>
          {EVENTS_STATIC_TEXT[key].CONTACT_INFO}
          <EmailLink href={`mailto:${EVENTS_STATIC_TEXT[key].CONTACT_EMAIL}`}>
            {EVENTS_STATIC_TEXT[key].CONTACT_EMAIL}
          </EmailLink>
        </DescriptionParagraph>
      </DescriptionContainer>
      <ButtonsContainer>
        <PrimaryButton
          Border="none"
          Height="40px"
          Width="220px"
          type="button"
          onClick={nextStep}
        >
          Start
        </PrimaryButton>
        <SecondaryButton
          Border="none"
          Height="40px"
          Width="220px"
          type="button"
          noDark
          onClick={closeForm}
        >
          Cancel
        </SecondaryButton>
      </ButtonsContainer>
    </Wrapper>
  );
};

EntryModalEvents.defaultProps = {
  update: false,
};

EntryModalEvents.propTypes = {
  nextStep: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  update: PropTypes.bool,
};

export default EntryModalEvents;
