const investorFiltersSchema = {
  SORT: {
    sortFieldLabel: 'Sort',
    radioGroupLabel: 'Date or Alphabetical',
  },
  CATEGORY: {
    label: 'Category',
    placeHolder: 'All',
  },
  COMPANY_NAME: {
    label: 'Company name',
  },
  STATUS: {
    label: 'Status',
    placeHolder: 'All',
  },
  CLASS_YEAR: {
    label: 'Class year',
    placeHolder: 'Year',
  },
  STAGE: {
    label: 'Stage',
    placeHolder: 'All',
  },
  CHECK_SIZES: {
    label: 'Check sizes',
    placeHolder: 'All',
  },
  GEO_FOCUS: {
    label: 'Geo focus',
    placeHolder: 'All',
  },
};

export default investorFiltersSchema;
