import styled from 'styled-components';

export const GroupLabel = styled.label`
  color: ${(props) => props.labelColor};
  font-weight: 600;
  font-size: ${(props) => props.fontSize};
  line-height: 18px;
  margin-bottom: 10px;
`;

export const Label = styled.label`
  color: ${(props) => props.labelColor};
  font-weight: 300;
  font-size: ${(props) => props.fontSize};
  line-height: 21px;
`;

export const Legend = styled.legend`
  font-weight: 600;
  font-size: 1rem;
`;
export const WrapperSingle = styled.div`
  display: flex;
  gap: 8px;
`;

export const WrapperGroup = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  gap: ${(props) => (props.flexDirection === 'row' ? '30px' : '5px')};
`;

export const Radio = styled.input.attrs(() => ({
  type: 'radio',
}))`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 16px;
  height: 16px;
  border: 1px solid ${(props) => props.outlineColor};
  border-radius: 50%;
  transition: 0.3s all;

  ::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin: 2px;
  }
  :checked {
    border: 1px solid ${(props) => props.fillColor};
    ::after {
      background-color: ${(props) => props.fillColor};
    }
  }
  :focus {
    outline: none;
  }
  :hover {
    border: 1px solid ${(props) => props.fillColor};
    ::after {
      background-color: ${(props) => props.fillColor};
    }
  }
`;
