import PropTypes from 'prop-types';
import { SORT_TYPES } from 'pages/AdminPage/TabControl/ResourceTabs/constants';
import { FiltersContainer } from 'components/common/Filters/Filters.styled';
import FilterInput from 'components/common/Filters/FilterInput/FilterInput';
import publicResourcesFiltersSchema from 'pages/AdminPage/TabControl/ResourceTabs/PublicResourcesTab/schema';
import FilterSelect from 'components/common/Filters/FilterSelect/FilterSelect';
import PUBLIC_RESOURCES_PARAMS from 'pages/AdminPage/TabControl/ResourceTabs/PublicResourcesTab/constants';
import ResourcesSort from 'components/common/Filters/ResourcesSort/ResourcesSort';

const radioButtonOptions = Object.values(SORT_TYPES).map((value) => ({
  label: value,
  name: 'publicResourcesSort',
}));

const PublicResourcesFilters = ({
  // eslint-disable-next-line react/prop-types
  publicResourcesQueryParams,
  orderByParam,
  sortByParam,
  isFiltersCleared,
  resourceName,
  categories,
  setCategory,
  targetAudiences,
  setTargetAudience,
  setResourceName,
  setSortType,
}) => {
  return (
    <FiltersContainer>
      <FilterInput
        {...publicResourcesFiltersSchema.RESOURCE_NAME}
        value={resourceName}
        setValue={setResourceName}
      />
      <FilterSelect
        isFiltersCleared={isFiltersCleared}
        {...publicResourcesFiltersSchema.TARGET_AUDIENCE}
        options={targetAudiences}
        value={
          publicResourcesQueryParams[PUBLIC_RESOURCES_PARAMS.TARGET_AUDIENCE]
        }
        setValue={setTargetAudience}
      />
      <FilterSelect
        isFiltersCleared={isFiltersCleared}
        {...publicResourcesFiltersSchema.CATEGORY}
        options={categories}
        value={publicResourcesQueryParams[PUBLIC_RESOURCES_PARAMS.CATEGORY]}
        setValue={setCategory}
      />
      <ResourcesSort
        setSortType={setSortType}
        sortedBy={sortByParam}
        orderedBy={orderByParam}
        options={radioButtonOptions}
        {...publicResourcesFiltersSchema.SORT}
      />
    </FiltersContainer>
  );
};

PublicResourcesFilters.propTypes = {
  resourceName: PropTypes.string.isRequired,
  sortByParam: PropTypes.string.isRequired,
  orderByParam: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  targetAudiences: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  setCategory: PropTypes.func.isRequired,
  setTargetAudience: PropTypes.func.isRequired,
  setResourceName: PropTypes.func.isRequired,
  setSortType: PropTypes.func.isRequired,
  isFiltersCleared: PropTypes.bool.isRequired,
};

export default PublicResourcesFilters;
