import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  TabControlsContainer,
  ListItemContainer,
  ListItem,
} from './TabControl.styled';
import { TAB_CONTROL_KEYS, SUB_HEADER_TABS } from './constants';

const TabControl = () => {
  const [activeTab, setActiveTab] = useState(TAB_CONTROL_KEYS.COMPANIES);
  const navigate = useNavigate();
  const handleTabClick = (key) => {
    setActiveTab(key);
    navigate(SUB_HEADER_TABS[key].url);
  };

  useEffect(() => {
    const currentPath = window.location.pathname;

    Object.keys(SUB_HEADER_TABS).forEach((key) => {
      if (currentPath.includes(SUB_HEADER_TABS[key].url)) setActiveTab(key);
    });
  }, []);

  return (
    <TabControlsContainer>
      <ListItemContainer>
        {Object.values(TAB_CONTROL_KEYS).map((key) => (
          <ListItem
            key={key}
            onClick={() => handleTabClick(key)}
            isActive={key === activeTab}
            width={SUB_HEADER_TABS[key].width}
          >
            {key}
          </ListItem>
        ))}
      </ListItemContainer>
      <Outlet />
    </TabControlsContainer>
  );
};

export default TabControl;
