import PropTypes from 'prop-types';

import { BrowserRouter } from 'react-router-dom';
import { AppProvider } from 'contexts/AppContext';

const AllTheProviders = ({ children }) => {
  return (
    <AppProvider>
      <BrowserRouter>{children}</BrowserRouter>
    </AppProvider>
  );
};

AllTheProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AllTheProviders;
