import { useState } from 'react';
import styled from 'styled-components';
import { WHITE, DARK_CHARCOAL, CRIMSON } from 'constants/colors';

const SvgWrapper = styled.div`
  background-color: ${(props) => (props.hover ? DARK_CHARCOAL : 'transparent')};
  border-radius: 50%;
  display: flex;
  align-items: center;
  height: 30px;
  width: 30px;
`;

const PageNavigationBack = () => {
  const [hover, setHover] = useState(false);

  return (
    <SvgWrapper hover={hover}>
      <svg
        width="28"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <path
          d="M15.4199 7.41L10.8299 12L15.4199 16.59L13.9999 18L7.99992 12L13.9999 6L15.4199 7.41Z"
          style={{
            ...(hover ? { fill: CRIMSON } : { fill: WHITE }),
          }}
        />
      </svg>
    </SvgWrapper>
  );
};

export default PageNavigationBack;
