const FilterIcon = () => (
  <svg
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.50004 14.5667C9.53338 14.8167 9.45004 15.0833 9.25838 15.2583C8.93338 15.5833 8.40838 15.5833 8.08338 15.2583L4.74171 11.9167C4.55004 11.725 4.46671 11.4667 4.50004 11.225V6.95833L0.508377 1.85C0.225044 1.49167 0.291711 0.966667 0.650044 0.683333C0.808378 0.566667 0.983377 0.5 1.16671 0.5H12.8334C13.0167 0.5 13.1917 0.566667 13.35 0.683333C13.7084 0.966667 13.775 1.49167 13.4917 1.85L9.50004 6.95833V14.5667ZM2.86671 2.16667L6.16671 6.38333V10.9833L7.83338 12.65V6.375L11.1334 2.16667H2.86671Z"
      fill="#545556"
    />
  </svg>
);

export default FilterIcon;
