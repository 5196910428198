import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'components/common/Modal/Modal';
import {
  Wrapper,
  Title,
  DescriptionParagraph,
  ButtonsContainer,
  BUTTONS_CSS,
  Error,
} from 'pages/AdminPage/components/Forms/PublicResource/RestorePublicResource/RestorePublicResource.styled';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import { restorePublicResources } from 'services/requests';
import useAdminPublicResourceStore from 'store/publicResource/useAdminPublicResourceStore';

const RestorePublicResource = () => {
  const [error, setError] = useState(false);
  const {
    state: { ids },
  } = useLocation();
  const restoreMultiplePublicResources = useAdminPublicResourceStore(
    (state) => state.restoreMultiplePublicResources,
  );
  const navigate = useNavigate();

  const closeForm = () =>
    navigate({ pathname: '/administration/public-resource' });

  const handleRestore = async () => {
    setError(false);
    let status;

    try {
      const res = await restorePublicResources(ids);
      status = res.status;
    } catch (e) {
      status = e.response?.status;
      setError(true);
    }

    if (status === 200) {
      restoreMultiplePublicResources(ids);
      closeForm();
    }
  };

  const count = ids.length;

  return (
    <Modal closeForm={closeForm}>
      <Wrapper>
        <Title>Restore Company</Title>
        <DescriptionParagraph>{`Are you sure you want to restore ${count} ${
          count > 1 ? 'resources' : 'resource'
        }?`}</DescriptionParagraph>
        {error && <Error>*Something went wrong</Error>}
        <ButtonsContainer>
          <SecondaryButton $ {...BUTTONS_CSS.secondary} onClick={closeForm}>
            Cancel
          </SecondaryButton>
          <PrimaryButton $ {...BUTTONS_CSS.primary} onClick={handleRestore}>
            Restore
          </PrimaryButton>
        </ButtonsContainer>
      </Wrapper>
    </Modal>
  );
};

export default RestorePublicResource;
