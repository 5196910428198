import { RAISIN_BLACK } from 'constants/colors';
import styled from 'styled-components';

export const OptionContent = styled.div`
  display: flex;
  align-items: center;
  display: flex;
  gap: 0 5px;
`;

export const OptionNameContainer = styled.div`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : 'var(RAISING_BLACK_OR_WHITE)'};
  display: flex;
  padding: ${(props) => (props.bgColor ? '0 5px' : '0')};
  border-radius: 4px;
`;

export const OptionName = styled.p`
  color: ${(props) => {
    if (!props.bgColor && props.disabledDarkMode) return RAISIN_BLACK;
    if (props.bgColor) return RAISIN_BLACK;
    return 'var(--WHITE_OR_RAISING_BLACK)';
  }};

  font-size: 14px;
  line-height: 20px;
  margin: 0;
  cursor: pointer;
`;

export const CloseButton = styled.div`
  display: flex;
  height: 16px;
  width: 16px;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;

  & > svg > path {
    fill: ${(props) =>
      props.disabledDarkMode ? RAISIN_BLACK : 'var(--WHITE_OR_RAISING_BLACK)'};
  }
`;
