import CompaniesContainer from 'pages/HomePage/components/CompaniesContainer/CompaniesContainer';
import {
  HomePageContainer,
  LandingSection,
  LandingSectionWrapper,
} from 'pages/HomePage/HomePage.styled';
import WelcomeToHbs from 'pages/HomePage/components/WelcomeToHbs/WelcomeToHbs';
import CreateUpdateHire from 'pages/HomePage/components/CreateUpdateHire/CreateUpdateHire';

const HomePage = () => (
  <HomePageContainer>
    <LandingSection>
      <LandingSectionWrapper>
        <WelcomeToHbs />
        <CreateUpdateHire />
      </LandingSectionWrapper>
    </LandingSection>
    <CompaniesContainer />
  </HomePageContainer>
);

export default HomePage;
