import { useState } from 'react';
import PropTypes from 'prop-types';
import { ADD_PROGRESS, STEPS } from 'components/Forms/Company/constants';
import EntryModalAdd from 'components/Forms/Company/EntryModal/EntryModalAdd';
import Modal from 'components/common/Modal/Modal';
import StepOne from 'components/Forms/Company/StepOne/StepOne';
import StepTwo from 'components/Forms/Company/StepTwo/StepTwo';
import StepThree from 'components/Forms/Company/StepThree/StepThree';
import Response from 'components/Forms/Company/Response/Response';
import { createCompany } from 'services/requests';
import { useAppContext } from 'contexts/AppContext';
import STEP_ONE_KEYS from 'components/Forms/Company/StepOne/constants';
import { defaultFormStateProp } from 'components/Forms/Company/propTypes';
import FOUNDER_INITIAL_STATE from 'components/Forms/Company/StepThree/schema';

const AddCompany = ({ closeForm }) => {
  const { user } = useAppContext();

  const [step, setStep] = useState(STEPS.ENTRY_MODAL);
  const [formState, setFormState] = useState({
    ...defaultFormStateProp(),
    [STEP_ONE_KEYS.CREATOR_FULL_NAME]: user
      ? user.fullName
      : defaultFormStateProp()[STEP_ONE_KEYS.CREATOR_HBS_EMAIL],

    [STEP_ONE_KEYS.CREATOR_HBS_EMAIL]: user
      ? user.hbsEmail
      : defaultFormStateProp()[STEP_ONE_KEYS.CREATOR_HBS_EMAIL],

    founders: [{ ...FOUNDER_INITIAL_STATE, position: 1 }],
  });

  return (
    <Modal closeForm={closeForm} progress={ADD_PROGRESS[step]}>
      {step === STEPS.ENTRY_MODAL && (
        <EntryModalAdd setStep={setStep} closeForm={closeForm} />
      )}
      {step === STEPS.STEP_ONE && (
        <StepOne
          nextStep={() => setStep(STEPS.STEP_TWO)}
          currentStep="1/3"
          closeForm={closeForm}
          formState={formState}
          setFormState={setFormState}
        />
      )}
      {step === STEPS.STEP_TWO && (
        <StepTwo
          backStep={() => setStep(STEPS.STEP_ONE)}
          nextStep={() => setStep(STEPS.STEP_THREE)}
          currentStep="2/3"
          closeForm={closeForm}
          formState={formState}
          setFormState={setFormState}
        />
      )}
      {step === STEPS.STEP_THREE && (
        <StepThree
          backStep={() => setStep(STEPS.STEP_TWO)}
          nextStep={() => setStep(STEPS.STEP_MESSAGE)}
          currentStep="3/3"
          closeForm={closeForm}
          formState={formState}
          setFormState={setFormState}
        />
      )}
      {step === STEPS.STEP_MESSAGE && (
        <Response formState={formState} request={createCompany} />
      )}
    </Modal>
  );
};

AddCompany.propTypes = {
  closeForm: PropTypes.func.isRequired,
};

export default AddCompany;
