import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'components/common/Modal/Modal';
import ConfirmPrompt from 'pages/AdminPage/components/Forms/Company/DeleteCompany/ConfirmPrompt/ConfirmPrompt';
import Response from 'pages/AdminPage/components/Forms/Company/DeleteCompany/Response/Response';
import { DELETE_COMPANY_STEPS } from 'pages/AdminPage/components/Forms/Company/DeleteCompany/constants';
import { deleteCompanies } from 'services/requests';

const DeleteCompany = () => {
  const [activeForm, setActiveForm] = useState(DELETE_COMPANY_STEPS.PROMPT);
  const {
    state: { ids },
  } = useLocation();

  const navigate = useNavigate();
  const closeForm = () => navigate({ pathname: '/administration/companies' });

  return (
    <Modal closeForm={closeForm}>
      {activeForm === DELETE_COMPANY_STEPS.PROMPT && (
        <ConfirmPrompt
          numberOfCompanies={ids.length}
          closeForm={closeForm}
          nextStep={() => setActiveForm(DELETE_COMPANY_STEPS.RESPONSE)}
        />
      )}
      {activeForm === DELETE_COMPANY_STEPS.RESPONSE && (
        <Response
          companyIds={ids}
          req={deleteCompanies}
          closeForm={closeForm}
        />
      )}
    </Modal>
  );
};
export default DeleteCompany;
