const repackState = (formState, keys) =>
  keys
    .filter((key) => key !== 'confirm_password')
    .reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue]: formState[currentValue].value,
      }),
      {},
    );

export const toggleTextPasswordTypes = (type) =>
  type === 'password' ? 'text' : 'password';

export default repackState;
