import PropTypes from 'prop-types';
import {
  FormControl,
  InputFieldAndButtonWrapper,
  InputField,
  InputHelperText,
  InputLabel,
} from 'components/common/FormComponents/Input/Input.styled';
import InputErrorIcon from 'assets/icons/InputErrorIcon';
import Spacer from 'components/common/Spacer/Spacer.styled';

const FormInput = ({
  name,
  label,
  inputType,
  value,
  onChangeHandler,
  isError,
  onBlurHandler,
  warnMsg,
  isDisabled,
  disabledDarkMode,
}) => (
  <FormControl disabledDarkMode={disabledDarkMode}>
    <InputLabel htmlFor={name} disabledDarkMode={disabledDarkMode}>
      {label}
    </InputLabel>
    <InputFieldAndButtonWrapper
      isError={isError}
      disabledDarkMode={disabledDarkMode}
      className={isDisabled ? 'disabled' : ''}
    >
      <InputField
        disabled={isDisabled}
        type={inputType}
        value={value}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        name={name}
        id={name}
        disabledDarkMode={disabledDarkMode}
        autoComplete="off"
      />
      {isError && <InputErrorIcon />}
    </InputFieldAndButtonWrapper>
    {isError ? (
      <InputHelperText>{warnMsg}</InputHelperText>
    ) : (
      <Spacer Height="24px" />
    )}
  </FormControl>
);

FormInput.defaultProps = {
  inputType: 'text',
  isError: false,
  warnMsg: '',
  isDisabled: false,
  disabledDarkMode: false,
};

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  inputType: PropTypes.string,
  isError: PropTypes.bool,
  onBlurHandler: PropTypes.func.isRequired,
  warnMsg: PropTypes.string,
  isDisabled: PropTypes.bool,
  disabledDarkMode: PropTypes.bool,
};

export default FormInput;
