import { BRIGHT_GRAY, CRIMSON } from 'constants/colors';
import styled, { keyframes } from 'styled-components';

const SpinnerAnimation = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }`;

export const LoadingSpinner = styled.div`
  width: 40px;
  height: 40px;
  border: 5px solid var(--DARK_CHARCOAL_OR_BRIGHT_GRAY);
  border-top: 5px solid ${CRIMSON};
  border-radius: 50%;
  animation: ${SpinnerAnimation} 1.5s linear infinite;
`;

export const LoadingSpinnerContainer = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  height: 50vh;
  margin-top: 120px;
`;

export const AbsoluteLoadingSpinnerContainer = styled(LoadingSpinnerContainer)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;

  & > div {
    border: 5px solid ${BRIGHT_GRAY};
    border-top: 5px solid ${CRIMSON};
  }
`;
