import { PUBLIC_KEYS } from 'components/Forms/Resources/constants';
import { isEmptyFieldValidator, isUrlValid } from 'utils/inputValidators';

export const ResourceInfoInputSchema = {
  [PUBLIC_KEYS.DEAL_URL]: {
    label: 'Deal URL*',
    inputType: 'text',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: "Enter your deal's URL",
    validator: isUrlValid,
  },
};

export const ResourceInfoSelectSchema = {
  [PUBLIC_KEYS.TARGET_AUDIENCES]: {
    label: 'Target audience*',
    placeHolder: 'Select the audience',
    MaxHeight: '130px',
    isError: false,
    validator: isEmptyFieldValidator,
  },
  [PUBLIC_KEYS.CATEGORIES]: {
    label: 'Category*',
    placeHolder: 'Select the category',
    MaxHeight: '170px',
    isError: false,
    validator: isEmptyFieldValidator,
  },
};

export const ResourceInfoDescriptionSchema = {
  [PUBLIC_KEYS.DEAL_DESCRIPTION]: {
    label: 'Describe the deal and how to redeem it*',
    placeholder: 'Enter your deal description...',
    warnMsg: 'Please enter description',
    validator: isEmptyFieldValidator,
    isError: false,
    disabledDarkMode: true,
    isDisabled: false,
    RowNum: 6,
  },
};
