import PropTypes from 'prop-types';
import {
  FilterControlsContainer,
  ToggleWrapper,
} from 'pages/AdminPage/components/Filters/FilterControls/FilterControls.styled';
import FilterSort from 'pages/AdminPage/components/Filters/FilterSort/FilterSort';
import ToggleFilters from './ToggleFilters';

const FilterControls = ({
  toggleFilters,
  setSortType,
  sortByParam,
  orderByParam,
  radioButtonOptions,
  sortParams,
  AddEntityButton,
  BulkImportButton,
}) => (
  <FilterControlsContainer>
    <ToggleWrapper>
      <ToggleFilters toggleFilters={toggleFilters} />
      <FilterSort
        radioGroupLabel="Date or Alphabetical"
        setSortType={setSortType}
        sortedBy={sortByParam}
        orderedBy={orderByParam}
        options={radioButtonOptions}
        sortParams={sortParams}
      />
    </ToggleWrapper>
    <ToggleWrapper>
      <AddEntityButton />
      {!!BulkImportButton && <BulkImportButton />}
    </ToggleWrapper>
  </FilterControlsContainer>
);

FilterControls.defaultProps = {
  BulkImportButton: null,
};

FilterControls.propTypes = {
  toggleFilters: PropTypes.func.isRequired,
  setSortType: PropTypes.func.isRequired,
  sortByParam: PropTypes.string.isRequired,
  orderByParam: PropTypes.string.isRequired,
  radioButtonOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  sortParams: PropTypes.shape({
    orderBy: PropTypes.string,
    sortBy: PropTypes.string,
  }).isRequired,
  AddEntityButton: PropTypes.elementType.isRequired,
  BulkImportButton: PropTypes.elementType,
};

export default FilterControls;
