const emptyStringToNull = (str) => (str === '' ? null : str);

const repackFormState = (formState, update) => {
  const nullOrEmpty = update ? [] : null;

  return {
    eventName: formState.eventName,
    rsvpLink: emptyStringToNull(formState.rsvpLink),
    audience: formState.audience.length
      ? formState.audience.map((audience) => audience.id)
      : nullOrEmpty,
    eventDate: formState.eventDate,
    eventDescription: formState.eventDescription,
    organizers: formState.organizers.length
      ? formState.organizers.map((organizer) => organizer.id)
      : nullOrEmpty,
    eventLocation: emptyStringToNull(formState.eventLocation),
    eventLogo: formState.eventLogo && formState.eventLogo.base64,
  };
};
export default repackFormState;
