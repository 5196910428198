import PropTypes from 'prop-types';
import {
  LoadingSpinner,
  LoadingSpinnerContainer,
} from 'components/common/LoadingSpinner/LoadingSpinner.styled';
import ResourcesNotFound from 'pages/AdminPage/TabControl/ResourceTabs/components/ResourcesNotFound/ResourcesNotFound';
import AdminTable from 'pages/AdminPage/components/AdminTable/AdminTable';
import EXCLUSIVE_RESOURCES_COLUMNS from 'pages/AdminPage/TabControl/ResourceTabs/ExclusiveResourcesTab/ExclusiveTable/exclusiveResourceTableSchema';
import ExclusiveResourceControlSelected from 'pages/AdminPage/TabControl/ResourceTabs/ExclusiveResourcesTab/ExclusiveTable/ExclusiveResourceControlSelected';
import Pagination from 'pages/AdminPage/components/AdminTable/AdditionalElements/Pagination/Pagination';
import { LIMIT_OPTIONS } from 'pages/AdminPage/TabControl/constants';

const ExclusiveResourceTable = ({
  exclusiveResources,
  totalNumOfExclusiveResources,
  exclusiveResourcesQueryParams,
  handlePageClick,
  handleItemsPerPageChange,
  fetching,
  handleRowClick,
}) => {
  if (fetching)
    return (
      <LoadingSpinnerContainer>
        <LoadingSpinner />
      </LoadingSpinnerContainer>
    );

  if (!exclusiveResources.length) return <ResourcesNotFound />;

  return (
    <>
      <AdminTable
        columns={EXCLUSIVE_RESOURCES_COLUMNS}
        data={exclusiveResources}
        ControlSelected={ExclusiveResourceControlSelected}
        handleRowClick={handleRowClick}
      />
      <Pagination
        totalRows={totalNumOfExclusiveResources}
        offset={exclusiveResourcesQueryParams.offset}
        limit={exclusiveResourcesQueryParams.limit}
        limitOptions={LIMIT_OPTIONS}
        handlePageClick={handlePageClick}
        handleItemsPerPageChange={handleItemsPerPageChange}
      />
    </>
  );
};

ExclusiveResourceTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  exclusiveResources: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalNumOfExclusiveResources: PropTypes.number.isRequired,
  exclusiveResourcesQueryParams: PropTypes.shape({
    offset: PropTypes.number,
    limit: PropTypes.number,
  }).isRequired,
  handlePageClick: PropTypes.func.isRequired,
  handleItemsPerPageChange: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  handleRowClick: PropTypes.func.isRequired,
};

export default ExclusiveResourceTable;
