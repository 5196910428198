import styled from 'styled-components';
import Masonry from 'react-masonry-css';

export const LoadMoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
`;

export const CompanyPreview = styled.span`
  font-size: 8px;
  color: var(--WHITE_OR_RAISING_BLACK);
`;

export const breakpointCols = {
  default: 8,
  2700: 7,
  2250: 6,
  1950: 5,
  1600: 4,
  1350: 3,
  1100: 2,
  700: 1,
};

export const CompanyListContainer = styled(Masonry).attrs({
  breakpointCols,
  columnClassName: 'company-list-column',
})`
  display: flex;
  margin-left: -20px;
  width: auto;
  .company-list-column {
    padding-left: 20px;
    background-clip: padding-box;
  }
  div > div {
    transition: all 0.5s ease;
  }
`;

export const FiltersSection = styled.div`
  margin: 15px 0;
  flex-direction: column;
`;

export const ClearFilters = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
`;

export const ClearFiltersButton = styled.button`
  min-height: 40px;
  padding: 0 8px 0 12px;
  margin-top: 4px;
  border: 1px solid var(--GRAY_80_OR_RAISIN_BLACK_ALPHA_03);
  border-radius: 4px;
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  font-weight: 600;
  font-size: 12px;
  background: transparent;
  cursor: pointer;
`;
