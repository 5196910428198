import getCategoryColor from 'pages/HomePage/utils';
import {
  CATEGORIES,
  FOUNDER_ATTRIBUTES,
  FUNDING_STAGE,
  GEOGRAPHIC_LOCATIONS,
  OPERATING_STAGE,
} from 'pages/HomePage/constants';

export const generateYearOptions = (howMuchToAddOnTop = 0) => {
  const max = new Date().getFullYear() + howMuchToAddOnTop;
  const min = max - 50;
  const years = [];

  for (let i = max; i >= min; i -= 1) {
    years.push({ label: i.toString() });
  }

  return years;
};

export const mapColors = (iterableObject) =>
  iterableObject.map((value) => ({
    id: value.id,
    label: value.colorName,
    color: value.colorValue,
  }));

export const mapFounderAttributes = (iterableObject) =>
  iterableObject.map((value) => ({
    id: value.id,
    label: value.attributeName,
    bgColor: getCategoryColor(value.attributeName, FOUNDER_ATTRIBUTES),
  }));

export const mapGeographicFocus = (iterableObject) =>
  iterableObject.map((value) => ({
    id: value.id,
    label: value.geographicLocationName,
    bgColor: getCategoryColor(
      value.geographicLocationName,
      GEOGRAPHIC_LOCATIONS,
    ),
  }));

export const mapCategories = (iterableObject) =>
  iterableObject.map((value) => ({
    id: value.id,
    label: value.companyCategoryName,
    bgColor: getCategoryColor(value.companyCategoryName, CATEGORIES),
  }));

export const mapFundingStages = (iterableObject) =>
  iterableObject.map((value) => ({
    id: value.id,
    label: value.fundingStageName,
    bgColor: getCategoryColor(value.fundingStageName, FUNDING_STAGE),
  }));

export const mapOperatingStages = (iterableObject) =>
  iterableObject.map((value) => ({
    id: value.id,
    label: value.stageName,
    bgColor: getCategoryColor(value.stageName, OPERATING_STAGE),
  }));
