import PropTypes from 'prop-types';
import {
  PopupButtonContainer,
  PopupCategory,
  PopupColumn,
  PopupContainer,
  PopupContent,
  PopupContentContainer,
  PopupDescriptionParagraph,
  PopupDescriptionSection,
  PopupExternalLink,
  PopupName,
  PopupSection,
  PopupSectionParagraph,
  PopupSingleCategoryContainer,
  PopupSubSectionName,
  PopupTitle,
  PopupTitleContainer,
} from 'components/common/Popup/Popup.styled';
import {
  FounderInfo,
  FounderInfoText,
  FounderName,
  Mba,
  MbaContainer,
  SingleFounder,
} from 'pages/HomePage/components/CompaniesContainer/CompanyPopup/CompanyPopup.styled';
import {
  CATEGORIES,
  FOUNDER_ATTRIBUTES,
  FUNDING_STAGE,
  GEOGRAPHIC_LOCATIONS,
  OPERATING_STAGE,
} from 'pages/HomePage/constants';
import getCategoryColor from 'pages/HomePage/utils';
import Spacer from 'components/common/Spacer/Spacer.styled';
import { PrimaryButton } from 'components/common/Button/Button.styled';
import IconPlaceholder from 'assets/icons/IconPlaceholder';
import validateURL from 'utils/linkValidator';
import PopupMultiCategories from 'components/common/Popup/PopupMultiCategories';
import { WHITE } from 'constants/colors';
import isURL from 'validator/lib/isURL';
import { useAppContext } from 'contexts/AppContext';
import { NOT_AVAILABLE } from '../constants';

const CompanyPopup = ({
  companyName,
  companyWebsite,
  isHiring,
  foundingYear,
  companyStage,
  fundingStage,
  creatorHbsClasscardLink,
  creatorHbsEmail,
  geographicFocus,
  founderAttributes,
  categories,
  companyDescription,
  founders,
}) => {
  const { user, getUserRole } = useAppContext();
  const role = getUserRole();

  const shouldDisplay = (data, displayPermission) =>
    !!user && data && (displayPermission || role === 'admin');

  return (
    <PopupContainer>
      <PopupContentContainer>
        <PopupTitleContainer>
          <PopupTitle>Company Information</PopupTitle>
          <PopupName>{companyName}</PopupName>
        </PopupTitleContainer>
        <PopupContent>
          <PopupColumn>
            <PopupSection>
              <PopupSubSectionName>Company Website</PopupSubSectionName>
              {companyWebsite && isURL(companyWebsite) ? (
                <PopupExternalLink
                  href={validateURL(companyWebsite)}
                  target="_blank"
                  rel="noreferrer"
                  hasUnderline={isURL(companyWebsite)}
                >
                  {companyWebsite}
                </PopupExternalLink>
              ) : (
                <PopupSectionParagraph>{NOT_AVAILABLE}</PopupSectionParagraph>
              )}
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Founding Year</PopupSubSectionName>
              <PopupSectionParagraph>
                {foundingYear || NOT_AVAILABLE}
              </PopupSectionParagraph>
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Is company hiring?</PopupSubSectionName>
              <PopupSectionParagraph>
                {isHiring ? 'Yes' : 'No'}
              </PopupSectionParagraph>
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Geographic focus</PopupSubSectionName>
              <PopupMultiCategories
                categories={geographicFocus}
                categoriesColors={GEOGRAPHIC_LOCATIONS}
              />
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>HBS Class Card</PopupSubSectionName>
              {creatorHbsClasscardLink ? (
                <PopupExternalLink
                  href={creatorHbsClasscardLink}
                  target="_blank"
                  rel="noreferrer"
                  hasUnderline={creatorHbsClasscardLink}
                >
                  {creatorHbsClasscardLink}
                </PopupExternalLink>
              ) : (
                <PopupSectionParagraph>{NOT_AVAILABLE}</PopupSectionParagraph>
              )}
            </PopupSection>
          </PopupColumn>
          <PopupColumn>
            <PopupSection>
              <PopupSubSectionName>Company Stage</PopupSubSectionName>
              <PopupSingleCategoryContainer>
                <PopupCategory
                  key={companyStage}
                  BgColor={getCategoryColor(companyStage, OPERATING_STAGE)}
                  Color={`${WHITE}`}
                  BorderRadius="4px"
                  Padding="1px 3px"
                >
                  {companyStage || NOT_AVAILABLE}
                </PopupCategory>
              </PopupSingleCategoryContainer>
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Funding Stage</PopupSubSectionName>
              <PopupSingleCategoryContainer>
                <PopupCategory
                  key={fundingStage}
                  BgColor={getCategoryColor(fundingStage, FUNDING_STAGE)}
                  Color={`${WHITE}`}
                  BorderRadius="4px"
                  Padding="1px 3px"
                >
                  {fundingStage || NOT_AVAILABLE}
                </PopupCategory>
              </PopupSingleCategoryContainer>
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Founder(s) attributes</PopupSubSectionName>
              <PopupMultiCategories
                categories={founderAttributes}
                categoriesColors={FOUNDER_ATTRIBUTES}
              />
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Category</PopupSubSectionName>
              <PopupMultiCategories
                categories={categories}
                categoriesColors={CATEGORIES}
              />
            </PopupSection>
          </PopupColumn>
        </PopupContent>
        <Spacer Height="15px" />
        <PopupDescriptionSection>
          <PopupSubSectionName>Company Description</PopupSubSectionName>
          <PopupDescriptionParagraph>
            {companyDescription || NOT_AVAILABLE}
          </PopupDescriptionParagraph>
        </PopupDescriptionSection>
        <FounderInfo>
          {founders
            .sort((a, b) => a.position - b.position)
            .map((founder) => (
              <SingleFounder key={founder.fullName}>
                <IconPlaceholder />
                <FounderName>{founder.fullName}</FounderName>
                <MbaContainer>
                  <Mba>
                    {founder.classYear
                      ? `MBA ‘ ${founder.classYear.toString().slice(-2)}`
                      : NOT_AVAILABLE}
                  </Mba>
                  <FounderInfoText>Founder: {companyName}</FounderInfoText>
                  {user && founder.founderEmail && (
                    <FounderInfoText>
                      Email: {founder.founderEmail}
                    </FounderInfoText>
                  )}
                  {shouldDisplay(
                    founder.alternativeEmail,
                    founder.displayAlternativeEmail,
                  ) && (
                    <FounderInfoText>
                      Contact Email: {founder.alternativeEmail}
                    </FounderInfoText>
                  )}
                  {shouldDisplay(founder.phone, founder.displayPhone) && (
                    <FounderInfoText>
                      Contact Phone: {founder.phone}
                    </FounderInfoText>
                  )}
                </MbaContainer>
              </SingleFounder>
            ))}
        </FounderInfo>
      </PopupContentContainer>
      <PopupButtonContainer>
        <a
          href={
            creatorHbsClasscardLink && isURL(creatorHbsClasscardLink)
              ? validateURL(creatorHbsClasscardLink)
              : `mailto:${creatorHbsEmail}`
          }
          target="_blank"
          rel="noreferrer"
        >
          <PrimaryButton Width="152px" Height="40px" Border="none">
            Contact Founder
          </PrimaryButton>
        </a>
        <Spacer Height="40px" />
      </PopupButtonContainer>
    </PopupContainer>
  );
};

CompanyPopup.propTypes = {
  companyName: PropTypes.string.isRequired,
  companyWebsite: PropTypes.string,
  companyStage: PropTypes.string.isRequired,
  foundingYear: PropTypes.number,
  fundingStage: PropTypes.string,
  creatorHbsClasscardLink: PropTypes.string,
  creatorHbsEmail: PropTypes.string.isRequired,
  geographicFocus: PropTypes.arrayOf(PropTypes.string),
  founderAttributes: PropTypes.arrayOf(PropTypes.string),
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  companyDescription: PropTypes.string.isRequired,
  founders: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      classYear: PropTypes.number,
      position: PropTypes.number.isRequired,
    }),
  ),
  isHiring: PropTypes.bool.isRequired,
};

CompanyPopup.defaultProps = {
  foundingYear: null,
  fundingStage: null,
  companyWebsite: null,
  creatorHbsClasscardLink: null,
  geographicFocus: [],
  founderAttributes: [],
  founders: {
    classYear: null,
  },
};

export default CompanyPopup;
