import { Button } from 'components/common/Button/Button.styled';
import { CRIMSON, WHITE } from 'constants/colors';

const getButtonStyles = (isUserFounder, handleGoToLink) => (
  <Button
    BgColor={isUserFounder ? CRIMSON : 'var(--RAISING_BLACK_OR_WHITE)'}
    Border={isUserFounder ? CRIMSON : '1px solid var(--WHITE_OR_RAISING_BLACK)'}
    Color={isUserFounder ? WHITE : 'var(--WHITE_OR_RAISING_BLACK)'}
    disabled={!isUserFounder}
    Height="40px"
    Width="100%"
    name="claimResource"
    onClick={handleGoToLink}
  >
    Claim Resource
  </Button>
);

export default getButtonStyles;
