import PropTypes from 'prop-types';
import {
  ERROR_CONFIG,
  THANK_YOU_CONFIG,
} from 'components/Forms/Company/Response/constants';
import repackFormState from 'components/Forms/Company/Response/utils';
import { formStateProp } from 'components/Forms/Company/propTypes';
import { StatusCodes } from 'http-status-codes';
import { useEffect, useState } from 'react';
import ResponsePopUp from 'components/common/ResponsePopUp/ResponsePopUp';

const Response = ({ formState, request }) => {
  const [success, setSuccess] = useState(true);
  const [errorConfig, setErrorConfig] = useState(ERROR_CONFIG);

  let ignore = false;

  useEffect(() => {
    const submitCompany = async () => {
      try {
        if (!ignore) {
          const requestBody = [
            repackFormState(formState),
            ...(formState.id ? [formState.id] : []),
          ];

          const responseData = await request(...requestBody);

          if (responseData.status !== StatusCodes.OK)
            throw new Error(`Failed: ${responseData.response}`);
        }

        setSuccess(true);
      } catch (error) {
        if (error.response.data?.message) {
          setErrorConfig({
            ...ERROR_CONFIG,
            descriptionText: error.response.data.message,
          });
        }

        setSuccess(false);
      }
    };

    submitCompany();

    return () => {
      ignore = true;
    };
  }, []);

  return <ResponsePopUp {...(success ? THANK_YOU_CONFIG : errorConfig)} />;
};

Response.propTypes = {
  formState: formStateProp().isRequired,
  request: PropTypes.func.isRequired,
};

export default Response;
