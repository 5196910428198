import { useReducer } from 'react';
import {
  INVESTOR_PARAMS,
  investorParamsActions,
} from 'pages/FundingPage/components/InvestorFilters/constants';
import {
  changeFilterOrPaginationParam,
  setInitialQueryParams,
} from './actions';

export const INITIAL_OFF_SET = 0;

const initialQueryParams = {
  [INVESTOR_PARAMS.FULL_NAME]: '',
  [INVESTOR_PARAMS.COMPANY_NAME]: '',
  [INVESTOR_PARAMS.CATEGORY]: null,
  [INVESTOR_PARAMS.CHECK_SIZE]: null,
  [INVESTOR_PARAMS.GEO_FOCUS]: null,
  [INVESTOR_PARAMS.LIMIT]: 50,
  [INVESTOR_PARAMS.OFFSET]: INITIAL_OFF_SET,
};

const investorQueryParamsReducer = (state, action) => {
  switch (action.type) {
    case investorParamsActions.SET_SORT_PARAM:
      return {
        ...state,
        ...action.payload.sortType,
      };

    case investorParamsActions.SET_FILTER_OR_PAGINATION_PARAM:
      return {
        ...state,
        [action.payload.field]: action.payload.data,
        [INVESTOR_PARAMS.OFFSET]:
          action.payload.field === INVESTOR_PARAMS.OFFSET
            ? action.payload.data
            : 0,
      };

    case investorParamsActions.SET_INITIAL_QUERY_PARAMS:
      return { ...initialQueryParams };

    default:
      throw new Error(`no such action: ${action.type}`);
  }
};

const useInvestorParams = () => {
  const [investorQueryParams, dispatch] = useReducer(
    investorQueryParamsReducer,
    {
      ...initialQueryParams,
    },
  );

  const setPaginationOffset = (offset) =>
    dispatch(changeFilterOrPaginationParam(INVESTOR_PARAMS.OFFSET, offset));

  const setFullName = ({ target: { value } }) =>
    dispatch(changeFilterOrPaginationParam(INVESTOR_PARAMS.FULL_NAME, value));

  const setCompanyName = ({ target: { value } }) =>
    dispatch(
      changeFilterOrPaginationParam(INVESTOR_PARAMS.COMPANY_NAME, value),
    );

  const setCategory = (option) =>
    dispatch(
      changeFilterOrPaginationParam(
        INVESTOR_PARAMS.CATEGORY,
        option ? option.id : null,
      ),
    );

  const setCheckSize = (option) =>
    dispatch(
      changeFilterOrPaginationParam(
        INVESTOR_PARAMS.CHECK_SIZE,
        option ? option.id : null,
      ),
    );

  const setGeoFocus = (option) =>
    dispatch(
      changeFilterOrPaginationParam(
        INVESTOR_PARAMS.GEO_FOCUS,
        option ? option.id : null,
      ),
    );

  const resetFilters = () => dispatch(setInitialQueryParams());

  return {
    investorQueryParams,
    setFullName,
    setCompanyName,
    setCategory,
    setCheckSize,
    setGeoFocus,
    setPaginationOffset,
    resetFilters,
  };
};

export default useInvestorParams;
