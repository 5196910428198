import NotFound from 'assets/icons/NotFound';
import { CONTACT_EMAIL } from 'constants/other';
import Dots from 'components/common/Dots/Dots';
import HOME_ROUTE from 'routes/constants';

import {
  NotFoundContainer,
  NotFoundTitle,
  NotFoundParagraph,
  DotsNotFound,
  HomeLink,
  Wrapper,
  ImageWrapper,
} from './NotFoundPage.styled';

const NotFoundPage = () => (
  <NotFoundContainer>
    <Wrapper>
      <DotsNotFound>
        <Dots />
      </DotsNotFound>
      <NotFoundTitle>
        We couldn&apos;t find the page that
        <br />
        you are looking for
      </NotFoundTitle>
      <NotFoundParagraph>
        Go back to <HomeLink to={HOME_ROUTE}>Home</HomeLink> or{' '}
        <a href={`mailto:${CONTACT_EMAIL}`}>Contact us</a> about the problem
      </NotFoundParagraph>
      <ImageWrapper>
        <NotFound />
      </ImageWrapper>
    </Wrapper>
  </NotFoundContainer>
);

export default NotFoundPage;
