export const ADD_AN_INVESTOR = 'ADD_AN_INVESTOR';

export const STEPS = {
  ENTRY_MODAL: 'ENTRY_MODAL',
  STEP_ONE: 'STEP_ONE',
  STEP_TWO: 'STEP_TWO',
  RESPONSE: 'RESPONSE',
};

export const ADD_PROGRESS = {
  [STEPS.ENTRY_MODAL]: '0%',
  [STEPS.STEP_ONE]: '50%',
  [STEPS.STEP_TWO]: '100%',
  [STEPS.RESPONSE]: '0%',
};
