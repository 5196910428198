const getOptionColor = (categoryName, list) => {
  const listOfKeys = Object.keys(list);
  const matchColor = listOfKeys.find((color) =>
    list[color].includes(categoryName),
  );

  return matchColor || null;
};

export const mapCategoriesToOptions = (iterableObject, categoryName) =>
  iterableObject.map((value) => ({
    id: value.id,
    label: value.name,
    bgColor: getOptionColor(value.name, categoryName),
  }));

export const addDays = (date, days) => date + days;
