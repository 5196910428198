import PropTypes from 'prop-types';
import {
  PopupButtonContainer,
  PopupColumn,
  PopupContainer,
  PopupContent,
  PopupContentContainer,
  PopupName,
  PopupSection,
  PopupSectionParagraph,
  PopupSubSectionName,
  PopupTitle,
  PopupTitleContainer,
} from 'components/common/Popup/Popup.styled';
import {
  CATEGORIES,
  FUNDING_STAGE,
  GEOGRAPHIC_LOCATIONS,
} from 'pages/HomePage/constants';
import Spacer from 'components/common/Spacer/Spacer.styled';
import { Button, PrimaryButton } from 'components/common/Button/Button.styled';
import validateURL from 'utils/linkValidator';
import PopupMultiCategories from 'components/common/Popup/PopupMultiCategories';
import isURL from 'validator/lib/isURL';
import CHECK_SIZES from 'constants/colorOptionPairs/checkSizesColors';
import Tooltip from 'components/common/Tooltip/Tooltip';
import { UnavailableWrap } from 'pages/FundingPage/FundingPage.styled';
import { CRIMSON, WHITE } from 'constants/colors';

export const NOT_AVAILABLE = 'N/A';

const InvestorPopup = ({
  fullName,
  investorEmail,
  categories,
  vcFirmName,
  classYear,
  fundingStages,
  geographicFocus,
  checkSizes,
  isUserLoggedIn,
}) => {
  return (
    <PopupContainer>
      <PopupContentContainer>
        <PopupTitleContainer>
          <PopupTitle>Investor Information</PopupTitle>
          <PopupName>{fullName}</PopupName>
        </PopupTitleContainer>
        <PopupContent>
          <PopupColumn>
            <PopupSection>
              <PopupSubSectionName>MBA graduation year</PopupSubSectionName>
              <PopupSectionParagraph>
                {classYear || NOT_AVAILABLE}
              </PopupSectionParagraph>
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Email</PopupSubSectionName>
              {isUserLoggedIn ? (
                <PopupSectionParagraph>
                  {investorEmail || NOT_AVAILABLE}
                </PopupSectionParagraph>
              ) : (
                <UnavailableWrap>
                  <Tooltip
                    Heigth="30px"
                    Width="150px"
                    Side="Top"
                    LeftOffSet="0px"
                    TopOffSet="0px"
                    ArrowLeftOffSet="20px"
                    text="Log in or sign up to view!"
                  >
                    Unavailable
                  </Tooltip>
                </UnavailableWrap>
              )}
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Check size</PopupSubSectionName>
              <PopupMultiCategories
                categories={checkSizes}
                categoriesColors={CHECK_SIZES}
              />
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Geographic focus</PopupSubSectionName>
              <PopupMultiCategories
                categories={geographicFocus}
                categoriesColors={GEOGRAPHIC_LOCATIONS}
              />
            </PopupSection>
          </PopupColumn>
          <PopupColumn>
            <PopupSection>
              <PopupSubSectionName>VC Firm Name</PopupSubSectionName>
              <PopupSectionParagraph>
                {vcFirmName || NOT_AVAILABLE}
              </PopupSectionParagraph>
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Company stage</PopupSubSectionName>
              <PopupMultiCategories
                categories={fundingStages}
                categoriesColors={FUNDING_STAGE}
              />
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Category</PopupSubSectionName>
              <PopupMultiCategories
                categories={categories}
                categoriesColors={CATEGORIES}
              />
            </PopupSection>
          </PopupColumn>
        </PopupContent>
        <Spacer Height="15px" />
      </PopupContentContainer>
      <PopupButtonContainer>
        <a
          href={
            investorEmail && isURL(investorEmail)
              ? validateURL(investorEmail)
              : `mailto:${investorEmail}`
          }
          target="_blank"
          rel="noreferrer"
          disabled={isUserLoggedIn}
        >
          {isUserLoggedIn ? (
            <PrimaryButton
              Width="152px"
              Height="40px"
              BgColor={
                isUserLoggedIn ? CRIMSON : 'var(--RAISING_BLACK_OR_WHITE)'
              }
              Border={
                isUserLoggedIn
                  ? 'none'
                  : '1px solid var(--WHITE_OR_RAISING_BLACK)'
              }
              Color={isUserLoggedIn ? WHITE : 'var(--WHITE_OR_RAISING_BLACK)'}
              disabled={!isUserLoggedIn}
            >
              Contact Investor
            </PrimaryButton>
          ) : (
            <Tooltip
              Heigth="30px"
              Width="150px"
              Side="Top"
              LeftOffSet="-80px"
              TopOffSet="0px"
              ArrowLeftOffSet="75px"
              text="Log in or sign up first!"
            >
              <Button
                Width="152px"
                Height="40px"
                BgColor={
                  isUserLoggedIn ? CRIMSON : 'var(--RAISING_BLACK_OR_WHITE)'
                }
                Border={
                  isUserLoggedIn
                    ? 'none'
                    : '1px solid var(--WHITE_OR_RAISING_BLACK)'
                }
                Color={isUserLoggedIn ? WHITE : 'var(--WHITE_OR_RAISING_BLACK)'}
                disabled
              >
                Contact Investor
              </Button>
            </Tooltip>
          )}
        </a>
        <Spacer Height="40px" />
      </PopupButtonContainer>
    </PopupContainer>
  );
};

InvestorPopup.defaultProps = {
  classYear: null,
  isUserLoggedIn: false,
};

InvestorPopup.propTypes = {
  fullName: PropTypes.string.isRequired,
  investorEmail: PropTypes.string.isRequired,
  vcFirmName: PropTypes.string.isRequired,
  classYear: PropTypes.number,
  checkSizes: PropTypes.arrayOf(PropTypes.string).isRequired,
  fundingStages: PropTypes.arrayOf(PropTypes.string).isRequired,
  geographicFocus: PropTypes.arrayOf(PropTypes.string).isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  isUserLoggedIn: PropTypes.bool,
};

export default InvestorPopup;
