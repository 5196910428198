import PropTypes from 'prop-types';
import BinIcon from 'assets/icons/BinIcon';
import RestoreIcon from 'assets/icons/RestoreIcon';
import {
  ControlsContainer,
  ControlsButton,
} from 'pages/AdminPage/components/AdminTable/AdditionalElements/Controls/Controls.styled';
import EditPencilIcon from 'assets/icons/EditPencilIcon';

const Controls = ({
  isDeleted,
  handleRestore,
  openDeleteModalForId,
  openEditModalForId,
}) => (
  <ControlsContainer>
    {!isDeleted ? (
      <>
        <ControlsButton type="button" onClick={openEditModalForId}>
          <EditPencilIcon />
        </ControlsButton>
        <ControlsButton type="button" onClick={openDeleteModalForId}>
          <BinIcon fillColor="var(--WHITE_OR_RAISING_BLACK)" />
        </ControlsButton>
      </>
    ) : (
      <ControlsButton type="button" onClick={handleRestore}>
        <RestoreIcon fillColor="var(--WHITE_OR_RAISING_BLACK)" />
      </ControlsButton>
    )}
  </ControlsContainer>
);

Controls.defaultProps = {
  isDeleted: false,
};

Controls.propTypes = {
  openDeleteModalForId: PropTypes.func.isRequired,
  openEditModalForId: PropTypes.func.isRequired,
  handleRestore: PropTypes.func.isRequired,
  isDeleted: PropTypes.bool,
};

export default Controls;
