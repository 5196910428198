import styled from 'styled-components';
import DEVICE from 'constants/deviceSizes';

export const SortWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 45px;
  position: relative;
  width: 150px;

  @media ${DEVICE.formResponsive} {
    width: 120px;
  }
`;

export const SortFieldWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const SortLabel = styled.label`
  color: var(--WHITE_OR_RAISING_BLACK);
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 10px;
`;

export const SortField = styled.div`
  align-items: center;
  border: 1px solid var(--GRAY_80_OR_RAISIN_BLACK_ALPHA_03);
  border-radius: 4px;
  cursor: pointer;
  color: var(--WHITE_OR_RAISING_BLACK);
  display: flex;
  flex: 1;
  font-size: 12px;
  line-height: 18px;
  min-width: 142px;
  justify-content: space-between;
  padding: 0 8px 0 12px;

  svg > path {
    fill: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  }
`;

export const DropdownMenuContainer = styled.div`
  position: relative;
`;

export const DropdownMenu = styled.div`
  background-color: var(--RAISING_BLACK_OR_WHITE);
  border: 1px solid var(--GRAY_80_OR_BRIGHT_GRAY);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  left: 0;
  max-height: ${(props) => props.MaxHeight};
  padding: 20px 0 18px 15px;
  overflow: auto;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  z-index: 30;
  width: calc(100% - 5px);

  @media ${DEVICE.formResponsive} {
    width: 145px;
  }
`;
