import PropTypes from 'prop-types';
import { CompanyListContainer } from './CompaniesContainer.styled';
import CompanyCard from './CompanyCard/CompanyCard';

const CompanyList = ({ data, setPreviewCompany }) => (
  <CompanyListContainer>
    {data.map((company) => (
      <CompanyCard
        {...company}
        key={company.id}
        previewCompanyDetails={() => setPreviewCompany(company)}
      />
    ))}
  </CompanyListContainer>
);

CompanyList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  setPreviewCompany: PropTypes.func.isRequired,
};

export default CompanyList;
