import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FiltersContainer } from 'pages/AdminPage/components/Filters/Filters.styled';
import FilterSelect from 'pages/AdminPage/components/Filters/FilterSelect/FilterSelect';
import useRequest from 'hooks/useRequest';
import {
  getAllInvestorCategories,
  getAllCheckSizes,
  getAllFundingStages,
} from 'services/requests';
import { INVESTOR_PARAMS } from 'pages/AdminPage/TabControl/InvestorTab/InvestorFilters/constants';
import investorFiltersSchema from 'pages/AdminPage/TabControl/InvestorTab/InvestorFilters/schema';
import useParamsProps from 'pages/AdminPage/TabControl/InvestorTab/InvestorFilters/useInvestorParams/propTypes';
import {
  generateStatusOptions,
  mapCheckSizes,
  mapCategories,
  mapFundingStages,
} from 'pages/AdminPage/TabControl/InvestorTab/InvestorFilters/utils';

const Filters = ({
  investorQueryParams,
  setCategory,
  setStage,
  setCheckSize,
  setStatus,
}) => {
  const [categories, setCategories] = useState([]);
  const [fundingStages, setFundingStages] = useState([]);
  const [checkSizes, setCheckSizes] = useState([]);
  const statusOptions = generateStatusOptions();

  const { request } = useRequest();

  useEffect(() => {
    let ignore = false;

    const fetchCategories = async () => {
      const response = await request(() => getAllInvestorCategories());

      if (!ignore && response) setCategories(mapCategories(response.data));
    };

    const fetchFundingStages = async () => {
      const response = await request(() => getAllFundingStages());

      if (!ignore && response)
        setFundingStages(mapFundingStages(response.data));
    };

    const fetchCheckSizes = async () => {
      const response = await request(() => getAllCheckSizes());

      if (!ignore && response) setCheckSizes(mapCheckSizes(response.data));
    };

    fetchCategories();
    fetchFundingStages();
    fetchCheckSizes();

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <FiltersContainer>
      <FilterSelect
        {...investorFiltersSchema.CATEGORY}
        options={categories}
        value={investorQueryParams[INVESTOR_PARAMS.CATEGORY]}
        setValue={setCategory}
      />
      <FilterSelect
        {...investorFiltersSchema.CHECK_SIZES}
        options={checkSizes}
        value={investorQueryParams[INVESTOR_PARAMS.CHECK_SIZE]}
        setValue={setCheckSize}
      />
      <FilterSelect
        {...investorFiltersSchema.STAGE}
        options={fundingStages}
        value={investorQueryParams[INVESTOR_PARAMS.STAGE]}
        setValue={setStage}
      />
      <FilterSelect
        {...investorFiltersSchema.STATUS}
        options={statusOptions}
        value={investorQueryParams[INVESTOR_PARAMS.STATUS]}
        setValue={setStatus}
      />
    </FiltersContainer>
  );
};

Filters.propTypes = {
  investorQueryParams: useParamsProps().isRequired,
  setCategory: PropTypes.func.isRequired,
  setStage: PropTypes.func.isRequired,
  setCheckSize: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
};

export default Filters;
