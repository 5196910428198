export const FILTERS_KEYS = {
  COMPANY_NAME: 'Company name',
  FOUNDER: 'Founder',
  CATEGORY: 'Category',
  FUNDING_STAGE: 'FundingStage',
  FOUNDING_YEAR: 'FoundingYear',
};

export const filterParamsSchema = {
  [FILTERS_KEYS.COMPANY_NAME]: 'companyName',
  [FILTERS_KEYS.FOUNDER]: 'founderName',
  [FILTERS_KEYS.CATEGORY]: 'category',
  [FILTERS_KEYS.FUNDING_STAGE]: 'stage',
  [FILTERS_KEYS.FOUNDING_YEAR]: 'foundingYear',
};

export const selectFiltersSchema = {
  [FILTERS_KEYS.CATEGORY]: {
    label: 'Category',
    placeHolder: 'Select',
    MaxHeight: '180px',
  },
  [FILTERS_KEYS.FUNDING_STAGE]: {
    label: 'Funding stage',
    placeHolder: 'Select',
    MaxHeight: '180px',
  },
  [FILTERS_KEYS.FOUNDING_YEAR]: {
    label: 'Founding year',
    placeHolder: 'Select',
    MaxHeight: '180px',
  },
};

export const textFilterSchema = {};
