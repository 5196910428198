import { useState } from 'react';
import PropTypes from 'prop-types';
import FilterControls from 'pages/AdminPage/components/Filters/FilterControls/FilterControls';
import Filters from 'pages/AdminPage/components/Filters/Filters';
import {
  SORT_KEYS,
  SORT_PARAMS,
} from 'pages/AdminPage/TabControl/CompanyTab/CompanyFilters/constants';
import useParamsProps from 'pages/AdminPage/TabControl/CompanyTab/CompanyFilters/useCompanyParams/propTypes';
import ClearFilters from 'pages/AdminPage/components/Filters/ClearFilters/ClearFilters';
import FiltersContainer from 'pages/AdminPage/TabControl/CompanyTab/CompanyFilters/CompanyFilters.styled';
import AddACompanyButton from 'pages/AdminPage/components/Filters/AddACompanyButton/AddACompanyButton';
import { radioButtonOptions } from 'pages/AdminPage/TabControl/CompanyTab/CompanyFilters/utils';
import BulkImportButton from 'pages/AdminPage/components/Filters/BulkImportButton/BulkImportButton';

const CompanyFilters = ({
  companyQueryParams,
  setCompanyName,
  setCategory,
  setFounder,
  setFoundingYear,
  setFundingStage,
  setSortType,
  resetFilters,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const toggleFilters = () => setShowFilters(!showFilters);

  return (
    <FiltersContainer id="scrollInto">
      <FilterControls
        toggleFilters={toggleFilters}
        setSortType={setSortType}
        sortByParam={companyQueryParams.sortBy}
        orderByParam={companyQueryParams.orderBy}
        radioButtonOptions={radioButtonOptions(SORT_KEYS)}
        sortParams={SORT_PARAMS}
        AddEntityButton={AddACompanyButton}
        BulkImportButton={BulkImportButton}
      />
      {showFilters && (
        <>
          <ClearFilters resetFilters={resetFilters} />
          <Filters
            companyQueryParams={companyQueryParams}
            setCategory={setCategory}
            setCompanyName={setCompanyName}
            setFounder={setFounder}
            setFoundingYear={setFoundingYear}
            setFundingStage={setFundingStage}
          />
        </>
      )}
    </FiltersContainer>
  );
};

CompanyFilters.propTypes = {
  companyQueryParams: useParamsProps().isRequired,
  setCompanyName: PropTypes.func.isRequired,
  setCategory: PropTypes.func.isRequired,
  setFounder: PropTypes.func.isRequired,
  setFoundingYear: PropTypes.func.isRequired,
  setFundingStage: PropTypes.func.isRequired,
  setSortType: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
};

export default CompanyFilters;
