import PropTypes from 'prop-types';
import ModalCloseButtonIcon from 'assets/icons/ModalCloseButtonIcon';
import {
  OptionContent,
  CloseButton,
  OptionName,
  OptionNameContainer,
} from 'components/common/Dropdown/Option/Option.styled';
import ColorCircleIcon from 'assets/icons/FilledCircleIcon';

const Option = ({ option, onTagRemove, isSelected, disabledDarkMode }) => (
  <OptionContent isSelected={isSelected}>
    {option.color && <ColorCircleIcon color={option.color} />}
    <OptionNameContainer
      isSelected={isSelected}
      bgColor={option.bgColor}
      disabledDarkMode={disabledDarkMode}
    >
      <OptionName disabledDarkMode={disabledDarkMode} bgColor={option.bgColor}>
        {option.label}
      </OptionName>
    </OptionNameContainer>
    {isSelected && (
      <CloseButton
        type="button"
        onClick={(e) => {
          onTagRemove(e, option);
        }}
        className="dropdown-tag-close"
        disabledDarkMode={disabledDarkMode}
      >
        <ModalCloseButtonIcon size="8px" />
      </CloseButton>
    )}
  </OptionContent>
);

Option.defaultProps = {
  option: {
    label: '',
    color: null,
    bgColor: null,
  },
  isSelected: false,
  onTagRemove: () => {},
  disabledDarkMode: false,
};

Option.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string,
    color: PropTypes.string,
    bgColor: PropTypes.string,
  }),
  isSelected: PropTypes.bool,
  onTagRemove: PropTypes.func,
  disabledDarkMode: PropTypes.bool,
};

export default Option;
