import axios from 'axios';
import {
  readLocalStorage,
  removeLocalStorageItem,
} from 'utils/localStorageUtils';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const authFetch = axios.create();

// Authentication Interceptors
authFetch.interceptors.request.use(
  (config) => {
    const user = readLocalStorage('user');

    if (user) {
      return {
        ...config,
        headers: {
          ...config.headers,
          common: {
            ...config.headers.common,
            Authorization: `Bearer ${user.token}`,
          },
        },
      };
    }

    return config;
  },
  (error) => Promise.reject(error),
);

authFetch.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      removeLocalStorageItem('user');
    }

    return Promise.reject(error);
  },
);

// User-related API functions
export const loginUser = async (data) => axios.post(`/login`, data);

export const registerUser = async (data) => axios.post(`/register`, data);

export const submitCaptcha = async (captchaToken) =>
  axios.post('/captcha-verify', { captchaToken });

export const forgotPassword = async (data) =>
  axios.post(`/user/forgot-password`, data);

export const resetPassword = async (data) =>
  axios.post(`/user/reset-password`, data);

// Company-related API functions
export const getAllCompanies = async (params) =>
  authFetch.get(`/companies`, { params });

export const getAllCompaniesByUserId = async () =>
  authFetch.get(`/user/companies`);

export const createCompany = async (data) => axios.post('/companies', data);

export const getCompanyById = async (companyId) =>
  axios.get(`/companies/${companyId}`);

export const updateCompanyById = async (data, companyId) =>
  authFetch.put(`/companies/${companyId}`, data);

// Miscellaneous Company API functions
export const getAllCardColors = async () => axios.get('/card-colors');

export const getAllCategories = async () => axios.get('/categories');

export const getAllGeologicalLocations = async () =>
  axios.get('/company-geographic-focus');

export const getAllFounderAttributes = async () =>
  axios.get('/company-founder-attributes');

export const getAllCompanyStages = async () => axios.get('/company-stages');

export const getAllFundingStages = async () => axios.get('/funding-stages');

export const getAllOptions = async () =>
  axios.all([
    getAllCardColors(),
    getAllCategories(),
    getAllCompanyStages(),
    getAllFundingStages(),
    getAllGeologicalLocations(),
  ]);
// Admin-Company related API functions
export const getStatisticsByPeriod = async (params) =>
  authFetch.get('/admin/companies/statistics', { params });

export const deleteCompanies = async (ids) =>
  authFetch.delete(`/admin/companies`, { data: { ids } });

export const restoreCompanies = async (ids) =>
  authFetch.put('/admin/companies', { ids });

export const getAdminCompanyModalOptions = async () =>
  axios.all([
    getAllCardColors(),
    getAllCategories(),
    getAllCompanyStages(),
    getAllFundingStages(),
    getAllGeologicalLocations(),
    getAllFounderAttributes(),
  ]);

export const bulkImportCompanies = async (csvFile, isPreview = false) => {
  const formData = new FormData();
  formData.append('file', csvFile);

  return authFetch.post('/admin/companies/bulk', formData, {
    params: {
      isPreview,
    },
  });
};

// Public Deal-related API functions
export const getPublicResources = async (params) =>
  authFetch.get('/public-deals', { params });

export const postPublicResource = async (data) =>
  axios.post('/public-deals', data);

export const getPublicResourceById = async (id) =>
  authFetch.get(`/public-deals/${id}`);

export const updatePublicResourceById = async (data, id) =>
  authFetch.put(`/public-deals/${id}`, data);

export const deletePublicResources = async (ids) =>
  authFetch.delete(`/admin/public-deals`, { data: { ids } });

export const restorePublicResources = async (ids) =>
  authFetch.put('/admin/public-deals/restore', { ids });

// Exclusive Deal-related API functions
export const getExclusiveDeals = async (params) =>
  authFetch.get('/exclusive-deals', { params });

export const getExclusiveResourceById = async (id) =>
  authFetch.get(`/exclusive-deals/${id}`);

export const postExclusiveResource = async (data) =>
  axios.post('/exclusive-deals', data);

export const updateExclusiveResourceById = async (data, id) =>
  authFetch.patch(`/exclusive-deals/${id}`, data);

export const deleteExclusiveResources = async (ids) =>
  authFetch.delete(`/admin/exclusive-deals`, { data: { ids } });

export const restoreExclusiveResources = async (ids) =>
  authFetch.put('/admin/exclusive-deals/restore', { ids });

// Miscellaneous Resource API functions
export const getTargetAudience = async () => axios.get('/target-audiences');

export const getPublicDealCategories = async () =>
  axios.get('/public-deal-categories');

export const getExclusiveDealCategories = async () =>
  axios.get('/exclusive-deal-categories');

export const getPublicResourceOptions = async () =>
  axios.all([getTargetAudience(), getPublicDealCategories()]);

// Event-related API functions
export const getEventAudiences = async () => axios.get('/event-audience');

export const getEventOrganizers = async () => axios.get('/event-organizers');

export const getEventCategories = async () =>
  axios.all([getEventAudiences(), getEventOrganizers()]);

export const getAllEvents = async () => axios.get('/events');

export const getEventById = async (id) => axios.get(`/events/${id}`);

export const createEvent = async (data) => axios.post('/events', data);

export const updateEvent = async (data, id) => axios.put(`/events/${id}`, data);

// Investor-related API functions
export const createInvestor = async (data) =>
  authFetch.post('/investors', data);

export const getAllInvestors = async (params) =>
  authFetch.get('/investors', { params });

export const getAllInvestorsAdmin = async (params) =>
  authFetch.get('/investors', {
    params: {
      ...params,
      status: params.status === null ? 'ALL' : params.status,
    },
  });

export const patchInvestorStatus = async (id, status) =>
  authFetch.patch(`/investors/${id}`, { status });

export const getAllCheckSizes = async () => axios.get('/check-sizes');

export const getAllInvestorCategories = async () =>
  axios.get('/investor-categories');

export const getAllInvestorFormOptions = async () =>
  axios.all([
    getAllInvestorCategories(),
    getAllFundingStages(),
    getAllGeologicalLocations(),
    getAllCheckSizes(),
  ]);

// Miscellaneous functions
export const subscribeToNewsletter = async (subscriberEmail) =>
  axios.post('/subscribers', { subscriberEmail });
