import styled from 'styled-components';
import { CRIMSON, DARK_CHARCOAL, DAVY_GRAY } from 'constants/colors';
import DEVICE from 'constants/deviceSizes';

export const PopupControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 100%;

  & > div {
    border: none;
  }

  & > div > button:first-child > svg {
    transform: scale(1.2);

    path {
      fill: ${DARK_CHARCOAL};
    }
  }

  & > div > button:last-child > svg {
    transform: scale(1.2);

    path {
      fill: ${CRIMSON};
    }
  }

  @media ${DEVICE.tablet} {
    position: relative;
    justify-content: center;
    bottom: auto;
    right: auto;
    width: 100%;
  }
`;

export const FounderInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 3%;
  width: 100%;

  @media ${DEVICE.formResponsive} {
    flex-direction: column;
    align-items: center;
  }
`;

export const SingleFounder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3%;

  @media ${DEVICE.formResponsive} {
    align-items: center;
  }
`;

export const FounderName = styled.p`
  color: ${DARK_CHARCOAL};
  font-size: 12px;
  font-weight: 600;
  line-height: 28px;
`;

export const MbaContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${DEVICE.formResponsive} {
    align-items: center;
  }
`;

export const Mba = styled.span`
  color: ${DAVY_GRAY};
  font-size: 12px;
`;

export const Founder = styled.span`
  color: ${DAVY_GRAY};
  font-size: 12px;
`;
