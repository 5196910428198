import PropTypes from 'prop-types';
import FilterIcon from 'assets/icons/FilterIcon';
import { ToggleFiltersButton } from 'pages/AdminPage/components/Filters/FilterControls/ToggleFilters.styled';

const ToggleFilters = ({ toggleFilters }) => (
  <ToggleFiltersButton onClick={toggleFilters}>
    <FilterIcon /> Filters
  </ToggleFiltersButton>
);

ToggleFilters.propTypes = {
  toggleFilters: PropTypes.func.isRequired,
};

export default ToggleFilters;
