import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StatusCodes } from 'http-status-codes';
import ResponsePopUp from 'components/common/ResponsePopUp/ResponsePopUp';
import {
  THANK_YOU_CONFIG,
  ERROR_CONFIG,
} from 'pages/AdminPage/components/Forms/ExclusiveResource/DeleteExclusiveResource/Response/constants';
import useAdminExclusiveResourceStore from 'store/exclusiveResource/useAdminExclusiveResourceStore';

const Response = ({ resourceIds, req, closeForm }) => {
  const [success, setSuccess] = useState(true);
  const deleteMultipleExclusiveResources = useAdminExclusiveResourceStore(
    (state) => state.deleteMultipleExclusiveResources,
  );

  useEffect(() => {
    let ignore = false;

    const deleteExclusiveResources = async () => {
      try {
        if (!ignore) {
          ignore = true;
          const responseData = await req(resourceIds);

          if (responseData.status === StatusCodes.OK) {
            deleteMultipleExclusiveResources(resourceIds);
            setSuccess(true);
          }
        }
      } catch (error) {
        setSuccess(false);
      }

      setTimeout(() => {
        closeForm();
      }, 1200);
    };

    deleteExclusiveResources();

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <ResponsePopUp
      {...(success
        ? { ...THANK_YOU_CONFIG, descriptionText: 'Successfully deleted' }
        : ERROR_CONFIG)}
    />
  );
};

Response.propTypes = {
  resourceIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  req: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
};

export default Response;
