import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import {
  Wrapper,
  FormContainer,
  Step,
  Title,
  TitleContainer,
  FieldContainer,
  ButtonsContainer,
  RowContainer,
  ColumnContainer,
} from 'components/Forms/Company/StepTwo/StepTwo.styled';
import Spacer from 'components/common/Spacer/Spacer.styled';
import SingleSelect from 'components/common/Dropdown/SingleSelect';
import MultiSelect from 'components/common/Dropdown/MultiSelect';
import BackArrow from 'assets/icons/BackArrow';
import {
  generateYearOptions,
  mapCategories,
  mapColors,
  mapFundingStages,
  mapGeographicFocus,
  mapOperatingStages,
} from 'components/Forms/Company/StepTwo/utils';
import { getAllOptions } from 'services/requests';
import useRequest from 'hooks/useRequest';
import { formStateProp } from 'components/Forms/Company/propTypes';
import useFormInput from 'components/common/FormComponents/Input/FormInput/useFormInput';
import FormInput from 'components/common/FormComponents/Input/FormInput/FormInput';
import {
  BUTTONS_CSS,
  STEP_TWO_KEYS,
} from 'components/Forms/Company/StepTwo/constants';
import FormDescription from 'components/common/FormComponents/Input/FormDescription/FormDescription';
import {
  CompanyInfoDescriptionSchema,
  CompanyInfoInputSchema,
  CompanyInfoSelectSchema,
} from 'components/Forms/Company/StepTwo/schema';

const StepTwo = ({
  backStep,
  nextStep,
  currentStep,
  closeForm,
  formState,
  setFormState,
}) => {
  const [inputsState, setInputsState] = useState(CompanyInfoInputSchema);
  const [selectsState, setSelectsState] = useState(CompanyInfoSelectSchema);
  const [descriptionState, setDescriptionState] = useState(
    CompanyInfoDescriptionSchema,
  );

  const onChangeHandler =
    (stateHandler, key) =>
    ({ target: { value } }) => {
      setFormState((prevState) => ({ ...prevState, [key]: value }));
      stateHandler((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          isError: prevState[key].isError && !prevState[key].validator(value),
        },
      }));
    };
  const { onBlurHandler, triggerError } = useFormInput();

  const yearOptions = generateYearOptions();
  const [cardColors, setCardColors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [companyStages, setCompanyStages] = useState([]);
  const [fundingStages, setFundingStages] = useState([]);
  const [geographicFocus, setGeographicFocus] = useState([]);

  const formEl = useRef();

  const { request } = useRequest();

  const fetchOptions = async () => {
    const allData = await request(() => getAllOptions());

    if (allData) {
      setCardColors(mapColors(allData[0].data));
      setCategories(mapCategories(allData[1].data));
      setCompanyStages(mapOperatingStages(allData[2].data));
      setFundingStages(mapFundingStages(allData[3].data));
      setGeographicFocus(mapGeographicFocus(allData[4].data));
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  const selectionHandler = (key, collection) => (option) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]: option && collection.find((object) => object.id === option.id),
    }));
    setSelectsState((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        isError: false,
      },
    }));
  };

  const yearSelectionHandler = (option) => {
    setSelectsState((prevState) => ({
      ...prevState,
      [STEP_TWO_KEYS.FOUNDING_YEAR]: {
        ...prevState[STEP_TWO_KEYS.FOUNDING_YEAR],
        isError: false,
      },
    }));
    setFormState((prevState) => ({
      ...prevState,
      [STEP_TWO_KEYS.FOUNDING_YEAR]:
        option && yearOptions.find((object) => object.label === option.label),
    }));
  };

  const multiSelectionHandler = (key, collection) => (options) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]:
        options &&
        options.map((option) =>
          collection.find((object) => object.id === option.id),
        ),
    }));
    setSelectsState((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        isError: false,
      },
    }));
  };

  const handleBack = (e) => {
    e.preventDefault();

    backStep();
  };

  const onClickSubmitButton = (e) => {
    e.preventDefault();

    const keysInput = Object.keys(inputsState);
    const keysSelect = Object.keys(selectsState);
    const keysDescription = Object.keys(descriptionState);

    const inputsWithErrors = keysInput.filter(
      (key) => !inputsState[key].validator(formState[key]),
    );
    const selectsWithErrors = keysSelect.filter(
      (key) => !selectsState[key].validator(formState[key]),
    );
    const descriptionWithError = keysDescription.filter(
      (key) => !descriptionState[key].validator(formState[key]),
    );

    inputsWithErrors.forEach((key) => triggerError(setInputsState, key));
    selectsWithErrors.forEach((key) => triggerError(setSelectsState, key));
    descriptionWithError.forEach((key) =>
      triggerError(setDescriptionState, key),
    );

    if (
      inputsWithErrors.length ||
      selectsWithErrors.length ||
      descriptionWithError.length
    )
      return;

    nextStep();
  };

  return (
    <Wrapper>
      <TitleContainer>
        <Step>
          <SecondaryButton
            Border="none"
            noDark
            onClick={handleBack}
            type="button"
          >
            <BackArrow />
          </SecondaryButton>
          {`Step ${currentStep}`}
        </Step>
        <Title>General Info</Title>
      </TitleContainer>

      <FormContainer onSubmit={onClickSubmitButton} ref={formEl}>
        <RowContainer>
          <FieldContainer>
            <FormInput
              {...inputsState[STEP_TWO_KEYS.COMPANY_NAME]}
              value={formState[STEP_TWO_KEYS.COMPANY_NAME]}
              name={STEP_TWO_KEYS.COMPANY_NAME}
              onChangeHandler={onChangeHandler(
                setInputsState,
                STEP_TWO_KEYS.COMPANY_NAME,
              )}
              onBlurHandler={onBlurHandler(
                setInputsState,
                STEP_TWO_KEYS.COMPANY_NAME,
              )}
            />
          </FieldContainer>
          <FieldContainer>
            <SingleSelect
              {...selectsState[STEP_TWO_KEYS.FOUNDING_YEAR]}
              selectedValue={formState[STEP_TWO_KEYS.FOUNDING_YEAR]}
              options={yearOptions}
              onChangeHandler={yearSelectionHandler}
              disabledDarkMode
            />
            <Spacer Height="20px" />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <FieldContainer>
            <FormInput
              {...inputsState[STEP_TWO_KEYS.COMPANY_WEBSITE]}
              value={formState[STEP_TWO_KEYS.COMPANY_WEBSITE]}
              name={STEP_TWO_KEYS.COMPANY_WEBSITE}
              onChangeHandler={onChangeHandler(
                setInputsState,
                STEP_TWO_KEYS.COMPANY_WEBSITE,
              )}
              onBlurHandler={onBlurHandler(
                setInputsState,
                STEP_TWO_KEYS.COMPANY_WEBSITE,
              )}
            />
          </FieldContainer>
          <FieldContainer>
            <MultiSelect
              key={STEP_TWO_KEYS.CATEGORIES}
              {...selectsState[STEP_TWO_KEYS.CATEGORIES]}
              selectedValue={formState[STEP_TWO_KEYS.CATEGORIES]}
              options={categories}
              onChangeHandler={multiSelectionHandler(
                STEP_TWO_KEYS.CATEGORIES,
                categories,
              )}
              disabledDarkMode
            />
            <Spacer Height="20px" />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <FieldContainer>
            <SingleSelect
              {...selectsState[STEP_TWO_KEYS.COMPANY_STAGE]}
              selectedValue={formState[STEP_TWO_KEYS.COMPANY_STAGE]}
              options={companyStages}
              onChangeHandler={selectionHandler(
                STEP_TWO_KEYS.COMPANY_STAGE,
                companyStages,
              )}
              disabledDarkMode
            />
            <Spacer Height="20px" />
          </FieldContainer>
          <FieldContainer>
            <SingleSelect
              {...selectsState[STEP_TWO_KEYS.FUNDING_STAGE]}
              selectedValue={formState[STEP_TWO_KEYS.FUNDING_STAGE]}
              options={fundingStages}
              onChangeHandler={selectionHandler(
                STEP_TWO_KEYS.FUNDING_STAGE,
                fundingStages,
              )}
              disabledDarkMode
            />
            <Spacer Height="20px" />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <ColumnContainer>
            <FieldContainer>
              <SingleSelect
                {...selectsState[STEP_TWO_KEYS.COMPANY_CARD_COLOR]}
                selectedValue={formState[STEP_TWO_KEYS.COMPANY_CARD_COLOR]}
                options={cardColors}
                onChangeHandler={selectionHandler(
                  STEP_TWO_KEYS.COMPANY_CARD_COLOR,
                  cardColors,
                )}
                disabledDarkMode
              />
              <Spacer Height="20px" />
            </FieldContainer>
            <FieldContainer>
              <MultiSelect
                key={STEP_TWO_KEYS.GEOGRAPHIC_FOCUS}
                {...selectsState[STEP_TWO_KEYS.GEOGRAPHIC_FOCUS]}
                selectedValue={formState[STEP_TWO_KEYS.GEOGRAPHIC_FOCUS]}
                options={geographicFocus}
                onChangeHandler={multiSelectionHandler(
                  STEP_TWO_KEYS.GEOGRAPHIC_FOCUS,
                  geographicFocus,
                )}
                disabledDarkMode
              />
            </FieldContainer>
          </ColumnContainer>
          <ColumnContainer>
            <FieldContainer>
              <FormDescription
                {...descriptionState[STEP_TWO_KEYS.COMPANY_DESCRIPTION]}
                value={formState[STEP_TWO_KEYS.COMPANY_DESCRIPTION]}
                name={STEP_TWO_KEYS.COMPANY_DESCRIPTION}
                onChangeHandler={onChangeHandler(
                  setDescriptionState,
                  STEP_TWO_KEYS.COMPANY_DESCRIPTION,
                )}
                onBlurHandler={onBlurHandler(
                  setDescriptionState,
                  STEP_TWO_KEYS.COMPANY_DESCRIPTION,
                )}
              />
            </FieldContainer>
          </ColumnContainer>
        </RowContainer>

        <ButtonsContainer>
          <SecondaryButton {...BUTTONS_CSS.secondary} onClick={closeForm}>
            Cancel
          </SecondaryButton>
          <Spacer Width="16px" />
          <PrimaryButton {...BUTTONS_CSS.primary}>Next: HBSBers</PrimaryButton>
          <Spacer Height="20px" />
        </ButtonsContainer>
      </FormContainer>
    </Wrapper>
  );
};

StepTwo.propTypes = {
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  formState: formStateProp().isRequired,
};

export default StepTwo;
