const getOptionColor = (categoryName, list) => {
  const listOfKeys = Object.keys(list);
  const matchColor = listOfKeys.find((color) =>
    list[color].includes(categoryName),
  );

  return matchColor || null;
};

export const mapEventAudiencesToOptions = (iterableObject, categoryName) =>
  iterableObject.map(({ id, audienceName }) => ({
    id,
    label: audienceName,
    bgColor: getOptionColor(audienceName, categoryName),
  }));

export const mapEventOrganizersToOptions = (iterableObject, categoryName) =>
  iterableObject.map(({ id, organizerName }) => ({
    id,
    label: organizerName,
    bgColor: getOptionColor(organizerName, categoryName),
  }));
