import { useEffect, useState } from 'react';
import {
  generateYearOptions,
  mapCategories,
  mapColors,
  mapFounderAttributes,
  mapFundingStages,
  mapGeographicFocus,
  mapOperatingStages,
} from 'components/Forms/Company/StepTwo/utils';
import { getAdminCompanyModalOptions } from 'services/requests';
import useRequest from 'hooks/useRequest';

const useEditModalOptions = () => {
  const [isFetchingOptions, setIsFetchingOptions] = useState(true);

  const yearOptions = generateYearOptions();
  const [cardColors, setCardColors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [companyStages, setCompanyStages] = useState([]);
  const [fundingStages, setFundingStages] = useState([]);
  const [geographicFocus, setGeographicFocus] = useState([]);
  const [founderAttributes, setFounderAttributes] = useState([]);

  const { request } = useRequest();

  useEffect(() => {
    let ignore = false;

    const fetchOptions = async () => {
      setIsFetchingOptions(true);

      const allData = await request(() => getAdminCompanyModalOptions());

      if (!ignore && allData) {
        setCardColors(mapColors(allData[0].data));
        setCategories(mapCategories(allData[1].data));
        setCompanyStages(mapOperatingStages(allData[2].data));
        setFundingStages(mapFundingStages(allData[3].data));
        setGeographicFocus(mapGeographicFocus(allData[4].data));
        setFounderAttributes(mapFounderAttributes(allData[5].data));
      }

      setIsFetchingOptions(false);
    };

    fetchOptions();

    return () => {
      ignore = true;
    };
  }, []);

  return {
    isFetchingOptions,
    yearOptions,
    cardColors,
    categories,
    companyStages,
    fundingStages,
    geographicFocus,
    founderAttributes,
  };
};

export default useEditModalOptions;
