import { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
  LoadingSpinnerContainer,
  LoadingSpinner,
} from 'components/common/LoadingSpinner/LoadingSpinner.styled';
import NoResults from 'components/common/NoResults/NoResults';
import AdminTable from 'pages/AdminPage/components/AdminTable/AdminTable';
import CompanyTabContainer from 'pages/AdminPage/TabControl/CompanyTab/CompanyTab.styled';
import COMPANY_COLUMNS from 'pages/AdminPage/TabControl/CompanyTab/CompanyTable/companyTableSchema';
import useCompanyParams from 'pages/AdminPage/TabControl/CompanyTab/CompanyFilters/useCompanyParams/useCompanyParams';
import CompanyFilters from 'pages/AdminPage/TabControl/CompanyTab/CompanyFilters/CompanyFilters';
import CompanyControlSelected from 'pages/AdminPage/TabControl/CompanyTab/CompanyTable/CompanyControlSelected';
import Modal from 'components/common/Modal/Modal';
import AdminCompanyPopup from 'pages/AdminPage/TabControl/CompanyTab/CompanyPopup/AdminCompanyPopup';
import useCompanyPreview from 'pages/AdminPage/TabControl/CompanyTab/useCompanyPreview';
import Pagination from 'pages/AdminPage/components/AdminTable/AdditionalElements/Pagination/Pagination';
import useRequest from 'hooks/useRequest';
import { getAllCompanies } from 'services/requests';
import { REQUEST_DEBOUNCE_DURATION } from 'constants/input_debounce_duration';
import { LIMIT_OPTIONS } from 'pages/AdminPage/TabControl/CompanyTab/CompanyFilters/constants';
import useAdminCompanyStore from 'store/company/useAdminCompanyStore';
import CompanyStatistics from './CompanyStatistics/CompanyStatistics';

const CompanyTab = () => {
  const {
    companyQueryParams,
    setCategory,
    setCompanyName,
    setFounder,
    setFoundingYear,
    setFundingStage,
    setStatisticParam,
    setSortType,
    setPaginationLimit,
    setPaginationOffset,
    resetFilters,
  } = useCompanyParams();

  const { request } = useRequest();
  const companies = useAdminCompanyStore((state) => state.companies);
  const totalNumOfCompanies = useAdminCompanyStore(
    (state) => state.totalNumOfCompanies,
  );
  const setCompanies = useAdminCompanyStore((state) => state.setCompanies);

  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    let ignore = false;

    const fetchCompanies = async () => {
      const response = await request(() => getAllCompanies(companyQueryParams));

      if (!ignore && response) {
        setCompanies(response.data.data, response.data.total);

        setFetching(false);
      }
    };

    if (!ignore) setFetching(true);

    const fetchPublicResourcesDebounced = setTimeout(
      fetchCompanies,
      REQUEST_DEBOUNCE_DURATION,
    );

    return () => {
      ignore = true;

      clearTimeout(fetchPublicResourcesDebounced);
    };
  }, [request, companyQueryParams]);

  const { previewCompany, handleRowClick, closePopup } = useCompanyPreview();

  const handlePageClick = (value) => {
    setPaginationOffset(value);
    document.getElementById('scrollInto').scrollIntoView();
  };

  const handleItemsPerPageChange = ({ target: { value } }) => {
    setPaginationLimit(+value);
    document.getElementById('scrollInto').scrollIntoView();
  };

  const displayCompanies = useCallback(() => {
    if (fetching)
      return (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      );

    if (!companies.length) return <NoResults />;

    return (
      <>
        <AdminTable
          columns={COMPANY_COLUMNS}
          data={companies}
          ControlSelected={CompanyControlSelected}
          handleRowClick={handleRowClick}
        />
        <Pagination
          totalRows={totalNumOfCompanies}
          offset={companyQueryParams.offset}
          limit={companyQueryParams.limit}
          limitOptions={LIMIT_OPTIONS}
          handlePageClick={handlePageClick}
          handleItemsPerPageChange={handleItemsPerPageChange}
        />
      </>
    );
  }, [companies, fetching]);

  return (
    <CompanyTabContainer>
      <Outlet />
      {!!previewCompany && (
        <Modal closeOnEscape closeForm={closePopup}>
          <AdminCompanyPopup {...previewCompany} closeForm={closePopup} />
        </Modal>
      )}
      <CompanyStatistics
        companyQueryParams={companyQueryParams}
        setStatisticParam={setStatisticParam}
        resetFilters={resetFilters}
      />
      <CompanyFilters
        companyQueryParams={companyQueryParams}
        setCategory={setCategory}
        setCompanyName={setCompanyName}
        setFounder={setFounder}
        setFoundingYear={setFoundingYear}
        setFundingStage={setFundingStage}
        setSortType={setSortType}
        resetFilters={resetFilters}
      />
      {displayCompanies()}
    </CompanyTabContainer>
  );
};

export default CompanyTab;
