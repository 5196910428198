import styled from 'styled-components';
import DEVICE from 'constants/deviceSizes';
import { CRIMSON, RAISIN_BLACK } from 'constants/colors';

export const Wrapper = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 0px 72px;
  width: 780px;

  @media ${DEVICE.tablet} {
    justify-content: center;
    margin: 0px 20px;
    width: 450px;
  }

  @media ${DEVICE.formResponsive} {
    width: 335px;
  }
`;

export const DescriptionContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  p {
    color: ${RAISIN_BLACK};
    font-size: 12px;
    line-height: 20px;
    margin: 0px;
    max-width: 480px;
    text-align: left;
  }

  b {
    color: ${RAISIN_BLACK};
  }

  a {
    color: ${CRIMSON};
    font-weight: 600;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
    &:hover::after {
      text-decoration: none;
    }
  }

  @media ${DEVICE.laptop} {
    text-align: center;
  }
`;
