import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'components/common/Modal/Modal';
import {
  LoadingSpinner,
  AbsoluteLoadingSpinnerContainer,
} from 'components/common/LoadingSpinner/LoadingSpinner.styled';
import useFetchExclusiveResourceById from 'pages/AdminPage/components/Forms/ExclusiveResource/EditExclusiveResource/hooks/useFetchExclusiveResourceById';
import EditExclusiveCombinedSteps from 'pages/AdminPage/components/Forms/ExclusiveResource/EditExclusiveResource/EditExclusiveCombinedSteps';
import useExclusiveResourceOptions from 'pages/AdminPage/components/Forms/ExclusiveResource/EditExclusiveResource/hooks/useExclusiveResourceOptions';

const EditExclusiveResource = () => {
  const {
    state: { ids },
  } = useLocation();

  const navigate = useNavigate();
  const closeForm = () =>
    navigate({ pathname: '/administration/exclusive-resource' });

  const { isLoading, initialExclusiveResourceData } =
    useFetchExclusiveResourceById(ids);

  const { isFetchingOptions, exclusiveDealCategories } =
    useExclusiveResourceOptions();

  if (isLoading || isFetchingOptions)
    return (
      <AbsoluteLoadingSpinnerContainer>
        <LoadingSpinner />
      </AbsoluteLoadingSpinnerContainer>
    );

  if (!initialExclusiveResourceData) return null;

  return (
    <Modal closeForm={closeForm} closeOnEscape>
      <EditExclusiveCombinedSteps
        closeForm={closeForm}
        initialExclusiveResourceData={initialExclusiveResourceData}
        exclusiveDealCategories={exclusiveDealCategories}
      />
    </Modal>
  );
};

export default EditExclusiveResource;
