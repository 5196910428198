import HBSResources from 'pages/ResourcePage/components/HBSResources/HBSResources';
import {
  ResourcesSection,
  LandingSectionWrapper,
  ResourcesAnchorPartnerWrapper,
  LandingSection,
} from 'pages/ResourcePage/ResourcePage.styled';
import AnchorPartner from 'components/common/AnchorPartner/AnchorPartner';
import IlabInfoSection from 'pages/ResourcePage/components/IlabInfoSection/IlabInfoSection';
import ExclusiveResourcesContainer from 'pages/ResourcePage/components/ExclusiveResourcesContainer/ExclusiveResourcesContainer';
import PublicResourcesContainer from './components/PublicResourcesContainer/PublicResourcesContainer';

const ResourcePage = () => (
  <ResourcesSection>
    <LandingSection>
      <LandingSectionWrapper>
        <HBSResources />
        <ResourcesAnchorPartnerWrapper>
          <AnchorPartner />
        </ResourcesAnchorPartnerWrapper>
      </LandingSectionWrapper>
    </LandingSection>
    <PublicResourcesContainer />
    <ExclusiveResourcesContainer />
    <IlabInfoSection />
  </ResourcesSection>
);
export default ResourcePage;
