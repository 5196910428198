/* eslint-disable react/prop-types */
import { NOT_AVAILABLE } from 'pages/AdminPage/TabControl/InvestorTab/AdminInvestorPopup/AdminInvestorPopup';
import { StatusIndicator } from 'pages/AdminPage/TabControl/InvestorTab/InvestorTab.styled';
import APPROVAL_STATUS_COLORS from 'pages/AdminPage/TabControl/InvestorTab/constants';
import { TDdescription } from 'pages/AdminPage/components/AdminTable/TableElements/TableElements.styled';

const INVESTOR_COLUMNS = [
  {
    Header: 'Investor Name',
    accessor: 'fullName',
    width: '15%',
  },
  {
    Header: 'Email',
    accessor: 'investorEmail',
    width: '17%',
  },
  {
    Header: 'VC firm',
    accessor: 'vcFirmName',
    width: '15%',
  },
  {
    Header: 'Class year',
    accessor: 'classYear',
    width: '10%',
    Cell: ({
      row: {
        original: { classYear },
      },
    }) => <TDdescription>{classYear || NOT_AVAILABLE}</TDdescription>,
  },
  {
    Header: 'Status',
    width: '8%',
    Cell: ({
      row: {
        original: { id, status },
      },
    }) => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <StatusIndicator id={id} Color={APPROVAL_STATUS_COLORS[status]}>
          {status}
        </StatusIndicator>
      </div>
    ),
  },
];

export default INVESTOR_COLUMNS;
