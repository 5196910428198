import PropTypes from 'prop-types';
import {
  PopupButtonContainer,
  PopupColumn,
  PopupContainer,
  PopupContent,
  PopupContentContainer,
  PopupName,
  PopupSection,
  PopupSectionParagraph,
  PopupSubSectionName,
  PopupTitle,
  PopupTitleContainer,
} from 'components/common/Popup/Popup.styled';
import {
  CATEGORIES,
  FUNDING_STAGE,
  GEOGRAPHIC_LOCATIONS,
} from 'pages/HomePage/constants';
import Spacer from 'components/common/Spacer/Spacer.styled';
import { PrimaryButton } from 'components/common/Button/Button.styled';
import PopupMultiCategories from 'components/common/Popup/PopupMultiCategories';
import CHECK_SIZES from 'constants/colorOptionPairs/checkSizesColors';
import SingleSelect from 'components/common/Dropdown/SingleSelect';
import {
  APPROVAL_STATUSES,
  generateStatusOptions,
} from 'pages/AdminPage/TabControl/InvestorTab/InvestorFilters/utils';
import { useState } from 'react';
import APPROVAL_STATUS_COLORS from 'pages/AdminPage/TabControl/InvestorTab/constants';
import { StatusIndicator } from 'pages/AdminPage/TabControl/InvestorTab/InvestorTab.styled';

export const NOT_AVAILABLE = 'N/A';

const AdminInvestorPopup = ({
  fullName,
  investorEmail,
  categories,
  vcFirmName,
  classYear,
  fundingStages,
  geographicFocus,
  checkSizes,
  status,
  updateInvestor,
  isUpdatingInvestor,
}) => {
  const statusOptions = generateStatusOptions().filter(
    (option) => option.id !== 'ALL',
  );
  const [investorStatus, setInvestorStatus] = useState(status);
  const onChangeHandler = (option) => setInvestorStatus(option.id);

  return (
    <PopupContainer>
      <PopupContentContainer>
        <PopupTitleContainer>
          <PopupTitle>Investor Information</PopupTitle>
          <PopupName>{fullName}</PopupName>
          <StatusIndicator Color={APPROVAL_STATUS_COLORS[status]}>
            {status}
          </StatusIndicator>
          <Spacer Height="15px" />
        </PopupTitleContainer>
        <PopupContent>
          <PopupColumn>
            <PopupSection>
              <PopupSubSectionName>Founding Year</PopupSubSectionName>
              <PopupSectionParagraph>
                {classYear || NOT_AVAILABLE}
              </PopupSectionParagraph>
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Email</PopupSubSectionName>
              <PopupSectionParagraph>
                {investorEmail || NOT_AVAILABLE}
              </PopupSectionParagraph>
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Check size</PopupSubSectionName>
              <PopupMultiCategories
                categories={checkSizes}
                categoriesColors={CHECK_SIZES}
              />
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Geographic focus</PopupSubSectionName>
              <PopupMultiCategories
                categories={geographicFocus}
                categoriesColors={GEOGRAPHIC_LOCATIONS}
              />
            </PopupSection>
            <PopupColumn>
              <SingleSelect
                label="Status"
                selectedValue={{
                  value: investorStatus,
                  label: APPROVAL_STATUSES[investorStatus],
                }}
                options={statusOptions}
                MaxHeight="120px"
                isError={false}
                disabledDarkMode
                validator={() => {}}
                onChangeHandler={onChangeHandler}
              />
            </PopupColumn>
          </PopupColumn>
          <PopupColumn>
            <PopupSection>
              <PopupSubSectionName>VC Firm Name</PopupSubSectionName>
              <PopupSectionParagraph>
                {vcFirmName || NOT_AVAILABLE}
              </PopupSectionParagraph>
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Company stage</PopupSubSectionName>
              <PopupMultiCategories
                categories={fundingStages}
                categoriesColors={FUNDING_STAGE}
              />
            </PopupSection>
            <PopupSection>
              <PopupSubSectionName>Category</PopupSubSectionName>
              <PopupMultiCategories
                categories={categories}
                categoriesColors={CATEGORIES}
              />
            </PopupSection>
          </PopupColumn>
        </PopupContent>
        <Spacer Height="15px" />
      </PopupContentContainer>
      <PopupButtonContainer>
        <PrimaryButton
          Width="152px"
          Height="40px"
          Border="none"
          disabled={status === investorStatus || isUpdatingInvestor}
          onClick={() => updateInvestor(investorStatus)}
        >
          {isUpdatingInvestor ? 'Updating...' : 'Update'}
        </PrimaryButton>
        <Spacer Height="40px" />
      </PopupButtonContainer>
    </PopupContainer>
  );
};

AdminInvestorPopup.defaultProps = {
  classYear: null,
};

AdminInvestorPopup.propTypes = {
  fullName: PropTypes.string.isRequired,
  investorEmail: PropTypes.string.isRequired,
  vcFirmName: PropTypes.string.isRequired,
  classYear: PropTypes.number,
  checkSizes: PropTypes.arrayOf(PropTypes.string).isRequired,
  fundingStages: PropTypes.arrayOf(PropTypes.string).isRequired,
  geographicFocus: PropTypes.arrayOf(PropTypes.string).isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  status: PropTypes.string.isRequired,
  updateInvestor: PropTypes.func.isRequired,
  isUpdatingInvestor: PropTypes.bool.isRequired,
};

export default AdminInvestorPopup;
