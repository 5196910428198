import PropTypes from 'prop-types';

const { shape, number, string, oneOfType, bool } = PropTypes;

const useParamsProps = () =>
  shape({
    category: number,
    founderName: string,
    companyName: string,
    foundingYear: oneOfType([number, string]),
    stage: number,
    sortBy: string,
    orderBy: string,
    limit: number,
    offset: number,
    created: number,
    updated: number,
    hiring: bool,
  });

export default useParamsProps;
