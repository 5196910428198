import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const HomePageContainer = styled.div`
  padding: 20px 75px 0;

  @media ${DEVICE.laptop} {
    padding: 20px 50px 0;
  }
  @media ${DEVICE.formResponsive} {
    padding: 20px 25px 0;
  }
`;

export const LandingSection = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
`;

export const LandingSectionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 40px;

  @media ${DEVICE.laptop} {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    max-width: 450px;
  }
`;
