import { useEffect, useState } from 'react';
import useRequest from 'hooks/useRequest';
import { getExclusiveDealCategories } from 'services/requests';
import { EXCLUSIVE_RESOURCE_CATEGORIES } from 'pages/AdminPage/components/Forms/ExclusiveResource/EditExclusiveResource/constants';
import { mapCategoriesToOptions } from 'pages/AdminPage/components/Forms/ExclusiveResource/EditExclusiveResource/utils';

const useExclusiveResourceOptions = () => {
  const [isFetchingOptions, setIsFetchingOptions] = useState(true);
  const [exclusiveDealCategories, setExclusiveDealCategories] = useState(null);

  const { request } = useRequest();

  useEffect(() => {
    const fetchOptions = async () => {
      setIsFetchingOptions(true);

      const data = await request(() => getExclusiveDealCategories());

      if (data) {
        setExclusiveDealCategories(
          mapCategoriesToOptions(data.data, EXCLUSIVE_RESOURCE_CATEGORIES),
        );
      }

      setIsFetchingOptions(false);
    };

    fetchOptions();
  }, []);

  return {
    isFetchingOptions,
    exclusiveDealCategories,
  };
};

export default useExclusiveResourceOptions;
