import { inputSchema, singleSelectSchema, multiSelectSchema } from '../schema';

export const triggerFieldErrors = (data, setError) => {
  const keysInput = Object.keys(inputSchema);
  const keysSingleSelect = Object.keys(singleSelectSchema);
  const keysMultiSelect = Object.keys(multiSelectSchema);

  const inputsWithErrors = keysInput.filter(
    (key) => !inputSchema[key].validator(data[key]),
  );
  const selectsWithErrors = keysSingleSelect.filter(
    (key) => !singleSelectSchema[key].validator(data[key]),
  );
  const descriptionWithError = keysMultiSelect.filter(
    (key) => !multiSelectSchema[key].validator(data[key]),
  );

  inputsWithErrors.forEach((key) => setError(data, key));
  selectsWithErrors.forEach((key) => setError(data, key));
  descriptionWithError.forEach((key) => setError(data, key));

  if (
    inputsWithErrors.length ||
    selectsWithErrors.length ||
    descriptionWithError.length
  )
    return true;

  return false;
};

export const triggerFounderErrors = (founders, setFounder, keys) => {
  let hasError = false;

  founders.forEach((founder) => {
    const fieldWithErrors = keys.filter((key) => {
      return key !== 'classYear' && !founder[key].validator(founder[key].value);
    });

    fieldWithErrors.push(
      ...(!founder.classYear.validator(founder.classYear.selectedValue)
        ? ['classYear']
        : []),
    );

    if (fieldWithErrors.length) {
      const founderCopy = founder;

      fieldWithErrors.forEach((key) => {
        founderCopy[key] = { ...founder[key], isError: true };
      });

      setFounder(founder.position)(founderCopy);

      hasError = true;
    }

    hasError = false;
  });

  return hasError;
};
