import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const ResetPasswordWrapper = styled.div`
  display: flex;
  margin-top: 9vh;
  flex-direction: column;
  min-height: calc(100vh - 9vh - 96px - 155px);
  align-items: center;
`;

export const TopContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;

  @media ${DEVICE.formResponsive} {
    max-width: 335px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Title = styled.p`
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  margin: 40px 0 0 0;
  text-align: center;
  font-size: 26px;
  line-height: 31px;
  letter-spacing: 0;
`;

export const SubTitle = styled.p`
  padding: 0 70px;
  text-align: center;
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  font-size: 16px;
  margin: 24px 0 40px 0;

  @media ${DEVICE.formResponsive} {
    padding: 0;
  }
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  button {
    padding: 10px 16px;
  }
`;
