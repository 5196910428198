const BRANDS = [
  ['Pulley', 'Stealh Co'],
  ['Hive Health', 'Rent the Runway'],
  ['Tagomi', 'Capchase'],
  ['Cowboy Ventures', 'NFX'],
  ['Thrive Capital', 'Kaya Ventures'],
  ['OneLibri', 'Hilma'],
  ['Affinity Labs', 'Ooga Labs'],
  ['Firsthand', 'Mic'],
  ['Exchange.com', 'RetSku'],
  ['ScoreBig Inc', 'iWrap'],
];

export default BRANDS;
