import PropTypes from 'prop-types';

const { shape, number, string, arrayOf } = PropTypes;

export const defaultInvestorFormState = () => ({
  investorFullName: '',
  email: '',
  investorVCFirm: '',
  mbaGradYear: null,
  checkSize: [],
  fundingStage: [],
  geographicFocus: [],
  categories: [],
});

export const InvestorFormStateProps = () =>
  shape({
    id: number,
    investorFullName: string,
    email: string,
    investorVCFirm: string,
    mbaGradYear: shape({
      id: PropTypes.number,
      label: PropTypes.string,
      bgColor: PropTypes.string,
      color: PropTypes.string,
    }),
    checkSize: arrayOf(
      shape({
        amount: string,
        id: number,
      }),
    ),
    fundingStage: arrayOf(
      shape({
        fundingStageName: string,
        id: number,
      }),
    ),
    geographicFocus: arrayOf(
      shape({
        geographicLocationName: string,
        id: number,
      }),
    ),
    categories: arrayOf(
      shape({
        companyCategoryName: string,
        id: number,
      }),
    ),
  });
