import {
  RESOURCES_PAGINATION_PARAMS,
  RESOURCES_SORT_PARAMS,
} from 'pages/ResourcePage/constants';

const PUBLIC_RESOURCES_PARAMS = {
  RESOURCE_NAME: 'name',
  CATEGORY: 'category',
  TARGET_AUDIENCE: 'target',
  WITH_DELETED: 'withDeleted',
  ...RESOURCES_PAGINATION_PARAMS,
  ...RESOURCES_SORT_PARAMS,
};

export default PUBLIC_RESOURCES_PARAMS;
