import styled from 'styled-components';
import DEVICE from 'constants/deviceSizes';
import {
  WHITE,
  DAVY_GRAY,
  CRIMSON,
  RAISIN_BLACK_ALPHA_03,
  DARK_CHARCOAL,
  BRIGHT_GRAY,
} from 'constants/colors';

export const FormControl = styled.div`
  background-color: ${(props) =>
    props.disabledDarkMode ? WHITE : 'var(--RAISING_BLACK_OR_WHITE)'};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputLabel = styled.label`
  color: ${(props) =>
    props.disabledDarkMode
      ? DARK_CHARCOAL
      : 'var(--BRIGHT_GRAY_OR_DARK_CHARCOAL)'};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 10px;
`;

export const InputFieldAndButtonWrapper = styled.div`
  background-color: ${(props) =>
    props.disabledDarkMode ? WHITE : 'var(--RAISING_BLACK_OR_WHITE)'};
  border: 1px solid
    ${(props) => {
      if (props.isError) return CRIMSON;
      return props.disabledDarkMode
        ? RAISIN_BLACK_ALPHA_03
        : 'var(--GRAY_80_OR_RAISIN_BLACK_ALPHA_03)';
    }};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  &.disabled {
    background: ${BRIGHT_GRAY};
    cursor: not-allowed;
  }
`;

export const InputField = styled.input`
  background: none;
  border: none;
  box-shadow: none;
  color: ${(props) =>
    props.disabledDarkMode ? DAVY_GRAY : 'var(--BRIGHT_GRAY_OR_DAVY_GRAY)'};
  height: 40px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  line-height: 18px;
  width: calc(100% - 20px);

  &:focus {
    outline: none;
  }
  ::placeholder {
    color: ${DAVY_GRAY};
    opacity: 0.5;
  }
  ::-ms-reveal {
    display: none;
  }

  @media ${DEVICE.formResponsive} {
    height: 35px;
    font-size: 16px;
  }
`;

export const TextArea = styled.textarea`
  background: none;
  border: none;
  color: ${(props) =>
    props.disabledDarkMode
      ? DARK_CHARCOAL
      : 'var(--BRIGHT_GRAY_OR_DARK_CHARCOAL)'};
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  line-height: 18px;
  resize: none;
  padding: 12px 0 0 12px;
  width: calc(100% - 20px);

  &:focus {
    outline: none;
  }
  ::placeholder {
    color: ${DAVY_GRAY};
    opacity: 0.5;
  }
  ::-ms-reveal {
    display: none;
  }

  @media ${DEVICE.formResponsive} {
    font-size: 16px;
  }
`;

export const InputHelperText = styled.p`
  position: relative;
  top: 0;
  color: ${CRIMSON};
  font-size: 10px;
  line-height: 24px;
  margin: 0px;
`;
