import COLORS from 'constants/audience_and_organizers_colors';

export const ADD_NEW_EVENT = 'Add a new event';
export const UPDATE_EVENT = 'Update event';

export const STEPS = {
  ENTRY_MODAL: 'ENTRY_MODAL',
  SELECT_EVENT: 'SELECT_EVENT',
  STEP_ONE: 'STEP_ONE',
  STEP_TWO: 'STEP_TWO',
  RESPONSE: 'RESPONSE',
};

export const PROGRESS = {
  [STEPS.ENTRY_MODAL]: '0%',
  [STEPS.STEP_ONE]: '50%',
  [STEPS.STEP_TWO]: '100%',
  [STEPS.RESPONSE]: '0%',
};

export const EVENT_AUDIENCE = {
  [COLORS.BLUE]: ['Venture Capital (VC)'],
  [COLORS.SKY_BLUE]: ['Social Impact'],
  [COLORS.TURQUOISE]: ['EtA / Searchers'],
  [COLORS.GREEN]: ['Founders'],
  [COLORS.YELLOW]: ['RCs'],
  [COLORS.WARM_YELLOW]: ['Joiners'],
  [COLORS.SALMON]: ['Growth / Private Equity (PE)'],
  [COLORS.LAVANDER]: ['Alumni'],
  [COLORS.PURPLE]: ['ECs'],
  [COLORS.GRAY]: ['i-lab Members'],
  [COLORS.ORANGE]: ['Other'],
};

export const EVENT_ORGANIZERS = {
  [COLORS.BLUE]: ['DRF', 'Rock Center for Entrepreneurship'],
  [COLORS.SKY_BLUE]: ['CPD', 'Women in Investing (WII)'],
  [COLORS.TURQUOISE]: ['EtA Club', 'Harvard i-lab'],
  [COLORS.GREEN]: ['Fintech Club', 'HBS'],
  [COLORS.YELLOW]: ['VCPE Club', 'E-Club'],
  [COLORS.WARM_YELLOW]: ['NFX'],
  [COLORS.SALMON]: ['MBA Fund'],
  [COLORS.LAVANDER]: ['Crypto Club'],
  [COLORS.PURPLE]: ['Pheonix Fund'],
  [COLORS.GRAY]: ['Tech Club'],
  [COLORS.ORANGE]: ['Other'],
};
