import PropTypes from 'prop-types';
import SingleSelect from 'components/common/Dropdown/SingleSelect';
import useRequest from 'hooks/useRequest';
import {
  generateYearOptions,
  mapCategories,
  mapFundingStages,
} from 'components/Forms/Company/StepTwo/utils';
import { useEffect, useState } from 'react';
import { getAllCategories, getAllFundingStages } from 'services/requests';
import {
  FiltersContainer,
  FilterWrapper,
  FilterInputField,
  FilterInputFieldWrapper,
  FilterLabel,
  FilterGroupWrapper,
} from './Filters.styled';
import {
  selectFiltersSchema,
  FILTERS_KEYS,
  filterParamsSchema,
} from './schema';

const Filters = ({ filters, handleFiltersChange, isFiltersApplied }) => {
  const [categories, setCategories] = useState([]);
  const [fundingStages, setFundingStages] = useState([]);
  const yearOptions = generateYearOptions();

  const { request } = useRequest();

  const selectionHandler = (field, collection) => (option) =>
    handleFiltersChange(
      field,
      option ? collection.find((object) => object.id === option.id).id : null,
    );

  useEffect(() => {
    let ignore = false;

    const fetchCategories = async () => {
      const response = await request(() => getAllCategories());

      if (!ignore && response) setCategories(mapCategories(response.data));
    };

    const fetchFundingStages = async () => {
      const response = await request(() => getAllFundingStages());

      if (!ignore && response)
        setFundingStages(mapFundingStages(response.data));
    };

    fetchFundingStages();

    fetchCategories();

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <FiltersContainer>
      <FilterWrapper>
        <FilterLabel>{FILTERS_KEYS.COMPANY_NAME}</FilterLabel>
        <FilterInputFieldWrapper>
          <FilterInputField
            value={filters[filterParamsSchema[FILTERS_KEYS.COMPANY_NAME]]}
            onChange={({ target: { value } }) =>
              handleFiltersChange(
                filterParamsSchema[FILTERS_KEYS.COMPANY_NAME],
                value,
              )
            }
          />
        </FilterInputFieldWrapper>
      </FilterWrapper>
      <FilterGroupWrapper>
        <FilterWrapper>
          <FilterLabel>{FILTERS_KEYS.FOUNDER}</FilterLabel>
          <FilterInputFieldWrapper>
            <FilterInputField
              value={filters[filterParamsSchema[FILTERS_KEYS.FOUNDER]]}
              onChange={({ target: { value } }) =>
                handleFiltersChange(
                  filterParamsSchema[FILTERS_KEYS.FOUNDER],
                  value,
                )
              }
            />
          </FilterInputFieldWrapper>
        </FilterWrapper>
        <FilterWrapper>
          <SingleSelect
            key={`${isFiltersApplied}:${FILTERS_KEYS.FOUNDING_YEAR}`}
            {...selectFiltersSchema[FILTERS_KEYS.FOUNDING_YEAR]}
            options={yearOptions}
            onChangeHandler={(option) =>
              handleFiltersChange(
                filterParamsSchema[FILTERS_KEYS.FOUNDING_YEAR],
                option && option.label,
              )
            }
            selectedValue={yearOptions.find(
              (object) =>
                object.label ===
                filters[filterParamsSchema[FILTERS_KEYS.FOUNDING_YEAR]],
            )}
          />
        </FilterWrapper>
      </FilterGroupWrapper>
      <FilterWrapper>
        <SingleSelect
          key={`${isFiltersApplied}:${FILTERS_KEYS.CATEGORY}`}
          {...selectFiltersSchema[FILTERS_KEYS.CATEGORY]}
          options={categories}
          onChangeHandler={selectionHandler(
            filterParamsSchema[FILTERS_KEYS.CATEGORY],
            categories,
          )}
          selectedValue={categories.find(
            (object) =>
              object.id === filters[filterParamsSchema[FILTERS_KEYS.CATEGORY]],
          )}
        />
      </FilterWrapper>
      <FilterWrapper>
        <SingleSelect
          key={`${isFiltersApplied}:${FILTERS_KEYS.FUNDING_STAGE}`}
          {...selectFiltersSchema[FILTERS_KEYS.FUNDING_STAGE]}
          options={fundingStages}
          onChangeHandler={selectionHandler(
            filterParamsSchema[FILTERS_KEYS.FUNDING_STAGE],
            fundingStages,
          )}
          selectedValue={fundingStages.find(
            (object) =>
              object.id ===
              filters[filterParamsSchema[FILTERS_KEYS.FUNDING_STAGE]],
          )}
        />
      </FilterWrapper>
    </FiltersContainer>
  );
};

Filters.defaultProps = {
  filters: {
    companyName: '',
    founderName: '',
    foundingYear: null,
    stage: null,
    category: null,
  },
};

Filters.propTypes = {
  isFiltersApplied: PropTypes.bool.isRequired,
  filters: PropTypes.shape({
    companyName: PropTypes.string,
    founderName: PropTypes.string,
    foundingYear: PropTypes.string,
    stage: PropTypes.number,
    category: PropTypes.number,
  }),
  handleFiltersChange: PropTypes.func.isRequired,
};

export default Filters;
