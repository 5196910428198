export const DEFAULT_PAGINATION_OFFSET = 0;

export const DEFAULT_PAGINATION_LIMIT = 50;

export const DEFAULT_SORT_TYPE = 'Newest';

export const NOT_AVAILABLE = 'N/A';

export const INITIAL_FILTERS = {
  companyName: '',
  founderName: '',
  foundingYear: null,
  stage: null,
  category: null,
};

export const companySortOptions = [
  {
    label: 'Newest',
    name: 'foundingYear',
  },
  {
    label: 'Oldest',
    name: 'foundingYear',
  },
  {
    label: 'A-Z',
    name: 'companyName',
  },
  {
    label: 'Z-A',
    name: 'companyName',
  },
];

export const sortQueryParams = {
  Newest: {
    sortBy: 'foundingYear',
    orderBy: 'DESC',
  },
  Oldest: {
    sortBy: 'foundingYear',
    orderBy: 'ASC',
  },
  'Z-A': {
    sortBy: 'companyName',
    orderBy: 'DESC',
  },
  'A-Z': {
    sortBy: 'companyName',
    orderBy: 'ASC',
  },
};
