export const STEP_TWO_KEYS = {
  EMAIL: 'email',
  CATEGORIES: 'categories',
  CHECK_SIZE: 'checkSize',
  FUNDING_STAGE: 'fundingStage',
  MBA_GRAD_YEAR: 'mbaGradYear',
  GEOGRAPHIC_FOCUS: 'geographicFocus',
};

export const BUTTONS_CSS = {
  secondary: {
    Border: '1px solid #545556',
    Width: '83px',
    Height: '40px',
    FontWeight: '600',
    FontSize: '14px',
    type: 'button',
    noDark: true,
  },
  primary: {
    Height: '40px',
    FontWeight: '600',
    FontSize: '14px',
    Width: '103px',
    Border: 'none',
    type: 'submit',
  },
};
