const AddIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99996 15.6665C5.32496 15.6665 2.33329 12.6748 2.33329 8.99984C2.33329 5.32484 5.32496 2.33317 8.99996 2.33317C12.675 2.33317 15.6666 5.32484 15.6666 8.99984C15.6666 12.6748 12.675 15.6665 8.99996 15.6665ZM8.99996 0.666504C7.90561 0.666504 6.82198 0.882052 5.81093 1.30084C4.79988 1.71963 3.88122 2.33346 3.1074 3.10728C1.5446 4.67008 0.666626 6.7897 0.666626 8.99984C0.666626 11.21 1.5446 13.3296 3.1074 14.8924C3.88122 15.6662 4.79988 16.28 5.81093 16.6988C6.82198 17.1176 7.90561 17.3332 8.99996 17.3332C11.2101 17.3332 13.3297 16.4552 14.8925 14.8924C16.4553 13.3296 17.3333 11.21 17.3333 8.99984C17.3333 7.90549 17.1177 6.82186 16.699 5.81081C16.2802 4.79976 15.6663 3.8811 14.8925 3.10728C14.1187 2.33346 13.2 1.71963 12.189 1.30084C11.1779 0.882052 10.0943 0.666504 8.99996 0.666504ZM9.83329 4.83317H8.16663V8.1665H4.83329V9.83317H8.16663V13.1665H9.83329V9.83317H13.1666V8.1665H9.83329V4.83317Z"
      fill="#545556"
    />
  </svg>
);

export default AddIcon;
