import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams, useNavigate } from 'react-router-dom';
import useFormInput from 'components/common/FormComponents/Input/FormInput/useFormInput';
import FormInput from 'components/common/FormComponents/Input/FormInput/FormInput';
import { PrimaryButton } from 'components/common/Button/Button.styled';
import Spacer from 'components/common/Spacer/Spacer.styled';
import { toggleTextPasswordTypes } from 'components/Forms/SignForm/utils';
import { resetPassword } from 'services/requests';
import { FormRowContainer } from 'components/Forms/SignForm/SignForm.styled';
import PasswordEyeToggle from 'components/common/PasswordEyeToggle/PasswordEyeToggle';
import { FormContainer, Wrapper } from './ResetPassword.styled';
import RESET_PASSWORD_FORM_INITIAL_STATE from './schemas';
import { RESET_PASSWORD_FIELD_NAME_KEYS } from './constants';
import {
  SIGN_IN_FIELD_NAME_KEYS,
  SUBMIT_BUTTON_CSS,
} from '../SignForm/constants';
import Alert from '../../common/Alert/Alert';

const keys = Object.values(RESET_PASSWORD_FIELD_NAME_KEYS);

const ResetPassword = ({ handleSuccess }) => {
  const { onChangeHandler, onBlurHandler } = useFormInput();

  const [formState, setFormState] = useState(RESET_PASSWORD_FORM_INITIAL_STATE);
  const [isSuccess, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const userId = searchParams.get('id');

  useEffect(() => {
    // if someone hits /reset-password without token and id redirect to home.
    if (!token || !userId) {
      navigate('/');
    }
  });

  useEffect(() => {
    if (isSuccess) handleSuccess(true);
  }, [isSuccess]);

  const handleEyeToggle = () =>
    setFormState((prevState) => ({
      ...prevState,
      [SIGN_IN_FIELD_NAME_KEYS.PASSWORD]: {
        ...prevState[SIGN_IN_FIELD_NAME_KEYS.PASSWORD],
        inputType: toggleTextPasswordTypes(
          prevState[SIGN_IN_FIELD_NAME_KEYS.PASSWORD].inputType,
        ),
      },
    }));

  const submitHandler = async (e) => {
    e.preventDefault();
    setSuccess(false);
    setError('');

    if (formState.confirm_password.value === formState.password.value) {
      try {
        const payload = {
          id: userId,
          token,
          password: formState.password.value,
        };
        const { status } = await resetPassword(payload);

        if (status === 200) setSuccess(true);
      } catch (err) {
        if (err.response.status === 400) {
          setError(
            'Your link has expired, please go to forgot password and request password reset link again.',
          );
        } else {
          setError(err.response.data?.message);
        }
      }
    }
  };

  return (
    <Wrapper>
      <FormContainer onSubmit={submitHandler}>
        {keys.map((key) => (
          <FormRowContainer key={key}>
            <FormInput
              {...formState[key]}
              key={key}
              name={key}
              onChangeHandler={onChangeHandler(setFormState, key)}
              onBlurHandler={onBlurHandler(setFormState, key)}
            />
            {key === SIGN_IN_FIELD_NAME_KEYS.PASSWORD && (
              <PasswordEyeToggle
                inputType={
                  formState[SIGN_IN_FIELD_NAME_KEYS.PASSWORD].inputType
                }
                isError={formState[SIGN_IN_FIELD_NAME_KEYS.PASSWORD].isError}
                handleEyeToggle={handleEyeToggle}
                enableDarkMode
              />
            )}
          </FormRowContainer>
        ))}
        <PrimaryButton
          {...SUBMIT_BUTTON_CSS}
          Width="188px"
          disabled={
            !formState.password.value || !formState.confirm_password.value
          }
        >
          Create new password
        </PrimaryButton>
        {error && (
          <>
            <Spacer Height="25px" />
            <Alert alertText={error} />
          </>
        )}
      </FormContainer>
    </Wrapper>
  );
};

ResetPassword.propTypes = {
  handleSuccess: PropTypes.func.isRequired,
};

export default ResetPassword;
