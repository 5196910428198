import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Option from 'components/common/Dropdown/Option/Option';
import DropdownShow from 'assets/icons/DropdownShow';
import {
  FormControl,
  SelectedValuesContainer,
  SelectedValues,
  SelectLabel,
  DropdownMenuContainer,
  DropdownMenu,
  DropdownItem,
  SearchBox,
  SearchBoxInput,
  PlaceHolder,
  SelectErrorIconWrapper,
} from 'components/common/Dropdown/Select.styled';
import useClickOutside from 'hooks/useClickOutside';
import SearchIcon from 'assets/icons/SearchIcon';
import DropdownHide from 'assets/icons/DropdownHide';
import Spacer from 'components/common/Spacer/Spacer.styled';
import InputErrorIcon from 'assets/icons/InputErrorIcon';

const SingleSelect = ({
  label,
  placeHolder,
  MaxHeight,
  options,
  selectedValue,
  isSearchable,
  onChangeHandler,
  disabledDarkMode,
  isError,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const searchRef = useRef();
  const contentRef = useRef(null);

  const closeDropDown = () => setShowMenu(false);

  useClickOutside(contentRef, closeDropDown);

  useEffect(() => {
    setSearchValue('');

    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  const menuVisibilityToggle = () => setShowMenu(!showMenu);

  const onTagRemove = (e) => {
    e.stopPropagation();
    onChangeHandler(null);
    setShowMenu(!showMenu);
  };

  const isSelected = (option) =>
    selectedValue && selectedValue.label === option.label;

  const onDropMenuItemClick = (option) => onChangeHandler(option);

  const onSearch = (e) => setSearchValue(e.target.value);

  const getOptions = useCallback(
    () =>
      !searchValue
        ? options
        : options.filter(
            (option) =>
              option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >=
              0,
          ),
    [searchValue, showMenu],
  );

  return (
    <FormControl
      disabledDarkMode={disabledDarkMode}
      onClick={menuVisibilityToggle}
      ref={contentRef}
    >
      <SelectLabel htmlFor={label} disabledDarkMode={disabledDarkMode}>
        {label}
      </SelectLabel>
      <SelectedValuesContainer
        id={label}
        disabledDarkMode={disabledDarkMode}
        isError={isError}
      >
        <SelectedValues key={selectedValue}>
          {selectedValue ? (
            <Option
              key={selectedValue.label}
              option={selectedValue}
              onTagRemove={onTagRemove}
              disabledDarkMode={disabledDarkMode}
            />
          ) : (
            <PlaceHolder disabledDarkMode={disabledDarkMode}>
              {placeHolder}
            </PlaceHolder>
          )}
        </SelectedValues>
        {showMenu ? (
          <DropdownHide onClick={() => setShowMenu(false)} />
        ) : (
          <DropdownShow />
        )}
        {isError && (
          <SelectErrorIconWrapper>
            <InputErrorIcon />
          </SelectErrorIconWrapper>
        )}
      </SelectedValuesContainer>
      {!showMenu && <Spacer Height="4px" />}
      {showMenu && (
        <DropdownMenuContainer disabledDarkMode={disabledDarkMode}>
          <DropdownMenu
            MaxHeight={MaxHeight}
            disabledDarkMode={disabledDarkMode}
          >
            {isSearchable && (
              <SearchBox disabledDarkMode={disabledDarkMode}>
                <SearchIcon />
                <SearchBoxInput
                  value={searchValue}
                  onChange={onSearch}
                  label={searchValue}
                  ref={searchRef}
                  placeholder="Search"
                  disabledDarkMode={disabledDarkMode}
                />
              </SearchBox>
            )}
            {getOptions().map((option) => (
              <DropdownItem
                key={option.label + option.id}
                onClick={() => onDropMenuItemClick(option)}
                isSelected={isSelected(option)}
              >
                <Option
                  option={option}
                  isSelected={isSelected(option)}
                  onTagRemove={onTagRemove}
                  disabledDarkMode={disabledDarkMode}
                />
              </DropdownItem>
            ))}
          </DropdownMenu>
        </DropdownMenuContainer>
      )}
    </FormControl>
  );
};

SingleSelect.defaultProps = {
  isSearchable: true,
  placeHolder: '',
  MaxHeight: '100px',
  options: PropTypes.arrayOf({
    label: '',
    color: 'none',
    bgColor: 'none',
  }),
  selectedValue: null,
  disabledDarkMode: false,
  isError: false,
};

SingleSelect.propTypes = {
  label: PropTypes.string.isRequired,
  placeHolder: PropTypes.string,
  MaxHeight: PropTypes.string,
  isSearchable: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      bgColor: PropTypes.string,
    }),
  ),
  selectedValue: PropTypes.shape({
    label: PropTypes.string,
    color: PropTypes.string,
    bgColor: PropTypes.string,
  }),
  onChangeHandler: PropTypes.func.isRequired,
  disabledDarkMode: PropTypes.bool,
  isError: PropTypes.bool,
};

export default SingleSelect;
