import { isEmptyFieldValidator, isEmptyOrTruthy } from 'utils/inputValidators';
import isEmail from 'validator/lib/isEmail';

import { STEP_TWO_KEYS } from 'components/Forms/Investor/StepTwo/constants';

export const InvestorInfoInputSchema = {
  [STEP_TWO_KEYS.EMAIL]: {
    label: 'Email*',
    inputType: 'email',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: "Enter investor's email",
    validator: isEmail,
  },
};

export const InvestorInfoSingleSelectSchema = {
  [STEP_TWO_KEYS.MBA_GRAD_YEAR]: {
    label: 'MBA grad year',
    placeHolder: 'Select a grad year',
    MaxHeight: '110px',
    isError: false,
    validator: isEmptyOrTruthy,
  },
};

export const InvestorInfoMultiSelectSchema = {
  [STEP_TWO_KEYS.CATEGORIES]: {
    label: 'Category*',
    placeHolder: 'Pick up to 3 categories',
    MaxHeight: '110px',
    isError: false,
    disabledDarkMode: true,
    validator: isEmptyFieldValidator,
  },
  [STEP_TWO_KEYS.GEOGRAPHIC_FOCUS]: {
    label: 'Geographical focus*',
    placeHolder: 'Pick up to 3 locations',
    MaxHeight: '110px',
    isError: false,
    disabledDarkMode: true,
    validator: isEmptyFieldValidator,
  },
  [STEP_TWO_KEYS.FUNDING_STAGE]: {
    label: 'Stage*',
    placeHolder: 'Select a stage',
    MaxHeight: '110px',
    isError: false,
    validator: isEmptyFieldValidator,
  },
  [STEP_TWO_KEYS.CHECK_SIZE]: {
    label: 'Check size*',
    placeHolder: 'Select a check size',
    MaxHeight: '110px',
    isError: false,
    validator: isEmptyFieldValidator,
  },
};
