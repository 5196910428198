import { useEffect, useState } from 'react';
import useRequest from 'hooks/useRequest';
import { mapCategoriesToOptions } from 'pages/AdminPage/components/Forms/PublicResource/EditPublicResource/utils';
import { getPublicResourceOptions } from 'services/requests';
import {
  PUBLIC_RESOURCE_CATEGORIES,
  PUBLIC_RESOURCE_TARGET_AUDIENCES,
} from 'pages/AdminPage/components/Forms/PublicResource/EditPublicResource/constants';

const usePublicResourceOptions = () => {
  const [isFetchingOptions, setIsFetchingOptions] = useState(true);

  const [targetAudiences, setTargetAudience] = useState(null);
  const [publicDealCategories, setPublicDealCategories] = useState(null);

  const { request } = useRequest();

  useEffect(() => {
    const fetchOptions = async () => {
      setIsFetchingOptions(true);

      const allData = await request(() => getPublicResourceOptions());

      if (allData) {
        setTargetAudience(
          mapCategoriesToOptions(
            allData[0].data,
            PUBLIC_RESOURCE_TARGET_AUDIENCES,
          ),
        );
        setPublicDealCategories(
          mapCategoriesToOptions(allData[1].data, PUBLIC_RESOURCE_CATEGORIES),
        );
      }

      setIsFetchingOptions(false);
    };

    fetchOptions();
  }, []);

  return {
    isFetchingOptions,
    targetAudiences,
    publicDealCategories,
  };
};

export default usePublicResourceOptions;
