export const generateYearOptions = (howMuchToAddOnTop = 0) => {
  const max = new Date().getFullYear() + howMuchToAddOnTop;
  const min = max - 50;
  const years = [];

  for (let i = max; i >= min; i -= 1) {
    years.push({ id: i.toString(), label: i.toString() });
  }

  return years;
};

export const mapCategories = (iterableObject) =>
  iterableObject.map((value) => ({
    id: value.id,
    label: value.companyCategoryName,
  }));

export const mapFundingStages = (iterableObject) =>
  iterableObject.map((value) => ({
    id: value.id,
    label: value.fundingStageName,
  }));

export const radioButtonOptions = (object) =>
  Object.values(object).map((value) => ({
    label: value,
    name: 'adminCompaniesSort',
  }));
