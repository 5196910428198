import { useState } from 'react';
import PropTypes from 'prop-types';

import {
  PrimaryButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import {
  FormContainer,
  Step,
  Title,
  TitleContainer,
  Wrapper,
  InputsContainer,
  ButtonsContainer,
} from 'components/Forms/Investor/StepOne/StepOne.styled';
import Spacer from 'components/common/Spacer/Spacer.styled';
import useFormInput from 'components/common/FormComponents/Input/FormInput/useFormInput';
import FormInput from 'components/common/FormComponents/Input/FormInput/FormInput';
import InvestorStepOneInputs from 'components/Forms/Investor/StepOne/schema';

import STEP_ONE_KEYS from 'components/Forms/Investor/StepOne/constants';
import { InvestorFormStateProps } from '../propTypes';

const keys = Object.values(STEP_ONE_KEYS);

const StepOne = ({
  nextStep,
  currentStep,
  closeForm,
  formState,
  setFormState,
}) => {
  const [inputsState, setInputsState] = useState(InvestorStepOneInputs);

  const onChangeHandler =
    (key) =>
    ({ target: { value } }) => {
      setFormState((prevState) => ({ ...prevState, [key]: value }));
      setInputsState((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          isError: prevState[key].isError && !prevState[key].validator(value),
        },
      }));
    };

  const { onBlurHandler, triggerError } = useFormInput();

  const onClickSubmitButton = (e) => {
    e.preventDefault();
    const fieldWithErrors = keys.filter(
      (key) => !inputsState[key].validator(formState[key]),
    );

    fieldWithErrors.forEach((key) => triggerError(setInputsState, key));

    if (fieldWithErrors.length) return;

    nextStep();
  };

  return (
    <Wrapper>
      <TitleContainer>
        <Step>{`Step ${currentStep}`}</Step>
        <Title>General Information</Title>
      </TitleContainer>
      <Spacer Height="20px" />
      <FormContainer onSubmit={onClickSubmitButton}>
        <InputsContainer>
          {keys.map((key) => (
            <div key={`${key}: ${formState[key].label}`}>
              <FormInput
                {...inputsState[key]}
                value={formState[key]}
                onChangeHandler={onChangeHandler(key)}
                onBlurHandler={onBlurHandler(setInputsState, key)}
                name={key}
              />
            </div>
          ))}
        </InputsContainer>
        <ButtonsContainer>
          <SecondaryButton
            Border="1px solid #545556"
            Width="83px"
            Height="40px"
            FontWeight="600"
            FontSize="14px"
            type="button"
            noDark
            onClick={closeForm}
          >
            Cancel
          </SecondaryButton>
          <Spacer Width="16px" />
          <PrimaryButton
            Height="40px"
            FontWeight="600"
            FontSize="14px"
            Width="173px"
            Border="none"
            type="submit"
          >
            Next: Investor Info
          </PrimaryButton>
        </ButtonsContainer>
      </FormContainer>
    </Wrapper>
  );
};

StepOne.propTypes = {
  nextStep: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  formState: InvestorFormStateProps().isRequired,
};

export default StepOne;
