import PropTypes from 'prop-types';
import {
  FilterInputField,
  FilterInputFieldWrapper,
  FilterLabel,
  FilterWrapper,
} from '../Filters.styled';

const FilterInput = ({ label, value, setValue }) => (
  <FilterWrapper>
    <FilterLabel>{label}</FilterLabel>
    <FilterInputFieldWrapper>
      <FilterInputField value={value} onChange={setValue} />
    </FilterInputFieldWrapper>
  </FilterWrapper>
);

FilterInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default FilterInput;
