import ResponseError from 'assets/icons/ResponseError';
import ResponseThankYou from 'assets/icons/ResponseThankYou';

export const THANK_YOU_CONFIG = {
  title: 'Thank you!',
  descriptionText:
    'Thank you for contributing to our community. If you want to access exclusive deals, please go to sign-up button and create an account.',
  SVG: <ResponseThankYou />,
};

export const ERROR_CONFIG = {
  title: 'Something went wrong',
  descriptionText:
    'The server encountered a temporary error and could not complete your request. Please go back to homepage or try again.',
  SVG: <ResponseError />,
};
