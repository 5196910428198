import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const TabControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px 0;
  margin: 24px 0;
`;

export const ListItemContainer = styled.div`
  display: flex;
  gap: 20px;

  @media ${DEVICE.tablet} {
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    scrollbar-width: none;
  }
`;

export const ListItem = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  border-bottom: ${(props) =>
    props.isActive ? `3px solid var(--BRIGHT_GRAY_OR_DAVY_GRAY)` : 'none'};
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  height: 30px;
  font-weight: ${(props) => (props.isActive ? `600` : '400')};
  font-size: 16px;
  line-height: 24px;
  flex-shrink: 0;
  width: ${(props) => props.width};
`;
