import styled from 'styled-components';

export const ControlsContainer = styled.div`
  border-left: 1px solid var(--WHITE_OR_RAISING_BLACK);
  display: flex;
  gap: 5px;
  height: 40px;
  justify-content: center;
`;

export const ControlsButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;
