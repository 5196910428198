const companyFiltersSchema = {
  SORT: {
    sortFieldLabel: 'Sort',
    radioGroupLabel: 'Date or Alphabetical',
  },
  CATEGORY: {
    label: 'Category',
    placeHolder: 'All',
  },
  COMPANY_NAME: {
    label: 'Company name',
  },
  FOUNDER: {
    label: 'Founder',
  },
  FOUNDING_YEAR: {
    label: 'Founding year',
    placeHolder: 'Year',
  },
  FUNDING_STAGE: {
    label: 'Funding stage',
    placeHolder: 'All',
  },
};

export default companyFiltersSchema;
