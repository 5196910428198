const cardColors = [
  '#C04000',
  '#ec8f9c',
  '#76A5AF',
  '#4db848',
  '#C2B280',
  '#DAA520',
  '#D7837F',
  '#9984D4',
  '#9E9E9E',
  '#F0B79C',
  '#946eb7',
];

export default cardColors;
