import PropTypes from 'prop-types';
import {
  InvestorCardContainer,
  InvestorCategoryContainer,
  InvestorCategories,
  InvestorCategoryTitle,
  InvestorDetails,
  InvestorDetailsTitle,
  InvestorName,
  SingleCategory,
  WebsiteLink,
  InvestorInitials,
  InvestorGraduationYear,
} from 'pages/FundingPage/components/InvestorCard/InvestorCard.styled';
import getCategoryColor, { getInitials } from 'pages/HomePage/utils';
import {
  CATEGORIES,
  FUNDING_STAGE,
  GEOGRAPHIC_LOCATIONS,
} from 'pages/HomePage/constants';
import CHECK_SIZES from 'constants/colorOptionPairs/checkSizesColors';
import { Button } from 'components/common/Button/Button.styled';
import Spacer from 'components/common/Spacer/Spacer.styled';
import { NOT_AVAILABLE } from 'pages/AdminPage/TabControl/InvestorTab/AdminInvestorPopup/AdminInvestorPopup';

const InvestorCard = ({
  fullName,
  investorEmail,
  cardColor,
  categories,
  vcFirmName,
  classYear,
  fundingStages,
  geographicFocus,
  checkSizes,
  previewInvestorDetails,
  isUserLoggedIn,
}) => (
  <InvestorCardContainer
    bgColor={cardColor}
    key={`${vcFirmName}-${fullName}`}
    onClick={previewInvestorDetails}
  >
    <InvestorName>{fullName}</InvestorName>
    <InvestorDetails>
      <InvestorCategories>
        {categories.map((category) => (
          <SingleCategory
            bgColor={getCategoryColor(category, CATEGORIES)}
            key={`${category}`}
          >
            {category}
          </SingleCategory>
        ))}
      </InvestorCategories>
      <InvestorCategoryContainer>
        <InvestorDetailsTitle>{vcFirmName}</InvestorDetailsTitle>
      </InvestorCategoryContainer>
      <InvestorCategoryContainer>
        <InvestorCategoryTitle>MBA graduation year</InvestorCategoryTitle>
        <InvestorGraduationYear>
          {classYear ? `'${Number(classYear) % 100}` : NOT_AVAILABLE}
        </InvestorGraduationYear>
      </InvestorCategoryContainer>
      <InvestorCategoryContainer>
        <InvestorCategoryTitle>Check size</InvestorCategoryTitle>
        <InvestorCategories>
          {checkSizes.map((check) => (
            <SingleCategory
              bgColor={getCategoryColor(check, CHECK_SIZES)}
              key={`${check}`}
            >
              {check}
            </SingleCategory>
          ))}
        </InvestorCategories>
      </InvestorCategoryContainer>
      <InvestorCategoryContainer>
        <InvestorCategoryTitle>Funding Stage</InvestorCategoryTitle>
        <InvestorCategories>
          {fundingStages.map((stage) => (
            <SingleCategory
              bgColor={getCategoryColor(stage, FUNDING_STAGE)}
              key={`${stage}`}
            >
              {stage}
            </SingleCategory>
          ))}
        </InvestorCategories>
      </InvestorCategoryContainer>
      <InvestorCategoryContainer>
        <InvestorCategoryTitle>Geographic focus</InvestorCategoryTitle>
        <InvestorCategories>
          {geographicFocus.map((focus) => (
            <SingleCategory
              bgColor={getCategoryColor(focus, GEOGRAPHIC_LOCATIONS)}
              key={`${focus}`}
            >
              {focus}
            </SingleCategory>
          ))}
        </InvestorCategories>
      </InvestorCategoryContainer>
      <Spacer Height="10px" />
      {investorEmail && isUserLoggedIn && (
        <WebsiteLink
          href={`mailto:${investorEmail}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            Width="100%"
            Height="40px"
            Display="flex"
            JustifyContent="center"
            AlignItems="center"
            Border="none"
            BgColor={cardColor}
            Color="white"
            FontSize="12px"
            FontWeight="600"
            onClick={(e) => e.stopPropagation()}
          >
            <InvestorInitials Color={cardColor}>
              {getInitials(fullName || '')}
            </InvestorInitials>
            Contact investor
          </Button>
        </WebsiteLink>
      )}
    </InvestorDetails>
  </InvestorCardContainer>
);

InvestorCard.defaultProps = {
  classYear: null,
  isUserLoggedIn: false,
};

InvestorCard.propTypes = {
  fullName: PropTypes.string.isRequired,
  investorEmail: PropTypes.string.isRequired,
  cardColor: PropTypes.string.isRequired,
  vcFirmName: PropTypes.string.isRequired,
  classYear: PropTypes.number,
  checkSizes: PropTypes.arrayOf(PropTypes.string).isRequired,
  fundingStages: PropTypes.arrayOf(PropTypes.string).isRequired,
  geographicFocus: PropTypes.arrayOf(PropTypes.string).isRequired,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  previewInvestorDetails: PropTypes.func.isRequired,
  isUserLoggedIn: PropTypes.bool,
};

export default InvestorCard;
