import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import useRequest from 'hooks/useRequest';
import { getAllInvestorFormOptions } from 'services/requests';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import {
  Wrapper,
  FormContainer,
  Step,
  Title,
  TitleContainer,
  FieldContainer,
  ButtonsContainer,
  RowContainer,
  ColumnContainer,
} from 'components/Forms/Investor/StepTwo/StepTwo.styled';
import Spacer from 'components/common/Spacer/Spacer.styled';
import SingleSelect from 'components/common/Dropdown/SingleSelect';
import MultiSelect from 'components/common/Dropdown/MultiSelect';
import BackArrow from 'assets/icons/BackArrow';
import useFormInput from 'components/common/FormComponents/Input/FormInput/useFormInput';
import FormInput from 'components/common/FormComponents/Input/FormInput/FormInput';
import {
  InvestorInfoInputSchema,
  InvestorInfoMultiSelectSchema,
  InvestorInfoSingleSelectSchema,
} from 'components/Forms/Investor/StepTwo/schema';
import { InvestorFormStateProps } from 'components/Forms/Investor/propTypes';

import {
  STEP_TWO_KEYS,
  BUTTONS_CSS,
} from 'components/Forms/Investor/StepTwo/constants';
import {
  mapCheckSizes,
  mapFundingStages,
  generateYearOptions,
  mapCategories,
  mapGeographicFocus,
} from 'components/Forms/Investor/StepTwo/utils';

const StepTwo = ({
  backStep,
  nextStep,
  currentStep,
  closeForm,
  formState,
  setFormState,
}) => {
  const [inputsState, setInputsState] = useState(InvestorInfoInputSchema);
  const [singleSelectStates, setSingleSelectStates] = useState(
    InvestorInfoSingleSelectSchema,
  );
  const [multiSelectStates, setMultiSelectStates] = useState(
    InvestorInfoMultiSelectSchema,
  );

  const onChangeHandler =
    (stateHandler, key) =>
    ({ target: { value } }) => {
      setFormState((prevState) => ({ ...prevState, [key]: value }));
      stateHandler((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          isError: prevState[key].isError && !prevState[key].validator(value),
        },
      }));
    };
  const { onBlurHandler, triggerError } = useFormInput();

  const yearOptions = generateYearOptions(2);
  const [categories, setCategories] = useState([]);
  const [fundingStages, setFundingStages] = useState([]);
  const [geographicFocus, setGeographicFocus] = useState([]);
  const [checkSizes, setCheckSizes] = useState([]);

  const formEl = useRef();

  const { request } = useRequest();

  useEffect(() => {
    let ignore = false;

    const fetchOptions = async () => {
      if (!ignore) {
        const allData = await request(() => getAllInvestorFormOptions());

        if (allData) {
          setCategories(mapCategories(allData[0].data));
          setFundingStages(mapFundingStages(allData[1].data));
          setGeographicFocus(mapGeographicFocus(allData[2].data));
          setCheckSizes(mapCheckSizes(allData[3].data));
        }
      }
    };

    fetchOptions();

    return () => {
      ignore = true;
    };
  }, [request]);

  const yearSelectionHandler = (option) => {
    setSingleSelectStates((prevState) => ({
      ...prevState,
      [STEP_TWO_KEYS.MBA_GRAD_YEAR]: {
        ...prevState[STEP_TWO_KEYS.MBA_GRAD_YEAR],
        isError: false,
      },
    }));
    setFormState((prevState) => ({
      ...prevState,
      [STEP_TWO_KEYS.MBA_GRAD_YEAR]:
        option && yearOptions.find((object) => object.label === option.label),
    }));
  };

  const multiSelectionHandler = (key, collection) => (options) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]:
        options &&
        options.map((option) =>
          collection.find((object) => object.id === option.id),
        ),
    }));
    setMultiSelectStates((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        isError: false,
      },
    }));
  };

  const handleBack = (e) => {
    e.preventDefault();

    backStep();
  };

  const onClickSubmitButton = (e) => {
    e.preventDefault();

    const keysInput = Object.keys(inputsState);
    const keysSingleSelect = Object.keys(singleSelectStates);
    const keysMultiSelect = Object.keys(multiSelectStates);

    const inputsWithErrors = keysInput.filter(
      (key) => !inputsState[key].validator(formState[key]),
    );
    const singleSelectsWithErrors = keysSingleSelect.filter(
      (key) => !singleSelectStates[key].validator(formState[key]),
    );
    const multiSelectsWithErrors = keysMultiSelect.filter(
      (key) => !multiSelectStates[key].validator(formState[key]),
    );

    inputsWithErrors.forEach((key) => triggerError(setInputsState, key));
    singleSelectsWithErrors.forEach((key) =>
      triggerError(setSingleSelectStates, key),
    );
    multiSelectsWithErrors.forEach((key) =>
      triggerError(setMultiSelectStates, key),
    );

    if (
      inputsWithErrors.length ||
      singleSelectsWithErrors.length ||
      multiSelectsWithErrors.length
    )
      return;

    nextStep();
  };

  return (
    <Wrapper>
      <TitleContainer>
        <Step>
          <SecondaryButton
            Border="none"
            noDark
            onClick={handleBack}
            type="button"
          >
            <BackArrow />
          </SecondaryButton>
          {`Step ${currentStep}`}
        </Step>
        <Title>General Info</Title>
      </TitleContainer>

      <FormContainer onSubmit={onClickSubmitButton} ref={formEl}>
        <RowContainer>
          <FieldContainer>
            <FormInput
              {...inputsState[STEP_TWO_KEYS.EMAIL]}
              value={formState[STEP_TWO_KEYS.EMAIL]}
              name={STEP_TWO_KEYS.EMAIL}
              onChangeHandler={onChangeHandler(
                setInputsState,
                STEP_TWO_KEYS.EMAIL,
              )}
              onBlurHandler={onBlurHandler(setInputsState, STEP_TWO_KEYS.EMAIL)}
            />
          </FieldContainer>
          <FieldContainer>
            <MultiSelect
              key={STEP_TWO_KEYS.CATEGORIES}
              {...multiSelectStates[STEP_TWO_KEYS.CATEGORIES]}
              selectedValue={formState[STEP_TWO_KEYS.CATEGORIES]}
              options={categories}
              onChangeHandler={multiSelectionHandler(
                STEP_TWO_KEYS.CATEGORIES,
                categories,
              )}
              disabledDarkMode
            />
            <Spacer Height="20px" />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <FieldContainer>
            <MultiSelect
              {...multiSelectStates[STEP_TWO_KEYS.FUNDING_STAGE]}
              selectedValue={formState[STEP_TWO_KEYS.FUNDING_STAGE]}
              options={fundingStages}
              onChangeHandler={multiSelectionHandler(
                STEP_TWO_KEYS.FUNDING_STAGE,
                fundingStages,
              )}
              disabledDarkMode
            />
            <Spacer Height="20px" />
          </FieldContainer>
          <FieldContainer>
            <SingleSelect
              {...singleSelectStates[STEP_TWO_KEYS.MBA_GRAD_YEAR]}
              selectedValue={formState[STEP_TWO_KEYS.MBA_GRAD_YEAR]}
              options={yearOptions}
              onChangeHandler={yearSelectionHandler}
              disabledDarkMode
            />
            <Spacer Height="20px" />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <ColumnContainer>
            <FieldContainer>
              <MultiSelect
                key={STEP_TWO_KEYS.GEOGRAPHIC_FOCUS}
                {...multiSelectStates[STEP_TWO_KEYS.GEOGRAPHIC_FOCUS]}
                selectedValue={formState[STEP_TWO_KEYS.GEOGRAPHIC_FOCUS]}
                options={geographicFocus}
                onChangeHandler={multiSelectionHandler(
                  STEP_TWO_KEYS.GEOGRAPHIC_FOCUS,
                  geographicFocus,
                )}
                disabledDarkMode
              />
            </FieldContainer>
          </ColumnContainer>
          <ColumnContainer>
            <FieldContainer>
              <MultiSelect
                key={STEP_TWO_KEYS.CHECK_SIZE}
                {...multiSelectStates[STEP_TWO_KEYS.CHECK_SIZE]}
                selectedValue={formState[STEP_TWO_KEYS.CHECK_SIZE]}
                options={checkSizes}
                onChangeHandler={multiSelectionHandler(
                  STEP_TWO_KEYS.CHECK_SIZE,
                  checkSizes,
                )}
                disabledDarkMode
              />
              <Spacer Height="20px" />
            </FieldContainer>
          </ColumnContainer>
        </RowContainer>

        <ButtonsContainer>
          <SecondaryButton {...BUTTONS_CSS.secondary} onClick={closeForm}>
            Cancel
          </SecondaryButton>
          <Spacer Width="16px" />
          <PrimaryButton {...BUTTONS_CSS.primary}>Submit</PrimaryButton>
          <Spacer Height="20px" />
        </ButtonsContainer>
      </FormContainer>
    </Wrapper>
  );
};

StepTwo.propTypes = {
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  formState: InvestorFormStateProps().isRequired,
};

export default StepTwo;
