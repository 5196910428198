import { PrimaryButton } from 'components/common/Button/Button.styled';
import NoResults from 'components/common/NoResults/NoResults';
import { CONTACT_EMAIL } from 'constants/other';
import { NoResultsContainer } from './ResourcesNotFound.styled';

const ResourcesNotFound = () => {
  return (
    <NoResultsContainer>
      <NoResults>
        <a href={`mailto:${CONTACT_EMAIL}`}>
          <PrimaryButton Width="170px" Height="40px" Border="none">
            Request a resource
          </PrimaryButton>
        </a>
      </NoResults>
    </NoResultsContainer>
  );
};

export default ResourcesNotFound;
