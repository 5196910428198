import styled from 'styled-components';
import DEVICE from 'constants/deviceSizes';

const AdminPageWrapper = styled.div`
  padding: 20px 130px 0;
  overflow: auto;

  @media ${DEVICE.laptop} {
    padding: 20px 50px 0;
  }
  @media ${DEVICE.formResponsive} {
    padding: 20px 25px 0;
    min-height: 100vh;
    overflow-x: hidden;
  }
`;

export default AdminPageWrapper;
