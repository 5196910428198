import styled from 'styled-components';
import DEVICE from 'constants/deviceSizes';
import { RAISIN_BLACK } from 'constants/colors';
import 'react-datepicker/dist/react-datepicker.css';

export const Wrapper = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 0 72px;
  width: 780px;

  @media ${DEVICE.tablet} {
    justify-content: center;
    margin: 0 20px;
    width: 450px;
  }

  @media ${DEVICE.formResponsive} {
    width: 335px;
  }
`;

export const Title = styled.p`
  color: ${RAISIN_BLACK};
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  margin: 0;
  text-align: center;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  height: 142px;
  justify-content: flex-end;

  @media ${DEVICE.formResponsive} {
    justify-content: center;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;

  @media ${DEVICE.formResponsive} {
    flex-direction: column;
    gap: 0;
  }
`;

export const FieldContainer = styled.div`
  flex: 1;
  width: 100%;
`;
