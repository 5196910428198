export const STEP_TWO_KEYS = {
  EVENT_DESCRIPTION: 'eventDescription',
  EVENT_LOCATION: 'eventLocation',
  AUDIENCE: 'audience',
  ORGANIZERS: 'organizers',
  EVENT_LOGO: 'eventLogo',
};

export const BUTTONS_CSS = {
  primary: {
    Height: '40px',
    FontWeight: '600',
    FontSize: '14px',
    Width: '173px',
    Border: 'none',
    type: 'submit',
  },
  secondary: {
    Border: '1px solid #545556',
    Width: '83px',
    Height: '40px',
    FontWeight: '600',
    FontSize: '14px',
    type: 'button',
    noDark: true,
  },
};
