import { create } from 'zustand';

const useAdminExclusiveResourceStore = create((set) => ({
  exclusiveResources: [],
  totalNumOfExclusiveResources: 0,
  setExclusiveResources: (exclusiveResources, totalNumOfExclusiveResources) =>
    set({ exclusiveResources, totalNumOfExclusiveResources }),
  updateExclusiveResource: (exclusiveResource) =>
    set((state) => ({
      exclusiveResources: state.exclusiveResources.map((c) =>
        c.id === exclusiveResource.id ? { ...c, ...exclusiveResource } : c,
      ),
      totalNumOfExclusiveResources: state.totalNumOfExclusiveResources,
    })),
  deleteMultipleExclusiveResources: (ids) =>
    set((state) => ({
      exclusiveResources: state.exclusiveResources.map((c) =>
        ids.includes(c.id) ? { ...c, deletedAt: new Date().toISOString() } : c,
      ),
      totalNumOfExclusiveResources: state.totalNumOfExclusiveResources,
    })),
  restoreMultipleExclusiveResources: (ids) =>
    set((state) => ({
      exclusiveResources: state.exclusiveResources.map((c) =>
        ids.includes(c.id) ? { ...c, deletedAt: null } : c,
      ),
      totalNumOfExclusiveResources: state.totalNumOfExclusiveResources,
    })),
}));

export default useAdminExclusiveResourceStore;
