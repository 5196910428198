import PropTypes from 'prop-types';
import {
  Wrapper,
  Step,
  TitleContainer,
  Title,
  SelectContainer,
  ButtonsContainer,
} from 'components/Forms/Events/SelectEvent/SelectEvent.styled';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import Spacer from 'components/common/Spacer/Spacer.styled';
import SingleSelect from 'components/common/Dropdown/SingleSelect';
import { useEffect, useState } from 'react';
import { getAllEvents, getEventById } from 'services/requests';
import useRequest from 'hooks/useRequest';

const SelectEvent = ({
  nextStep,
  closeForm,
  setFormState,
  audience,
  organizers,
}) => {
  const [options, setOptions] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isError, setIsError] = useState(false);
  const { request } = useRequest();

  const getEvents = async () => {
    const responseData = await request(() => getAllEvents());

    if (responseData.data) {
      const option = responseData.data.map(({ id, eventName: label }) => ({
        id,
        label,
      }));

      setOptions(option);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  const onChangeHandler = (option) => {
    setSelectedEvent(option);
    setIsError(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (selectedEvent) nextStep();
    setIsError(true);
  };

  const repackData = (data) => {
    const dataRepacked = {
      ...data,
      organizers: [
        ...organizers.filter((item) => data.organizers.includes(item.label)),
      ],
      audience: [
        ...audience.filter((item) => data.audience.includes(item.label)),
      ],
      eventDate: new Date(data.eventDate),
    };
    return dataRepacked;
  };

  useEffect(() => {
    const getEvent = async () => {
      const responseData = await request(() => getEventById(selectedEvent?.id));

      if (responseData)
        setFormState((prevState) => ({
          ...prevState,
          ...repackData(responseData.data),
        }));
    };

    if (selectedEvent) getEvent();
  }, [selectedEvent]);

  return (
    <Wrapper>
      <TitleContainer>
        <Step>Step 1/4</Step>
        <Title>
          Which event do you
          <br /> want to update?
        </Title>
      </TitleContainer>
      <SelectContainer>
        <SingleSelect
          key={options.length}
          label="Event name*"
          isSearchable
          placeHolder="Select an event..."
          options={options}
          selectedValue={selectedEvent}
          onChangeHandler={onChangeHandler}
          MaxHeight="150px"
          disabledDarkMode
          isError={isError}
        />
      </SelectContainer>
      <ButtonsContainer>
        <SecondaryButton
          Border="1px solid #545556"
          Width="83px"
          Height="40px"
          FontWeight="600"
          FontSize="14px"
          type="button"
          noDark
          onClick={closeForm}
        >
          Cancel
        </SecondaryButton>
        <Spacer Width="16px" />
        <PrimaryButton
          Height="40px"
          FontWeight="600"
          FontSize="14px"
          Width="158px"
          Border="none"
          type="button"
          onClick={submitHandler}
        >
          Next: General Info
        </PrimaryButton>
      </ButtonsContainer>
    </Wrapper>
  );
};

SelectEvent.propTypes = {
  nextStep: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  audience: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      audienceName: PropTypes.string,
      length: PropTypes.number,
    }),
  ).isRequired,
  organizers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      organizerName: PropTypes.string,
      length: PropTypes.number,
    }),
  ).isRequired,
};

export default SelectEvent;
