import { isEmptyFieldValidator } from 'utils/inputValidators';
import STEP_ONE_KEYS from 'components/Forms/Investor/StepOne/constants';

const InvestorStepOneInputs = {
  [STEP_ONE_KEYS.INVESTOR_FULL_NAME]: {
    label: "Investor's full name*",
    inputType: 'text',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: "Enter investor's full name",
    validator: isEmptyFieldValidator,
  },
  [STEP_ONE_KEYS.INVESTOR_VC_FIRM]: {
    label: 'VC Firm*',
    inputType: 'text',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: "Enter investor's VC Firm",
    validator: isEmptyFieldValidator,
  },
};

export default InvestorStepOneInputs;
