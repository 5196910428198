import styled from 'styled-components';
import DEVICE from 'constants/deviceSizes';
import { CRIMSON, RAISIN_BLACK } from 'constants/colors';

export const Wrapper = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 0 72px;
  width: 780px;

  @media ${DEVICE.tablet} {
    margin: auto;
    margin: 0 20px;
    width: 450px;
  }

  @media ${DEVICE.formResponsive} {
    width: 335px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 70px;
  width: 100%;
`;

export const Step = styled.p`
  color: ${CRIMSON};
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
  text-align: left;
`;

export const Title = styled.p`
  color: ${RAISIN_BLACK};
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  text-align: left;
  margin: 0;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const RowContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;

  @media ${DEVICE.formResponsive} {
    flex-direction: column;
    gap: 10px;
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex: 1;

  @media ${DEVICE.formResponsive} {
    gap: 10px;
  }
`;

export const FieldContainer = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  height: 142px;
  justify-content: flex-end;

  @media ${DEVICE.formResponsive} {
    justify-content: center;
    margin-bottom: 10px;
  }
`;
