import styled from 'styled-components';
import { Button } from 'components/common/Button/Button.styled';
import { CRIMSON, WHITE } from 'constants/colors';

const AddButton = styled(Button)`
  align-items: center;
  background-color: ${WHITE};
  border: 1px solid var(--WHITE_OR_CRIMSON);
  color: ${CRIMSON};
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  justify-content: space-around;
  width: 169px;
`;

export default AddButton;
