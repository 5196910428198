export const COMPANY_STATE_ACTIONS = {
  CHANGE_FILED_VALUE: 'CHANGE_FILED_VALUE',
  SET_FOUNDERS: 'SET_FOUNDERS',
};

export const changeFieldValue = (key, value) => ({
  type: COMPANY_STATE_ACTIONS.CHANGE_FILED_VALUE,
  payload: { key, value },
});

export const setFounders = (founders) => ({
  type: COMPANY_STATE_ACTIONS.SET_FOUNDERS,
  payload: { founders },
});

export const companyStateReducer = (state, action) => {
  if (action.type === COMPANY_STATE_ACTIONS.CHANGE_FILED_VALUE)
    return {
      ...state,
      [action.payload.key]: action.payload.value,
    };

  if (action.type === COMPANY_STATE_ACTIONS.SET_FOUNDERS)
    return {
      ...state,
      founders: action.payload.founders,
    };

  return new Error(`no such action: ${action.type}`);
};
