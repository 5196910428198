import { SILVER_SAND } from 'constants/colors';
import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const DotsContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 300px;
  z-index: -1;

  @media ${DEVICE.tablet} {
    width: auto;
  }
`;

export const DotsRow = styled.div`
  display: flex;
`;

export const Dot = styled.span`
  width: 9px;
  height: 9px;
  background: ${SILVER_SAND};
  border-radius: 50%;
  margin: 7px 4px;
  opacity: 0.1;

  @media ${DEVICE.formResponsive} {
    margin: 3px 2px;
  }

  @media ${DEVICE.laptop} {
    margin: 4px 3px;
    width: 7px;
    height: 7px;
  }
`;
