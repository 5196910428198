import { shape, number, string, bool, arrayOf, instanceOf } from 'prop-types';

export const defaultPublicFormStateProps = () => ({
  contactName: '',
  contactEmail: '',
  dealName: '',
  dealDescription: '',
  dealUrl: '',
  dealImage: null,
  isExclusive: false,
  targetAudiences: [],
  categories: [],
  expiringDate: null,
});

export const publicFormStateProps = () =>
  shape({
    contactName: string,
    contactEmail: string,
    dealName: string,
    dealDescription: string,
    dealUrl: string,
    isExclusive: bool,
    targetAudiences: arrayOf(
      shape({
        name: string,
        id: number,
      }),
    ),
    categories: arrayOf(
      shape({
        name: string,
        id: number,
      }),
    ),
    expiringDate: instanceOf(Date),
  });

export const defaultExclusiveFormStateProps = () => ({
  contactName: '',
  contactEmail: '',
  companyName: '',
  companyDescription: '',
  companyLogo: null,
  dealDescription: '',
  companyUrl: '',
  promotion: '',
  isExclusive: true,
  categories: [],
  expiringDate: null,
});

export const exclusiveFormStateProps = () =>
  shape({
    contactName: string,
    contactEmail: string,
    companyName: string,
    companyDescription: string,
    dealDescription: string,
    companyUrl: string,
    promotion: string,
    isExclusive: bool,
    categories: arrayOf(
      shape({
        name: string,
        id: number,
      }),
    ),
    expiringDate: instanceOf(Date),
  });

export const dataProps = () =>
  shape({
    contactName: string,
    contactEmail: string,
    companyName: string,
    companyDescription: string,
    dealDescription: string,
    dealName: string,
    dealUrl: string,
    companyUrl: string,
    promotion: string,
    isExclusive: bool,
    expiringDate: string,
    categories: arrayOf(number),
    targetAudiences: arrayOf(number),
  });
