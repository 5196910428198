import { useReducer } from 'react';
import {
  changeFieldValue,
  exclusiveResourceStateReducer,
} from './exclusiveResourceStateReducer';
import useExclusiveResourceValidationErrors from './useExclusiveResourceValidationErrors';
import {
  exclusiveResourceDescriptionSchema,
  exclusiveResourceInputSchema,
  exclusiveResourceSelectSchema,
} from '../schemas';

const useExclusiveResourceState = (initialExclusiveResourceData) => {
  const [exclusiveResourceData, dispatch] = useReducer(
    exclusiveResourceStateReducer,
    {
      ...initialExclusiveResourceData,
    },
  );

  const { validationErrors, setValidationError } =
    useExclusiveResourceValidationErrors();

  const inputOnChangeHandler =
    (key) =>
    ({ target: { value } }) => {
      setValidationError(
        key,
        validationErrors[key] &&
          !exclusiveResourceInputSchema[key].validator(value),
      );
      dispatch(changeFieldValue(key, value));
    };

  const inputOnBlurHandler =
    (key) =>
    ({ target: { value } }) => {
      dispatch(changeFieldValue(key, value));

      setValidationError(
        key,
        !exclusiveResourceInputSchema[key].validator(value),
      );
    };

  const descriptionOnChangeHandler =
    (key) =>
    ({ target: { value } }) => {
      setValidationError(
        key,
        validationErrors[key] &&
          !exclusiveResourceDescriptionSchema[key].validator(value),
      );
      dispatch(changeFieldValue(key, value));
    };

  const descriptionOnBlurHandler =
    (key) =>
    ({ target: { value } }) => {
      dispatch(changeFieldValue(key, value));

      setValidationError(
        key,
        !exclusiveResourceDescriptionSchema[key].validator(value),
      );
    };

  const multiSelectionHandler = (key, collection) => (options) => {
    dispatch(
      changeFieldValue(
        key,
        options &&
          options.map((option) =>
            collection.find((object) => object.id === option.id),
          ),
      ),
    );

    setValidationError(
      key,
      !exclusiveResourceSelectSchema[key].validator(options),
    );
  };

  const selectDateHandler = (key) => (date) => {
    dispatch(changeFieldValue(key, date));
  };

  const setUploadedFile = (key) => (data) =>
    dispatch(changeFieldValue(key, data));

  return {
    exclusiveResourceData,
    validationErrors,
    inputOnChangeHandler,
    inputOnBlurHandler,
    descriptionOnBlurHandler,
    descriptionOnChangeHandler,
    multiSelectionHandler,
    selectDateHandler,
    setUploadedFile,
    setValidationError,
  };
};

export default useExclusiveResourceState;
