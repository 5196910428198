import PropTypes from 'prop-types';
import { DAVY_GRAY } from 'constants/colors';

const DropdownShow = ({ fill = DAVY_GRAY }) => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.41 0.580017L6 5.17002L10.59 0.580017L12 2.00002L6 8.00002L0 2.00002L1.41 0.580017Z"
      fill={fill}
    />
  </svg>
);

DropdownShow.defaultProps = {
  fill: DAVY_GRAY,
};

DropdownShow.propTypes = {
  fill: PropTypes.string,
};

export default DropdownShow;
