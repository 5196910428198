import { Outlet, useNavigate } from 'react-router-dom';

import WelcomeToHbs from 'pages/HomePage/components/WelcomeToHbs/WelcomeToHbs';
import BrandsShowcase from 'components/common/BrandsShowcase/BrandsShowcase';
import { useAppContext } from 'contexts/AppContext';
import FundingPig from 'assets/icons/FundingPig';
import {
  AddInvestorButton,
  AddInvestorDisabledNoticeText,
  ActionSectionContainer,
  ActionSectionWrapper,
  FundingPigWrapper,
  LandingSectionWrapper,
  FundingPageContainer,
  LandingSection,
  ReachFoundersLint,
} from 'pages/FundingPage/FundingPage.styled';
import InvestorsContainer from 'pages/FundingPage/components/InvestorsContainer/InvestorsContainer';

const FundingPage = () => {
  const { user } = useAppContext();
  const navigate = useNavigate();

  const isUserLoggedIn = user !== null;

  const addInvestorHandler = () => navigate('/funding/add');

  return (
    <FundingPageContainer>
      <LandingSection>
        <LandingSectionWrapper>
          <WelcomeToHbs />
          <ActionSectionContainer>
            <ActionSectionWrapper>
              <FundingPigWrapper>
                <FundingPig />
              </FundingPigWrapper>
              <BrandsShowcase />
              <AddInvestorButton
                disabled={!isUserLoggedIn}
                onClick={addInvestorHandler}
              >
                Add an Investor
              </AddInvestorButton>
              {!isUserLoggedIn && (
                <AddInvestorDisabledNoticeText>
                  You must be logged in to add an investor.
                  <br />
                  Contact our{' '}
                  <ReachFoundersLint to="/about#founders">
                    founders
                  </ReachFoundersLint>{' '}
                  if you wish to apply.
                </AddInvestorDisabledNoticeText>
              )}
            </ActionSectionWrapper>
          </ActionSectionContainer>
        </LandingSectionWrapper>
      </LandingSection>
      <InvestorsContainer />
      <Outlet />
    </FundingPageContainer>
  );
};

export default FundingPage;
