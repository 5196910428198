export const companyParamsActions = {
  SET_FILTER_OR_PAGINATION_PARAM: 'SET_FILTER_OR_PAGINATION_PARAM',
  SET_SORT_PARAM: 'SET_SORT_PARAM',
  SET_INITIAL_QUERY_PARAMS: 'SET_INITIAL_QUERY_PARAMS',
};

export const COMPANY_SORT_PARAMS = {
  SORT_BY: 'sortBy',
  ORDER_BY: 'orderBy',
};

export const COMPANY_PAGINATION_PARAMS = {
  LIMIT: 'limit',
  OFFSET: 'offset',
};

export const COMPANY_STATISTIC_PARAMS = {
  CREATED: 'created',
  UPDATED: 'updated',
  HIRING: 'hiring',
};

export const COMPANY_PARAMS = {
  CATEGORY: 'category',
  COMPANY_NAME: 'companyName',
  FOUNDER: 'founderName',
  FOUNDING_YEAR: 'foundingYear',
  FUNDING_STAGE: 'stage',
  WITH_DELETED: 'withDeleted',
  ...COMPANY_PAGINATION_PARAMS,
  ...COMPANY_SORT_PARAMS,
  ...COMPANY_STATISTIC_PARAMS,
};

export const SORT_KEYS = {
  NEWEST: 'Newest',
  OLDEST: 'Oldest',
  ALPHABETICAL_ASC: 'A-Z',
  ALPHABETICAL_DESC: 'Z-A',
};

export const SORT_PARAMS = {
  [SORT_KEYS.NEWEST]: {
    [COMPANY_PARAMS.SORT_BY]: 'foundingYear',
    [COMPANY_PARAMS.ORDER_BY]: 'DESC',
  },
  [SORT_KEYS.OLDEST]: {
    [COMPANY_PARAMS.SORT_BY]: 'foundingYear',
    [COMPANY_PARAMS.ORDER_BY]: 'ASC',
  },
  [SORT_KEYS.ALPHABETICAL_DESC]: {
    [COMPANY_PARAMS.SORT_BY]: 'companyName',
    [COMPANY_PARAMS.ORDER_BY]: 'DESC',
  },
  [SORT_KEYS.ALPHABETICAL_ASC]: {
    [COMPANY_PARAMS.SORT_BY]: 'companyName',
    [COMPANY_PARAMS.ORDER_BY]: 'ASC',
  },
};

export const LIMIT_OPTIONS = [10, 20, 50, 100];
