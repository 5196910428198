import { isEmptyFieldValidator } from 'utils/inputValidators';

const TRUE = true;
const FALSE = false;

const generalInfoInputSchema = {
  [TRUE]: {
    label: 'Company name*',
    inputType: 'text',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your company name',
    validator: isEmptyFieldValidator,
  },
  [FALSE]: {
    label: 'Resource name*',
    inputType: 'text',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your resource name',
    validator: isEmptyFieldValidator,
  },
};

export default generalInfoInputSchema;
