import PropTypes from 'prop-types';
import Modal from 'components/common/Modal/Modal';
import {
  ButtonsContainer,
  DescriptionParagraph,
  Title,
  Wrapper,
  BUTTONS_CSS,
} from 'pages/AdminPage/components/Forms/Company/BulkImportCompany/BulkImportCompanies.styled';
import { PrimaryButton } from 'components/common/Button/Button.styled';
import {
  PLEASE_TRY_AGAIN,
  STATUS,
} from 'pages/AdminPage/components/Forms/Company/BulkImportCompany/constants';

const BulkImportCompaniesResp = ({ status, message, closeForm, goBack }) => {
  return (
    <Modal closeForm={closeForm}>
      <Wrapper>
        <Title>
          {status === STATUS.VALIDATING_FILE_FAILURE &&
            'Data Validation Failed'}
          {status === STATUS.IMPORT_SUCCESS && 'Companies Imported'}
        </Title>
        <DescriptionParagraph>
          {status === STATUS.VALIDATING_FILE_FAILURE &&
            'The file you uploaded is not valid.\n Please check the following errors:'}
          {status === STATUS.IMPORT_SUCCESS &&
            'The companies were successfully imported.'}
        </DescriptionParagraph>
        <DescriptionParagraph>
          {status === STATUS.VALIDATING_FILE_FAILURE &&
            (message.errors ? (
              <ul>
                {message.errors.map((msg) => (
                  <li key={msg}>{msg}</li>
                ))}
              </ul>
            ) : (
              <span>{message || PLEASE_TRY_AGAIN}</span>
            ))}
          {status === STATUS.IMPORT_SUCCESS && message}
        </DescriptionParagraph>
        <ButtonsContainer>
          <PrimaryButton $ {...BUTTONS_CSS.primary} onClick={goBack}>
            Close
          </PrimaryButton>
        </ButtonsContainer>
      </Wrapper>
    </Modal>
  );
};

BulkImportCompaniesResp.propTypes = {
  status: PropTypes.oneOf(Object.values(STATUS)).isRequired,
  message: PropTypes.oneOfType([
    PropTypes.shape({
      errors: PropTypes.arrayOf(PropTypes.string),
    }),
    PropTypes.string,
  ]).isRequired,
  closeForm: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default BulkImportCompaniesResp;
