import PropTypes from 'prop-types';
import HidePasswordIcon from 'assets/icons/HidePasswordIcon';
import ShowPasswordIcon from 'assets/icons/ShowPasswordIcon';
import EyeContainer from 'components/common/PasswordEyeToggle/PasswordEyeToggle.styled';

const PasswordEyeToggle = ({
  inputType,
  isError,
  handleEyeToggle,
  enableDarkMode,
}) => {
  return (
    <EyeContainer onClick={handleEyeToggle} isError={isError}>
      {inputType === 'password' ? (
        <ShowPasswordIcon
          {...(enableDarkMode
            ? { fill: 'var(--BRIGHT_GRAY_OR_DAVY_GRAY)' }
            : {})}
        />
      ) : (
        <HidePasswordIcon
          {...(enableDarkMode
            ? { fill: 'var(--BRIGHT_GRAY_OR_DAVY_GRAY)' }
            : {})}
        />
      )}
    </EyeContainer>
  );
};

PasswordEyeToggle.defaultProps = {
  enableDarkMode: false,
};

PasswordEyeToggle.propTypes = {
  inputType: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  handleEyeToggle: PropTypes.func.isRequired,
  enableDarkMode: PropTypes.bool,
};

export default PasswordEyeToggle;
