import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from 'components/common/Modal/Modal';
import ConfirmPrompt from 'pages/AdminPage/components/Forms/PublicResource/DeletePublicResource/ConfirmPrompt/ConfirmPrompt';
import Response from 'pages/AdminPage/components/Forms/PublicResource/DeletePublicResource/Response/Response';
import DELETE_PUBLIC_RESOURCE_STEPS from 'pages/AdminPage/components/Forms/PublicResource/DeletePublicResource/constants';
import { deletePublicResources } from 'services/requests';

const DeletePublicResource = () => {
  const [activeForm, setActiveForm] = useState(
    DELETE_PUBLIC_RESOURCE_STEPS.PROMPT,
  );
  const {
    state: { ids },
  } = useLocation();

  const navigate = useNavigate();
  const closeForm = () =>
    navigate({ pathname: '/administration/public-resource' });

  return (
    <Modal closeForm={closeForm}>
      {activeForm === DELETE_PUBLIC_RESOURCE_STEPS.PROMPT && (
        <ConfirmPrompt
          numberOfPublicResources={ids.length}
          closeForm={closeForm}
          nextStep={() => setActiveForm(DELETE_PUBLIC_RESOURCE_STEPS.RESPONSE)}
        />
      )}
      {activeForm === DELETE_PUBLIC_RESOURCE_STEPS.RESPONSE && (
        <Response
          resourceIds={ids}
          req={deletePublicResources}
          closeForm={closeForm}
        />
      )}
    </Modal>
  );
};
export default DeletePublicResource;
