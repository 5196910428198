import styled from 'styled-components';
import DEVICE from 'constants/deviceSizes';
import { CRIMSON, DAVY_GRAY, RAISIN_BLACK } from 'constants/colors';

export const Wrapper = styled.div`
  align-content: stretch;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  min-height: 353px;
  max-width: 580px;
  margin: 0 72px;

  @media ${DEVICE.formResponsive} {
    margin: 0 20px;
    min-width: 335px;
  }
`;

export const Title = styled.h1`
  color: ${DAVY_GRAY};
  font-weight: 700;
  font-size: 22px;
  line-height: 71px;
  margin: 0;
  text-align: center;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
  text-align: center;

  a {
    color: ${CRIMSON};
    font-weight: 600;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
    &:hover::after {
      text-decoration: none;
    }
  }

  @media ${DEVICE.laptop} {
    margin: 0 20px;
    min-height: 300px;
  }
`;

export const DescriptionParagraph = styled.p`
  color: ${RAISIN_BLACK};
  font-size: 14px;
  line-height: 21px;
  margin: 0;
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

export const EmailLink = styled.a`
  color: ${CRIMSON};
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  &:hover::after {
    text-decoration: none;
  }
`;
