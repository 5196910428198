import { isEmptyFieldValidator } from 'utils/inputValidators';
import { FORGOT_PASSWORD_FIELD_NAME_KEYS } from './constants';

const FORGOT_PASSWORD_FORM_INITIAL_STATE = {
  [FORGOT_PASSWORD_FIELD_NAME_KEYS.EMAIL]: {
    label: 'Enter email*',
    inputType: 'email',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your email',
    validator: isEmptyFieldValidator,
  },
};

export default FORGOT_PASSWORD_FORM_INITIAL_STATE;
