import { useEffect, useState } from 'react';
import useRequest from 'hooks/useRequest';
import repackReceivedData from 'components/Forms/Company/SelectCompany/utils';
import { getCompanyById } from 'services/requests';

const useFetchCompanyById = (ids) => {
  const { request } = useRequest();

  const [isLoading, setIsLoading] = useState(true);
  const [initialCompanyData, setInitialCompanyData] = useState(null);

  useEffect(() => {
    const getCompany = async () => {
      setIsLoading(true);
      const responseData = await request(() => getCompanyById(ids));

      if (responseData) {
        setInitialCompanyData((prevState) => ({
          ...prevState,
          ...repackReceivedData(responseData.data),
        }));
      }

      setIsLoading(false);
    };

    getCompany();
  }, [ids]);

  return { isLoading, initialCompanyData };
};

export default useFetchCompanyById;
