export const resourcesTypeOptions = [
  {
    label: 'Funding Opportunities & Resource for Everyone',
    name: 'resourceTypes',
  },
  { label: 'Exclusive Deal for HBSBuilds Members', name: 'resourceTypes' },
];

export const GENERAL_INFO_TEXT = {
  DESCRIPTION_LABEL: 'Which type of resource are you adding?* ',
  DESCRIPTION:
    'Exclusive deals are reserved for HBS founders of the companies, while resources for all are available for all campus members.',
  GRATITUDE: 'Thanks for adding your resource!',
};
