const IconPlaceholder = () => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 0C11.2928 0 9.60235 0.336255 8.02512 0.989566C6.44788 1.64288 5.01477 2.60045 3.80761 3.80761C1.36964 6.24558 0 9.55219 0 13C0 16.4478 1.36964 19.7544 3.80761 22.1924C5.01477 23.3995 6.44788 24.3571 8.02512 25.0104C9.60235 25.6637 11.2928 26 13 26C16.4478 26 19.7544 24.6304 22.1924 22.1924C24.6304 19.7544 26 16.4478 26 13C26 11.2928 25.6637 9.60235 25.0104 8.02512C24.3571 6.44788 23.3995 5.01477 22.1924 3.80761C20.9852 2.60045 19.5521 1.64288 17.9749 0.989566C16.3977 0.336255 14.7072 0 13 0ZM13 8.307C15.041 9.62 17.446 10.4 20.046 10.4C21.06 10.4 22.035 10.283 22.971 10.062C23.244 10.985 23.4 11.973 23.4 13C23.4 18.733 18.733 23.4 13 23.4C9.1 23.4 5.707 21.242 3.9 18.057L6.175 15.6V14.3C6.175 13.869 6.3462 13.4557 6.65095 13.151C6.9557 12.8462 7.36902 12.675 7.8 12.675C8.23098 12.675 8.6443 12.8462 8.94905 13.151C9.25379 13.4557 9.425 13.869 9.425 14.3V15.6H13V8.307ZM18.2 12.675C17.769 12.675 17.3557 12.8462 17.051 13.151C16.7462 13.4557 16.575 13.869 16.575 14.3C16.575 14.731 16.7462 15.1443 17.051 15.449C17.3557 15.7538 17.769 15.925 18.2 15.925C18.631 15.925 19.0443 15.7538 19.349 15.449C19.6538 15.1443 19.825 14.731 19.825 14.3C19.825 13.869 19.6538 13.4557 19.349 13.151C19.0443 12.8462 18.631 12.675 18.2 12.675Z"
      fill="#A51C30"
    />
  </svg>
);

export default IconPlaceholder;
