import PropTypes from 'prop-types';
import { NoResultsWrapper, NoResultsMessage } from './NoResults.styled';
import NoResultsIllustration from './NoResultsIllustration';

const NoResults = ({ message, children }) => (
  <NoResultsWrapper>
    <NoResultsIllustration />
    <NoResultsMessage>{message}</NoResultsMessage>
    {children}
  </NoResultsWrapper>
);

NoResults.defaultProps = {
  children: null,
  message: (
    <>
      Sorry, we can’t seem to find what you are looking for. <br />
      Please try entering other criteria.
    </>
  ),
};

NoResults.propTypes = {
  children: PropTypes.node,
  message: PropTypes.node,
};

export default NoResults;
