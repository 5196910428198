import { DARK_CHARCOAL, WHITE } from 'constants/colors';
import styled from 'styled-components';

export const TooltipContainer = styled.div`
  position: relative;
  z-index: 200;
`;

export const TooltipParagraph = styled.p`
  text-align: center;
`;

export const TooltipBox = styled.div`
  background: ${DARK_CHARCOAL};
  border-radius: 5px;
  display: flex;
  height: ${(props) => props.Height};
  width: ${(props) => props.Width};
  margin-left: 50%;
  padding: 5px;
  min-width: 0;
  position: absolute;
  align-items: center;
  justify-content: center;

  p {
    font-weight: 400;
    color: ${WHITE};
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const TopTooltipBox = styled(TooltipBox)`
  bottom: calc(100% + 5px);
  left: ${(props) => props.LeftOffSet};
`;

export const RightTooltipBox = styled(TooltipBox)`
  left: calc(100% + 5px);
  top: ${(props) => props.TopOffSet};
`;

export const BottomTooltipBox = styled(TooltipBox)`
  top: calc(100% + 5px);
  left: ${(props) => props.LeftOffSet};
`;

export const LeftTooltipBox = styled(TooltipBox)`
  right: calc(100% + 5px);
  top: ${(props) => props.TopOffSet};
`;

export const TooltipArrow = styled.div`
  border-width: 5px;
  border-style: solid;
  position: absolute;
  z-index: 20;
  top: ${(props) => props.ArrowLeftTopSet};
  left: ${(props) => props.ArrowLeftOffSet};
`;

export const TopTooltipArrow = styled(TooltipArrow)`
  border-color: ${DARK_CHARCOAL} transparent transparent transparent;
  bottom: -10px;
`;

export const RightTooltipArrow = styled(TooltipArrow)`
  border-color: transparent ${DARK_CHARCOAL} transparent transparent;
  left: -10px;
`;

export const BottomTooltipArrow = styled(TooltipArrow)`
  border-color: transparent transparent ${DARK_CHARCOAL} transparent;
  top: -10px;
`;

export const LeftTooltipArrow = styled(TooltipArrow)`
  border-color: transparent transparent transparent ${DARK_CHARCOAL};
  right: -10px;
`;
