const useFormInput = () => {
  const onChangeHandler =
    (setFormState, key) =>
    ({ target: { value } }) =>
      setFormState((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          value,
          isError: prevState[key].isError && !prevState[key].validator(value),
        },
      }));

  const onBlurHandler =
    (setFormState, key) =>
    ({ target: { value } }) =>
      setFormState((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          isError: !prevState[key].validator(value),
        },
      }));

  const triggerError = (setFormState, key) =>
    setFormState((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        isError: true,
      },
    }));

  return { onChangeHandler, onBlurHandler, triggerError };
};

export default useFormInput;
