import PropTypes from 'prop-types';

const { shape, number, string, arrayOf, instanceOf } = PropTypes;

export const defaultEventFormStateProps = () => ({
  eventName: '',
  rsvpLink: '',
  audience: [],
  eventDate: null,
  eventDescription: '',
  organizers: [],
  eventLocation: '',
  eventLogo: null,
});

export const eventFormStateProps = () =>
  shape({
    eventName: string,
    rsvpLink: string,
    audience: arrayOf(
      shape({
        audienceName: string,
        id: number,
      }),
    ),
    eventDate: instanceOf(Date),
    eventDescription: string,
    organizers: arrayOf(
      shape({
        organizerName: string,
        id: number,
      }),
    ),
    eventLocation: string,
  });
