export const DESC_TEXT = {
  normal:
    "Symphony is HBSBuilds' trusted partner for all of its tech needs, helping to power the platform and scaling its feature set and design from the beginning. ",
  bold: 'Need help building your business? Earn 15% off the first three months of working with Symphony.',
};

export const CONTACT_SYMPHONY_BUTTON_CSS = {
  Width: '171px',
  Height: '40px',
  Border: 'none',
};
