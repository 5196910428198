// TODO: fetch data from BE API instead

import { CATEGORIES } from 'pages/AboutPage/FoundersSection/constants';

const STATIC_DATA = {
  [CATEGORIES.TEAM]: {
    subcategories: [
      {
        title: 'Co-Leads 2023-24',
        cards: [
          {
            name: 'Louden Richason',
            mba: 'MBA ’24',
            personalLinks: {
              classcardLink:
                'https://secure.hbs.edu/classcards/detail.do?prsnId=1387372',
              linkedIn: 'https://www.linkedin.com/in/loudenrichason/',
            },
          },
          {
            name: 'Malay Shah',
            mba: 'MBA ’24',
            personalLinks: {
              classcardLink:
                'https://secure.hbs.edu/classcards/detail.do?prsnId=1385869',
              linkedIn: 'https://www.linkedin.com/in/yalamshah/',
            },
          },
          {
            name: 'Nancy Yu',
            mba: 'MBA ’24',
            personalLinks: {
              classcardLink:
                'https://secure.hbs.edu/classcards/detail.do?prsnId=1411669',
              linkedIn: 'https://www.linkedin.com/in/nancynxyu/',
            },
          },
        ],
      },
      {
        title: 'Founders',
        cards: [
          {
            name: 'Will Sternlicht',
            mba: "MPP/MBA '23",
            jobs: [
              {
                position: 'Founding Team',
                companyName: 'Pulley',
                companyWebsite: 'https://www.withpulley.com/',
              },
            ],
            personalLinks: {
              classcardLink:
                'https://secure.hbs.edu/classcards/detail.do?prsnId=1249426',
              linkedIn: 'https://www.linkedin.com/in/william-sternlicht/',
            },
          },
          {
            name: 'Lindsay Dorf',
            mba: "MS/MBA '23",
            jobs: [
              {
                position: 'Founder',
                companyName: 'Stealh Co',
                companyWebsite: null,
              },
            ],
            personalLinks: {
              classcardLink:
                'https://secure.hbs.edu/classcards/detail.do?prsnId=1311403',
              linkedIn: 'https://www.linkedin.com/in/lindsay-dorf/',
            },
          },
          {
            name: 'Camille Ang',
            mba: "MPA-ID/MBA '22",
            jobs: [
              {
                position: 'Co-Founder',
                companyName: 'Hive Health',
                companyWebsite: 'https://hivehealth.com/us/',
              },
            ],
            personalLinks: {
              classcardLink:
                'https://www.alumni.hbs.edu/community/Pages/view-profile.aspx?q=camille+ang&alumId=1197187&sort=score+desc&dir=1',
              linkedIn: 'https://www.linkedin.com/in/camille-ang-19224647/',
            },
          },
        ],
      },
    ],
  },
  [CATEGORIES.OPERATING_ADVISORY_BOARD]: [
    {
      name: 'Jennifer Fleiss',
      mba: "MBA '09",
      jobs: [
        {
          position: 'Co-Founder',
          companyName: 'Rent the Runway',
          companyWebsite: 'https://www.renttherunway.com/',
        },
        {
          position: 'Venture Partner',
          companyName: 'Volition Capital',
          companyWebsite: 'https://www.volitioncapital.com/',
        },
      ],
      personalLinks: {
        classcardLink:
          'https://www.alumni.hbs.edu/community/Pages/view-profile.aspx?q=jennifer+fleiss&alumId=436621&sort=score+desc&dir=1',
        linkedIn: 'https://www.linkedin.com/in/jennifer-fleiss-18577314/',
      },
    },
    {
      name: 'Emmanuel Straschnov',
      mba: "MBA '12",
      jobs: [
        {
          position: 'Founder',
          companyName: 'Bubble',
          companyWebsite: 'https://bubble.io/',
        },
      ],
      personalLinks: {
        classcardLink:
          'https://www.alumni.hbs.edu/community/Pages/view-profile.aspx?q=emmanuel&alumId=560595&sort=score+desc&dir=1',
        linkedIn: 'https://www.linkedin.com/in/straschnov/',
      },
    },
    {
      name: 'Marc Bhargava',
      mba: "MBA '16",
      jobs: [
        {
          position: 'Co-Founder',
          companyName: 'Tagomi (acq by Coinbase)',
          companyWebsite: 'https://client.tagomi.com/',
        },
      ],
      personalLinks: {
        classcardLink:
          'https://www.alumni.hbs.edu/community/Pages/view-profile.aspx?q=tagomi&alumId=758875&sort=score+desc&dir=1',
        linkedIn: 'https://www.linkedin.com/in/marc-bhargava-55030311/',
      },
    },
    {
      name: 'Miguel Fernandez Larrea',
      mba: "ex MBA '21",
      jobs: [
        {
          position: 'Co-Founder',
          companyName: 'Capchase',
          companyWebsite: 'https://www.capchase.com/',
        },
      ],
      personalLinks: {
        classcardLink: null,
        linkedIn: 'https://www.linkedin.com/in/miguelfernandezlarrea/',
      },
    },
    {
      name: 'Kiki Freedman',
      mba: "MBA '20",
      jobs: [
        {
          position: 'Founder',
          companyName: 'Hey Jane',
          companyWebsite: 'https://www.heyjane.co/',
        },
      ],
      personalLinks: {
        classcardLink:
          'https://www.alumni.hbs.edu/community/Pages/view-profile.aspx?q=hey+jane&alumId=1079440&sort=score+desc&dir=1',
        linkedIn: 'https://www.linkedin.com/in/kgfreedman/',
      },
    },
  ],
  [CATEGORIES.VC_ADVISORY_BOARD]: [
    {
      name: 'Aileen Lee',
      mba: "MBA '97",
      jobs: [
        {
          position: 'Co-Founder & GP',
          companyName: 'Cowboy Ventures',
          companyWebsite: 'https://www.cowboy.vc/',
        },
      ],
      personalLinks: {
        classcardLink:
          'https://www.alumni.hbs.edu/community/Pages/view-profile.aspx?q=Aileen+Lee&alumId=122084&sort=score+desc&dir=1',
        linkedIn: 'https://www.linkedin.com/in/aileenwlee',
      },
    },
    {
      name: 'James Currier',
      mba: "MBA '99",
      jobs: [
        {
          position: 'Co-Founder & GP',
          companyName: 'NFX',
          companyWebsite: 'https://www.nfx.com/',
        },
      ],
      personalLinks: {
        classcardLink:
          'https://www.alumni.hbs.edu/community/Pages/view-profile.aspx?q=James+Currier&alumId=3701&sort=score+desc&dir=1',
        linkedIn: 'https://www.linkedin.com/in/jamescurrier/',
      },
    },
    {
      name: 'Joshua Kushner',
      mba: "MBA '11",
      jobs: [
        {
          position: 'Co-Founder & GP',
          companyName: 'Thrive Capital',
          companyWebsite: 'https://thrivecap.com/',
        },
        {
          position: 'Co-Founder',
          companyName: 'Oscar Health',
          companyWebsite: 'https://www.hioscar.com/',
        },
      ],
      personalLinks: {
        classcardLink:
          'https://www.alumni.hbs.edu/community/Pages/view-profile.aspx?q=kushner&alumId=434943&sort=score+desc&dir=1',
        linkedIn: 'https://www.linkedin.com/in/joshua-kushner-711b45230/',
      },
    },
    {
      name: 'Madeline Darcy',
      mba: "MBA '19",
      jobs: [
        {
          position: 'Co-Founder & GP',
          companyName: 'Kaya Ventures',
          companyWebsite: 'https://www.kaya.ventures/',
        },
      ],
      personalLinks: {
        classcardLink:
          'https://www.alumni.hbs.edu/community/Pages/view-profile.aspx?q=madeline&alumId=982164&sort=score+desc&dir=1',
        linkedIn: 'https://www.linkedin.com/in/madelinedarcy/',
      },
    },
  ],
  [CATEGORIES.CLASS_REPS]: [
    {
      name: 'Sandeep Pannu',
      mba: "MBA '97",
      jobs: [
        {
          position: 'Co-Founder',
          companyName: 'OneLibri',
          companyWebsite: 'https://onelibri.com/',
        },
      ],
      personalLinks: {
        classcardLink:
          'https://www.alumni.hbs.edu/community/Pages/view-profile.aspx?q=sandeep+pannu&alumId=93541&sort=score+desc&dir=1',
        linkedIn: 'https://www.linkedin.com/in/sandeeppannu/',
      },
    },
    {
      name: 'Nina Mullen',
      mba: "MBA '07",
      jobs: [
        {
          position: 'Co-Founder',
          companyName: 'Hilma',
          companyWebsite: 'https://www.hilma.co/',
        },
      ],
      personalLinks: {
        classcardLink:
          'https://www.alumni.hbs.edu/community/Pages/view-profile.aspx?q=nina+mullen&alumId=780176&sort=score+desc&dir=1',
        linkedIn: 'https://www.linkedin.com/in/nina-mullen-99a83115/',
      },
    },
  ],
};

export default STATIC_DATA;
