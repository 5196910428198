import PropTypes from 'prop-types';
import { InvestorListContainer } from 'pages/FundingPage/components/InvestorsContainer/InvestorsContainer.styled';
import InvestorCard from 'pages/FundingPage/components/InvestorCard/InvestorCard';
import Spacer from 'components/common/Spacer/Spacer.styled';

const InvestorList = ({ data, setPreviewInvestor, isUserLoggedIn }) => (
  <>
    <Spacer Height="20px" />
    <InvestorListContainer>
      {data.map((investor) => (
        <InvestorCard
          {...investor}
          key={investor.id}
          previewInvestorDetails={() => setPreviewInvestor(investor)}
          isUserLoggedIn={isUserLoggedIn}
        />
      ))}
    </InvestorListContainer>
  </>
);

InvestorList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  setPreviewInvestor: PropTypes.func.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
};

export default InvestorList;
