import { useReducer } from 'react';
import {
  companyParamsActions,
  SORT_PARAMS,
  SORT_KEYS,
} from 'pages/AdminPage/TabControl/CompanyTab/CompanyFilters/constants';
import {
  changeFilterOrPaginationParam,
  changeSortType,
  setInitialQueryParams,
} from './actions';
import { COMPANY_PARAMS } from '../constants';

const initialQueryParams = {
  ...SORT_PARAMS[SORT_KEYS.NEWEST],
  [COMPANY_PARAMS.CATEGORY]: null,
  [COMPANY_PARAMS.COMPANY_NAME]: '',
  [COMPANY_PARAMS.FOUNDER]: '',
  [COMPANY_PARAMS.FOUNDING_YEAR]: null,
  [COMPANY_PARAMS.FUNDING_STAGE]: null,
  [COMPANY_PARAMS.LIMIT]: 10,
  [COMPANY_PARAMS.OFFSET]: 0,
  [COMPANY_PARAMS.CREATED]: null,
  [COMPANY_PARAMS.UPDATED]: null,
  [COMPANY_PARAMS.HIRING]: null,
  [COMPANY_PARAMS.WITH_DELETED]: true,
};

const companyQueryParamsReducer = (state, action) => {
  switch (action.type) {
    case companyParamsActions.SET_SORT_PARAM:
      return {
        ...state,
        ...action.payload.sortType,
      };

    case companyParamsActions.SET_FILTER_OR_PAGINATION_PARAM:
      return {
        ...state,
        [action.payload.field]: action.payload.data,
        [COMPANY_PARAMS.OFFSET]:
          action.payload.field === COMPANY_PARAMS.OFFSET
            ? action.payload.data
            : 0,
      };

    case companyParamsActions.SET_INITIAL_QUERY_PARAMS:
      return { ...initialQueryParams };

    default:
      throw new Error(`no such action: ${action.type}`);
  }
};

const useCompanyParams = () => {
  const [companyQueryParams, dispatch] = useReducer(companyQueryParamsReducer, {
    ...initialQueryParams,
  });

  const setPaginationLimit = (limit) =>
    dispatch(changeFilterOrPaginationParam(COMPANY_PARAMS.LIMIT, limit));

  const setPaginationOffset = (offset) =>
    dispatch(changeFilterOrPaginationParam(COMPANY_PARAMS.OFFSET, offset));

  const setCompanyName = ({ target: { value } }) =>
    dispatch(changeFilterOrPaginationParam(COMPANY_PARAMS.COMPANY_NAME, value));

  const setFounder = ({ target: { value } }) =>
    dispatch(changeFilterOrPaginationParam(COMPANY_PARAMS.FOUNDER, value));

  const setFoundingYear = (option) =>
    dispatch(
      changeFilterOrPaginationParam(
        COMPANY_PARAMS.FOUNDING_YEAR,
        option ? option.id : null,
      ),
    );

  const setFundingStage = (option) =>
    dispatch(
      changeFilterOrPaginationParam(
        COMPANY_PARAMS.FUNDING_STAGE,
        option ? option.id : null,
      ),
    );

  const setCategory = (option) =>
    dispatch(
      changeFilterOrPaginationParam(
        COMPANY_PARAMS.CATEGORY,
        option ? option.id : null,
      ),
    );

  const setStatisticParam = (key, value) =>
    dispatch(changeFilterOrPaginationParam(key, value));

  const setSortType = (type) => dispatch(changeSortType(type));

  const resetFilters = () => dispatch(setInitialQueryParams());

  return {
    companyQueryParams,
    setCategory,
    setCompanyName,
    setFounder,
    setFoundingYear,
    setFundingStage,
    setSortType,
    setPaginationLimit,
    setPaginationOffset,
    setStatisticParam,
    resetFilters,
  };
};

export default useCompanyParams;
