import CHECK_SIZES from 'constants/colorOptionPairs/checkSizesColors';
import { CATEGORIES } from 'pages/HomePage/constants';

const getOptionColor = (categoryName, list) => {
  const listOfKeys = Object.keys(list);
  const matchColor = listOfKeys.find((color) =>
    list[color].includes(categoryName),
  );

  return matchColor || null;
};

export const mapCategories = (iterableObject) =>
  iterableObject.map((value) => ({
    id: value.id,
    label: value.investorCategoryName,
    bgColor: getOptionColor(value.investorCategoryName, CATEGORIES),
  }));

export const mapCheckSizes = (iterableObject) =>
  iterableObject.map((value) => ({
    id: value.id,
    label: value.amount,
    bgColor: getOptionColor(value.amount, CHECK_SIZES),
  }));
