import { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/common/Modal/Modal';
import useFormInput from 'components/common/FormComponents/Input/FormInput/useFormInput';
import FormInput from 'components/common/FormComponents/Input/FormInput/FormInput';
import { PrimaryButton } from 'components/common/Button/Button.styled';
import Spacer from 'components/common/Spacer/Spacer.styled';
import ForgotPasswordIcon from 'assets/icons/ForgotPasswordIcon';
import EmailSentIcon from 'assets/icons/EmailSentIcon';
import { forgotPassword } from 'services/requests';
import {
  FormContainer,
  IconWrapper,
  SubTitle,
  Title,
  Wrapper,
} from './ForgotPassword.styled';
import FORGOT_PASSWORD_FORM_INITIAL_STATE from './schemas';
import { FORGOT_PASSWORD_FIELD_NAME_KEYS } from './constants';
import { SUBMIT_BUTTON_CSS } from '../SignForm/constants';
import Alert from '../../common/Alert/Alert';

const keys = Object.values(FORGOT_PASSWORD_FIELD_NAME_KEYS);

const ForgotPassword = ({ setActiveForm }) => {
  const closeForm = () => setActiveForm(null);
  const { onChangeHandler, onBlurHandler } = useFormInput();

  const [formState, setFormState] = useState(
    FORGOT_PASSWORD_FORM_INITIAL_STATE,
  );
  const [isSuccess, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    setSuccess(false);
    setError(false);

    try {
      const payload = { email: formState.email.value };
      const { status } = await forgotPassword(payload);

      if (status === 200) setSuccess(true);
    } catch (err) {
      setError(err.response.data?.message);
    }
  };

  return (
    <Modal closeForm={closeForm}>
      <Wrapper>
        {!isSuccess && (
          <>
            <IconWrapper>
              <ForgotPasswordIcon />
            </IconWrapper>
            <Title>Forgot your password?</Title>
            <SubTitle>
              Please enter your email below and we will send you reset password
              instructions.
            </SubTitle>
            <FormContainer onSubmit={submitHandler}>
              {keys.map((key) => (
                <FormInput
                  {...formState[key]}
                  key={key}
                  name={key}
                  onChangeHandler={onChangeHandler(setFormState, key)}
                  onBlurHandler={onBlurHandler(setFormState, key)}
                />
              ))}
              <PrimaryButton
                {...SUBMIT_BUTTON_CSS}
                disabled={!formState.email.value}
              >
                Submit
              </PrimaryButton>
              {error && (
                <>
                  <Spacer Height="25px" />
                  <Alert alertText={error} />
                </>
              )}
            </FormContainer>
          </>
        )}
        {isSuccess && (
          <>
            <IconWrapper>
              <EmailSentIcon />
            </IconWrapper>
            <Title>Please check your email</Title>
            <SubTitle>
              <strong>
                Please make sure to double check your spam folder if needed
              </strong>
              <br />
              We have sent you reset password instructions.
            </SubTitle>
          </>
        )}
      </Wrapper>
    </Modal>
  );
};

ForgotPassword.propTypes = {
  setActiveForm: PropTypes.func.isRequired,
};

export default ForgotPassword;
