import styled from 'styled-components';
import DEVICE from 'constants/deviceSizes';

export const BottomContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StayInTouch = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StayInTouchParagraph = styled.p`
  height: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  margin-top: 24px;

  @media ${DEVICE.laptop} {
    width: 100%;
    height: 18px;
    font-size: 13px;
    line-height: 18px;
    margin-top: 16px;
  }
`;

export const BottomParagraph = styled.p`
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  height: 21px;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  margin-bottom: 24px;

  @media ${DEVICE.laptop} {
    height: 17px;
    font-size: 11px;
    line-height: 16px;
  }
`;
