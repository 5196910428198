import { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import useInvestorStore from 'store/investor/useInvestorStore';
import useRequest from 'hooks/useRequest';
import { getAllInvestorsAdmin, patchInvestorStatus } from 'services/requests';
import {
  LoadingSpinner,
  LoadingSpinnerContainer,
} from 'components/common/LoadingSpinner/LoadingSpinner.styled';
import Modal from 'components/common/Modal/Modal';
import NoResults from 'components/common/NoResults/NoResults';
import { REQUEST_DEBOUNCE_DURATION } from 'constants/input_debounce_duration';
import { InvestorTabContainer } from 'pages/AdminPage/TabControl/InvestorTab/InvestorTab.styled';
import useInvestorPreview from 'pages/AdminPage/TabControl/InvestorTab/useInvestorPreview';
import Pagination from 'pages/AdminPage/components/AdminTable/AdditionalElements/Pagination/Pagination';
import AdminTable from 'pages/AdminPage/components/AdminTable/AdminTable';
import INVESTOR_COLUMNS from 'pages/AdminPage/TabControl/InvestorTab/InvestorTable/investorTableSchema';
import useInvestorParams from 'pages/AdminPage/TabControl/InvestorTab/InvestorFilters/useInvestorParams/useInvestorParams';
import InvestorFilters from 'pages/AdminPage/TabControl/InvestorTab/InvestorFilters/InvestorFilters';
import { LIMIT_OPTIONS } from 'pages/AdminPage/TabControl/InvestorTab/InvestorFilters/constants';
import AdminInvestorPopup from 'pages/AdminPage/TabControl/InvestorTab/AdminInvestorPopup/AdminInvestorPopup';

const InvestorTab = () => {
  const {
    investorQueryParams,
    setCategory,
    setStatus,
    setCheckSize,
    setStage,
    setSortType,
    setPaginationLimit,
    setPaginationOffset,
    resetFilters,
  } = useInvestorParams();

  const { previewInvestor, closePopup, setPreviewInvestor } =
    useInvestorPreview();

  const { request } = useRequest();
  const investors = useInvestorStore((state) => state.investors);
  const totalNumOfCompanies = useInvestorStore(
    (state) => state.totalNumOfInvestors,
  );
  const setInvestors = useInvestorStore((state) => state.setInvestors);
  const updateInvestorStatus = useInvestorStore(
    (state) => state.updateInvestorStatus,
  );

  const [isUpdatingInvestor, setIsUpdatingInvestor] = useState(false);
  const updateInvestor = (investorId) => async (newStatus) => {
    setIsUpdatingInvestor(true);

    try {
      const response = await patchInvestorStatus(investorId, newStatus);

      if (response.status !== 200) throw new Error('Failed to update investor');

      updateInvestorStatus(investorId, newStatus);
      setPreviewInvestor((prev) => ({ ...prev, status: newStatus }));

      setIsUpdatingInvestor(false);
    } catch (error) {
      setIsUpdatingInvestor(false);
    }
  };

  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    let ignore = false;

    const fetchCompanies = async () => {
      const response = await request(() =>
        getAllInvestorsAdmin(investorQueryParams),
      );

      if (!ignore && response) {
        setInvestors(response.data.data, response.data.total);

        setFetching(false);
      }
    };

    if (!ignore) setFetching(true);

    const fetchPublicResourcesDebounced = setTimeout(
      fetchCompanies,
      REQUEST_DEBOUNCE_DURATION,
    );

    return () => {
      ignore = true;

      clearTimeout(fetchPublicResourcesDebounced);
    };
  }, [request, investorQueryParams]);

  const handlePageClick = (value) => {
    setPaginationOffset(value);
    document.getElementById('scrollInto').scrollIntoView();
  };

  const handleItemsPerPageChange = ({ target: { value } }) => {
    setPaginationLimit(+value);
    document.getElementById('scrollInto').scrollIntoView();
  };

  const displayInvestors = useCallback(() => {
    if (fetching)
      return (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      );

    if (!investors.length) return <NoResults />;

    return (
      <>
        <AdminTable
          columns={INVESTOR_COLUMNS}
          data={investors}
          ControlSelected={() => null}
          handleRowClick={(investor) => setPreviewInvestor(investor)}
        />
        <Pagination
          totalRows={totalNumOfCompanies}
          offset={investorQueryParams.offset}
          limit={investorQueryParams.limit}
          limitOptions={LIMIT_OPTIONS}
          handlePageClick={handlePageClick}
          handleItemsPerPageChange={handleItemsPerPageChange}
        />
      </>
    );
  }, [investors, fetching]);

  return (
    <InvestorTabContainer>
      <Outlet />
      {!!previewInvestor && (
        <Modal closeOnEscape closeForm={closePopup}>
          <AdminInvestorPopup
            {...previewInvestor}
            closeForm={closePopup}
            updateInvestor={updateInvestor(previewInvestor.id)}
            isUpdatingInvestor={isUpdatingInvestor}
          />
        </Modal>
      )}
      <InvestorFilters
        investorQueryParams={investorQueryParams}
        setCategory={setCategory}
        setStatus={setStatus}
        setCheckSize={setCheckSize}
        setStage={setStage}
        setSortType={setSortType}
        resetFilters={resetFilters}
      />
      {displayInvestors()}
    </InvestorTabContainer>
  );
};

export default InvestorTab;
