import { createContext, useMemo, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const ErrorStateContext = createContext();

export const useErrorState = () => useContext(ErrorStateContext);

const ErrorStateProvider = ({ children }) => {
  const [error, setError] = useState(null);

  const contextValue = useMemo(() => ({ error, setError }), [error]);

  // TODO: We are currently using only window.alert,
  // this context will be used in case we want to make our custom popup

  return (
    <ErrorStateContext.Provider value={contextValue}>
      {children}
    </ErrorStateContext.Provider>
  );
};

ErrorStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorStateProvider;
