import PropTypes from 'prop-types';
import {
  GroupLabel,
  WrapperGroup,
} from 'components/common/RadioButtonGroup/Radio.styled';
import { DAVY_GRAY } from 'constants/colors';
import RadioButton from './RadioButton';
import { FONT_SIZES, TEXT_SIZES } from './constants';

const RadioButtonGroup = ({
  groupLabel,
  flexDirection,
  selectedLabel,
  options,
  onChange,
  textSize,
  radioButtonFillColor,
  radioButtonLabelColor,
  radioButtonOutlineColor,
}) => (
  <>
    {groupLabel && (
      <GroupLabel
        fontSize={FONT_SIZES[textSize].groupLabel}
        labelColor={radioButtonLabelColor}
      >
        {groupLabel}
      </GroupLabel>
    )}
    <WrapperGroup flexDirection={flexDirection}>
      {options.map(({ label, name }) => (
        <RadioButton
          fontSize={FONT_SIZES[textSize].singleLabel}
          value={label}
          label={label}
          key={`${label}: ${name}`}
          fillColor={radioButtonFillColor}
          labelColor={radioButtonLabelColor}
          outlineColor={radioButtonOutlineColor}
          id={label}
          name={name}
          defaultChecked={selectedLabel === label}
          onChange={onChange}
        />
      ))}
    </WrapperGroup>
  </>
);

RadioButtonGroup.defaultProps = {
  selectedLabel: '',
  groupLabel: null,
  flexDirection: 'row',
  textSize: TEXT_SIZES.MEDIUM,
  radioButtonFillColor: DAVY_GRAY,
  radioButtonOutlineColor: DAVY_GRAY,
  radioButtonLabelColor: DAVY_GRAY,
};

RadioButtonGroup.propTypes = {
  groupLabel: PropTypes.string,
  selectedLabel: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  flexDirection: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  textSize: PropTypes.string,
  radioButtonFillColor: PropTypes.string,
  radioButtonLabelColor: PropTypes.string,
  radioButtonOutlineColor: PropTypes.string,
};

export default RadioButtonGroup;
