export const investorParamsActions = {
  SET_FILTER_OR_PAGINATION_PARAM: 'SET_FILTER_OR_PAGINATION_PARAM',
  SET_INITIAL_QUERY_PARAMS: 'SET_INITIAL_QUERY_PARAMS',
};

export const INVESTOR_PAGINATION_PARAMS = {
  LIMIT: 'limit',
  OFFSET: 'offset',
};

export const INVESTOR_PARAMS = {
  FULL_NAME: 'fullName',
  COMPANY_NAME: 'companyName',
  CATEGORY: 'category',
  CHECK_SIZE: 'checkSize',
  GEO_FOCUS: 'geoFocus',
  ...INVESTOR_PAGINATION_PARAMS,
};

export const LIMIT_OPTIONS = [10, 20, 50, 100];
