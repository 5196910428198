import {
  RESOURCES_PAGINATION_PARAMS,
  RESOURCES_SORT_PARAMS,
} from 'pages/ResourcePage/constants';

// eslint-disable-next-line import/prefer-default-export
export const PUBLIC_RESOURCES_PARAMS = {
  RESOURCE_NAME: 'name',
  CATEGORY: 'category',
  TARGET_AUDIENCE: 'target',
  ...RESOURCES_PAGINATION_PARAMS,
  ...RESOURCES_SORT_PARAMS,
};
