import PropTypes from 'prop-types';
import {
  CheckboxWrapper,
  CheckboxLabel,
  CheckboxInput,
} from 'components/common/CheckBox/CheckBox.styled';

const SingleCheckbox = ({
  label,
  checked,
  disabledDarkMode,
  onChangeHandler,
}) => (
  <CheckboxWrapper>
    <CheckboxInput checked={checked} onChange={() => onChangeHandler()} />
    <CheckboxLabel disabledDarkMode={disabledDarkMode}>{label}</CheckboxLabel>
  </CheckboxWrapper>
);

SingleCheckbox.defaultProps = {
  disabledDarkMode: false,
};

SingleCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabledDarkMode: PropTypes.bool,
  onChangeHandler: PropTypes.func.isRequired,
};

export default SingleCheckbox;
