import cardColors from 'constants/cardColors';

const getRandomCardColor = () =>
  cardColors[Math.floor(Math.random() * cardColors.length)];
const addCardColor = (investor) => {
  const color = getRandomCardColor();
  return { ...investor, cardColor: color };
};
const augmentInvestorsWithCardColor = (investors) =>
  investors.map(addCardColor);

export default augmentInvestorsWithCardColor;
