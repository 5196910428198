import ForgotPasswordIcon from 'assets/icons/ForgotPasswordIcon';
import PasswordChangedIcon from 'assets/icons/PasswordChangedIcon';

const KEYS = {
  TRUE: true,
  FALSE: false,
};

const RESET_PASSWORD_STATIC_DATA = {
  [KEYS.TRUE]: {
    title: 'Password successfully changed',
    sub_title: 'You can now proceed to Sign in',
    icon: <PasswordChangedIcon />,
  },
  [KEYS.FALSE]: {
    title: 'Forgot your password?',
    sub_title:
      'Your new password must be different from the previously used passwords',
    icon: <ForgotPasswordIcon />,
  },
};

export default RESET_PASSWORD_STATIC_DATA;
