import styled from 'styled-components';
import Slider from 'react-slick';
import DEVICE from 'constants/deviceSizes';
import { CRIMSON } from 'constants/colors';

const Carousel = styled(Slider)`
  .slick-slider,
  .slick-initialized {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
  }

  margin-top: 24px;

  .slick-slide {
    display: flex;
    justify-content: center;
  }

  .slick-dots {
    @media ${DEVICE.tablet} {
      bottom: -60px;
    }
  }

  .slick-track {
    margin: 0;
  }

  .slick-arrow {
    background-color: var(--RAISING_BLACK_OR_WHITE);
  }

  .slick-prev {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 1px solid var(--WHITE_OR_CRIMSON);
    left: -3%;
    z-index: 2;

    @media ${DEVICE.laptop} {
      left: -1%;
    }

    @media ${DEVICE.tablet} {
      left: -4%;
    }

    &::before {
      content: '';
      height: 10px;
      width: 10px;
      border-top: 2px solid ${CRIMSON};
      border-right: 2px solid ${CRIMSON};
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-25%, -50%) rotate(-135deg);
    }
  }

  .slick-next {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 1px solid var(--WHITE_OR_CRIMSON);
    right: -3%;
    z-index: 2;

    @media ${DEVICE.laptop} {
      right: -1%;
    }

    @media ${DEVICE.tablet} {
      right: -4%;
    }

    &::before {
      content: '';
      height: 10px;
      width: 10px;
      border-top: 2px solid ${CRIMSON};
      border-right: 2px solid ${CRIMSON};
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-75%, -50%) rotate(45deg);
    }
  }

  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    border: none;
    cursor: default;
    display: none;

    &::before {
      display: none;
    }
  }

  .cbtukD span:hover {
    background-color: var(--WHITE_OR_RAISING_BLACK);
    @media ${DEVICE.tablet} {
      left: 10px;
    }
  }

  .gqFupW span:hover {
    background-color: var(--WHITE_OR_RAISING_BLACK);
    @media ${DEVICE.tablet} {
      right: 10px;
    }
  }
`;

export default Carousel;
