import styled from 'styled-components';

export const InvestorTabContainer = styled.div`
  width: 100%;
  margin-bottom: 60px;
`;

export const StatusIndicator = styled.div`
  width: 86px;
  height: 24px;
  padding: 4px 8px;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.Color};
  color: white;
`;
