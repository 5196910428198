import Check from 'assets/icons/Check';
import {
  SubscribeWrapper,
  SubscribeInput,
  SubscribeButton,
  LoadingSpinner,
} from 'components/common/SubscribeInput/SubscribeInput.styled';
import { useState } from 'react';
import { subscribeToNewsletter } from 'services/requests';
import { emailValidator } from 'utils/inputValidators';

const Subscribe = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const subscribe = async () => {
    setLoading(true);

    await subscribeToNewsletter(email);

    setLoading(false);

    setEmailSent(true);
  };

  const isEmailValid = emailValidator(email);

  const buttonContent = () => {
    if (!emailSent && loading) return <LoadingSpinner />;

    if (emailSent && !loading) return <Check />;

    return 'Subscribe';
  };

  return (
    <SubscribeWrapper>
      <SubscribeInput
        type="email"
        disabled={emailSent}
        placeholder="Enter your email..."
        value={email}
        onChange={({ target: { value } }) => setEmail(value)}
      />
      <SubscribeButton
        type="button"
        onClick={subscribe}
        disabled={!isEmailValid || emailSent}
      >
        {buttonContent()}
      </SubscribeButton>
    </SubscribeWrapper>
  );
};

export default Subscribe;
