import styled from 'styled-components';

const Container = styled.div`
  bottom: 100px;
  cursor: pointer;
  position: fixed;
  right: -1px;
  z-index: 100;
`;
export default Container;
