import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StatusCodes } from 'http-status-codes';
import ResponsePopUp from 'components/common/ResponsePopUp/ResponsePopUp';
import {
  ERROR_CONFIG,
  THANK_YOU_CONFIG,
} from 'components/Forms/Resources/Response/constants';
import useAdminCompanyStore from 'store/company/useAdminCompanyStore';

const Response = ({ companyIds, req, closeForm }) => {
  const [success, setSuccess] = useState(true);
  const deleteMultipleCompanies = useAdminCompanyStore(
    (state) => state.deleteMultipleCompanies,
  );

  useEffect(() => {
    let ignore = false;

    const deleteCompanies = async () => {
      try {
        if (!ignore) {
          const responseData = await req(companyIds);

          if (responseData.status !== StatusCodes.OK)
            throw new Error(`Failed: ${responseData.response}`);
        }

        setSuccess(true);
        deleteMultipleCompanies(companyIds);

        setTimeout(() => {
          closeForm();
        }, 1200);
      } catch (error) {
        setSuccess(false);
      }
    };

    deleteCompanies();

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <ResponsePopUp
      {...(success
        ? { ...THANK_YOU_CONFIG, descriptionText: 'Successfully deleted' }
        : ERROR_CONFIG)}
    />
  );
};

Response.propTypes = {
  companyIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  req: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
};

export default Response;
