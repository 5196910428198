import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Spacer from 'components/common/Spacer/Spacer.styled';
import RadioButtonGroup from 'components/common/RadioButtonGroup/RadioButtonGroup';
import DropdownHide from 'assets/icons/DropdownHide';
import DropdownShow from 'assets/icons/DropdownShow';
import useClickOutside from 'hooks/useClickOutside';
import { TEXT_SIZES } from 'components/common/RadioButtonGroup/constants';
import { sortConfig, SORT_TYPES } from 'pages/ResourcePage/constants';
import {
  DropdownMenu,
  SortField,
  SortFieldWrapper,
  SortWrapper,
  DropdownMenuContainer,
} from './ResourcesSort.styled';
import { FilterLabel } from '../Filters.styled';

const ResourcesSort = ({
  sortFieldLabel,
  radioGroupLabel,
  options,
  setSortType,
  sortedBy,
  orderedBy,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const contentRef = useRef(null);

  useClickOutside(contentRef, () => setShowDropdown(false));

  const onSortTypeChange = ({ target: { value } }) =>
    setSortType(
      Object.keys(SORT_TYPES).find((key) => SORT_TYPES[key] === value),
    );

  const sortByType = Object.entries(sortConfig).find(
    ([, sortOption]) =>
      sortOption.sortBy === sortedBy && sortOption.orderBy === orderedBy,
  )[0];

  const sortedByLabel = SORT_TYPES[sortByType];

  return (
    <SortWrapper>
      <SortFieldWrapper>
        <FilterLabel>{sortFieldLabel}</FilterLabel>
        <SortField id={sortedBy} onClick={() => setShowDropdown(!showDropdown)}>
          {sortedByLabel}
          {showDropdown ? <DropdownHide /> : <DropdownShow />}
        </SortField>
        <Spacer Height="4px" />
      </SortFieldWrapper>
      {showDropdown && (
        <DropdownMenuContainer ref={contentRef}>
          <DropdownMenu>
            <RadioButtonGroup
              radioButtonFillColor="var(--BRIGHT_GRAY_OR_DAVY_GRAY)"
              radioButtonLabelColor="var(--BRIGHT_GRAY_OR_DAVY_GRAY)"
              radioButtonOutlineColor="var(--DAVY_GRAY_OR_BRIGHT_GRAY)"
              key={sortByType}
              textSize={TEXT_SIZES.MEDIUM}
              flexDirection="column"
              groupLabel={radioGroupLabel}
              options={options}
              selectedLabel={sortedByLabel}
              onChange={onSortTypeChange}
            />
          </DropdownMenu>
        </DropdownMenuContainer>
      )}
    </SortWrapper>
  );
};

ResourcesSort.propTypes = {
  sortFieldLabel: PropTypes.string.isRequired,
  radioGroupLabel: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setSortType: PropTypes.func.isRequired,
  sortedBy: PropTypes.string.isRequired,
  orderedBy: PropTypes.string.isRequired,
};

export default ResourcesSort;
