import { useAppContext } from 'contexts/AppContext';
import { ADD_A_COMPANY } from 'components/Forms/Company/constants';
import PlusIcon from 'assets/icons/PlusIcon';
import AddButton from 'pages/AdminPage/components/Filters/AddACompanyButton/AddACompanyButton.styled';

const AddACompanyButton = () => {
  const { setActiveForm } = useAppContext();

  return (
    <AddButton onClick={() => setActiveForm(ADD_A_COMPANY)}>
      <PlusIcon />
      <p>Add a company</p>
    </AddButton>
  );
};

export default AddACompanyButton;
