const ResponseError = () => (
  <svg
    width="156"
    height="108"
    viewBox="0 0 156 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M155.5 96.3064V96.3057V10.8792V10.8785C155.496 8.12717 154.401 5.48961 152.456 3.5441C150.51 1.59859 147.873 0.503893 145.121 0.5H145.121L10.8792 0.5L10.8785 0.500001C8.12717 0.503895 5.48962 1.59859 3.54411 3.5441C1.5986 5.48961 0.503895 8.12717 0.5 10.8785V10.8792L0.5 96.3057L0.500001 96.3064C0.503895 99.0577 1.5986 101.695 3.54411 103.641C5.48962 105.586 8.12717 106.681 10.8785 106.685H10.8792H145.121H145.121C147.873 106.681 150.51 105.586 152.456 103.641C154.401 101.695 155.496 99.0577 155.5 96.3064ZM10.8799 104.523C8.70123 104.52 6.61259 103.653 5.07207 102.113C3.53161 100.572 2.665 98.4839 2.66227 96.3053V10.8796C2.665 8.70104 3.53161 6.61252 5.07206 5.07206C6.61252 3.53161 8.70104 2.66498 10.8796 2.66226H145.12C147.299 2.66498 149.387 3.53161 150.928 5.07206C152.468 6.61259 153.335 8.70122 153.338 10.8799V96.305C153.335 98.4837 152.468 100.572 150.928 102.113C149.387 103.653 147.299 104.52 145.12 104.523H10.8799Z"
      fill="#EAEAEA"
      stroke="#EAEAEA"
    />
    <path
      d="M153.838 16.3705H2.16267C2.00854 16.3705 1.86073 16.3093 1.75175 16.2003C1.64277 16.0913 1.58154 15.9435 1.58154 15.7894C1.58154 15.6353 1.64277 15.4874 1.75175 15.3785C1.86073 15.2695 2.00854 15.2083 2.16267 15.2083H153.838C153.992 15.2083 154.14 15.2695 154.249 15.3785C154.358 15.4874 154.419 15.6353 154.419 15.7894C154.419 15.9435 154.358 16.0913 154.249 16.2003C154.14 16.3093 153.992 16.3705 153.838 16.3705Z"
      fill="#EAEAEA"
    />
    <path
      d="M12.0414 12.0413C13.6462 12.0413 14.9471 10.7404 14.9471 9.13564C14.9471 7.53089 13.6462 6.22998 12.0414 6.22998C10.4367 6.22998 9.13574 7.53089 9.13574 9.13564C9.13574 10.7404 10.4367 12.0413 12.0414 12.0413Z"
      fill="#A51C30"
    />
    <path
      d="M21.9213 12.0413C23.526 12.0413 24.8269 10.7404 24.8269 9.13564C24.8269 7.53089 23.526 6.22998 21.9213 6.22998C20.3165 6.22998 19.0156 7.53089 19.0156 9.13564C19.0156 10.7404 20.3165 12.0413 21.9213 12.0413Z"
      fill="#A51C30"
    />
    <path
      d="M31.8002 12.0413C33.4049 12.0413 34.7059 10.7404 34.7059 9.13564C34.7059 7.53089 33.4049 6.22998 31.8002 6.22998C30.1954 6.22998 28.8945 7.53089 28.8945 9.13564C28.8945 10.7404 30.1954 12.0413 31.8002 12.0413Z"
      fill="#A51C30"
    />
    <path
      d="M76.483 43.2364H71.7902C71.0642 43.2364 70.3679 42.948 69.8545 42.4346C69.3411 41.9212 69.0527 41.225 69.0527 40.4989C69.0527 39.7729 69.3411 39.0766 69.8545 38.5633C70.3679 38.0499 71.0642 37.7615 71.7902 37.7615H76.483C77.209 37.7615 77.9053 38.0499 78.4187 38.5633C78.9321 39.0766 79.2205 39.7729 79.2205 40.4989C79.2205 41.225 78.9321 41.9212 78.4187 42.4346C77.9053 42.948 77.209 43.2364 76.483 43.2364Z"
      fill="#C0C0BF"
    />
    <path
      d="M108.55 54.7733H103.858C103.132 54.7733 102.435 54.4849 101.922 53.9715C101.409 53.4581 101.12 52.7618 101.12 52.0358C101.12 51.3098 101.409 50.6135 101.922 50.1001C102.435 49.5868 103.132 49.2983 103.858 49.2983H108.55C109.276 49.2983 109.973 49.5868 110.486 50.1001C110.999 50.6135 111.288 51.3098 111.288 52.0358C111.288 52.7618 110.999 53.4581 110.486 53.9715C109.973 54.4849 109.276 54.7733 108.55 54.7733Z"
      fill="#C0C0BF"
    />
    <path
      d="M123.412 54.7733H118.719C117.993 54.7733 117.297 54.4849 116.783 53.9715C116.27 53.4581 115.981 52.7618 115.981 52.0358C115.981 51.3098 116.27 50.6135 116.783 50.1001C117.297 49.5868 117.993 49.2983 118.719 49.2983H123.412C124.138 49.2983 124.834 49.5868 125.347 50.1001C125.861 50.6135 126.149 51.3098 126.149 52.0358C126.149 52.7618 125.861 53.4581 125.347 53.9715C124.834 54.4849 124.138 54.7733 123.412 54.7733Z"
      fill="#C0C0BF"
    />
    <path
      d="M138.272 54.7733H133.579C132.853 54.7733 132.157 54.4849 131.644 53.9715C131.13 53.4581 130.842 52.7618 130.842 52.0358C130.842 51.3098 131.13 50.6135 131.644 50.1001C132.157 49.5868 132.853 49.2983 133.579 49.2983H138.272C138.998 49.2983 139.694 49.5868 140.208 50.1001C140.721 50.6135 141.01 51.3098 141.01 52.0358C141.01 52.7618 140.721 53.4581 140.208 53.9715C139.694 54.4849 138.998 54.7733 138.272 54.7733Z"
      fill="#C0C0BF"
    />
    <path
      d="M108.942 43.4317H87.0422C86.3161 43.4317 85.6198 43.1433 85.1065 42.6299C84.5931 42.1166 84.3047 41.4203 84.3047 40.6943C84.3047 39.9682 84.5931 39.272 85.1065 38.7586C85.6198 38.2452 86.3161 37.9568 87.0422 37.9568H108.942C109.668 37.9568 110.364 38.2452 110.878 38.7586C111.391 39.272 111.679 39.9682 111.679 40.6943C111.679 41.4203 111.391 42.1166 110.878 42.6299C110.364 43.1433 109.668 43.4317 108.942 43.4317Z"
      fill="#C0C0BF"
    />
    <path
      d="M93.6899 54.3814H71.7902C71.0642 54.3814 70.3679 54.093 69.8545 53.5796C69.3411 53.0663 69.0527 52.37 69.0527 51.644C69.0527 50.9179 69.3411 50.2217 69.8545 49.7083C70.3679 49.1949 71.0642 48.9065 71.7902 48.9065H93.6899C94.416 48.9065 95.1123 49.1949 95.6256 49.7083C96.139 50.2217 96.4274 50.9179 96.4274 51.644C96.4274 52.37 96.139 53.0663 95.6256 53.5796C95.1123 54.093 94.416 54.3814 93.6899 54.3814Z"
      fill="#C0C0BF"
    />
    <path
      d="M136.708 43.4317H119.892C119.166 43.4317 118.469 43.1433 117.956 42.6299C117.443 42.1166 117.154 41.4203 117.154 40.6943C117.154 39.9682 117.443 39.272 117.956 38.7586C118.469 38.2452 119.166 37.9568 119.892 37.9568H136.708C137.434 37.9568 138.13 38.2452 138.643 38.7586C139.157 39.272 139.445 39.9682 139.445 40.6943C139.445 41.4203 139.157 42.1166 138.643 42.6299C138.13 43.1433 137.434 43.4317 136.708 43.4317Z"
      fill="#C0C0BF"
    />
    <path
      d="M76.483 66.3092H71.7902C71.0642 66.3092 70.3679 66.0207 69.8545 65.5074C69.3411 64.994 69.0527 64.2977 69.0527 63.5717C69.0527 62.8457 69.3411 62.1494 69.8545 61.636C70.3679 61.1226 71.0642 60.8342 71.7902 60.8342H76.483C77.209 60.8342 77.9053 61.1226 78.4187 61.636C78.9321 62.1494 79.2205 62.8457 79.2205 63.5717C79.2205 64.2977 78.9321 64.994 78.4187 65.5074C77.9053 66.0207 77.209 66.3092 76.483 66.3092Z"
      fill="#C0C0BF"
    />
    <path
      d="M108.942 66.5045H87.0422C86.3161 66.5045 85.6198 66.2161 85.1065 65.7027C84.5931 65.1893 84.3047 64.493 84.3047 63.767C84.3047 63.041 84.5931 62.3447 85.1065 61.8313C85.6198 61.318 86.3161 61.0295 87.0422 61.0295H108.942C109.668 61.0295 110.364 61.318 110.878 61.8313C111.391 62.3447 111.679 63.041 111.679 63.767C111.679 64.493 111.391 65.1893 110.878 65.7027C110.364 66.2161 109.668 66.5045 108.942 66.5045Z"
      fill="#C0C0BF"
    />
    <path
      d="M136.708 66.5045H119.892C119.166 66.5045 118.469 66.2161 117.956 65.7027C117.443 65.1893 117.154 64.493 117.154 63.767C117.154 63.041 117.443 62.3447 117.956 61.8313C118.469 61.318 119.166 61.0295 119.892 61.0295H136.708C137.434 61.0295 138.13 61.318 138.643 61.8313C139.157 62.3447 139.445 63.041 139.445 63.767C139.445 64.493 139.157 65.1893 138.643 65.7027C138.13 66.2161 137.434 66.5045 136.708 66.5045Z"
      fill="#C0C0BF"
    />
    <path
      d="M129.277 75.695C129.278 74.9692 129.567 74.2734 130.08 73.7602C130.593 73.247 131.289 72.9583 132.015 72.9575H136.708C137.434 72.9575 138.13 73.2459 138.643 73.7593C139.157 74.2727 139.445 74.969 139.445 75.695C139.445 76.421 139.157 77.1173 138.643 77.6307C138.13 78.144 137.434 78.4325 136.708 78.4325H132.015C131.289 78.4316 130.593 78.143 130.08 77.6298C129.567 77.1166 129.278 76.4208 129.277 75.695Z"
      fill="#C0C0BF"
    />
    <path
      d="M96.8184 75.8903C96.8192 75.1645 97.1078 74.4687 97.621 73.9555C98.1342 73.4423 98.8301 73.1536 99.5558 73.1528H121.456C122.182 73.1528 122.878 73.4412 123.391 73.9546C123.905 74.468 124.193 75.1643 124.193 75.8903C124.193 76.6163 123.905 77.3126 123.391 77.826C122.878 78.3394 122.182 78.6278 121.456 78.6278H99.5558C98.8301 78.627 98.1342 78.3383 97.621 77.8251C97.1078 77.3119 96.8192 76.6161 96.8184 75.8903Z"
      fill="#C0C0BF"
    />
    <path
      d="M69.0527 75.8903C69.0535 75.1645 69.3422 74.4687 69.8554 73.9555C70.3686 73.4423 71.0644 73.1536 71.7902 73.1528H88.6061C89.3321 73.1528 90.0284 73.4412 90.5418 73.9546C91.0551 74.468 91.3435 75.1643 91.3435 75.8903C91.3435 76.6163 91.0551 77.3126 90.5418 77.826C90.0284 78.3394 89.3321 78.6278 88.6061 78.6278H71.7902C71.0644 78.627 70.3686 78.3383 69.8554 77.8251C69.3422 77.3119 69.0535 76.6161 69.0527 75.8903Z"
      fill="#C0C0BF"
    />
    <path
      d="M31.4689 39.4199C32.8161 37.0866 36.1839 37.0866 37.5311 39.4199L57.0167 73.1699C58.3638 75.5033 56.6799 78.4199 53.9856 78.4199H15.0144C12.3201 78.4199 10.6362 75.5033 11.9833 73.1699L31.4689 39.4199Z"
      stroke="#333333"
    />
    <path
      d="M34.4262 53.1699C35.0879 53.1699 35.6208 53.713 35.6082 54.3746L35.3784 66.4604C35.3686 66.9792 34.9451 67.3947 34.4262 67.3947C33.9073 67.3947 33.4839 66.9792 33.474 66.4604L33.2442 54.3746C33.2316 53.713 33.7645 53.1699 34.4262 53.1699ZM34.5246 73.1699C34.0984 73.1699 33.7377 72.9992 33.4426 72.6578C33.1475 72.3164 33 71.8992 33 71.4061C33 70.9129 33.1475 70.4957 33.4426 70.1543C33.7377 69.8129 34.0984 69.6422 34.5246 69.6422C34.9344 69.6422 35.2787 69.8129 35.5574 70.1543C35.8525 70.4957 36 70.9129 36 71.4061C36 71.8992 35.8525 72.3164 35.5574 72.6578C35.2787 72.9992 34.9344 73.1699 34.5246 73.1699Z"
      fill="#333333"
    />
  </svg>
);

export default ResponseError;
