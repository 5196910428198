const CompanyHiringIcon = () => (
  <svg
    width="74"
    height="74"
    viewBox="0 0 74 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="37" cy="37" r="32" fill="white" />
    <circle cx="37" cy="37" r="36.5" stroke="white" />
    <path
      d="M45 31C45.58 31 46.05 31.2 46.42 31.59C46.8 32 47 32.45 47 33V44C47 44.55 46.8 45 46.42 45.41C46.05 45.8 45.58 46 45 46H29C28.42 46 27.95 45.8 27.58 45.41C27.2 45 27 44.55 27 44V33C27 32.45 27.2 32 27.58 31.59C27.95 31.2 28.42 31 29 31H33V29C33 28.42 33.2 27.95 33.58 27.58C33.95 27.2 34.42 27 35 27H39C39.58 27 40.05 27.2 40.42 27.58C40.8 27.95 41 28.42 41 29V31H45ZM29 33V44H45V33H29ZM39 31V29H35V31H39Z"
      fill="#A51C30"
    />
  </svg>
);

export default CompanyHiringIcon;
