export const TEXT_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
};

export const FONT_SIZES = {
  [TEXT_SIZES.SMALL]: {
    groupLabel: '10px',
    singleLabel: '12px',
  },
  [TEXT_SIZES.MEDIUM]: {
    groupLabel: '12px',
    singleLabel: '14px',
  },
};
