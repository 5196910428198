import PropTypes from 'prop-types';
import {
  StatusBoxContent,
  StatusBoxDescription,
  StatusBoxValue,
  StatusBoxContainer,
} from './StatusBox.styled';

const StatusBox = ({ svg, value, description, isActive, onClick }) => (
  <StatusBoxContainer isActive={isActive} onClick={onClick}>
    {svg}
    <StatusBoxContent>
      <StatusBoxValue>{value}</StatusBoxValue>
      <StatusBoxDescription>{description}</StatusBoxDescription>
    </StatusBoxContent>
  </StatusBoxContainer>
);

StatusBox.defaultProps = {
  isActive: false,
};

StatusBox.propTypes = {
  svg: PropTypes.element.isRequired,
  value: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default StatusBox;
