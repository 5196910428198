import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Sidemenu,
  ListItem,
  ListWrapper,
  ToggleWrapper,
  Paragraph,
  HamburgerTooltipContainer,
  AnchorContainer,
  ProfileWrapper,
  BottomWrapper,
  SideMenuDivider,
} from 'components/Header/Sidebar/Sidebar.styled';
import {
  CheckBoxWrapper,
  CheckBox,
  CheckBoxLabel,
} from 'components/Header/ToggleSwitch/ToggleSwitch.styled';
import AnchorPartner from 'components/common/AnchorPartner/AnchorPartner';
import { useAppContext } from 'contexts/AppContext';
import { SIGN_IN, SIGN_UP } from 'components/Forms/SignForm/constants';
import { InvertedButton } from 'components/common/Button/Button.styled';
import Tooltip from 'components/common/Tooltip/Tooltip';
import useDisableScroll from 'hooks/useDisableScroll';
import Spacer from 'components/common/Spacer/Spacer.styled';
import useMountTransition from 'hooks/useMountTransition';
import USER_ROLES from 'constants/user_roles';

const Sidebar = ({
  open,
  toggleSwitch,
  isDark,
  closeHamburger: closeSideMenu,
}) => {
  const { setActiveForm, user, removeUser, getUserRole } = useAppContext();
  const location = useLocation();
  useDisableScroll();

  const isSideMenuTransitioned = useMountTransition(open, 1000);
  const navigate = useNavigate();

  const logOut = () => {
    removeUser();
    closeSideMenu();
    navigate('/');
  };

  return (
    <Sidemenu show={isSideMenuTransitioned}>
      <ListWrapper>
        {getUserRole() === USER_ROLES.ADMIN && (
          <ListItem to="/administration/companies" onClick={closeSideMenu}>
            Admin
          </ListItem>
        )}
        <ListItem to="/" onClick={closeSideMenu}>
          Companies
        </ListItem>
        <ListItem to="/resources" onClick={closeSideMenu}>
          Resources
        </ListItem>
        <ListItem to="/funding" onClick={closeSideMenu}>
          Funding
        </ListItem>
        <ListItem to="/about" onClick={closeSideMenu}>
          About
        </ListItem>
        {!(
          getUserRole() === USER_ROLES.ADMIN &&
          location.pathname.startsWith('/administration')
        ) && (
          <ToggleWrapper>
            <Paragraph>{`Go ${isDark ? 'Light' : 'Dark'} Theme`}</Paragraph>
            <CheckBoxWrapper>
              <CheckBox
                id="checkbox"
                type="checkbox"
                onChange={toggleSwitch}
                checked={isDark}
              />
              <CheckBoxLabel htmlFor="checkbox" />
            </CheckBoxWrapper>
          </ToggleWrapper>
        )}

        <SideMenuDivider />

        {user ? (
          <>
            <Paragraph>Howdy, {user.fullName.split(' ')[0]}</Paragraph>
            <ProfileWrapper>
              <Paragraph>Profile</Paragraph>
              <HamburgerTooltipContainer>
                <Tooltip
                  text="Coming soon!"
                  Heigth="30px"
                  Width="120px"
                  Side="Right"
                  LeftOffSet="-100px"
                  TopOffSet="-5px"
                  ArrowLeftOffSet="130px"
                />
              </HamburgerTooltipContainer>
            </ProfileWrapper>
            <InvertedButton
              Border="none"
              FontSize="15px"
              Padding="10px 0"
              TextAlign="left"
              Width="80px"
              onClick={logOut}
            >
              Log Out
            </InvertedButton>
          </>
        ) : (
          <>
            <Paragraph
              onClick={() => {
                setActiveForm(SIGN_IN);
                closeSideMenu();
              }}
            >
              Sign in
            </Paragraph>
            <Paragraph
              onClick={() => {
                setActiveForm(SIGN_UP);
                closeSideMenu();
              }}
            >
              Sign up
            </Paragraph>
          </>
        )}
      </ListWrapper>
      <BottomWrapper>
        <AnchorContainer onClick={closeSideMenu}>
          <AnchorPartner />
        </AnchorContainer>
        <Spacer Height="70px" />
      </BottomWrapper>
    </Sidemenu>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleSwitch: PropTypes.func.isRequired,
  isDark: PropTypes.bool.isRequired,
  closeHamburger: PropTypes.func.isRequired,
};

export default Sidebar;
