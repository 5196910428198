import { useEffect, useState } from 'react';
import useRequest from 'hooks/useRequest';
import { getPublicResourceById } from 'services/requests';
import { unpackPublicResource } from 'pages/AdminPage/components/Forms/PublicResource/EditPublicResource/utils';

const useFetchPublicResourceById = (id) => {
  const { request } = useRequest();

  const [isLoading, setIsLoading] = useState(true);
  const [initialPublicResourceData, setInitialPublicResourceData] =
    useState(null);

  useEffect(() => {
    const getResource = async () => {
      setIsLoading(true);

      const responseData = await request(() => getPublicResourceById(id));

      if (responseData) {
        setInitialPublicResourceData((prevState) => ({
          ...prevState,
          ...unpackPublicResource(responseData.data),
        }));
      }

      setIsLoading(false);
    };

    getResource();
  }, [id]);

  return { isLoading, initialPublicResourceData };
};

export default useFetchPublicResourceById;
