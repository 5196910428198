import { DAVY_GRAY } from 'constants/colors';
import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const Title = styled.p`
  color: ${DAVY_GRAY};
  margin: 40px 0 0 0;
  text-align: center;
  font-size: 26px;
  line-height: 31px;
  letter-spacing: 0;
`;

export const SubTitle = styled.p`
  text-align: center;
  color: ${DAVY_GRAY};
  font-size: 16px;
  margin: 24px 0 40px 0;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  min-width: 376px;
  max-width: 500px;
  margin: 0 72px 70px;

  @media ${DEVICE.formResponsive} {
    min-width: 335px;
    margin: 0 20px;
  }
`;

export const IconWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;
