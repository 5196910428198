import { useState, useEffect } from 'react';
import useRequest from 'hooks/useRequest';
import {
  getExclusiveDealCategories,
  getExclusiveDeals,
} from 'services/requests';
import Modal from 'components/common/Modal/Modal';
import ResourcePopup from 'pages/ResourcePage/components/ResourcePopup/ResourcePopup';
import ResourceCard from 'pages/ResourcePage/components/ResourceCard/ResourceCard';
import Carousel from 'components/common/Carousel/Carousel.styled';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ADD_A_COMPANY } from 'components/Forms/Company/constants';
import { SIGN_UP } from 'components/Forms/SignForm/constants';
import { REQUEST_DEBOUNCE_DURATION } from 'constants/input_debounce_duration';
import { useAppContext } from 'contexts/AppContext';
import {
  EXCLUSIVE_RESOURCE_CATEGORIES,
  RESOURCES_CAROUSEL_SETTINGS,
} from 'pages/ResourcePage/constants';
import { mapCategoriesToOptions } from 'components/Forms/Resources/StepTwo/utils';
import WarnIcon from 'assets/icons/InputErrorIcon';
import {
  LoadingSpinner,
  LoadingSpinnerContainer,
} from 'components/common/LoadingSpinner/LoadingSpinner.styled';
import FilterControls from '../FilterControls/FilterControls';
import {
  ExclusiveResourceRedemptionInfo,
  ResourcesContainer,
  ResourcesHeading,
} from '../../ResourcePage.styled';
import { EXCLUSIVE_RESOURCES_PARAMS } from './constants';
import useExclusiveResourcesQueryParams from './useExclusiveResourcesParams';
import ExclusiveResourcesFilters from './components/ExclusiveResourcesFilters/ExclusiveResourcesFilters';
import { mapExclusiveData } from '../../utils';
import ResourcesNotFound from '../ResourcesNotFound/ResourcesNotFound';

const ExclusiveResourcesContainer = () => {
  const {
    exclusiveResourcesQueryParams,
    resetFilters,
    setCategory,
    setCompanyName,
    setDealDescription,
    setSortType,
  } = useExclusiveResourcesQueryParams();
  const [previewResource, setPreviewResource] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isFiltersCleared, setIsFiltersCleared] = useState(false);
  const [displayFilters, setDisplayFilters] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [exclusiveResourcesData, setExclusiveResourcesData] = useState({
    data: [],
    total: 0,
  });
  const [isUserFounder, setIsUserFounder] = useState(false);

  const { setActiveForm, user } = useAppContext();

  const { request } = useRequest();

  const closePopup = () => setPreviewResource(null);

  const isFiltersApplied = Boolean(
    exclusiveResourcesQueryParams[EXCLUSIVE_RESOURCES_PARAMS.COMPANY_NAME] ||
      exclusiveResourcesQueryParams[
        EXCLUSIVE_RESOURCES_PARAMS.DEAL_DESCRIPTION
      ] ||
      exclusiveResourcesQueryParams[EXCLUSIVE_RESOURCES_PARAMS.CATEGORY],
  );

  const clearFilters = () => {
    if (isFiltersApplied) {
      setFetching(true);
      resetFilters();
      setIsFiltersCleared(!isFiltersCleared);
    }
  };

  useEffect(() => {
    let ignore = false;

    const fetchCategories = async () => {
      const fetchedCategories = await request(() =>
        getExclusiveDealCategories(),
      );

      if (!ignore && fetchedCategories)
        setCategories(
          mapCategoriesToOptions(
            fetchedCategories.data,
            EXCLUSIVE_RESOURCE_CATEGORIES,
          ),
        );
    };

    fetchCategories();

    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    let ignore = false;

    const fetchExclusiveResources = async () => {
      const fetchedResources = await request(() =>
        getExclusiveDeals(exclusiveResourcesQueryParams),
      );

      if (!ignore) {
        if (fetchedResources) {
          setExclusiveResourcesData({
            data: fetchedResources.data.data,
            total: fetchedResources.data.total,
          });

          setIsUserFounder(fetchedResources.data.isAuthorized);
        }

        setFetching(false);
      }
    };

    if (!ignore) setFetching(true);
    const fetchExclusiveResourcesDebounced = setTimeout(
      fetchExclusiveResources,
      REQUEST_DEBOUNCE_DURATION,
    );

    return () => {
      ignore = true;

      clearTimeout(fetchExclusiveResourcesDebounced);
    };
  }, [request, exclusiveResourcesQueryParams, user]);

  const getDisplay = () => {
    if (fetching)
      return (
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      );

    if (!exclusiveResourcesData.data.length) return <ResourcesNotFound />;

    return (
      <Carousel {...RESOURCES_CAROUSEL_SETTINGS}>
        {exclusiveResourcesData.data.map((exclusiveResource) => (
          <ResourceCard
            key={exclusiveResource.companyName}
            {...mapExclusiveData(exclusiveResource)}
            previewResourceDetails={() => setPreviewResource(exclusiveResource)}
            isUserFounder={isUserFounder}
          />
        ))}
      </Carousel>
    );
  };

  return (
    <ResourcesContainer>
      <ResourcesHeading>
        Exclusive Deals ({exclusiveResourcesData.total})
      </ResourcesHeading>
      {!fetching && !isUserFounder ? (
        <ExclusiveResourceRedemptionInfo>
          <WarnIcon />
          <p>
            To claim exclusive resources, please{' '}
            <button type="button" onClick={() => setActiveForm(ADD_A_COMPANY)}>
              submit a company
            </button>{' '}
            and{' '}
            <button type="button" onClick={() => setActiveForm(SIGN_UP)}>
              create an account
            </button>
            . Currently, these resources are only available to HBS founders.
          </p>
        </ExclusiveResourceRedemptionInfo>
      ) : null}
      <FilterControls
        toggleFilters={() => setDisplayFilters(!displayFilters)}
        clearFilters={clearFilters}
      />
      {displayFilters && (
        <ExclusiveResourcesFilters
          exclusiveResourcesQueryParams={exclusiveResourcesQueryParams}
          companyName={
            exclusiveResourcesQueryParams[
              EXCLUSIVE_RESOURCES_PARAMS.COMPANY_NAME
            ]
          }
          dealDescription={
            exclusiveResourcesQueryParams[
              EXCLUSIVE_RESOURCES_PARAMS.DEAL_DESCRIPTION
            ]
          }
          sortByParam={
            exclusiveResourcesQueryParams[EXCLUSIVE_RESOURCES_PARAMS.SORT_BY]
          }
          orderByParam={
            exclusiveResourcesQueryParams[EXCLUSIVE_RESOURCES_PARAMS.ORDER_BY]
          }
          categories={categories}
          isFiltersCleared={isFiltersCleared}
          setDealDescription={setDealDescription}
          setCategory={setCategory}
          setSortType={setSortType}
          setCompanyName={setCompanyName}
        />
      )}
      {!!previewResource && (
        <Modal closeOnEscape closeForm={closePopup}>
          <ResourcePopup
            {...mapExclusiveData(previewResource)}
            isUserFounder={isUserFounder}
          />
        </Modal>
      )}
      {getDisplay()}
    </ResourcesContainer>
  );
};
export default ExclusiveResourcesContainer;
