import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';

import AllTheProviders from 'AllTheProviders';
import ErrorStateProvider from 'providers/ErrorStateProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AllTheProviders>
      <ErrorStateProvider>
        <App />
      </ErrorStateProvider>
    </AllTheProviders>
  </React.StrictMode>,
);
