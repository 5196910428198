import {
  StayInTouch,
  StayInTouchParagraph,
  BottomParagraph,
  BottomContentWrapper,
} from 'pages/HomePage/components/WelcomeToHbs/WelcomeToHbs.styled';
import Subscribe from 'components/common/SubscribeInput/SubscribeInput';
import Dots from 'components/common/Dots/Dots';
import {
  WelcomeToPageContainer,
  WelcomeToPageParagraph,
  WelcomeToPageRedLine,
  WelcomeToPageTitle,
} from 'components/common/WelcomeToPage/WelcomeToPage.styled';

const WelcomeToHbs = () => (
  <WelcomeToPageContainer>
    <Dots />
    <WelcomeToPageTitle>Welcome to HBSBuilds!</WelcomeToPageTitle>
    <WelcomeToPageParagraph>
      An independent set of resources for founders from the HBS community built
      by members of the HBS community.
    </WelcomeToPageParagraph>
    <WelcomeToPageRedLine />
    <BottomContentWrapper>
      <StayInTouch>
        <StayInTouchParagraph>Stay in touch!</StayInTouchParagraph>
        <BottomParagraph>
          Receive a Quarterly Roundup of HBS-related startup news
        </BottomParagraph>
      </StayInTouch>
      <Subscribe />
    </BottomContentWrapper>
  </WelcomeToPageContainer>
);

export default WelcomeToHbs;
