import React from 'react';
import PropTypes from 'prop-types';

import FounderCard from 'pages/AboutPage/FoundersSection/FounderCard/FounderCard';
import {
  DescriptionParagraph,
  FounderCategorySection,
  FoundersCategoryContainer,
  FoundersCategoryTitle,
  FoundersCategorySubTitle,
} from 'pages/AboutPage/FoundersSection/FounderCategories.styled';
import {
  CATEGORIES,
  CLASS_REP_DESC,
} from 'pages/AboutPage/FoundersSection/constants';
import STATIC_DATA from 'pages/AboutPage/FoundersSection/staticData';
import SymphonyContact from 'pages/AboutPage/FoundersSection/SymphonyContact/SymphonyContact';
import { CONTACT_EMAIL } from 'constants/other';
import Spacer from 'components/common/Spacer/Spacer.styled';

const categoryKeys = Object.keys(CATEGORIES).filter(
  (key) => CATEGORIES[key] !== CATEGORIES.CLASS_REPS,
);

const FoundersSection = ({ title, cards, sub }) => {
  return (
    <>
      {!sub && <FoundersCategoryTitle>{title}</FoundersCategoryTitle>}
      {sub && <FoundersCategorySubTitle>{title}</FoundersCategorySubTitle>}
      <FoundersCategoryContainer>
        {cards.map((founder) => (
          <FounderCard key={founder.name} {...founder} />
        ))}
      </FoundersCategoryContainer>
    </>
  );
};

FoundersSection.defaultProps = {
  sub: false,
};

FoundersSection.propTypes = {
  title: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  sub: PropTypes.bool,
};

const FounderCategories = () => (
  <FounderCategorySection id="#founders">
    {categoryKeys.map((key) => {
      if (Array.isArray(STATIC_DATA[CATEGORIES[key]])) {
        return (
          <FoundersSection
            key={key}
            cards={STATIC_DATA[CATEGORIES[key]]}
            title={CATEGORIES[key]}
          />
        );
      }
      return (
        <React.Fragment key={key}>
          <FoundersCategoryTitle>{CATEGORIES[key]}</FoundersCategoryTitle>
          {STATIC_DATA[CATEGORIES[key]].subcategories.map((sub) => (
            <FoundersSection
              key={sub.title}
              title={sub.title}
              cards={sub.cards}
              sub
            />
          ))}
        </React.Fragment>
      );
    })}
    <FoundersCategoryTitle>{CATEGORIES.CLASS_REPS}</FoundersCategoryTitle>
    <DescriptionParagraph>
      {CLASS_REP_DESC[0]}
      <a href={`mailto:${CONTACT_EMAIL}`}>{CLASS_REP_DESC[1]}</a>
      {CLASS_REP_DESC[2]}
    </DescriptionParagraph>
    <FoundersCategoryContainer>
      {STATIC_DATA[CATEGORIES.CLASS_REPS].map((founder) => (
        <FounderCard key={founder.name} {...founder} />
      ))}
    </FoundersCategoryContainer>
    <Spacer Height="30px" />
    <FoundersCategoryTitle id="#anchor-partner">
      Anchor Partner
    </FoundersCategoryTitle>
    <SymphonyContact />
  </FounderCategorySection>
);

export default FounderCategories;
