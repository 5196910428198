import { CRIMSON } from 'constants/colors';
import styled, { keyframes } from 'styled-components';

export const BrandsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BrandNameWrapper = styled.span`
  font-size: 18px;
  line-height: 40px;
`;

export const disappear = keyframes`
    0% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
`;

export const BrandName = styled.b`
  font-size: 20px;
  line-height: 40px;
  color: ${CRIMSON};
  animation: ${disappear} 3s infinite;
`;
