import styled from 'styled-components';
import { CRIMSON, MODAL_BACKGROUND, WHITE } from 'constants/colors';
import DEVICE from 'constants/deviceSizes';

const ModalScreenContainer = styled.div`
  align-items: flex-start;
  background: ${MODAL_BACKGROUND};
  display: flex;
  justify-content: center;
  position: fixed;
  padding-top: 50px;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 200;

  @media ${DEVICE.tablet} {
    padding-top: 0px;
  }
`;

const ModalContentContainer = styled.div`
  align-items: center;
  background: ${WHITE};
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgb(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  max-height: calc(86vh);
  overflow: scroll;
  overflow-x: hidden;
  position: relative;

  touch-action: pan-y;
  -ms-touch-action: pan-y;

  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  &:focus {
    outline: none;
  }

  @media ${DEVICE.tablet} {
    max-height: none;
    height: 100vh;
  }

  @media ${DEVICE.formResponsive} {
    width: 100%;
  }
`;

const MobileBottomSpacer = styled.div`
  display: none;

  @media ${DEVICE.formResponsive} {
    display: block;
    height: 100px;
  }
`;

const ModalCloseButton = styled.div`
  align-self: flex-end;
  display: flex;
  margin: 20px 20px 0px 0px;
  height: 20px;
  width: 20px;

  @media ${DEVICE.formResponsive} {
    margin: 15px 15px 0px 0px;
  }

  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

const ModalProgress = styled.div`
  display: flex;
  align-self: ${(props) => (props.progress === '0%' ? 'center' : 'flex-start')};
  background: ${CRIMSON};
  border-radius: 0px 0px 4px 4px;
  height: 4px;
  min-height: 4px;
  width: ${(props) => (props.progress === '0%' ? '60%' : props.progress)};
`;

const ModalFormContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export {
  ModalScreenContainer,
  ModalContentContainer,
  ModalProgress,
  ModalCloseButton,
  MobileBottomSpacer,
  ModalFormContent,
};
