const UploadCloudIcon = () => (
  <svg
    width="34"
    height="24"
    viewBox="0 0 34 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.4125 9.22268C26.4492 4.33518 22.1567 0.666016 17 0.666016C12.9058 0.666016 9.35 2.98935 7.57917 6.38935C3.315 6.84268 0 10.4552 0 14.8327C0 17.087 0.895533 19.249 2.48959 20.8431C4.08365 22.4371 6.24566 23.3327 8.5 23.3327H26.9167C27.8469 23.3327 28.768 23.1495 29.6273 22.7935C30.4867 22.4375 31.2676 21.9158 31.9253 21.258C32.5831 20.6003 33.1048 19.8194 33.4608 18.96C33.8168 18.1006 34 17.1795 34 16.2493C34 12.5093 31.0958 9.47768 27.4125 9.22268ZM26.9167 20.4993H8.5C6.99711 20.4993 5.55577 19.9023 4.49306 18.8396C3.43036 17.7769 2.83333 16.3356 2.83333 14.8327C2.83333 11.9285 5.00083 9.50601 7.87667 9.20852L9.3925 9.05268L10.1008 7.70685C11.4467 5.11435 14.0817 3.49935 17 3.49935C20.7117 3.49935 23.9133 6.13435 24.6358 9.77518L25.0608 11.9002L27.2283 12.056C29.4383 12.1977 31.1667 14.0535 31.1667 16.2493C31.1667 17.3765 30.7189 18.4575 29.9219 19.2546C29.1248 20.0516 28.0438 20.4993 26.9167 20.4993ZM11.3333 13.416H14.9458V17.666H19.0542V13.416H22.6667L17 7.74935L11.3333 13.416Z"
      fill="#545556"
    />
  </svg>
);

export default UploadCloudIcon;
