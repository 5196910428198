import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import UploadCloudIcon from 'assets/icons/UploadCloudIcon';
import {
  DragNDropContainer,
  DragNDropWrapper,
  Label,
  DragNDropField,
  DragNDropParagraph,
  CloseIconContainer,
  ImageError,
} from 'components/common/DragNDrop/DragNDrop.styled';
import Preview from 'components/common/DragNDrop/Preview/Preview';
import MAXIMUM_FILE_SIZE_IN_BYTES, {
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from 'components/common/DragNDrop/constants';
import { bytesToMegaBytes } from 'components/common/DragNDrop/utils';
import sanitize from 'sanitize-filename';
import BinIcon from 'assets/icons/BinIcon';
import { DAVY_GRAY } from 'constants/colors';

// TODO: Maybe adapt to accept any kind of files

const DragNDrop = ({
  uploadedFile,
  setUploadedFile,
  maximumFileSizeInBytes,
  label,
  dndParagraph,
}) => {
  const [error, setError] = useState();
  const handleDropAccepted = useCallback((acceptedFiles) => {
    setError(null);
    const file = acceptedFiles[0];
    const fileReader = new FileReader();

    fileReader.onloadend = () =>
      setUploadedFile({
        path: sanitize(acceptedFiles[0].path),
        base64: fileReader.result.split(',')[1],
        preview: fileReader.result,
      });
    fileReader.readAsDataURL(file);
  }, []);

  const handleDropRejected = () => {
    setUploadedFile(null);
    setError(
      `File is too big. Maximum file size is ${bytesToMegaBytes(
        maximumFileSizeInBytes,
      )} MB.`,
    );
  };
  const handleDeleteUploadedFIle = (e) => {
    e.preventDefault();
    setUploadedFile(null);
    setError(null);
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        'image/*': [],
      },
      maxSize: maximumFileSizeInBytes,
      onDropAccepted: handleDropAccepted,
      onDropRejected: handleDropRejected,
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  return (
    <DragNDropWrapper>
      <Label>{label}</Label>
      {!!uploadedFile && (
        <CloseIconContainer onClick={handleDeleteUploadedFIle}>
          <BinIcon fillColor={DAVY_GRAY} />
        </CloseIconContainer>
      )}
      <DragNDropContainer {...getRootProps({ style })}>
        {uploadedFile ? (
          <Preview image={uploadedFile} />
        ) : (
          <DragNDropField>
            <input {...getInputProps()} />
            <UploadCloudIcon />
            <DragNDropParagraph>{dndParagraph}</DragNDropParagraph>
          </DragNDropField>
        )}
      </DragNDropContainer>
      {error && <ImageError>{error}</ImageError>}
    </DragNDropWrapper>
  );
};

DragNDrop.defaultProps = {
  uploadedFile: null,
  maximumFileSizeInBytes: MAXIMUM_FILE_SIZE_IN_BYTES,
  dndParagraph: 'Drag and drop or upload an image',
};

DragNDrop.propTypes = {
  uploadedFile: PropTypes.oneOfType([
    PropTypes.shape({
      path: PropTypes.string,
      base64: PropTypes.string,
      preview: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  dndParagraph: PropTypes.string,
  maximumFileSizeInBytes: PropTypes.number,
  setUploadedFile: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default DragNDrop;
