import { useState } from 'react';
import PropTypes from 'prop-types';
import FilterControls from 'pages/AdminPage/components/Filters/FilterControls/FilterControls';
import ClearFilters from 'pages/AdminPage/components/Filters/ClearFilters/ClearFilters';
import FiltersContainer from 'pages/AdminPage/TabControl/CompanyTab/CompanyFilters/CompanyFilters.styled';
import useParamsProps from 'pages/AdminPage/TabControl/InvestorTab/InvestorFilters/useInvestorParams/propTypes';
import Filters from 'pages/AdminPage/TabControl/InvestorTab/InvestorFilters/Filters';
import { radioButtonOptions } from 'pages/AdminPage/TabControl/InvestorTab/InvestorFilters/utils';
import AddAInvestorButton from 'pages/AdminPage/TabControl/InvestorTab/AddAInvestorButton/AddAInvestorButton';
import {
  SORT_KEYS,
  SORT_PARAMS,
} from 'pages/AdminPage/TabControl/InvestorTab/InvestorFilters/constants';

const InvestorFilters = ({
  investorQueryParams,
  setStatus,
  setCategory,
  setCheckSize,
  setStage,
  setSortType,
  resetFilters,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const toggleFilters = () => setShowFilters(!showFilters);

  return (
    <FiltersContainer id="scrollInto">
      <FilterControls
        toggleFilters={toggleFilters}
        setSortType={setSortType}
        sortByParam={investorQueryParams.sortBy}
        orderByParam={investorQueryParams.orderBy}
        radioButtonOptions={radioButtonOptions(SORT_KEYS)}
        sortParams={SORT_PARAMS}
        AddEntityButton={AddAInvestorButton}
      />
      {showFilters && (
        <>
          <ClearFilters resetFilters={resetFilters} />
          <Filters
            investorQueryParams={investorQueryParams}
            setCategory={setCategory}
            setStage={setStage}
            setCheckSize={setCheckSize}
            setStatus={setStatus}
          />
        </>
      )}
    </FiltersContainer>
  );
};

InvestorFilters.propTypes = {
  investorQueryParams: useParamsProps().isRequired,
  setStatus: PropTypes.func.isRequired,
  setCategory: PropTypes.func.isRequired,
  setCheckSize: PropTypes.func.isRequired,
  setStage: PropTypes.func.isRequired,
  setSortType: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
};

export default InvestorFilters;
