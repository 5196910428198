import {
  CardContainer,
  Image,
  SubImage,
  CategoryContainer,
  CompanyName,
  ExpirationDateContainer,
  ExpirationDate,
  SingleCategory,
  TargetAudience,
  TargetAudienceContainer,
  Description,
  CategoriesAndTargetsWrapper,
  ButtonContainer,
  DiscountContainer,
  Discount,
  ContentContainer,
} from 'pages/ResourcePage/components/ResourceCard/ResourceCard.styled';
import PropTypes from 'prop-types';
import validateURL from 'utils/linkValidator';
import { CRIMSON } from 'constants/colors';
import {
  getDaysDiff,
  getDateFromIsoString,
  getCurrentDate,
  formatDate,
} from 'pages/ResourcePage/utils';
import { NOT_AVAILABLE } from 'pages/HomePage/components/CompaniesContainer/constants';
import getButtonStyles from 'pages/ResourcePage/components/ResourceCard/utils';
import isURL from 'validator/lib/isURL';
import getCategoryColor from 'pages/HomePage/utils';
import {
  EXCLUSIVE_RESOURCE_CATEGORIES,
  PUBLIC_RESOURCE_CATEGORIES,
  PUBLIC_RESOURCE_TARGET_AUDIENCES,
} from 'pages/ResourcePage/constants';

const ResourceCard = ({
  name,
  description,
  image,
  url,
  expiringDate,
  categories,
  targetAudiences,
  discountPricing,
  promotion,
  isExclusive,
  previewResourceDetails,
  isUserFounder,
}) => {
  const handleGoToLink = (e) => {
    e.preventDefault();

    if (!isExclusive && isURL(url)) {
      window.open(validateURL(url), '_blank');
      return;
    }

    if (isExclusive && promotion && isURL(promotion)) {
      window.open(validateURL(promotion), '_blank');
      return;
    }

    previewResourceDetails(e);
  };

  const handleOnCardClick = (e) => {
    e.preventDefault();
    if (!isUserFounder || e.target.getAttribute('name') === 'claimResource')
      return;
    previewResourceDetails(e);
  };

  return (
    <CardContainer onClick={handleOnCardClick} isDisabled={!isUserFounder}>
      <ContentContainer>
        {image ? (
          <Image src={`data:image/png;base64,${image}`} />
        ) : (
          <SubImage>{name.slice(0, 1).toUpperCase()}</SubImage>
        )}
        <CompanyName>{name}</CompanyName>
        {expiringDate && (
          <ExpirationDateContainer>
            Expiration Date
            <ExpirationDate
              Color={
                getDaysDiff(
                  getDateFromIsoString(expiringDate),
                  getCurrentDate(),
                ) < 7
                  ? CRIMSON
                  : 'var(--BRIGHT_GRAY_OR_DARK_CHARCOAL)'
              }
            >
              {formatDate(expiringDate)}
            </ExpirationDate>
          </ExpirationDateContainer>
        )}
        <CategoryContainer>
          Category
          <CategoriesAndTargetsWrapper>
            {categories.map((category) => (
              <SingleCategory
                bgColor={getCategoryColor(
                  category,
                  isExclusive
                    ? EXCLUSIVE_RESOURCE_CATEGORIES
                    : PUBLIC_RESOURCE_CATEGORIES,
                )}
                key={category + name}
              >
                {category}
              </SingleCategory>
            ))}
          </CategoriesAndTargetsWrapper>
        </CategoryContainer>
        {!isExclusive ? (
          <TargetAudienceContainer>
            Target Audience
            <CategoriesAndTargetsWrapper>
              {targetAudiences.map((targetAudience) => (
                <TargetAudience
                  bgColor={getCategoryColor(
                    targetAudience,
                    PUBLIC_RESOURCE_TARGET_AUDIENCES,
                  )}
                  key={targetAudience}
                >
                  {targetAudience}
                </TargetAudience>
              ))}
            </CategoriesAndTargetsWrapper>
          </TargetAudienceContainer>
        ) : (
          <DiscountContainer>
            Deal description
            <Discount>{discountPricing || NOT_AVAILABLE}</Discount>
          </DiscountContainer>
        )}
        <Description>{description}</Description>
      </ContentContainer>
      <ButtonContainer>
        {getButtonStyles(isUserFounder, handleGoToLink)}
      </ButtonContainer>
    </CardContainer>
  );
};

ResourceCard.defaultProps = {
  targetAudiences: [],
  discountPricing: '',
  image: null,
  url: '',
  expiringDate: null,
  description: null,
  promotion: '',
  isUserFounder: true,
};

ResourceCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  expiringDate: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  targetAudiences: PropTypes.arrayOf(PropTypes.string),
  discountPricing: PropTypes.string,
  promotion: PropTypes.string,
  isExclusive: PropTypes.bool.isRequired,
  previewResourceDetails: PropTypes.func.isRequired,
  isUserFounder: PropTypes.bool,
};

export default ResourceCard;
