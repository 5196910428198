import PropTypes from 'prop-types';
import { NOT_AVAILABLE } from 'pages/HomePage/components/CompaniesContainer/constants';
import getCategoryColor from 'pages/HomePage/utils';
import {
  PopupCategory,
  PopupMultiCategoriesContainer,
  PopupSectionParagraph,
} from './Popup.styled';

const PopupMultiCategories = ({ categories, categoriesColors }) =>
  categories && categories.length ? (
    <PopupMultiCategoriesContainer>
      {categories.map((category) => (
        <PopupCategory
          key={category}
          BgColor={getCategoryColor(category, categoriesColors)}
          BorderRadius="4px"
          Padding="1px 3px"
        >
          {category}
        </PopupCategory>
      ))}
    </PopupMultiCategoriesContainer>
  ) : (
    <PopupSectionParagraph>{NOT_AVAILABLE}</PopupSectionParagraph>
  );

PopupMultiCategories.defaultProps = {
  categories: null,
};

PopupMultiCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  categoriesColors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string))
    .isRequired,
};

export default PopupMultiCategories;
