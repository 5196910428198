export default {
  BLUE: '#D2DFFC',
  SKY_BLUE: '#D6EFFB',
  TURQUOISE: '#CCF3E9',
  GREEN: '#D8F5C9',
  YELLOW: '#FCEABC',
  WARM_YELLOW: '#FAE3D7',
  SALMON: '#F9DDE5',
  LAVANDER: '#F9DCF4',
  PURPLE: '#EBE3FC',
  GRAY: '#EEEEEE',
  ORANGE: '#F0B79C',
};
