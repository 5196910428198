import styled from 'styled-components';
import { DARK_CHARCOAL } from 'constants/colors';
import DEVICE from 'constants/deviceSizes';

export const PopupContainer = styled.div`
  color: ${DARK_CHARCOAL};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 68px;
  width: 780px;

  @media ${DEVICE.tablet} {
    height: 100%;
    margin: 0 20px;
    width: 500px;
  }

  @media ${DEVICE.formResponsive} {
    margin: 0 20px;
    width: 335px;
  }
`;

export const PopupImage = styled.img`
  width: 90%;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 20px;
`;

export const PopupContentContainer = styled.div`
  min-width: 780px;
  display: flex;
  flex-direction: column;

  @media ${DEVICE.tablet} {
    min-width: auto;
  }
`;

export const PopupTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PopupTitle = styled.h3`
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  margin: 0;
`;

export const PopupName = styled.h3`
  font-size: 24px;
  line-height: 36px;
  text-align: center;
`;

export const PopupContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 15px 0;

  @media ${DEVICE.formResponsive} {
    flex-direction: column;
  }
`;

export const PopupColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 15px 0;
`;

export const PopupSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 350px;
`;

export const PopupSubSectionName = styled.span`
  color: ${DARK_CHARCOAL};
  font-weight: 600;
  font-size: 12px;
  line-height: 30px;
`;

export const PopupSectionParagraph = styled.p`
  font-size: 12px;
  line-height: 24px;
  margin: 0;
  word-break: break-all;
  white-space: normal;
`;

export const PopupExternalLink = styled.a`
  color: ${DARK_CHARCOAL};
  font-size: 12px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline !important;
  white-space: normal;
`;

export const PopupDate = styled(PopupSectionParagraph)`
  color: ${(props) => props.Color};
`;

export const PopupSingleCategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  gap: 5px;
`;

export const PopupMultiCategoriesContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export const PopupCategory = styled.span`
  background-color: ${(props) => props.BgColor};
  border-radius: ${(props) => props.BorderRadius};
  color: #212121;
  font-size: 12px;
  max-width: 20vw;
  line-height: 22px;
  padding: ${(props) => props.Padding};

  @media ${DEVICE.laptop} {
    max-width: 35vw;
  }

  @media ${DEVICE.tablet} {
    max-width: 40vw;
  }

  @media ${DEVICE.formResponsive} {
    max-width: fit-content;
  }
`;

export const PopupDescriptionSection = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 28px;
  width: 100%;
`;

export const PopupDescriptionParagraph = styled.p`
  margin: 0;
  max-width: 60vw;
  line-height: 22px;
  text-align: left;

  @media ${DEVICE.laptop} {
    max-width: 80vw;
  }

  @media ${DEVICE.tablet} {
    max-width: 60vw;
  }

  @media ${DEVICE.formResponsive} {
    max-width: 80vw;
  }
`;

export const PopupButtonContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  justify-content: space-between;

  @media ${DEVICE.tablet} {
    margin-top: 20px;
  }

  @media ${DEVICE.formResponsive} {
    align-items: center;
  }
`;
