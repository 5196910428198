import PropTypes from 'prop-types';
import {
  RowsPerPageLabel,
  RowsPerPageWrapper,
  StyledSelect,
} from 'pages/AdminPage/components/AdminTable/AdditionalElements/Pagination/PageSelector/PageSelector.styled';

const PageSelector = ({ limit, onChangeHandler, limitOptions }) => (
  <RowsPerPageWrapper>
    <RowsPerPageLabel htmlFor="items-per-page">
      Rows per page:{' '}
      <StyledSelect
        id="items-per-page"
        value={limit}
        onChange={onChangeHandler}
      >
        {limitOptions.map((value) => (
          <option value={value} key={value}>
            {value}
          </option>
        ))}
      </StyledSelect>
    </RowsPerPageLabel>
  </RowsPerPageWrapper>
);

PageSelector.propTypes = {
  limit: PropTypes.number.isRequired,
  limitOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChangeHandler: PropTypes.func.isRequired,
};

export default PageSelector;
