/* eslint-disable react/prop-types */
import { useTable, useRowSelect } from 'react-table';
import {
  Table,
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TR,
} from 'pages/AdminPage/components/AdminTable/TableElements/TableElements.styled';
import isRowSelected from 'pages/AdminPage/components/AdminTable/utils';

const AdminTable = ({
  columns,
  data,
  ControlSelected,
  handleRowClick = () => {},
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
    },
    useRowSelect,
  );

  const deletedStyle = {
    textDecoration: 'line-through',
    opacity: 0.5,
  };

  return (
    <TableWrapper>
      <Table {...getTableProps()}>
        <THead>
          {headerGroups.map((headerGroup) => (
            <TR {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TH {...column.getHeaderProps()} width={column.width}>
                  {column.render('Header')}
                </TH>
              ))}
            </TR>
          ))}
        </THead>
        <TBody {...getTableBodyProps()}>
          {!!selectedFlatRows.length && (
            <ControlSelected selectedFlatRows={selectedFlatRows} />
          )}
          {rows.map((row) => {
            prepareRow(row);
            const rowProps = row.getRowProps();

            return (
              <TR
                {...rowProps}
                style={row.original?.deletedAt && { ...deletedStyle }}
                isSelected={isRowSelected(rowProps.key, selectedRowIds)}
                onClick={() => handleRowClick(row.original)}
              >
                {row.cells.map((cell) => (
                  <TD {...cell.getCellProps()}>{cell.render('Cell')}</TD>
                ))}
              </TR>
            );
          })}
        </TBody>
      </Table>
    </TableWrapper>
  );
};

export default AdminTable;
