import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import DeleteSelected from 'pages/AdminPage/components/AdminTable/AdditionalElements/DeleteSelected/DeleteSelected';
import RestoreSelected from 'pages/AdminPage/components/AdminTable/AdditionalElements/RestoreSelected/RestoreSelected';

const CompanyControlSelected = ({ selectedFlatRows }) => {
  const navigate = useNavigate();
  const ids = selectedFlatRows.map((row) => row.original.id);
  const deletedRows = selectedFlatRows.filter((row) => row.original.deletedAt);

  const openDeleteModalWithIds = () =>
    navigate('/administration/companies/delete', { state: { ids } });

  const handleRestore = () => {
    const deletedIds = deletedRows.map((row) => row.original.id);
    navigate('/administration/companies/restore', {
      state: { ids: deletedIds },
    });
  };

  // if user selects one for deletion and one for restore don't show any buttons
  // to avoid confusion.
  if (deletedRows.length > 0 && deletedRows.length === ids.length) {
    return <RestoreSelected colSpan="6" callBackFunc={handleRestore} />;
  }

  if (deletedRows.length === 0 && ids.length > 0) {
    return <DeleteSelected colSpan="6" callBackFunc={openDeleteModalWithIds} />;
  }

  return null;
};

CompanyControlSelected.propTypes = {
  selectedFlatRows: PropTypes.arrayOf(Object).isRequired,
};

export default CompanyControlSelected;
