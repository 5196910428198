export const SIGN_IN = 'Sign in';
export const SIGN_UP = 'Sign up';

export const SIGN_IN_DONT_HAVE = 'Don’t have an account?';
export const SIGN_UP_ALREADY_HAVE = 'Already have an account?';

export const SIGN_IN_FIELD_NAME_KEYS = {
  HBS_EMAIL: 'hbsEmail',
  PASSWORD: 'password',
};

export const SIGN_UP_FIELD_NAME_KEYS = {
  FULL_NAME: 'fullName',
  HBS_EMAIL: 'hbsEmail',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirm_password',
};

export const SUBMIT_BUTTON_CSS = {
  Border: 'none',
  Height: '40px',
  FontSize: '14px',
  FontWeight: '600px',
  responsiveHeight: '40px',
  type: 'submit',
};

export const INITIAL_ALERT_STATE = {
  shouldLoadAlert: false,
  alertText: '',
};
