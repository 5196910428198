import styled from 'styled-components';
import Masonry from 'react-masonry-css';

export const InvestorPreview = styled.span`
  font-size: 8px;
  color: var(--WHITE_OR_RAISING_BLACK);
`;

export const breakpointCols = {
  default: 8,
  2700: 7,
  2250: 6,
  1950: 5,
  1600: 4,
  1350: 3,
  1100: 2,
  700: 1,
};

export const InvestorListContainer = styled(Masonry).attrs({
  breakpointCols,
  columnClassName: 'investor-list-column',
})`
  display: flex;
  margin-left: -20px;
  width: auto;
  .investor-list-column {
    padding-left: 20px;
    background-clip: padding-box;
  }
  div > div {
    transition: all 0.5s ease;
  }
`;

export const LoadMoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
`;

export const InvestorCountPreview = styled.span`
  font-size: 8px;
  color: var(--WHITE_OR_RAISING_BLACK);
`;
