import { useState } from 'react';
import { EXCLUSIVE_KEYS } from 'pages/AdminPage/components/Forms/ExclusiveResource/EditExclusiveResource/constants';

const INITIAL_EXCLUSIVE_RESOURCE_VALIDATION_ERRORS = {
  [EXCLUSIVE_KEYS.CATEGORIES]: false,
  [EXCLUSIVE_KEYS.COMPANY_DESCRIPTION]: false,
  [EXCLUSIVE_KEYS.COMPANY_LOGO]: false,
  [EXCLUSIVE_KEYS.CONTACT_NAME]: false,
  [EXCLUSIVE_KEYS.CONTACT_EMAIL]: false,
  [EXCLUSIVE_KEYS.COMPANY_WEBSITE]: false,
  [EXCLUSIVE_KEYS.COMPANY_NAME]: false,
  [EXCLUSIVE_KEYS.DEAL_DESCRIPTION]: false,
  [EXCLUSIVE_KEYS.EXPIRING_DATE]: false,
  [EXCLUSIVE_KEYS.PROMOTION]: false,
};

const useExclusiveResourceValidationErrors = () => {
  const [validationErrors, setValidationErrors] = useState(
    INITIAL_EXCLUSIVE_RESOURCE_VALIDATION_ERRORS,
  );

  const setValidationError = (key, value) =>
    setValidationErrors((prevState) => ({ ...prevState, [key]: value }));

  return { validationErrors, setValidationError };
};

export default useExclusiveResourceValidationErrors;
