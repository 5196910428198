import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Controls from 'pages/AdminPage/components/AdminTable/AdditionalElements/Controls/Controls';

const ControlCell = ({
  isDeleted,
  id,
  deleteWithIdPath,
  editWithIdPath,
  restoreWithIdPath,
}) => {
  const navigate = useNavigate();

  // TODO: See to make one higher order function to replace these
  const openDeleteModalForId = (e) => {
    e.stopPropagation();

    navigate(deleteWithIdPath, {
      state: { ids: [id] },
    });
  };

  const openEditModalForId = (e) => {
    e.stopPropagation();

    navigate(editWithIdPath, { state: { ids: [id] } });
  };

  const handleRestore = (e) => {
    e.stopPropagation();

    navigate(restoreWithIdPath, {
      state: { ids: [id] },
    });
  };

  return (
    <Controls
      openDeleteModalForId={openDeleteModalForId}
      openEditModalForId={openEditModalForId}
      handleRestore={handleRestore}
      isDeleted={isDeleted}
    />
  );
};

ControlCell.propTypes = {
  id: PropTypes.number.isRequired,
  isDeleted: PropTypes.bool.isRequired,
  deleteWithIdPath: PropTypes.string.isRequired,
  editWithIdPath: PropTypes.string.isRequired,
  restoreWithIdPath: PropTypes.string.isRequired,
};

export default ControlCell;
