import styled from 'styled-components';
import { LIGHT_GRAY, DAVY_GRAY } from 'constants/colors';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Paragraph = styled.p`
  margin: 0;
  font-size: ${(props) => (props.small ? '7px' : '10px')};
  padding-top: ${(props) => (props.spacingTop ? '10px' : 0)};
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
  width: 35px;
  height: 12px;
  padding: 10px 10px 0;
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 12px;
  left: 10px;
  width: 35px;
  height: 12px;
  background: ${LIGHT_GRAY};
  border-radius: 13px;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    border-radius: 13px;
    width: 18px;
    height: 12px;
    background: ${DAVY_GRAY};
    transition: 0.2s;
  }
`;

export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 35px;
  height: 12px;

  &:checked + ${CheckBoxLabel} {
    background: ${LIGHT_GRAY};

    &::after {
      content: '';
      display: block;
      border-radius: 13px;
      width: 18px;
      height: 12px;
      margin-left: 17px;
      transition: 0.2s;
    }
  }
`;
