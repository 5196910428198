import Path from './Icons.styled';

const SymphonyLogo = () => (
  <svg
    width="195"
    height="38"
    viewBox="0 0 195 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_12592)">
      <Path
        d="M115.38 8.51129L109.721 14.2409V13.0253L114.126 8.52899C113.749 8.56239 113.374 8.62151 113.005 8.70594L109.721 12.0414V10.8258L111.168 9.37835C110.653 9.63315 110.084 9.93751 109.723 10.2861V0H104.677V28.0483H109.721V15.2177L116.24 8.60331C115.955 8.55797 115.668 8.52726 115.38 8.51129Z"
        fill="black"
      />
      <Path
        d="M117.158 8.82629L112.415 13.5809C112.825 13.435 113.25 13.3376 113.682 13.2907L117.884 9.07048C117.642 8.98024 117.399 8.8953 117.158 8.82629Z"
        fill="black"
      />
      <Path
        d="M118.722 9.44403L114.88 13.3033C115.152 13.3374 115.42 13.3967 115.681 13.4803L119.351 9.79439C119.147 9.66876 118.937 9.55551 118.722 9.44403Z"
        fill="black"
      />
      <Path
        d="M120.066 10.2911L116.515 13.8584C116.713 13.9808 116.898 14.1243 117.066 14.2866L120.59 10.7476C120.428 10.583 120.251 10.4397 120.066 10.2911Z"
        fill="black"
      />
      <Path
        d="M121.416 11.5918C121.348 11.5086 121.275 11.429 121.203 11.3476L117.611 14.9574C117.738 15.1673 117.845 15.3896 117.928 15.6209L121.651 11.882C121.575 11.7829 121.497 11.6873 121.416 11.5918Z"
        fill="black"
      />
      <Path
        d="M122.137 12.6092L118.167 16.5959C118.211 16.8944 118.238 17.1951 118.249 17.4965L122.477 13.2497C122.374 13.0305 122.261 12.8167 122.137 12.6092Z"
        fill="black"
      />
      <Path
        d="M118.37 19.6854L123.093 14.8848C123.03 14.6176 122.984 14.3539 122.903 14.1204L118.37 18.7034V19.6854Z"
        fill="black"
      />
      <Path
        d="M118.37 21.8836L123.386 16.7892C123.361 16.4813 123.356 16.1875 123.315 15.9044L118.37 20.8997V21.8836Z"
        fill="black"
      />
      <Path
        d="M118.37 24.0829L123.414 18.9336V18.3408C123.414 18.2099 123.456 18.0825 123.453 17.9551L118.37 23.1061V24.0829Z"
        fill="black"
      />
      <Path
        d="M118.37 26.2802L123.414 21.1239V20.1418L118.37 25.2982V26.2802Z"
        fill="black"
      />
      <Path
        d="M118.37 28.0485H118.603L123.414 23.3222V22.3401L118.37 27.4964V28.0485Z"
        fill="black"
      />
      <Path
        d="M120.793 28.049L123.414 25.5203V24.5383L119.813 28.049H120.793Z"
        fill="black"
      />
      <Path
        d="M122.98 28.048L123.414 27.7188V26.735L122.002 28.048H122.98Z"
        fill="black"
      />
      <Path
        d="M132.593 19.7071L128.707 23.6106C128.824 23.8159 128.951 24.0158 129.087 24.2105L132.808 20.4733C132.719 20.2232 132.647 19.9672 132.593 19.7071Z"
        fill="black"
      />
      <Path
        d="M133.19 21.3046L129.608 24.902C129.756 25.0789 129.913 25.2559 130.072 25.4186L133.585 21.892C133.44 21.7056 133.308 21.5094 133.19 21.3046Z"
        fill="black"
      />
      <Path
        d="M134.17 22.5211L130.692 26.0123C130.868 26.1645 131.044 26.3113 131.231 26.4529L134.721 22.9475C134.527 22.8188 134.343 22.6763 134.17 22.5211Z"
        fill="black"
      />
      <Path
        d="M135.51 23.3718L131.946 26.9497C132.149 27.0771 132.353 27.1992 132.565 27.3036L136.245 23.6053C135.994 23.5475 135.749 23.4693 135.51 23.3718Z"
        fill="black"
      />
      <Path
        d="M137.529 23.7681C137.455 23.7681 137.381 23.7681 137.309 23.7681L133.386 27.707C133.618 27.8061 133.856 27.8946 134.09 27.976L138.34 23.7115C138.071 23.7504 137.801 23.7693 137.529 23.7681Z"
        fill="black"
      />
      <Path
        d="M137.529 8.47198C131.742 8.47198 127.369 13.0284 127.343 18.4573C127.343 18.8112 127.357 18.9881 127.378 19.2589L128.176 18.4573H129.386L127.517 20.3719C127.568 20.6426 127.628 20.8903 127.693 21.1522L130.371 18.4573H131.582L128.005 22.0883C128.093 22.3242 128.19 22.5543 128.296 22.7784L132.485 18.5794C132.485 18.5528 132.485 18.4661 132.485 18.4661C132.508 15.7517 134.554 13.2408 137.536 13.2408C140.519 13.2408 142.559 15.7482 142.582 18.4573C142.584 18.7918 142.555 19.1258 142.498 19.4553L143.493 18.4573H144.703L141.752 21.4654C141.369 22.0811 140.854 22.6038 140.246 22.996L135.036 28.2373C135.306 28.301 135.579 28.3222 135.857 28.3647L145.681 18.4643H146.891L136.955 28.4815C137.145 28.4921 137.336 28.5151 137.528 28.5151C137.663 28.5151 137.795 28.5151 137.928 28.5151L147.712 18.6555C147.712 18.6024 147.712 18.6448 147.712 18.4643C147.691 13.0284 143.319 8.47198 137.529 8.47198Z"
        fill="black"
      />
      <Path
        d="M140.526 28.1087L147.348 21.2554C147.462 20.8486 147.55 20.4349 147.612 20.0168L139.268 28.3883C139.693 28.321 140.113 28.2277 140.526 28.1087Z"
        fill="black"
      />
      <Path
        d="M146.537 23.2864L142.573 27.2677C144.251 26.3554 145.629 24.972 146.537 23.2864Z"
        fill="black"
      />
      <Path
        d="M44.3487 8.86737L42.6997 10.6192V11.6012L45.3265 8.86737H44.3487Z"
        fill="black"
      />
      <Path
        d="M46.5369 8.86737L42.6997 12.8169V13.8007L47.5146 8.86737H46.5369Z"
        fill="black"
      />
      <Path
        d="M47.5679 10.4401V10.0579L42.7036 15.0125V15.9945L49.3983 9.23859C48.7588 9.54294 48.2849 9.94108 47.5679 10.4401Z"
        fill="black"
      />
      <Path
        d="M51.202 8.6509L42.6997 17.2135V18.1956L52.312 8.51288C51.9392 8.53225 51.5683 8.57838 51.202 8.6509Z"
        fill="black"
      />
      <Path
        d="M42.6997 8.86737V9.40353L43.1384 8.86737H42.6997Z"
        fill="black"
      />
      <Path
        d="M55.2914 8.89526L50.7002 13.5066C51.0926 13.3766 51.4999 13.2975 51.9123 13.2712L56.0067 9.15715C55.7741 9.05983 55.5345 8.97312 55.2914 8.89526Z"
        fill="black"
      />
      <Path
        d="M56.8225 9.55573L53.0576 13.3354C53.3174 13.3835 53.5718 13.4576 53.8169 13.5566L57.4339 9.92909C57.233 9.79638 57.0304 9.67074 56.8225 9.55573Z"
        fill="black"
      />
      <Path
        d="M58.1207 10.4482L54.5972 13.9872C54.784 14.1216 54.9559 14.2759 55.1098 14.4473L58.6334 10.9083C58.4695 10.7526 58.2969 10.5969 58.1207 10.4482Z"
        fill="black"
      />
      <Path
        d="M60.1064 10.6523L55.6016 15.177C55.713 15.4069 55.801 15.6476 55.8641 15.8954L62.5429 9.20135C61.6652 9.56416 60.8445 10.0529 60.1064 10.6523Z"
        fill="black"
      />
      <Path
        d="M56.2131 17.7394V17.9004L65.4765 8.52209C65.0832 8.54664 64.6919 8.59629 64.3049 8.67073L56.125 16.9467C56.1444 17.1891 56.2131 17.451 56.2131 17.7394Z"
        fill="black"
      />
      <Path
        d="M68.4625 8.84705L63.6528 13.6831C64.0862 13.4967 64.5424 13.3693 65.0094 13.3044L69.1936 9.10185C68.954 9.00807 68.7091 8.92667 68.4625 8.84705Z"
        fill="black"
      />
      <Path
        d="M59.8052 18.4571L61.1653 17.1282C61.1459 16.8115 61.1195 16.4894 61.0878 16.2027L58.8256 18.4571H57.61L67.476 8.59036C67.187 8.54081 66.8963 8.48773 66.5951 8.46649L56.6287 18.4571H56.0244V28.0478H61.2569V18.3545L61.0895 18.4571H59.8052Z"
        fill="black"
      />
      <Path
        d="M70.0324 9.47571L66.2305 13.2943C66.5016 13.3269 66.7689 13.3862 67.0286 13.4712L70.6596 9.82253C70.457 9.7022 70.2473 9.58542 70.0324 9.47571Z"
        fill="black"
      />
      <Path
        d="M71.3781 10.3213L67.8545 13.8603C68.0512 13.9851 68.2343 14.1304 68.4006 14.2938L71.9136 10.7654C71.7375 10.6168 71.5613 10.4646 71.3781 10.3213Z"
        fill="black"
      />
      <Path
        d="M72.6839 11.592C72.6276 11.5212 72.5659 11.454 72.5078 11.3867L68.9243 14.9841C69.0491 15.1993 69.1496 15.4278 69.2238 15.6654L72.9376 11.9317C72.8583 11.8149 72.7738 11.7017 72.6839 11.592Z"
        fill="black"
      />
      <Path
        d="M73.4184 12.6703L69.4297 16.6765C69.4632 16.985 69.4809 17.2951 69.4825 17.6055L73.7478 13.3215C73.6421 13.095 73.5364 12.8792 73.4184 12.6703Z"
        fill="black"
      />
      <Path
        d="M72.9372 18.4578L74.5845 16.8405C74.5633 16.5326 74.5334 16.2176 74.4929 15.931L71.9594 18.4578H70.7491L74.3026 14.9188C74.2409 14.6516 74.1687 14.3774 74.0894 14.1314L69.766 18.4578H69.5405V28.0485H74.5862V18.4578H74.7624V18.0579L74.1387 18.4578H72.9372Z"
        fill="black"
      />
      <Path
        d="M44.4849 18.4575L54.3244 8.61196C54.0397 8.55194 53.7521 8.50647 53.4628 8.47571L43.5159 18.4575H42.709V28.0481H47.7441V18.4575H46.673L47.9203 17.4365V16.4562L45.6899 18.461L44.4849 18.4575Z"
        fill="black"
      />
      <Path
        d="M152.061 8.86737V9.48492L152.651 8.86737H152.061Z"
        fill="black"
      />
      <Path
        d="M153.861 8.86737L152.061 10.7006V11.6826L154.839 8.86737H153.861Z"
        fill="black"
      />
      <Path
        d="M157.104 8.86737H156.049L152.061 12.8983V13.8804L157.104 8.92222V8.86737Z"
        fill="black"
      />
      <Path
        d="M157.104 10.4398V10.1372L152.061 15.0918V16.0756L158.875 9.28961C158.237 9.59257 157.641 9.97934 157.104 10.4398Z"
        fill="black"
      />
      <Path
        d="M160.63 8.68135L152.061 17.2953V18.2773L161.778 8.52209C161.393 8.55024 161.009 8.60344 160.63 8.68135Z"
        fill="black"
      />
      <Path
        d="M164.851 8.84705L160.186 13.5327C160.585 13.3997 160.999 13.3142 161.419 13.2779L165.582 9.09655C165.34 9.00276 165.099 8.9196 164.851 8.84705Z"
        fill="black"
      />
      <Path
        d="M166.414 9.47571L162.592 13.3138C162.86 13.3532 163.124 13.4172 163.38 13.5049L167.035 9.83315C166.84 9.70574 166.627 9.58719 166.414 9.47571Z"
        fill="black"
      />
      <Path
        d="M167.747 10.3342L164.197 13.8962C164.393 14.0219 164.575 14.1684 164.74 14.3333L168.263 10.7943C168.103 10.6315 167.93 10.4781 167.747 10.3342Z"
        fill="black"
      />
      <Path
        d="M169.044 11.5924C168.989 11.5269 168.931 11.4632 168.868 11.3995L165.272 15.0182C165.397 15.2318 165.501 15.4577 165.58 15.6923L169.317 11.9375C169.229 11.8207 169.139 11.7039 169.044 11.5924Z"
        fill="black"
      />
      <Path
        d="M169.8 12.6703L165.808 16.68C165.847 16.9818 165.871 17.2853 165.88 17.5896L170.133 13.318C170.033 13.0964 169.922 12.8802 169.8 12.6703Z"
        fill="black"
      />
      <Path
        d="M170.527 18.4577H169.317L170.964 16.8403C170.941 16.5307 170.91 16.2157 170.871 15.9326L168.339 18.4577H167.129L170.682 14.9187C170.621 14.6497 170.55 14.3736 170.471 14.1295L166.151 18.4506H165.933V28.0413H171.158V18.3267C171.158 18.2276 171.086 18.0949 171.084 17.9976L170.527 18.4577Z"
        fill="black"
      />
      <Path
        d="M153.996 18.4582L163.862 8.58267C163.575 8.53489 163.281 8.48358 162.982 8.46411L153.012 18.4582H152.053V28.0489H157.279V18.4582H156.179L157.286 17.5186V16.5348L155.212 18.4582H153.996Z"
        fill="black"
      />
      <Path
        d="M189.789 8.89526L189.739 9.0032L189.849 8.89526H189.789Z"
        fill="black"
      />
      <Path
        d="M191.059 8.86737L188.77 11.1783L187.988 12.9408L192.037 8.86737H191.059Z"
        fill="black"
      />
      <Path
        d="M193.247 8.86737L187.019 15.1367L186.237 16.8991L194.225 8.86737H193.247Z"
        fill="black"
      />
      <Path
        d="M194.183 11.1359L195 9.33275L185.088 19.2879L184.6 20.3868L179.007 8.86737H173.374H173.36L181.93 26.1748L182.411 25.1503L192.358 15.165L193.173 13.3637L183.276 23.3065L184.343 21.0185L194.183 11.1359Z"
        fill="black"
      />
      <Path
        d="M180.725 29.0493L190.531 19.2003L191.346 17.3972L181.551 27.2356L180.725 29.0493Z"
        fill="black"
      />
      <Path
        d="M178.876 33.1034L188.704 23.2331L189.519 21.43L179.703 31.2914L178.876 33.1034Z"
        fill="black"
      />
      <Path
        d="M177.026 37.1603L186.876 27.267L187.692 25.4656L177.853 35.3465L177.026 37.1603Z"
        fill="black"
      />
      <Path
        d="M178.365 38.0002L185.05 31.2938L185.865 29.489L177.388 38.0002H178.365Z"
        fill="black"
      />
      <Path
        d="M180.555 38L183.223 35.3263L184.038 33.5214L179.576 38H180.555Z"
        fill="black"
      />
      <Path d="M182.009 38L182.213 37.5505L181.764 38H182.009Z" fill="black" />
      <Path
        d="M7.73047 8.5144L7.94717 8.50378C7.86965 8.50555 7.75161 8.50909 7.73047 8.5144Z"
        fill="white"
      />
      <Path
        d="M8.10432 12.8839C8.26112 12.8697 8.42144 12.8609 8.58705 12.8609C8.71389 12.8609 8.83898 12.8609 8.96407 12.8768L12.5087 9.31304C12.2727 9.22633 12.0295 9.13609 11.7776 9.06531L7.96338 12.9016L8.10432 12.8839Z"
        fill="black"
      />
      <Path
        d="M10.724 13.3082L14.0132 10.0045C13.8142 9.89129 13.5992 9.77804 13.3684 9.6701L9.99463 13.0587C10.2426 13.1269 10.4861 13.2103 10.724 13.3082Z"
        fill="black"
      />
      <Path
        d="M11.5396 13.7069C11.7492 13.8255 11.95 13.9529 12.1403 14.0856L12.6424 13.5813L14.4465 10.7819L11.5396 13.7069Z"
        fill="black"
      />
      <Path
        d="M12.3006 17.7092L12.0593 17.5782L8.71191 20.9403L8.74186 20.9562L8.93566 21.0518L9.02199 21.0978C9.10479 21.1438 9.18231 21.1898 9.25806 21.2358L9.31444 21.2747L9.34087 21.2924L12.6882 17.9304C12.579 17.8596 12.468 17.7924 12.3535 17.7269L12.3006 17.7092Z"
        fill="black"
      />
      <Path
        d="M13.8863 18.9331C13.7612 18.8004 13.6308 18.6712 13.4934 18.5509L13.4353 18.5031L13.3807 18.4589L9.94873 21.897C9.97616 21.9415 10.0009 21.9875 10.0227 22.035C10.0237 22.0415 10.0237 22.048 10.0227 22.0545C10.0491 22.1169 10.0703 22.1814 10.0861 22.2474C10.0861 22.2668 10.095 22.2863 10.0985 22.3058C10.1133 22.3821 10.121 22.4597 10.1214 22.5376C10.1216 22.5967 10.118 22.6558 10.1108 22.7145L13.8863 18.9331Z"
        fill="black"
      />
      <Path
        d="M15.1706 21.5014L15.1512 21.3758C15.1336 21.2908 15.116 21.1988 15.0949 21.1263L7.7518 28.5051V27.3956L14.8324 20.2309C14.8006 20.1601 14.7372 20.0911 14.702 20.0239C14.6914 20.0009 14.6703 19.9779 14.6579 19.9566C14.591 19.8328 14.5663 19.7142 14.4818 19.6027L7.7518 26.4135V25.1979L8.90752 23.9822C8.54218 24.1034 8.15943 24.1632 7.7747 24.1592L7.7518 24.2105V24.1592C5.7698 24.1486 3.80014 23.4372 2.507 22.2552L0 25.9499C2.24978 27.6065 4.96225 28.5087 7.7518 28.5281C8.09023 28.5256 8.42833 28.5061 8.76482 28.4697L15.2358 21.9721C15.2358 21.9438 15.2358 21.9172 15.2358 21.8907C15.2135 21.7574 15.1918 21.6276 15.1706 21.5014Z"
        fill="black"
      />
      <Path
        d="M11.7754 27.6397L14.9043 24.4971C15.0696 24.0783 15.178 23.6391 15.2267 23.1912L10.2427 28.1971C10.7697 28.0594 11.2828 27.8728 11.7754 27.6397Z"
        fill="black"
      />
      <Path
        d="M7.73591 20.5268C7.73303 20.5252 7.73058 20.5229 7.72874 20.5201C7.72691 20.5173 7.72574 20.5142 7.72534 20.5109C7.72534 20.5109 7.72534 20.5215 7.73944 20.5268H7.75706C7.78815 20.5421 7.82059 20.5546 7.85395 20.564L7.89447 20.5781L7.96847 20.6029L7.89624 20.5763L11.2753 17.186C11.0445 17.0869 10.8243 16.9913 10.5917 16.9029L7.75177 19.819V18.6034L9.70734 16.5808C9.45893 16.4959 9.24047 16.4145 9.00263 16.3349L7.75177 17.6213V16.4056L8.11822 16.0517C8.0002 15.9808 7.86765 15.9379 7.73063 15.9261C6.65066 15.5722 6.08337 15.1033 6.08337 14.4291C6.08337 13.6718 6.67004 13.148 7.75177 12.9551V12.0102L10.8772 8.81271C10.6076 8.75078 10.3627 8.69593 10.0861 8.65169L7.75177 11.0264V9.81071L8.98501 8.52782C8.74189 8.51013 8.4741 8.49951 8.23273 8.49951H8.04423L7.75177 8.82864V8.51721C7.75177 8.51721 7.7412 8.51721 7.75177 8.51721C4.14542 8.70831 0.880859 10.9715 0.880859 14.4875C0.880859 18.5945 4.98579 19.5217 7.73591 20.5268Z"
        fill="black"
      />
      <Path
        d="M90.7137 8.4938C88.7997 8.47063 86.9315 9.082 85.3984 10.2332V10.0863L80.3545 15.2462V16.2283L85.3984 11.072V12.2876L80.3545 17.4439V18.426L85.3984 13.2714V14.4871L80.3545 19.6416V20.6255L85.3984 15.4692V15.4532C86.4802 14.2146 88.2525 13.2573 90.0425 13.2573C93.0375 13.2573 94.9755 15.7558 94.9755 18.5322C94.9755 21.5403 92.819 23.7681 89.9368 23.7681C88.1944 23.7681 86.4872 22.7613 85.4055 21.5191V21.0802L80.3545 26.2366V27.2186L85.3984 22.0623V23.278L80.3545 28.4343V29.4164L85.3984 24.26V25.481L80.3545 30.632V31.6158L85.3984 26.4595V26.8364C86.8413 27.9866 88.5326 28.5281 90.4371 28.5281C93.2806 28.5281 95.9955 27.2593 97.6921 25.2757C99.2752 23.3909 100.132 20.998 100.107 18.5322C100.107 12.8609 95.9268 8.4938 90.7137 8.4938Z"
        fill="black"
      />
      <Path
        d="M80.3545 9.22754V9.63275L80.6787 9.22754H80.3545Z"
        fill="black"
      />
      <Path
        d="M81.889 9.22754L80.3545 10.8484V11.8322L82.8668 9.22754H81.889Z"
        fill="black"
      />
      <Path
        d="M84.0771 9.22754L80.3545 13.0461V14.03L85.0549 9.22754H84.0771Z"
        fill="black"
      />
      <Path
        d="M85.3985 16.6844L80.3545 21.8389V22.8228L85.3985 17.6665V16.6844Z"
        fill="black"
      />
      <Path
        d="M85.3985 19.4894V18.8825L80.3545 24.0388V25.0209L85.3985 19.8646V19.4894Z"
        fill="black"
      />
      <Path
        d="M80.3545 33.8134L85.3985 28.6571V27.675L80.3545 32.8313V33.8134Z"
        fill="black"
      />
      <Path
        d="M80.3545 36.0115L85.3985 30.8552V29.8731L80.3545 35.0294V36.0115Z"
        fill="black"
      />
      <Path
        d="M80.3545 37.2759H81.0909L85.3985 33.0521V32.07L80.3545 37.2263V37.2759Z"
        fill="black"
      />
      <Path
        d="M83.2791 37.2763L85.3985 35.252V34.2681L82.3013 37.2763H83.2791Z"
        fill="black"
      />
      <Path
        d="M85.3983 37.2765V36.4678L84.4893 37.2765H85.3983Z"
        fill="black"
      />
      <Path
        d="M33.863 8.89526L33.8154 9.0032L33.9229 8.89526H33.863Z"
        fill="white"
      />
      <Path
        d="M35.1333 8.86737L32.8465 11.1783L32.0625 12.9408L36.1128 8.86737H35.1333Z"
        fill="black"
      />
      <Path
        d="M37.3234 8.86737L31.0955 15.1367L30.3115 16.8991L38.3012 8.86737H37.3234Z"
        fill="black"
      />
      <Path
        d="M28.6766 20.3868L23.083 8.86737H17.4489H17.4365L26.004 26.1748L26.4867 25.1503L36.4319 15.165L37.2477 13.3637L27.35 23.3065L28.4194 21.0185L38.2589 11.1359L39.0746 9.33275L29.1629 19.2879L28.6766 20.3868Z"
        fill="black"
      />
      <Path
        d="M24.7988 29.0493L34.6049 19.2003L35.4223 17.3972L25.6251 27.2356L24.7988 29.0493Z"
        fill="black"
      />
      <Path
        d="M22.9507 33.1034L32.7778 23.2331L33.5953 21.43L23.777 31.2914L22.9507 33.1034Z"
        fill="black"
      />
      <Path
        d="M21.1025 37.1603L30.9508 27.267L31.7683 25.4656L21.9288 35.3465L21.1025 37.1603Z"
        fill="black"
      />
      <Path
        d="M22.4416 38.0002L29.124 31.2938L29.9415 29.489L21.4639 38.0002H22.4416Z"
        fill="black"
      />
      <Path
        d="M24.6296 38L27.297 35.3263L28.1144 33.5214L23.6519 38H24.6296Z"
        fill="black"
      />
      <Path d="M26.083 38L26.2873 37.5505L25.8398 38H26.083Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_1_12592">
        <rect width="195" height="38" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SymphonyLogo;
