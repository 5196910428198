const investorFiltersSchema = {
  FULL_NAME: {
    label: 'Investor Name',
  },
  COMPANY_NAME: {
    label: 'VC Fund Name',
  },
  CATEGORY: {
    label: 'Category',
    placeHolder: 'All',
  },
  CHECK_SIZES: {
    label: 'Check size',
    placeHolder: 'All',
  },
  GEO_FOCUS: {
    label: 'Geographic focus',
    placeHolder: 'All',
  },
};

export default investorFiltersSchema;
