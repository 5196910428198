import PropTypes from 'prop-types';
import {
  ClearFiltersContainer,
  ClearFiltersButton,
} from 'pages/AdminPage/components/Filters/ClearFilters/ClearFilters.styled';

const ClearFilters = ({ resetFilters }) => (
  <ClearFiltersContainer>
    <ClearFiltersButton onClick={resetFilters}>
      Clear filters
    </ClearFiltersButton>
  </ClearFiltersContainer>
);

ClearFilters.propTypes = {
  resetFilters: PropTypes.func.isRequired,
};

export default ClearFilters;
