import { STEP_TWO_KEYS } from 'components/Forms/Company/StepTwo/constants';
import {
  isEmptyFieldValidator,
  isEmptyOrValidUrl,
  isTruthy,
} from 'utils/inputValidators';

export const CompanyInfoInputSchema = {
  [STEP_TWO_KEYS.COMPANY_NAME]: {
    label: 'Company name*',
    inputType: 'text',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter your company name',
    validator: isEmptyFieldValidator,
  },
  [STEP_TWO_KEYS.COMPANY_WEBSITE]: {
    label: 'Company website',
    inputType: 'text',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Link you have provided is not valid',
    validator: isEmptyOrValidUrl,
  },
};

export const CompanyInfoSelectSchema = {
  [STEP_TWO_KEYS.CATEGORIES]: {
    label: 'Category*',
    placeHolder: 'Pick up to 3 categories',
    MaxHeight: '110px',
    isError: false,
    disabledDarkMode: true,
    validator: isEmptyFieldValidator,
  },
  [STEP_TWO_KEYS.GEOGRAPHIC_FOCUS]: {
    label: 'Geographical focus',
    placeHolder: 'Pick up to 3 locations',
    MaxHeight: '110px',
    isError: false,
    disabledDarkMode: true,
    validator: () => true,
  },
  [STEP_TWO_KEYS.COMPANY_CARD_COLOR]: {
    label: 'Color of the card*',
    placeHolder: 'Select a card color',
    MaxHeight: '110px',
    isError: false,
    validator: isTruthy,
  },
  [STEP_TWO_KEYS.COMPANY_STAGE]: {
    label: 'Company stage*',
    placeHolder: 'Select a company stage',
    MaxHeight: '110px',
    isError: false,
    validator: isTruthy,
  },
  [STEP_TWO_KEYS.FUNDING_STAGE]: {
    label: 'Funding stage*',
    placeHolder: 'Select a funding stage',
    MaxHeight: '150px',
    isError: false,
    validator: isTruthy,
  },
  [STEP_TWO_KEYS.FOUNDING_YEAR]: {
    label: 'Founding year*',
    placeHolder: 'Select a founding year',
    MaxHeight: '110px',
    isError: false,
    validator: isTruthy,
  },
};

export const CompanyInfoDescriptionSchema = {
  [STEP_TWO_KEYS.COMPANY_DESCRIPTION]: {
    label: 'Description*',
    placeholder: 'Enter your company description...',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Please enter your description',
    validator: isEmptyFieldValidator,
    RowNum: 7,
  },
};
