import styled from 'styled-components';

const EyeContainer = styled.div`
  display: flex;
  position: absolute;
  right: ${(props) => (props.isError ? '35px' : '10px')};
  top: 40px;
`;

export default EyeContainer;
