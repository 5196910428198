import styled from 'styled-components';
import { DAVY_GRAY, RAISIN_BLACK } from 'constants/colors';
import DEVICE from 'constants/deviceSizes';

export const Wrapper = styled.div`
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 200px;
  margin: 0 72px;
  gap: 16px 0;
  justify-content: space-between;
  width: 520px;

  @media ${DEVICE.tablet} {
    justify-content: center;
    height: 80vh;
    margin: 0 42px;
    width: 520px;
  }

  @media ${DEVICE.formResponsive} {
    margin: 0 20px;
    width: 335px;
  }
`;

export const Title = styled.h1`
  color: ${DAVY_GRAY};
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
`;

export const DescriptionParagraph = styled.p`
  color: ${RAISIN_BLACK};
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 16px;
  max-width: 700px;
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  align-self: flex-end;
  display: flex;
  justify-content: center;
  justify-self: flex-end;
  margin-bottom: 20px;
  gap: 16px;
`;

export const BUTTONS_CSS = {
  primary: {
    Height: '40px',
    FontWeight: '500',
    FontSize: '14px',
    Width: '110px',
    Border: 'none',
    type: 'submit',
  },
  secondary: {
    Border: `1px solid ${DAVY_GRAY}`,
    Width: '95px',
    Height: '40px',
    FontWeight: '500',
    FontSize: '14px',
    type: 'button',
    noDark: true,
  },
};
