import PropTypes from 'prop-types';
import {
  FormControl,
  InputFieldAndButtonWrapper,
  InputHelperText,
  InputLabel,
  TextArea,
} from 'components/common/FormComponents/Input/Input.styled';
import InputErrorIcon from 'assets/icons/InputErrorIcon';
import Spacer from 'components/common/Spacer/Spacer.styled';

const FormDescription = ({
  name,
  label,
  value,
  placeholder,
  onChangeHandler,
  isError,
  onBlurHandler,
  warnMsg,
  isDisabled,
  RowNum,
  disabledDarkMode,
}) => (
  <FormControl disabledDarkMode={disabledDarkMode}>
    <InputLabel htmlFor={name} disabledDarkMode={disabledDarkMode}>
      {label}
    </InputLabel>
    <InputFieldAndButtonWrapper
      isError={isError}
      disabledDarkMode={disabledDarkMode}
    >
      <TextArea
        disabled={isDisabled}
        value={value}
        placeholder={placeholder}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        name={name}
        id={name}
        rows={RowNum}
        disabledDarkMode={disabledDarkMode}
        autoComplete="off"
      />
      {isError && <InputErrorIcon />}
    </InputFieldAndButtonWrapper>
    {isError ? (
      <InputHelperText>{warnMsg}</InputHelperText>
    ) : (
      <Spacer Height="24px" />
    )}
  </FormControl>
);

FormDescription.defaultProps = {
  isError: false,
  warnMsg: '',
  isDisabled: false,
  disabledDarkMode: false,
  placeholder: '',
  RowNum: 5,
};

FormDescription.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  onBlurHandler: PropTypes.func.isRequired,
  warnMsg: PropTypes.string,
  isDisabled: PropTypes.bool,
  disabledDarkMode: PropTypes.bool,
  RowNum: PropTypes.number,
};

export default FormDescription;
