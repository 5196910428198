import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from 'components/common/Button/Button.styled';
import styled from 'styled-components';

const Button = styled(PrimaryButton)`
  align-items: center;
  border: 0;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 40px;
  justify-content: space-around;
  width: 109px;
`;
const BulkImportButton = () => {
  const navigate = useNavigate();

  const onClick = () => navigate('/administration/companies/bulk-import');

  return <Button onClick={onClick}>Bulk Import</Button>;
};

export default BulkImportButton;
