import { Divider } from 'components/common/Divider/Divider.styled';
import { BRIGHT_GRAY, CRIMSON, DAVY_GRAY, WHITE } from 'constants/colors';
import styled from 'styled-components';

export const CompanyCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 8px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#fff')};
  border: none;
  padding: 16px;
  margin-bottom: 20px;
`;

export const IsHiringWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: flex-end;
`;

export const CompanyDetails = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 6px;
  padding: 16px;
`;

export const CompanyName = styled.div`
  color: ${WHITE};
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 8px;
  font-weight: 600;
`;

export const CompanyCategories = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 10px;
  gap: 6px;
`;

export const SingleCategory = styled.span`
  background-color: ${(props) => props.bgColor};
  border-radius: 4px;
  color: ${DAVY_GRAY};
  height: 19px;
  font-size: 10px;
  line-height: 20px;
  white-space: nowrap;
  padding: 0 6px;
  text-align: center;
`;

export const CompanyDetailsTitle = styled.div`
  margin-top: 8px;
  color: ${DAVY_GRAY};
`;

export const CompanyCardDivider = styled(Divider).attrs({ color: BRIGHT_GRAY })`
  margin: 16px 0;
`;

export const StageContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 10px;
  margin-top: 8px;
  color: ${DAVY_GRAY};
  filter: contrast(70%);
`;

export const Stage = styled.span`
  color: ${(props) => props.stageColor};
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const NotAvailableParagraph = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
`;

export const CompanyDescription = styled.div`
  max-height: 60px;
  margin-bottom: 24px;
  padding-bottom: 12px;
  font-size: 12px;
  line-height: 19px;
  color: ${DAVY_GRAY};
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
`;

export const FounderInitials = styled.div`
  color: ${(props) => props.Color ?? CRIMSON};
  position: absolute;
  top: 8px;
  left: 12px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  background-color: white;
  border-radius: 50%;
`;

export const WebsiteLink = styled.a`
  color: ${WHITE};
  text-decoration: none;
`;
