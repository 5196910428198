const ResponseThankYou = () => (
  <svg
    width="150"
    height="123"
    viewBox="0 0 150 123"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_490_682)">
      <path
        d="M75.4719 9H8.55729C6.28853 9.00256 4.11343 9.9058 2.50919 11.5116C0.904936 13.1173 0.00254895 15.2945 0 17.5654V96.211C0.00254942 98.4819 0.904938 100.659 2.50919 102.265C4.11344 103.871 6.28853 104.774 8.55729 104.776H75.4719C77.7406 104.774 79.9157 103.871 81.52 102.265C83.1242 100.659 84.0266 98.4819 84.0291 96.211V17.5654C84.0266 15.2945 83.1242 13.1173 81.52 11.5116C79.9157 9.9058 77.7406 9.00256 75.4719 9Z"
        fill="#F2F2F2"
      />
      <path
        d="M75.4719 11.4656H8.55723C6.9416 11.4674 5.39266 12.1106 4.25023 13.2541C3.1078 14.3976 2.46519 15.948 2.46338 17.5652V96.2108C2.46519 97.8279 3.10781 99.3783 4.25023 100.522C5.39266 101.665 6.9416 102.309 8.55723 102.31H75.4719C77.0875 102.309 78.6364 101.665 79.7788 100.522C80.9212 99.3783 81.5638 97.8279 81.5656 96.2108V17.5652C81.5638 15.948 80.9212 14.3976 79.7788 13.2541C78.6364 12.1106 77.0875 11.4674 75.4719 11.4656Z"
        fill="white"
      />
      <path
        d="M65.4014 34.3339H31.5632C31.1853 34.3339 30.8228 34.1836 30.5556 33.9162C30.2883 33.6487 30.1382 33.2859 30.1382 32.9076C30.1382 32.5293 30.2883 32.1665 30.5556 31.899C30.8228 31.6315 31.1853 31.4812 31.5632 31.4812H65.4014C65.5886 31.4812 65.7739 31.5181 65.9468 31.5898C66.1197 31.6615 66.2768 31.7665 66.4091 31.899C66.5414 32.0314 66.6464 32.1887 66.718 32.3617C66.7896 32.5348 66.8265 32.7203 66.8265 32.9076C66.8265 33.0949 66.7896 33.2804 66.718 33.4534C66.6464 33.6265 66.5414 33.7837 66.4091 33.9162C66.2768 34.0486 66.1197 34.1537 65.9468 34.2254C65.7739 34.297 65.5886 34.3339 65.4014 34.3339Z"
        fill="#F2F2F2"
      />
      <path
        d="M71.279 39.1476H31.5626C31.1849 39.1472 30.8229 38.9967 30.556 38.7293C30.2891 38.4619 30.1392 38.0993 30.1392 37.7213C30.1392 37.3433 30.2891 36.9807 30.556 36.7133C30.8229 36.4458 31.1849 36.2953 31.5626 36.2949H71.279C71.6567 36.2953 72.0188 36.4458 72.2857 36.7133C72.5526 36.9807 72.7025 37.3433 72.7025 37.7213C72.7025 38.0993 72.5526 38.4619 72.2857 38.7293C72.0188 38.9967 71.6567 39.1472 71.279 39.1476Z"
        fill="#F2F2F2"
      />
      <path
        d="M65.4014 55.9072H31.5632C31.1853 55.9072 30.8228 55.7569 30.5556 55.4894C30.2883 55.2219 30.1382 54.8591 30.1382 54.4808C30.1382 54.1025 30.2883 53.7397 30.5556 53.4722C30.8228 53.2047 31.1853 53.0544 31.5632 53.0544H65.4014C65.5886 53.0544 65.7739 53.0913 65.9468 53.163C66.1197 53.2347 66.2768 53.3398 66.4091 53.4722C66.5414 53.6047 66.6464 53.7619 66.718 53.935C66.7896 54.108 66.8265 54.2935 66.8265 54.4808C66.8265 54.6681 66.7896 54.8536 66.718 55.0267C66.6464 55.1997 66.5414 55.3569 66.4091 55.4894C66.2768 55.6218 66.1197 55.7269 65.9468 55.7986C65.7739 55.8703 65.5886 55.9072 65.4014 55.9072Z"
        fill="#F2F2F2"
      />
      <path
        d="M71.279 60.7219H31.5626C31.1849 60.7214 30.8229 60.571 30.556 60.3035C30.2891 60.0361 30.1392 59.6735 30.1392 59.2955C30.1392 58.9175 30.2891 58.5549 30.556 58.2875C30.8229 58.02 31.1849 57.8696 31.5626 57.8691H71.279C71.6567 57.8696 72.0188 58.02 72.2857 58.2875C72.5526 58.5549 72.7025 58.9175 72.7025 59.2955C72.7025 59.6735 72.5526 60.0361 72.2857 60.3035C72.0188 60.571 71.6567 60.7214 71.279 60.7219Z"
        fill="#F2F2F2"
      />
      <path
        d="M65.4014 77.4816H31.5632C31.1853 77.4816 30.8228 77.3314 30.5556 77.0639C30.2883 76.7964 30.1382 76.4336 30.1382 76.0553C30.1382 75.677 30.2883 75.3142 30.5556 75.0467C30.8228 74.7792 31.1853 74.6289 31.5632 74.6289H65.4014C65.5887 74.6287 65.7742 74.6654 65.9473 74.737C66.1203 74.8086 66.2776 74.9136 66.4101 75.0461C66.5426 75.1786 66.6477 75.3359 66.7194 75.5091C66.7911 75.6822 66.8281 75.8678 66.8281 76.0553C66.8281 76.2427 66.7911 76.4283 66.7194 76.6015C66.6477 76.7746 66.5426 76.932 66.4101 77.0644C66.2776 77.1969 66.1203 77.3019 65.9473 77.3735C65.7742 77.4451 65.5887 77.4818 65.4014 77.4816Z"
        fill="#F2F2F2"
      />
      <path
        d="M71.279 82.2951H31.5626C31.1849 82.2947 30.8229 82.1442 30.556 81.8768C30.2891 81.6093 30.1392 81.2468 30.1392 80.8687C30.1392 80.4907 30.2891 80.1282 30.556 79.8607C30.8229 79.5933 31.1849 79.4428 31.5626 79.4424H71.279C71.6567 79.4428 72.0188 79.5933 72.2857 79.8607C72.5526 80.1282 72.7025 80.4907 72.7025 80.8687C72.7025 81.2468 72.5526 81.6093 72.2857 81.8768C72.0188 82.1442 71.6567 82.2947 71.279 82.2951Z"
        fill="#F2F2F2"
      />
      <path
        d="M23.983 41.333H12.008C11.8268 41.3328 11.6532 41.2607 11.5251 41.1325C11.397 41.0043 11.3249 40.8304 11.3247 40.6491V29.9796C11.3249 29.7983 11.397 29.6244 11.5251 29.4962C11.6532 29.368 11.8268 29.2959 12.008 29.2957H23.983C24.1642 29.2959 24.3379 29.368 24.466 29.4962C24.5941 29.6244 24.6661 29.7983 24.6663 29.9796V40.6491C24.6661 40.8304 24.5941 41.0043 24.466 41.1325C24.3379 41.2607 24.1642 41.3328 23.983 41.333Z"
        fill="#E6E6E6"
      />
      <path
        d="M23.983 62.9065H12.008C11.8268 62.9063 11.6532 62.8342 11.5251 62.706C11.397 62.5777 11.3249 62.4039 11.3247 62.2226V51.5531C11.3249 51.3718 11.397 51.1979 11.5251 51.0697C11.6532 50.9415 11.8268 50.8693 12.008 50.8691H23.983C24.1642 50.8693 24.3379 50.9415 24.466 51.0697C24.5941 51.1979 24.6661 51.3718 24.6663 51.5531V62.2226C24.6661 62.4039 24.5941 62.5777 24.466 62.706C24.3379 62.8342 24.1642 62.9063 23.983 62.9065Z"
        fill="#E6E6E6"
      />
      <path
        d="M23.983 84.4807H12.008C11.8268 84.4805 11.6532 84.4084 11.5251 84.2802C11.397 84.152 11.3249 83.9781 11.3247 83.7968V73.1273C11.3249 72.946 11.397 72.7721 11.5251 72.6439C11.6532 72.5157 11.8268 72.4436 12.008 72.4434H23.983C24.1642 72.4436 24.3379 72.5157 24.466 72.6439C24.5941 72.7721 24.6661 72.946 24.6663 73.1273V83.7968C24.6661 83.9781 24.5941 84.152 24.466 84.2802C24.3379 84.4084 24.1642 84.4805 23.983 84.4807Z"
        fill="#E6E6E6"
      />
      <path
        d="M134.419 83.3807L134.95 78.7338L137.612 55.4347L138.143 50.7878C138.398 48.5314 137.748 46.2657 136.337 44.4879C134.925 42.7101 132.867 41.5654 130.613 41.3048L70.2162 34.3921C67.9619 34.1366 65.6984 34.7867 63.9222 36.1996C62.1461 37.6126 61.0025 39.673 60.7422 41.9289L60.7408 41.9409L57.0201 74.5097L57.0188 74.5217C56.7635 76.7782 57.413 79.0439 58.8246 80.8217C60.2362 82.5995 62.2947 83.7442 64.5485 84.0047L124.945 90.9175C127.199 91.1729 129.463 90.5229 131.239 89.1099C133.015 87.697 134.159 85.6366 134.419 83.3807Z"
        fill="#E6E6E6"
      />
      <path
        d="M131.972 83.1005L132.601 77.5966L135.067 56.0114L135.695 50.5076C135.877 48.9007 135.415 47.2873 134.409 46.0212C133.404 44.7552 131.938 43.94 130.333 43.7545L69.9366 36.8418C68.3312 36.6598 66.7193 37.1228 65.4545 38.129C64.1897 39.1351 63.3752 40.6024 63.1899 42.2089L63.1885 42.2209L59.4679 74.7897L59.4665 74.8018C59.2847 76.4087 59.7472 78.0221 60.7525 79.2881C61.7577 80.5541 63.2236 81.3693 64.8285 81.5548L125.225 88.4676C126.831 88.6495 128.442 88.1866 129.707 87.1804C130.972 86.1742 131.786 84.707 131.972 83.1005Z"
        fill="white"
      />
      <path
        d="M122.085 53.5693L88.4662 49.7213C88.2802 49.7002 88.1001 49.6426 87.9363 49.5518C87.7724 49.461 87.6281 49.3388 87.5115 49.1921C87.3949 49.0454 87.3084 48.8772 87.2568 48.697C87.2052 48.5168 87.1896 48.3282 87.2109 48.142C87.2322 47.9558 87.2899 47.7756 87.3808 47.6117C87.4717 47.4478 87.5939 47.3035 87.7406 47.187C87.8873 47.0704 88.0554 46.9839 88.2355 46.9325C88.4156 46.881 88.604 46.8656 88.79 46.8871L122.409 50.735C122.784 50.7783 123.127 50.969 123.362 51.2651C123.597 51.5611 123.705 51.9384 123.662 52.314C123.619 52.6896 123.429 53.0328 123.133 53.2682C122.838 53.5035 122.461 53.6118 122.085 53.5693Z"
        fill="#E6E6E6"
      />
      <path
        d="M127.38 59.0211L87.9202 54.5048C87.7342 54.4837 87.5541 54.426 87.3903 54.3352C87.2265 54.2444 87.0822 54.1222 86.9656 53.9755C86.849 53.8288 86.7625 53.6606 86.7109 53.4804C86.6593 53.3002 86.6437 53.1117 86.665 52.9254C86.6863 52.7392 86.744 52.559 86.8349 52.3952C86.9258 52.2313 87.048 52.087 87.1947 51.9704C87.3413 51.8539 87.5095 51.7674 87.6895 51.716C87.8696 51.6645 88.058 51.6491 88.244 51.6705L127.703 56.1869C127.889 56.2082 128.069 56.2659 128.233 56.3568C128.396 56.4476 128.541 56.5699 128.657 56.7165C128.773 56.8632 128.86 57.0313 128.911 57.2114C128.963 57.3915 128.979 57.5799 128.957 57.766C128.936 57.9521 128.878 58.1322 128.788 58.296C128.697 58.4598 128.575 58.6041 128.428 58.7206C128.282 58.8372 128.114 58.9237 127.934 58.9753C127.754 59.0268 127.565 59.0424 127.38 59.0211Z"
        fill="#E6E6E6"
      />
      <path
        d="M126.279 68.5602L68.104 61.9017C67.7288 61.8584 67.3861 61.6677 67.1513 61.3716C66.9164 61.0756 66.8086 60.6983 66.8515 60.3227C66.8944 59.9471 67.0845 59.6039 67.3801 59.3685C67.6757 59.1332 68.0525 59.0249 68.4278 59.0674L126.603 65.7259C126.789 65.7471 126.969 65.8047 127.133 65.8955C127.297 65.9863 127.441 66.1085 127.558 66.2552C127.674 66.4019 127.761 66.5701 127.812 66.7503C127.864 66.9305 127.88 67.1191 127.858 67.3053C127.837 67.4915 127.779 67.6717 127.688 67.8355C127.597 67.9994 127.475 68.1437 127.329 68.2603C127.182 68.3768 127.014 68.4633 126.834 68.5148C126.654 68.5662 126.465 68.5817 126.279 68.5602H126.279Z"
        fill="#E6E6E6"
      />
      <path
        d="M125.92 73.3638L67.5576 66.6839C67.1824 66.6406 66.8397 66.4499 66.6049 66.1539C66.37 65.8578 66.2622 65.4805 66.3051 65.1049C66.348 64.7293 66.5382 64.3861 66.8337 64.1508C67.1293 63.9154 67.5061 63.8071 67.8814 63.8497L126.244 70.5296C126.619 70.5729 126.961 70.7635 127.196 71.0596C127.431 71.3557 127.539 71.733 127.496 72.1086C127.453 72.4842 127.263 72.8274 126.967 73.0627C126.672 73.2981 126.295 73.4064 125.92 73.3638Z"
        fill="#E6E6E6"
      />
      <path
        d="M125.208 77.9318L67.0331 71.2733C66.8471 71.2522 66.667 71.1946 66.5032 71.1038C66.3393 71.013 66.195 70.8908 66.0784 70.7441C65.9618 70.5974 65.8753 70.4292 65.8237 70.249C65.7721 70.0688 65.7565 69.8802 65.7778 69.694C65.7991 69.5078 65.8568 69.3276 65.9477 69.1637C66.0386 68.9998 66.1608 68.8555 66.3075 68.739C66.4542 68.6224 66.6223 68.5359 66.8024 68.4845C66.9825 68.433 67.1709 68.4176 67.3569 68.4391L125.532 75.0976C125.907 75.1409 126.25 75.3316 126.485 75.6276C126.72 75.9237 126.827 76.301 126.785 76.6766C126.742 77.0522 126.552 77.3954 126.256 77.6307C125.96 77.8661 125.584 77.9744 125.208 77.9318V77.9318Z"
        fill="#E6E6E6"
      />
      <path
        d="M124.849 82.7366L66.4868 76.0567C66.1116 76.0134 65.7689 75.8227 65.5341 75.5267C65.2992 75.2306 65.1914 74.8533 65.2343 74.4777C65.2772 74.1021 65.4674 73.7589 65.7629 73.5236C66.0585 73.2882 66.4353 73.1799 66.8106 73.2225L125.173 79.9023C125.359 79.9235 125.539 79.9811 125.703 80.0719C125.867 80.1627 126.011 80.2849 126.127 80.4316C126.244 80.5783 126.331 80.7465 126.382 80.9267C126.434 81.1069 126.449 81.2955 126.428 81.4817C126.407 81.6679 126.349 81.8481 126.258 82.012C126.167 82.1758 126.045 82.3202 125.898 82.4367C125.752 82.5533 125.584 82.6397 125.404 82.6912C125.223 82.7427 125.035 82.7581 124.849 82.7366Z"
        fill="#E6E6E6"
      />
      <path
        d="M80.1413 55.8138L68.2438 54.4521C68.0638 54.4313 67.8994 54.3399 67.7867 54.1979C67.674 54.0559 67.6221 53.875 67.6425 53.6948L68.8535 43.0944C68.8743 42.9143 68.9656 42.7498 69.1075 42.637C69.2493 42.5241 69.43 42.4722 69.61 42.4926L81.5075 43.8544C81.6875 43.8752 81.8519 43.9666 81.9646 44.1085C82.0773 44.2505 82.1292 44.4314 82.1088 44.6116L80.8978 55.212C80.877 55.3921 80.7857 55.5566 80.6438 55.6695C80.502 55.7823 80.3213 55.8342 80.1413 55.8138Z"
        fill="#A51C30"
      />
    </g>
    <circle cx="111" cy="14" r="13" stroke="#EAEAEA" strokeWidth="2" />
    <line x1="111" y1="8" x2="111" y2="20" stroke="#EAEAEA" strokeWidth="2" />
    <line x1="117" y1="14" x2="105" y2="14" stroke="#EAEAEA" strokeWidth="2" />
    <defs>
      <clipPath id="clip0_490_682">
        <rect
          width="150"
          height="114"
          fill="white"
          transform="translate(0 9)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ResponseThankYou;
