import styled from 'styled-components';
import { CRIMSON } from 'constants/colors';
import DEVICE from 'constants/deviceSizes';

export const WelcomeToPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 500px;

  @media ${DEVICE.formResponsive} {
    width: 90%;
  }
`;

export const WelcomeToPageTitle = styled.h1`
  color: ${CRIMSON};
  margin: 0;
  font-weight: 700;
  font-size: 32px;

  @media ${DEVICE.formResponsive} {
    height: 40px;
    font-size: 21px;
    line-height: 36px;
  }
`;

export const WelcomeToPageParagraph = styled.p`
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  margin: 0;
  font-size: 14px;
  line-height: 24px;

  @media ${DEVICE.formResponsive} {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const WelcomeToPageRedLine = styled.div`
  width: 80px;
  height: 8px;
  margin: 16px 0px;
  background: ${CRIMSON};

  @media ${DEVICE.formResponsive} {
    margin: 12px 0px;
  }

  @media ${DEVICE.laptop} {
    width: 58px;
    height: 5px;
  }
`;
