export const investorParamsActions = {
  SET_FILTER_OR_PAGINATION_PARAM: 'SET_FILTER_OR_PAGINATION_PARAM',
  SET_SORT_PARAM: 'SET_SORT_PARAM',
  SET_INITIAL_QUERY_PARAMS: 'SET_INITIAL_QUERY_PARAMS',
};

export const INVESTOR_SORT_PARAMS = {
  SORT_BY: 'sortBy',
  ORDER_BY: 'orderBy',
};

export const INVESTOR_PAGINATION_PARAMS = {
  LIMIT: 'limit',
  OFFSET: 'offset',
};

export const INVESTOR_PARAMS = {
  CLASS_YEAR: 'classYear',
  CATEGORY: 'category',
  STATUS: 'status',
  STAGE: 'stage',
  FULL_NAME: 'fullName',
  CHECK_SIZE: 'checkSize',
  ...INVESTOR_SORT_PARAMS,
  ...INVESTOR_PAGINATION_PARAMS,
};

export const SORT_KEYS = {
  NEWEST: 'Newest',
  OLDEST: 'Oldest',
  ALPHABETICAL_ASC: 'A-Z',
  ALPHABETICAL_DESC: 'Z-A',
};

export const SORT_PARAMS = {
  [SORT_KEYS.NEWEST]: {
    [INVESTOR_PARAMS.SORT_BY]: 'classYear',
    [INVESTOR_PARAMS.ORDER_BY]: 'DESC',
  },
  [SORT_KEYS.OLDEST]: {
    [INVESTOR_PARAMS.SORT_BY]: 'classYear',
    [INVESTOR_PARAMS.ORDER_BY]: 'ASC',
  },
  [SORT_KEYS.ALPHABETICAL_DESC]: {
    [INVESTOR_PARAMS.SORT_BY]: 'fullName',
    [INVESTOR_PARAMS.ORDER_BY]: 'DESC',
  },
  [SORT_KEYS.ALPHABETICAL_ASC]: {
    [INVESTOR_PARAMS.SORT_BY]: 'fullName',
    [INVESTOR_PARAMS.ORDER_BY]: 'ASC',
  },
};

export const LIMIT_OPTIONS = [10, 20, 50, 100];
