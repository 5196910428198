import { useState } from 'react';
import { readLocalStorage, writeLocalStorage } from 'utils/localStorageUtils';

const useDarkMode = () => {
  const [theme, setTheme] = useState(() => !!readLocalStorage('DARKTHEME'));

  const setMode = (mode) => {
    writeLocalStorage('DARKTHEME', mode);
    setTheme(mode);
  };

  const themeToggler = () => {
    setMode(!theme);
  };

  return [theme, themeToggler];
};

export default useDarkMode;
