import { CRIMSON } from 'constants/colors';
import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const FounderCategorySection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
`;

export const FoundersCategorySubTitle = styled.p`
  color: var(--WHITE_OR_DAVY_GRAY);
  margin: 20px 0 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  padding-left: 24px;

  @media ${DEVICE.formResponsive} {
    font-size: 18px;
    line-height: 36px;
    width: 100%;
  }
`;
export const FoundersCategoryTitle = styled.p`
  color: var(--WHITE_OR_DAVY_GRAY);
  margin: 20px 0 0;
  font-weight: 600;
  font-size: 32px;
  line-height: 72px;

  @media ${DEVICE.formResponsive} {
    font-size: 24px;
    line-height: 36px;
    width: 100%;
  }
`;

export const FoundersCategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0;
  gap: 45px;
  width: 100%;

  @media ${DEVICE.tablet} {
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
  }
`;

export const DescriptionParagraph = styled.p`
  color: var(--BRIGHT_GRAY_OR_DAVY_GRAY);
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0;
  text-align: left;
  max-width: 690px;

  a {
    font-weight: 540;
    color: ${CRIMSON};

    :hover {
      text-decoration: underline;
    }
  }

  @media ${DEVICE.formResponsive} {
    font-size: 14px;
  }
`;
