import styled from 'styled-components';

export const RowsPerPageWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 30px;
`;

export const RowsPerPageLabel = styled.label`
  color: var(--WHITE_OR_RAISING_BLACK);
  font-size: 14px;
`;

export const StyledSelect = styled.select`
  background-color: var(--RAISING_BLACK_OR_WHITE);
  border: none;
  border-radius: 4px;
  color: var(--WHITE_OR_RAISING_BLACK);
  font-size: 14px;
  font-weight: 600;
  height: 30px;
  text-align: center;
  width: 50px;
`;
