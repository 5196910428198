import { STEP_ONE_KEYS } from 'components/Forms/Events/StepOne/constants';
import {
  isEmptyFieldValidator,
  isEmptyOrValidUrl,
  isValidDate,
} from 'utils/inputValidators';

const EventStepOneSchema = {
  [STEP_ONE_KEYS.EVENT_NAME]: {
    label: 'Event name*',
    inputType: 'text',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter an event name',
    validator: isEmptyFieldValidator,
  },
  [STEP_ONE_KEYS.RSVP_LINK]: {
    label: 'Sign up/RSVP Link',
    inputType: 'text',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter a valid url',
    validator: isEmptyOrValidUrl,
  },
  [STEP_ONE_KEYS.EVENT_DATE]: {
    label: 'Event date*',
    isError: false,
    warnMsg: 'Enter a valid date',
    validator: isValidDate,
  },
};

export default EventStepOneSchema;
