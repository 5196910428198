import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from 'components/common/Modal/Modal';
import {
  Wrapper,
  Title,
  DescriptionParagraph,
  ButtonsContainer,
  BUTTONS_CSS,
  Form,
  InputLabel,
  FileNamePreview,
} from 'pages/AdminPage/components/Forms/Company/BulkImportCompany/BulkImportCompanies.styled';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import {
  LoadingSpinnerContainer,
  LoadingSpinner,
} from 'components/common/LoadingSpinner/LoadingSpinner.styled';
import UploadCloudIcon from 'assets/icons/UploadCloudIcon';
import {
  isImportSuccess,
  isRequestOnGoing,
  isValidatingFileFailure,
  isValidatingFileSuccess,
  NOT_400_ERROR_MESSAGE,
  STATUS,
} from 'pages/AdminPage/components/Forms/Company/BulkImportCompany/constants';
import { useEffect } from 'react';
import { bulkImportCompanies } from 'services/requests';

const LoadingSpinnerContainerOverride = styled(LoadingSpinnerContainer)`
  margin: 0;
  height: 50px;
`;

const BulkImportCompaniesCsv = ({
  closeForm,
  nextStep,
  status,
  setStatus,
  setMessage,
  selectedFile,
  setSelectedFile,
}) => {
  useEffect(() => {
    if (isImportSuccess(status) || isValidatingFileFailure(status)) {
      nextStep();
    }
  }, [status]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) setSelectedFile(null);

    // Add validation that it is .csv file
    setSelectedFile(file);
  };

  const checkData = async () => {
    if (selectedFile && !isRequestOnGoing(status)) {
      setStatus(STATUS.REQUEST_ON_GOING);
      try {
        const response = await bulkImportCompanies(selectedFile, true);
        setStatus(STATUS.VALIDATING_FILE_SUCCESS);
        setMessage(response.data);
      } catch (e) {
        if (e.response.status === 400) {
          setStatus(STATUS.VALIDATING_FILE_FAILURE);
          setMessage(e.response.data);
        } else {
          setStatus(STATUS.VALIDATING_FILE_FAILURE);
          setMessage(NOT_400_ERROR_MESSAGE);
        }
      }
    }
  };

  const submitData = async () => {
    if (selectedFile && isValidatingFileSuccess(status)) {
      setStatus(STATUS.REQUEST_ON_GOING);
      try {
        const response = await bulkImportCompanies(selectedFile);

        setStatus(STATUS.IMPORT_SUCCESS);
        setMessage(response.data);
      } catch (e) {
        if (e.response.status === 400) {
          setStatus(STATUS.VALIDATING_FILE_FAILURE);
          setMessage(e.response.data);
        } else {
          setStatus(STATUS.VALIDATING_FILE_FAILURE);
          setMessage(NOT_400_ERROR_MESSAGE);
        }
      }
    }
  };

  return (
    <Modal closeForm={closeForm}>
      <Wrapper>
        <Title>Bulk import companies</Title>
        <DescriptionParagraph>
          Select a CSV file to import companies
        </DescriptionParagraph>
        <Form>
          {!isRequestOnGoing(status) && (
            <InputLabel htmlFor="file-upload" className="custom-file-upload">
              <UploadCloudIcon /> Select a file
              <input
                id="file-upload"
                type="file"
                style={{ display: 'none' }}
                accept=".csv"
                onChange={handleFileUpload}
              />
            </InputLabel>
          )}
          <div>
            {status == null &&
              (selectedFile ? (
                <FileNamePreview>
                  Selected file: <span>{selectedFile.name}</span>
                </FileNamePreview>
              ) : (
                <FileNamePreview>
                  <i>No file selected</i>
                </FileNamePreview>
              ))}
            {selectedFile && isValidatingFileSuccess(status) && (
              <FileNamePreview>
                <span style={{ color: 'green' }}>
                  {selectedFile.name} is valid
                </span>
              </FileNamePreview>
            )}
            {selectedFile && isValidatingFileFailure(status) && (
              <FileNamePreview>
                <span>${selectedFile.name} contains errors</span>
              </FileNamePreview>
            )}
            {isRequestOnGoing(status) && (
              <LoadingSpinnerContainerOverride>
                <LoadingSpinner />
              </LoadingSpinnerContainerOverride>
            )}
          </div>
          <ButtonsContainer>
            <SecondaryButton
              $
              {...BUTTONS_CSS.secondary}
              onClick={checkData}
              disabled={!selectedFile || isRequestOnGoing(status)}
            >
              Check your data
            </SecondaryButton>
            <PrimaryButton
              $
              {...BUTTONS_CSS.primary}
              onClick={submitData}
              disabled={
                !selectedFile ||
                isRequestOnGoing(status) ||
                !isValidatingFileSuccess(status)
              }
            >
              Import companies
            </PrimaryButton>
          </ButtonsContainer>
        </Form>
      </Wrapper>
    </Modal>
  );
};

BulkImportCompaniesCsv.defaultProps = {
  selectedFile: null,
  status: null,
};

BulkImportCompaniesCsv.propTypes = {
  closeForm: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  status: PropTypes.oneOf(Object.values(STATUS)),
  setStatus: PropTypes.func.isRequired,
  selectedFile: PropTypes.shape({
    name: PropTypes.string,
  }),
  setSelectedFile: PropTypes.func.isRequired,
};

export default BulkImportCompaniesCsv;
