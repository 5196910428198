import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

const AnchorPartnerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-top: 40px;
  margin-left: 25px;

  @media ${DEVICE.laptop} {
    display: none;
  }
`;

export default AnchorPartnerContainer;
