import { useState } from 'react';
import {
  INPUT_KEYS,
  MULTI_SELECT_KEYS,
  SINGLE_SELECT_KEYS,
} from 'pages/AdminPage/components/Forms/Company/EditCompany/constants';

const INITIAL_COMPANY_VALIDATION_ERRORS = {
  [INPUT_KEYS.CREATOR_FULL_NAME]: false,
  [INPUT_KEYS.CREATOR_HBS_EMAIL]: false,
  [INPUT_KEYS.CREATOR_HBS_CLASSCARD_LINK]: false,
  [INPUT_KEYS.COMPANY_WEBSITE]: false,
  [INPUT_KEYS.COMPANY_NAME]: false,
  [INPUT_KEYS.COMPANY_DESCRIPTION]: false,
  [SINGLE_SELECT_KEYS.COMPANY_CARD_COLOR]: false,
  [SINGLE_SELECT_KEYS.COMPANY_STAGE]: false,
  [SINGLE_SELECT_KEYS.FOUNDING_YEAR]: false,
  [SINGLE_SELECT_KEYS.FUNDING_STAGE]: false,
  [MULTI_SELECT_KEYS.CATEGORIES]: false,
  [MULTI_SELECT_KEYS.GEOGRAPHIC_FOCUS]: false,
  [MULTI_SELECT_KEYS.FOUNDER_ATTRIBUTES]: false,
};

const useCompanyValidationErrors = () => {
  const [validationErrors, setValidationErrors] = useState(
    INITIAL_COMPANY_VALIDATION_ERRORS,
  );

  const setValidationError = (key, value) =>
    setValidationErrors((prevState) => ({ ...prevState, [key]: value }));

  return { validationErrors, setValidationError };
};

export default useCompanyValidationErrors;
