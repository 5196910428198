import getCategoryColor from 'pages/HomePage/utils';
import {
  CATEGORIES,
  FUNDING_STAGE,
  GEOGRAPHIC_LOCATIONS,
} from 'pages/HomePage/constants';
import CHECK_SIZES from 'constants/colorOptionPairs/checkSizesColors';

export const generateYearOptions = (howMuchToAddOnTop = 0) => {
  const max = new Date().getFullYear() + howMuchToAddOnTop;
  const min = max - 50;
  const years = [];

  for (let i = max; i >= min; i -= 1) {
    years.push({ label: i.toString() });
  }

  return years;
};

export const mapColors = (iterableObject) =>
  iterableObject.map((value) => ({
    id: value.id,
    label: value.colorName,
    color: value.colorValue,
  }));

export const mapGeographicFocus = (iterableObject) =>
  iterableObject.map((value) => ({
    id: value.id,
    label: value.geographicLocationName,
    bgColor: getCategoryColor(
      value.geographicLocationName,
      GEOGRAPHIC_LOCATIONS,
    ),
  }));

export const mapCategories = (iterableObject) =>
  iterableObject.map((value) => ({
    id: value.id,
    label: value.investorCategoryName,
    bgColor: getCategoryColor(value.investorCategoryName, CATEGORIES),
  }));

export const mapFundingStages = (iterableObject) =>
  iterableObject.map((value) => ({
    id: value.id,
    label: value.fundingStageName,
    bgColor: getCategoryColor(value.fundingStageName, FUNDING_STAGE),
  }));

export const mapCheckSizes = (iterableObject) =>
  iterableObject.map((value) => ({
    id: value.id,
    label: value.amount,
    bgColor: getCategoryColor(value.amount, CHECK_SIZES),
  }));
