import { STEP_TWO_KEYS } from 'components/Forms/Events/StepTwo/constants';
import { isEmptyFieldValidator } from 'utils/inputValidators';

export const EventStepTwoInputSchema = {
  [STEP_TWO_KEYS.EVENT_LOCATION]: {
    label: 'Event location',
    inputType: 'text',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    validator: () => true,
  },
};

export const EventStepTwoSelectSchema = {
  [STEP_TWO_KEYS.AUDIENCE]: {
    label: 'Audience/Industry',
    placeHolder: 'Pick up to three audience categories',
    MaxHeight: '130px',
    isError: false,
    validator: () => true,
  },
  [STEP_TWO_KEYS.ORGANIZERS]: {
    label: 'Organizers',
    placeHolder: 'Pick up to three organizers',
    MaxHeight: '130px',
    isError: false,
    validator: () => true,
  },
};

export const EventStepTwoDescriptionSchema = {
  [STEP_TWO_KEYS.EVENT_DESCRIPTION]: {
    label: 'Event description*',
    inputType: 'text',
    value: '',
    isError: false,
    isDisabled: false,
    disabledDarkMode: true,
    warnMsg: 'Enter an event description',
    validator: isEmptyFieldValidator,
    placeHolder: 'Enter an event description',
    RowNum: 6,
  },
};
