import styled from 'styled-components';
import DEVICE from 'constants/deviceSizes';
import { DAVY_GRAY } from 'constants/colors';

export const Wrapper = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 0px 72px;
  width: 780px;

  @media ${DEVICE.tablet} {
    justify-content: center;
    height: auto;
    margin: 0px 20px;
    width: 450px;
  }

  @media ${DEVICE.formResponsive} {
    width: 335px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FieldContainer = styled.div`
  flex: 1;
  width: 100%;
`;

export const ExclusiveDealsNote = styled.p`
  color: ${DAVY_GRAY};
  font-size: 12px;
  line-height: 18px;
  margin: 0;
`;
