import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Option from 'components/common/Dropdown/Option/Option';
import DropdownShow from 'assets/icons/DropdownShow';
import {
  FormControl,
  SelectedValuesContainer,
  SelectedValues,
  SelectLabel,
  DropdownMenu,
  DropdownItem,
  SearchBox,
  SearchBoxInput,
  PlaceHolder,
  DropdownMenuContainer,
  SelectErrorIconWrapper,
} from 'components/common/Dropdown/Select.styled';
import useClickOutside from 'hooks/useClickOutside';
import SearchIcon from 'assets/icons/SearchIcon';
import DropdownHide from 'assets/icons/DropdownHide';
import Spacer from 'components/common/Spacer/Spacer.styled';
import InputErrorIcon from 'assets/icons/InputErrorIcon';

const MultiSelect = ({
  label,
  placeHolder,
  MaxHeight,
  options,
  selectedValue,
  selectLimit,
  isSearchable,
  onChangeHandler,
  isError,
  disabledDarkMode,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const searchRef = useRef();
  const contentRef = useRef(null);

  const closeDropDown = () => {
    setShowMenu(false);
  };

  useClickOutside(contentRef, closeDropDown);

  useEffect(() => {
    setSearchValue('');

    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  const menuVisibilityToggle = () => setShowMenu(!showMenu);

  const removeOption = (option) =>
    selectedValue.filter((o) => o.label !== option.label);

  const onTagRemove = (e, option) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    onChangeHandler(newValue);
    setShowMenu(!showMenu);
  };

  const isSelected = (option) =>
    selectedValue.filter((o) => o.label === option.label).length > 0;

  const appendSelectedValues = (option) => {
    let newValue;

    if (selectedValue.findIndex((o) => o.label === option.label) >= 0) {
      newValue = removeOption(option);
    } else if (selectedValue.length < selectLimit) {
      newValue = selectedValue;
      newValue.push({ ...option });
    } else newValue = selectedValue;

    return newValue;
  };

  const onDropMenuItemClick = (option) =>
    onChangeHandler(appendSelectedValues(option));

  const onSearch = (e) => setSearchValue(e.target.value);

  const getOptions = useCallback(
    () =>
      !searchValue
        ? options
        : options.filter(
            (option) =>
              option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >=
              0,
          ),
    [searchValue, showMenu],
  );

  return (
    <FormControl
      disabledDarkMode={disabledDarkMode}
      onClick={menuVisibilityToggle}
      ref={contentRef}
    >
      <SelectLabel htmlFor={label} disabledDarkMode={disabledDarkMode}>
        {label}{' '}
      </SelectLabel>
      <SelectedValuesContainer
        disabledDarkMode={disabledDarkMode}
        isError={isError}
      >
        <SelectedValues id={label}>
          {!selectedValue.length ? (
            <PlaceHolder disabledDarkMode={disabledDarkMode}>
              {placeHolder}
            </PlaceHolder>
          ) : (
            <>
              {selectedValue.map((option) => (
                <Option
                  value={label}
                  key={option.label}
                  option={option}
                  onTagRemove={onTagRemove}
                  isSelected
                  disabledDarkMode={disabledDarkMode}
                />
              ))}
            </>
          )}
        </SelectedValues>
        {showMenu ? <DropdownHide /> : <DropdownShow />}
        {isError && (
          <SelectErrorIconWrapper>
            <InputErrorIcon />
          </SelectErrorIconWrapper>
        )}
      </SelectedValuesContainer>
      {!showMenu && <Spacer Height="4px" />}
      {showMenu && (
        <DropdownMenuContainer>
          <DropdownMenu
            MaxHeight={MaxHeight}
            disabledDarkMode={disabledDarkMode}
          >
            {isSearchable && (
              <SearchBox disabledDarkMode={disabledDarkMode}>
                <SearchIcon />
                <SearchBoxInput
                  onChange={onSearch}
                  label={searchValue}
                  ref={searchRef}
                  placeholder="Search"
                  disabledDarkMode={disabledDarkMode}
                />
              </SearchBox>
            )}
            {getOptions().map((option) => (
              <DropdownItem
                key={option.label}
                onClick={() => onDropMenuItemClick(option)}
                isSelected={isSelected(option) && selectedValue.length === 3}
              >
                <Option
                  key={option.label}
                  option={option}
                  isSelected={isSelected(option)}
                  onTagRemove={onTagRemove}
                  disabledDarkMode={disabledDarkMode}
                />
              </DropdownItem>
            ))}
          </DropdownMenu>
        </DropdownMenuContainer>
      )}
    </FormControl>
  );
};

MultiSelect.defaultProps = {
  isSearchable: true,
  placeHolder: '',
  MaxHeight: '100px',
  selectLimit: 3,
  options: [],
  selectedValue: [],
  isError: false,
  disabledDarkMode: false,
};

MultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  placeHolder: PropTypes.string,
  MaxHeight: PropTypes.string,
  selectLimit: PropTypes.number,
  isSearchable: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      bgColor: PropTypes.string,
    }),
  ),
  selectedValue: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      color: PropTypes.string,
      bgColor: PropTypes.string,
    }),
  ),
  onChangeHandler: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  disabledDarkMode: PropTypes.bool,
};

export default MultiSelect;
