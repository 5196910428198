import FilterIcon from 'assets/icons/FilterIcon';
import PropTypes from 'prop-types';
import { ToggleResourcesFiltersButton } from './FilterControls.styled';

const ToggleFilters = ({ toggleFilters }) => (
  <ToggleResourcesFiltersButton onClick={() => toggleFilters()}>
    <FilterIcon /> Filters
  </ToggleResourcesFiltersButton>
);

ToggleFilters.propTypes = {
  toggleFilters: PropTypes.func.isRequired,
};

export default ToggleFilters;
