import {
  RESOURCES_PAGINATION_PARAMS,
  RESOURCES_SORT_PARAMS,
} from 'pages/ResourcePage/constants';

// eslint-disable-next-line import/prefer-default-export
export const EXCLUSIVE_RESOURCES_PARAMS = {
  COMPANY_NAME: 'companyName',
  DEAL_DESCRIPTION: 'description',
  CATEGORY: 'category',
  ...RESOURCES_PAGINATION_PARAMS,
  ...RESOURCES_SORT_PARAMS,
};
