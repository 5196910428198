import PropTypes from 'prop-types';
import RestoreIcon from 'assets/icons/RestoreIcon';
import {
  SelectedForRestoreWrapper,
  TDSelectedForRestore,
  TRSelectedForRestore,
} from 'pages/AdminPage/components/AdminTable/AdditionalElements/RestoreSelected/RestoreSelected.styled';

const RestoreSelected = ({ colSpan, callBackFunc }) => (
  <TRSelectedForRestore>
    <TDSelectedForRestore colSpan={colSpan}>
      <SelectedForRestoreWrapper onClick={callBackFunc}>
        <RestoreIcon fillColor="var(--WHITE_OR_RAISING_BLACK)" />
        <p>Restore</p>
      </SelectedForRestoreWrapper>
    </TDSelectedForRestore>
  </TRSelectedForRestore>
);

RestoreSelected.propTypes = {
  colSpan: PropTypes.string.isRequired,
  callBackFunc: PropTypes.func.isRequired,
};

export default RestoreSelected;
