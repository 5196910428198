import PropTypes from 'prop-types';

import {
  ThumbsContainer,
  Thumb,
  ThumbInner,
  Img,
} from 'components/common/DragNDrop/Preview/styleConstants';

const Preview = ({ image }) => {
  const img = image.preview
    ? image
    : { preview: `data:image/png;base64, ${image}`, path: '' };

  return (
    <ThumbsContainer>
      <Thumb>
        <ThumbInner>
          <Img src={img.preview} alt={img.path} />
        </ThumbInner>
      </Thumb>
    </ThumbsContainer>
  );
};

Preview.propTypes = {
  image: PropTypes.oneOfType([
    PropTypes.shape({
      path: PropTypes.string,
      preview: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
};

export default Preview;
