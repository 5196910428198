import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const SymphonyContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const SymphonyContactDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  max-width: 720px;
`;

export const SymphonyContactParagraph = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;

  @media ${DEVICE.formResponsive} {
    font-size: 14px;
  }
`;

export const SymphonyContactBoldParagraph = styled(SymphonyContactParagraph)`
  font-weight: 600;
`;
