import Path from './Icons.styled';

const SymphonyLetterLogo = () => {
  return (
    <svg
      width="19"
      height="24"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path d="M9.26392 0.0176044L9.52359 0.00488281C9.4307 0.00700317 9.28925 0.0112434 9.26392 0.0176044Z" />
      <Path d="M9.71308 5.2526C9.90097 5.23563 10.0931 5.22503 10.2915 5.22503C10.4435 5.22503 10.5934 5.22503 10.7433 5.24411L14.991 0.973615C14.7081 0.869715 14.4167 0.761574 14.1148 0.676758L9.54419 5.2738L9.71308 5.2526Z" />
      <Path d="M12.8523 5.76191L16.7938 1.8031C16.5552 1.66739 16.2977 1.53169 16.0211 1.40234L11.9783 5.46293C12.2754 5.5447 12.5673 5.64455 12.8523 5.76191Z" />
      <Path d="M13.8286 6.23941C14.0798 6.38148 14.3205 6.53415 14.5485 6.69318L15.1502 6.08886L17.312 2.73438L13.8286 6.23941Z" />
      <Path d="M14.7404 11.0358L14.4511 10.8789L10.4399 14.9077L10.4758 14.9268L10.7081 15.0413L10.8115 15.0964C10.9107 15.1515 11.0036 15.2067 11.0944 15.2618L11.162 15.3084L11.1936 15.3296L15.2048 11.3009C15.0739 11.2161 14.9409 11.1355 14.8037 11.057L14.7404 11.0358Z" />
      <Path d="M18.1791 15.5799L18.1559 15.4293C18.1348 15.3276 18.1137 15.2173 18.0883 15.1304L9.28906 23.9725V22.643L17.7738 14.0574C17.7358 13.9726 17.6598 13.8899 17.6175 13.8093C17.6049 13.7818 17.5795 13.7542 17.5648 13.7288C17.4845 13.5803 17.455 13.4383 17.3537 13.3047L9.28906 21.4661V20.0094L10.674 18.5527C10.2362 18.6979 9.77753 18.7696 9.31651 18.7647L9.28906 18.8262V18.7647C6.91402 18.752 4.55375 17.8996 3.00417 16.4832L0 20.9106C2.69593 22.8957 5.94632 23.9768 9.28906 24C9.69461 23.9971 10.0998 23.9738 10.503 23.9301L18.2572 16.1439C18.2572 16.11 18.2572 16.0782 18.2572 16.0464C18.2305 15.8866 18.2044 15.7311 18.1791 15.5799Z" />
      <Path d="M14.1106 22.9352L17.86 19.1694C18.058 18.6675 18.188 18.1411 18.2464 17.6045L12.2739 23.6031C12.9054 23.4381 13.5203 23.2145 14.1106 22.9352Z" />
      <Path d="M9.27284 14.4124C9.26938 14.4105 9.26644 14.4077 9.26424 14.4044C9.26204 14.4011 9.26064 14.3973 9.26017 14.3933C9.26017 14.3933 9.26017 14.4061 9.27706 14.4124H9.29817C9.33543 14.4308 9.3743 14.4457 9.41428 14.457L9.46284 14.4739L9.55151 14.5036L9.46495 14.4718L13.5141 10.4091C13.2376 10.2903 12.9737 10.1758 12.695 10.0698L9.29184 13.5643V12.1075L11.6352 9.68391C11.3375 9.58213 11.0758 9.48459 10.7908 9.38917L9.29184 10.9307V9.47399L9.73096 9.04991C9.58953 8.96495 9.43069 8.91347 9.2665 8.89936C7.97237 8.47528 7.29258 7.91337 7.29258 7.10549C7.29258 6.19796 7.99559 5.57031 9.29184 5.33919V4.20689L13.037 0.375313C12.714 0.301098 12.4206 0.235366 12.0891 0.182355L9.29184 3.02794V1.57122L10.7696 0.0339265C10.4783 0.0127224 10.1574 0 9.86818 0H9.64229L9.29184 0.394396V0.0212046C9.29184 0.0212046 9.27917 0.0212046 9.29184 0.0212046C4.97031 0.250209 1.05835 2.96221 1.05835 7.17547C1.05835 12.0969 5.97733 13.208 9.27284 14.4124Z" />
      <Path d="M16.6408 12.5019C16.4909 12.3428 16.3347 12.188 16.17 12.0439L16.1003 11.9866L16.0349 11.9336L11.9224 16.0535C11.9552 16.1068 11.9848 16.1621 12.011 16.2189C12.0122 16.2267 12.0122 16.2345 12.011 16.2423C12.0426 16.3171 12.068 16.3944 12.087 16.4734C12.087 16.4967 12.0976 16.52 12.1018 16.5434C12.1196 16.6349 12.1288 16.7279 12.1293 16.8211C12.1295 16.892 12.1253 16.9628 12.1166 17.0332L16.6408 12.5019Z" />
    </svg>
  );
};

export default SymphonyLetterLogo;
