export const RESOURCE_STATE_ACTIONS = {
  CHANGE_FILED_VALUE: 'CHANGE_FILED_VALUE',
};

export const changeFieldValue = (key, value) => ({
  type: RESOURCE_STATE_ACTIONS.CHANGE_FILED_VALUE,
  payload: { key, value },
});

export const exclusiveResourceStateReducer = (state, action) => {
  if (action.type === RESOURCE_STATE_ACTIONS.CHANGE_FILED_VALUE)
    return {
      ...state,
      [action.payload.key]: action.payload.value,
    };

  return new Error(`no such action: ${action.type}`);
};
