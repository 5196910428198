import { useAppContext } from 'contexts/AppContext';
import {
  CreateUpdateHireContainer,
  AddUpdateCompanyContainer,
  AddCompanyButton,
  UpdateCompanyButton,
  CreateUpdateHireWrapper,
} from 'pages/HomePage/components/CreateUpdateHire/CreateUpdateHire.styled';
import {
  ADD_A_COMPANY,
  UPDATE_A_COMPANY,
} from 'components/Forms/Company/constants';
import BrandsShowcase from '../../../../components/common/BrandsShowcase/BrandsShowcase';

const CreateUpdateHire = () => {
  const { setActiveForm } = useAppContext();

  return (
    <CreateUpdateHireContainer>
      <CreateUpdateHireWrapper>
        <BrandsShowcase />
        <AddUpdateCompanyContainer>
          <AddCompanyButton onClick={() => setActiveForm(ADD_A_COMPANY)}>
            {ADD_A_COMPANY}
          </AddCompanyButton>
          <UpdateCompanyButton onClick={() => setActiveForm(UPDATE_A_COMPANY)}>
            {UPDATE_A_COMPANY}
          </UpdateCompanyButton>
        </AddUpdateCompanyContainer>
      </CreateUpdateHireWrapper>
    </CreateUpdateHireContainer>
  );
};

export default CreateUpdateHire;
