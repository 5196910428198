import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BulkImportCompaniesCsv from 'pages/AdminPage/components/Forms/Company/BulkImportCompany/BulkImportCompaniesCsv';
import BulkImportCompaniesResp from 'pages/AdminPage/components/Forms/Company/BulkImportCompany/BulkImportCompaniesResp';
import { BULK_IMPORT_COMPANY_STEPS, isImportSuccess } from './constants';

const BulkImportCompanies = () => {
  const navigate = useNavigate();
  const closeForm = useCallback(() => {
    navigate({ pathname: '/administration/companies' });
  }, []);

  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const [currentStep, setCurrentStep] = useState(BULK_IMPORT_COMPANY_STEPS.CSV);
  const nextStep = () => setCurrentStep(BULK_IMPORT_COMPANY_STEPS.RESPONSE);
  const goBack = () => {
    if (isImportSuccess(status))
      navigate({ pathname: '/administration/companies' });

    setCurrentStep(BULK_IMPORT_COMPANY_STEPS.CSV);
    // clear all states
    setMessage('');
    setStatus(null);
    setSelectedFile(null);
  };

  return (
    <>
      {BULK_IMPORT_COMPANY_STEPS.CSV === currentStep && (
        <BulkImportCompaniesCsv
          closeForm={closeForm}
          nextStep={nextStep}
          setMessage={setMessage}
          status={status}
          setStatus={setStatus}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
        />
      )}
      {BULK_IMPORT_COMPANY_STEPS.RESPONSE === currentStep && (
        <BulkImportCompaniesResp
          closeForm={closeForm}
          goBack={goBack}
          message={message}
          status={status}
        />
      )}
    </>
  );
};

export default BulkImportCompanies;
