import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

const CompanyStatisticsContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;

  @media ${DEVICE.tablet} {
    flex-direction: column;
  }
`;

export default CompanyStatisticsContainer;
