import styled from 'styled-components';

export const TRSelectedForDeletion = styled.tr`
  background: transparent;
  height: 20px !important;
`;

export const TDSelectedForDeletion = styled.td``;

export const SelectedForDeletionWrapper = styled.div`
  padding: 4px;
  margin-bottom: 4px;
  align-items: center;
  border: 1px solid var(--WHITE_OR_RAISING_BLACK);
  border-radius: 4px;
  display: flex;
  gap: 0 5px;
  justify-content: center;
  height: 25px;
  width: 70px;
  cursor: pointer;

  p {
    color: var(--WHITE_OR_RAISING_BLACK);
    font-size: 12px;
    padding-top: 3px;
  }
`;
