import { useEffect, useRef, useState } from 'react';
import { StatusCodes } from 'http-status-codes';
import PropTypes from 'prop-types';
import {
  Wrapper,
  FormContainer,
  Title,
  ButtonsContainer,
  FieldContainer,
  RowContainer,
} from 'pages/AdminPage/components/Forms/PublicResource/EditPublicResource/EditPublicResource.styled';
import Spacer from 'components/common/Spacer/Spacer.styled';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import MultiSelect from 'components/common/Dropdown/MultiSelect';
import DragNDrop from 'components/common/DragNDrop/DragNDrop';
import HbsDatePicker from 'components/common/HbsDatePicker/HbsDatePicker';
import FormInput from 'components/common/FormComponents/Input/FormInput/FormInput';
import FormDescription from 'components/common/FormComponents/Input/FormDescription/FormDescription';
import {
  publicResourceDescriptionSchema,
  publicResourceInputSchema,
  publicResourceSelectSchema,
} from 'pages/AdminPage/components/Forms/PublicResource/EditPublicResource/schemas';
import { publicFormStateProps } from 'pages/AdminPage/components/Forms/PublicResource/EditPublicResource/propTypes';
import { INITIAL_ALERT_STATE } from 'components/Forms/SignForm/constants';
import usePublicResourceState from 'pages/AdminPage/components/Forms/PublicResource/EditPublicResource/hooks/usePublicResourceState';
import { updatePublicResourceById } from 'services/requests';
import useAdminPublicResourceStore from 'store/publicResource/useAdminPublicResourceStore';
import { SubTitle } from 'pages/AdminPage/components/Forms/Company/EditCompany/EditCombinedSteps/EditCombinedSteps.styled';
import AlertDanger from 'components/common/Alert/Alert';
import { BUTTONS_CSS, PUBLIC_KEYS } from './constants';
import { packPublicResource, preparePublicResourceData } from './utils';

const EditPublicCombinedSteps = ({
  closeForm,
  initialPublicResourceData,
  targetAudiences,
  publicDealCategories,
}) => {
  const currentDate = new Date();

  const {
    publicResourceData,
    validationErrors,
    inputOnChangeHandler,
    inputOnBlurHandler,
    descriptionOnBlurHandler,
    descriptionOnChangeHandler,
    multiSelectionHandler,
    selectDateHandler,
    setUploadedFile,
    setValidationError,
  } = usePublicResourceState(initialPublicResourceData);

  const [success, setSuccess] = useState(false);
  const [alert, setAlert] = useState(INITIAL_ALERT_STATE);
  const updatePublicResource = useAdminPublicResourceStore(
    (state) => state.updatePublicResource,
  );

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (alert.alertText === 'Success') {
        closeForm();
      }

      setAlert(INITIAL_ALERT_STATE);
    }, 1500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [alert]);

  const alertRef = useRef(null);

  const scrollIntoAlert = () =>
    alertRef.current.scrollIntoView({ behavior: 'smooth' });

  const submitPublicResource = async () => {
    scrollIntoAlert();

    try {
      const responseData = await updatePublicResourceById(
        {
          ...preparePublicResourceData(publicResourceData),
        },
        publicResourceData.id,
      );

      if (responseData.status === StatusCodes.OK) {
        setAlert({
          shouldLoadAlert: true,
          alertText: 'Success',
        });
        updatePublicResource({
          ...packPublicResource(publicResourceData),
          id: publicResourceData.id,
        });

        setSuccess(true);
      } else {
        throw new Error(`Failed: ${responseData.response}`);
      }
    } catch (error) {
      setAlert({
        shouldLoadAlert: true,
        alertText: error.response.data.message,
      });

      setSuccess(false);
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    let hasError = false;

    const keysInput = Object.keys(publicResourceInputSchema);
    const keysMultiSelect = Object.keys(publicResourceSelectSchema);
    const keysDescriptions = Object.keys(publicResourceDescriptionSchema);

    const inputsWithErrors = keysInput.filter(
      (key) =>
        !publicResourceInputSchema[key].validator(publicResourceData[key]),
    );
    const selectsWithErrors = keysMultiSelect.filter(
      (key) =>
        !publicResourceSelectSchema[key].validator(publicResourceData[key]),
    );
    const descriptionWithError = keysDescriptions.filter(
      (key) =>
        !publicResourceDescriptionSchema[key].validator(
          publicResourceData[key],
        ),
    );

    inputsWithErrors.forEach((key) => setValidationError(key, true));
    selectsWithErrors.forEach((key) => setValidationError(key, true));
    descriptionWithError.forEach((key) => setValidationError(key, true));

    if (
      inputsWithErrors.length ||
      selectsWithErrors.length ||
      descriptionWithError.length
    )
      hasError = true;

    if (hasError) {
      setAlert({
        shouldLoadAlert: true,
        alertText: 'Check for warnings first',
      });

      return;
    }

    submitPublicResource();
  };

  if (!initialPublicResourceData) return null;

  return (
    <Wrapper>
      <Title>Edit Resource info</Title>
      <SubTitle ref={alertRef}>Please fill in the data below</SubTitle>
      {alert.shouldLoadAlert ? (
        <AlertDanger
          alertText={alert.alertText}
          {...(success && { bgColor: '#dcedc8', textColor: '#2e7d32' })}
          showIcon={false}
        />
      ) : (
        <Spacer Height="40px" />
      )}
      <FormContainer>
        <FormInput
          {...publicResourceInputSchema[PUBLIC_KEYS.DEAL_NAME]}
          name={PUBLIC_KEYS.DEAL_NAME}
          value={publicResourceData[PUBLIC_KEYS.DEAL_NAME]}
          isError={validationErrors[PUBLIC_KEYS.DEAL_NAME]}
          onBlurHandler={inputOnBlurHandler(PUBLIC_KEYS.DEAL_NAME)}
          onChangeHandler={inputOnChangeHandler(PUBLIC_KEYS.DEAL_NAME)}
        />
        <RowContainer>
          <FieldContainer>
            <FormInput
              {...publicResourceInputSchema[PUBLIC_KEYS.DEAL_URL]}
              name={PUBLIC_KEYS.DEAL_URL}
              value={publicResourceData[PUBLIC_KEYS.DEAL_URL]}
              isError={validationErrors[PUBLIC_KEYS.DEAL_URL]}
              onBlurHandler={inputOnBlurHandler(PUBLIC_KEYS.DEAL_URL)}
              onChangeHandler={inputOnChangeHandler(PUBLIC_KEYS.DEAL_URL)}
            />
          </FieldContainer>
          <FieldContainer>
            <MultiSelect
              {...publicResourceSelectSchema[PUBLIC_KEYS.TARGET_AUDIENCES]}
              selectedValue={publicResourceData[PUBLIC_KEYS.TARGET_AUDIENCES]}
              options={targetAudiences}
              onChangeHandler={multiSelectionHandler(
                PUBLIC_KEYS.TARGET_AUDIENCES,
                targetAudiences,
              )}
              isError={validationErrors[PUBLIC_KEYS.TARGET_AUDIENCES]}
              disabledDarkMode
            />
            <Spacer Height="20px" />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <FieldContainer>
            <MultiSelect
              {...publicResourceSelectSchema[PUBLIC_KEYS.CATEGORIES]}
              selectedValue={publicResourceData[PUBLIC_KEYS.CATEGORIES]}
              options={publicDealCategories}
              onChangeHandler={multiSelectionHandler(
                PUBLIC_KEYS.CATEGORIES,
                publicDealCategories,
              )}
              isError={validationErrors[PUBLIC_KEYS.CATEGORIES]}
              disabledDarkMode
            />
            <Spacer Height="20px" />
          </FieldContainer>
          <FieldContainer>
            <HbsDatePicker
              selectedDate={publicResourceData[PUBLIC_KEYS.EXPIRING_DATE]}
              minDate={currentDate}
              setSelectedDate={selectDateHandler(PUBLIC_KEYS.EXPIRING_DATE)}
            />
          </FieldContainer>
        </RowContainer>
        <RowContainer>
          <FieldContainer>
            <FormDescription
              {...publicResourceDescriptionSchema[PUBLIC_KEYS.DEAL_DESCRIPTION]}
              value={publicResourceData[PUBLIC_KEYS.DEAL_DESCRIPTION]}
              name={PUBLIC_KEYS.DEAL_DESCRIPTION}
              onChangeHandler={descriptionOnChangeHandler(
                PUBLIC_KEYS.DEAL_DESCRIPTION,
              )}
              onBlurHandler={descriptionOnBlurHandler(
                PUBLIC_KEYS.DEAL_DESCRIPTION,
              )}
            />
          </FieldContainer>
          <FieldContainer>
            <DragNDrop
              uploadedFile={publicResourceData[PUBLIC_KEYS.DEAL_IMAGE]}
              setUploadedFile={setUploadedFile(PUBLIC_KEYS.DEAL_IMAGE)}
              label="Deal logo"
              dndParagraph="Drag and drop or upload a company logo"
            />
          </FieldContainer>
        </RowContainer>
        <Spacer Height="10px" />
        <RowContainer>
          <FieldContainer>
            <FormInput
              {...publicResourceInputSchema[PUBLIC_KEYS.CONTACT_NAME]}
              name={PUBLIC_KEYS.CONTACT_NAME}
              value={publicResourceData[PUBLIC_KEYS.CONTACT_NAME]}
              isError={validationErrors[PUBLIC_KEYS.CONTACT_NAME]}
              onBlurHandler={inputOnBlurHandler(PUBLIC_KEYS.CONTACT_NAME)}
              onChangeHandler={inputOnChangeHandler(PUBLIC_KEYS.CONTACT_NAME)}
            />
          </FieldContainer>
          <FieldContainer>
            <FormInput
              {...publicResourceInputSchema[PUBLIC_KEYS.CONTACT_EMAIL]}
              name={PUBLIC_KEYS.CONTACT_EMAIL}
              value={publicResourceData[PUBLIC_KEYS.CONTACT_EMAIL]}
              isError={validationErrors[PUBLIC_KEYS.CONTACT_EMAIL]}
              onBlurHandler={inputOnBlurHandler(PUBLIC_KEYS.CONTACT_EMAIL)}
              onChangeHandler={inputOnChangeHandler(PUBLIC_KEYS.CONTACT_EMAIL)}
            />
          </FieldContainer>
        </RowContainer>
        <ButtonsContainer>
          <SecondaryButton {...BUTTONS_CSS.secondary} onClick={closeForm}>
            Cancel
          </SecondaryButton>
          <Spacer Width="16px" />
          <PrimaryButton {...BUTTONS_CSS.primary} onClick={submitForm}>
            Submit
          </PrimaryButton>
        </ButtonsContainer>
      </FormContainer>
    </Wrapper>
  );
};

EditPublicCombinedSteps.propTypes = {
  closeForm: PropTypes.func.isRequired,
  initialPublicResourceData: publicFormStateProps().isRequired,
  targetAudiences: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  ).isRequired,
  publicDealCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    }),
  ).isRequired,
};

export default EditPublicCombinedSteps;
