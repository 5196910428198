import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper,
  FormContainer,
  Step,
  Title,
  TitleContainer,
  FieldContainer,
  ButtonsContainer,
  RowContainer,
  TitleParagraph,
  ClickableDiv,
} from 'components/Forms/Company/StepThree/StepThree.styled';
import Recaptcha from 'components/common/Recaptcha/Recaptcha';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import BackArrow from 'assets/icons/BackArrow';
import Spacer from 'components/common/Spacer/Spacer.styled';
import Founder from 'components/Forms/Company/StepThree/Founder/Founder';
import AddIcon from 'assets/icons/AddIcon';
import FOUNDER_INITIAL_STATE, {
  FoundersSelectSchema,
} from 'components/Forms/Company/StepThree/schema';
import { formStateProp } from 'components/Forms/Company/propTypes';
import MultiSelect from 'components/common/Dropdown/MultiSelect';
import STEP_THREE_KEYS from 'components/Forms/Company/StepThree/constants';
import { getAllFounderAttributes } from 'services/requests';
import { mapFounderAttributes } from 'components/Forms/Company/StepTwo/utils';
import useRequest from 'hooks/useRequest';
import { Divider } from 'components/common/Divider/Divider.styled';
import { RAISIN_BLACK_ALPHA_03 } from 'constants/colors';

const StepThree = ({
  backStep,
  nextStep,
  currentStep,
  closeForm,
  formState,
  setFormState,
}) => {
  const { request } = useRequest();
  const [numberOfFounders, setNumberOfFounders] = useState(
    formState.founders.length,
  );
  const [founderAttributes, setFounderAttributes] = useState([]);
  const [captchaState, setCaptchaState] = useState(false);
  const [selectsState, setSelectsState] = useState(FoundersSelectSchema);

  useEffect(() => {
    let ignore = false;

    const getFounderAttributes = async () => {
      const responseData = await request(() => getAllFounderAttributes());

      if (!ignore && responseData.data)
        setFounderAttributes(mapFounderAttributes(responseData.data));
    };

    getFounderAttributes();

    return () => {
      ignore = true;
    };
  }, []);

  const setFounder = (position) => (founderState) =>
    setFormState((prevState) => {
      const index = prevState.founders.findIndex(
        (founder) => founder.position === position,
      );
      const foundersCopy = formState.founders;

      foundersCopy[index] = founderState;

      return {
        ...prevState,
        founders: foundersCopy,
      };
    });

  const multiSelectionHandler = (key, collection) => (options) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]:
        options &&
        options.map((option) =>
          collection.find((object) => object.id === option.id),
        ),
    }));
    setSelectsState((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        isError: false,
      },
    }));
  };

  const backAndSave = (e) => {
    e.preventDefault();

    backStep();
  };

  const nextAndSave = (e) => {
    e.preventDefault();

    let hasError = false;

    const keys = Object.keys(FOUNDER_INITIAL_STATE);

    formState.founders.forEach((founder) => {
      const fieldWithErrors = keys.filter((key) => {
        return (
          key !== 'classYear' && !founder[key].validator(founder[key].value)
        );
      });
      fieldWithErrors.push(
        ...(!founder.classYear.validator(founder.classYear.selectedValue)
          ? ['classYear']
          : []),
      );

      if (fieldWithErrors.length) hasError = true;

      const founderCopy = founder;

      fieldWithErrors.forEach((key) => {
        founderCopy[key] = { ...founder[key], isError: true };
      });

      setFounder(founder.position)(founderCopy);
    });
    if (!hasError && captchaState) nextStep();
  };

  const sliceFounders = (prevState, position) => ({
    ...prevState,
    founders: prevState.founders
      .filter((founder) => founder.position !== position)
      .map((founder) =>
        founder.position > position
          ? { ...founder, position: founder.position - 1 }
          : founder,
      ),
  });

  const removeFounder = (e, position) => {
    e.preventDefault();

    setFormState((prevState) => sliceFounders(prevState, position));
    setNumberOfFounders((prevState) => prevState - 1);
  };

  const addFounder = (e) => {
    e.preventDefault();
    setNumberOfFounders((prevState) => prevState + 1);
    setFormState((prevState) => ({
      ...prevState,
      founders: [
        ...prevState.founders,
        { ...FOUNDER_INITIAL_STATE, position: numberOfFounders + 1 },
      ],
    }));
  };

  return (
    <Wrapper>
      <FormContainer>
        <TitleContainer>
          <Step>
            <SecondaryButton Border="none" noDark onClick={backAndSave}>
              <BackArrow />
            </SecondaryButton>
            {`Step ${currentStep}`}
          </Step>
          <Title>Team Info</Title>
          <TitleParagraph>
            Add info about founders. You can add up to 3 founders and select
            their attributes.
          </TitleParagraph>
        </TitleContainer>
        <Spacer Height="10px" />
        <RowContainer>
          <FieldContainer>
            <MultiSelect
              key={STEP_THREE_KEYS.FOUNDER_ATTRIBUTES}
              {...selectsState[STEP_THREE_KEYS.FOUNDER_ATTRIBUTES]}
              selectedValue={formState[STEP_THREE_KEYS.FOUNDER_ATTRIBUTES]}
              options={founderAttributes}
              onChangeHandler={multiSelectionHandler(
                STEP_THREE_KEYS.FOUNDER_ATTRIBUTES,
                founderAttributes,
              )}
              disabledDarkMode
            />
          </FieldContainer>
        </RowContainer>
        <Spacer Height="20px" />
        <Divider color={RAISIN_BLACK_ALPHA_03} />
        <Spacer Height="25px" />
        <div key={numberOfFounders}>
          {formState.founders.map((founder) => (
            <Founder
              key={founder.position}
              founder={founder}
              setFounder={setFounder(founder.position)}
              position={+founder.position}
              removeFounder={removeFounder}
            />
          ))}
        </div>
        {numberOfFounders < 4 && (
          <RowContainer>
            <FieldContainer>
              <ClickableDiv>
                <p>
                  HBSBer #{numberOfFounders + 1} on Founding Team (if
                  applicable)
                </p>
                <button onClick={addFounder} type="button">
                  <AddIcon />
                </button>
              </ClickableDiv>
              <Spacer Heigth="30px" />
            </FieldContainer>
          </RowContainer>
        )}
      </FormContainer>

      <Recaptcha setCaptchaState={setCaptchaState} />
      <Spacer Height="40px" />

      <ButtonsContainer>
        <SecondaryButton
          Border="1px solid #545556"
          Width="83px"
          Height="40px"
          FontWeight="600"
          FontSize="14px"
          type="button"
          noDark
          onClick={closeForm}
        >
          Cancel
        </SecondaryButton>
        <Spacer Width="16px" />
        <PrimaryButton
          Height="40px"
          FontWeight="600"
          FontSize="14px"
          Width="173px"
          Border="none"
          type="button"
          onClick={nextAndSave}
          disabled={!captchaState}
        >
          Submit
        </PrimaryButton>
      </ButtonsContainer>
      <Spacer Heigth="10px" />
    </Wrapper>
  );
};

StepThree.propTypes = {
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  formState: formStateProp().isRequired,
};

export default StepThree;
