import styled from 'styled-components';

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px 7% 0;
`;

export const WelcomeAndAnchorContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
