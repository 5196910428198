import useRequest from 'hooks/useRequest';
import { useEffect, useState } from 'react';
import { getEventCategories } from 'services/requests';
import { EVENT_AUDIENCE, EVENT_ORGANIZERS } from './constants';
import {
  mapEventAudiencesToOptions,
  mapEventOrganizersToOptions,
} from './StepTwo/utils';

const useAudienceOranizersData = () => {
  const [audience, setAudience] = useState([]);
  const [organizers, setOrganizers] = useState([]);
  const { request } = useRequest();

  const fetchOptions = async () => {
    const allData = await request(getEventCategories);

    if (allData) {
      setAudience(mapEventAudiencesToOptions(allData[0].data, EVENT_AUDIENCE));
      setOrganizers(
        mapEventOrganizersToOptions(allData[1].data, EVENT_ORGANIZERS),
      );
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  return {
    audience,
    organizers,
  };
};

export default useAudienceOranizersData;
