import { useEffect, useState } from 'react';
import useRequest from 'hooks/useRequest';
import { getExclusiveResourceById } from 'services/requests';
import { unpackExclusiveResource } from 'pages/AdminPage/components/Forms/ExclusiveResource/EditExclusiveResource/utils';

const useFetchExclusiveResourceById = (id) => {
  const { request } = useRequest();

  const [isLoading, setIsLoading] = useState(true);
  const [initialExclusiveResourceData, setInitialExclusiveResourceData] =
    useState(null);

  useEffect(() => {
    const getResource = async () => {
      setIsLoading(true);

      const responseData = await request(() => getExclusiveResourceById(id));

      if (responseData) {
        setInitialExclusiveResourceData((prevState) => ({
          ...prevState,
          ...unpackExclusiveResource(responseData.data),
        }));
      }

      setIsLoading(false);
    };

    getResource();
  }, [id]);

  return { isLoading, initialExclusiveResourceData };
};

export default useFetchExclusiveResourceById;
