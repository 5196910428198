import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Wrapper,
  RowContainer,
} from 'components/Forms/Events/StepTwo/StepTwo.styled';
import {
  TitleContainer,
  Title,
  Step,
  FormContainer,
  ButtonsContainer,
  FieldContainer,
} from 'components/Forms/Events/StepOne/StepOne.styled';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';
import BackArrow from 'assets/icons/BackArrow';
import FormInput from 'components/common/FormComponents/Input/FormInput/FormInput';
import MultiSelect from 'components/common/Dropdown/MultiSelect';
import FormDescription from 'components/common/FormComponents/Input/FormDescription/FormDescription';
import DragNDrop from 'components/common/DragNDrop/DragNDrop';
import Spacer from 'components/common/Spacer/Spacer.styled';
import {
  STEP_TWO_KEYS,
  BUTTONS_CSS,
} from 'components/Forms/Events/StepTwo/constants';
import {
  EventStepTwoInputSchema,
  EventStepTwoDescriptionSchema,
  EventStepTwoSelectSchema,
} from 'components/Forms/Events/StepTwo/schema';
import useFormInput from 'components/common/FormComponents/Input/FormInput/useFormInput';
import { eventFormStateProps } from 'components/Forms/Events/propTypes';

const StepTwo = ({
  currentStep,
  backStep,
  nextStep,
  closeForm,
  setFormState,
  formState,
  update,
  audience,
  organizers,
}) => {
  const [inputsState, setInputsState] = useState(EventStepTwoInputSchema);
  const [selectsState, setSelectsState] = useState(EventStepTwoSelectSchema);
  const [descriptionState, setDescriptionState] = useState(
    EventStepTwoDescriptionSchema,
  );

  const { onBlurHandler, triggerError } = useFormInput();

  const setUploadedFile = (data) =>
    setFormState((prevState) => ({
      ...prevState,
      [STEP_TWO_KEYS.EVENT_LOGO]: data,
    }));

  const onChangeHandler =
    (stateHandler, key) =>
    ({ target: { value } }) => {
      setFormState((prevState) => ({ ...prevState, [key]: value }));
      stateHandler((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          isError: prevState[key].isError && !prevState[key].validator(value),
        },
      }));
    };

  const multiSelectionHandler = (key, collection) => (options) => {
    setFormState((prevState) => ({
      ...prevState,
      [key]:
        options &&
        options.map((option) =>
          collection.find((object) => object.id === option.id),
        ),
    }));
    setSelectsState((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        isError: false,
      },
    }));
  };

  const onClickSubmitButton = (e) => {
    e.preventDefault();

    const keysDescription = Object.keys(descriptionState);

    const descriptionWithError = keysDescription.filter(
      (key) => !descriptionState[key].validator(formState[key]),
    );

    descriptionWithError.forEach((key) =>
      triggerError(setDescriptionState, key),
    );

    if (descriptionWithError.length) return;

    nextStep();
  };

  return (
    <Wrapper>
      <TitleContainer>
        <Step>
          <SecondaryButton
            Border="none"
            noDark
            type="button"
            onClick={backStep}
          >
            <BackArrow />
          </SecondaryButton>
          {`Step ${currentStep}`}
        </Step>
        <Title>General Info</Title>
      </TitleContainer>
      <Spacer Height="20px" />
      <FormContainer>
        <FieldContainer fullWidth>
          <FormInput
            {...inputsState[STEP_TWO_KEYS.EVENT_LOCATION]}
            value={formState[STEP_TWO_KEYS.EVENT_LOCATION]}
            onChangeHandler={onChangeHandler(
              setInputsState,
              STEP_TWO_KEYS.EVENT_LOCATION,
            )}
            onBlurHandler={onBlurHandler(
              setInputsState,
              STEP_TWO_KEYS.EVENT_LOCATION,
            )}
            name={STEP_TWO_KEYS.EVENT_LOCATION}
            disabledDarkMode
          />
        </FieldContainer>
        <RowContainer>
          <FieldContainer>
            <MultiSelect
              key={`${STEP_TWO_KEYS.AUDIENCE}+${audience.length}`}
              {...selectsState[STEP_TWO_KEYS.AUDIENCE]}
              selectedValue={formState[STEP_TWO_KEYS.AUDIENCE]}
              options={audience}
              onChangeHandler={multiSelectionHandler(
                STEP_TWO_KEYS.AUDIENCE,
                audience,
              )}
              disabledDarkMode
            />
          </FieldContainer>
          <FieldContainer>
            <MultiSelect
              key={`${STEP_TWO_KEYS.ORGANIZERS}+${organizers.length}`}
              {...selectsState[STEP_TWO_KEYS.ORGANIZERS]}
              selectedValue={formState[STEP_TWO_KEYS.ORGANIZERS]}
              options={organizers}
              onChangeHandler={multiSelectionHandler(
                STEP_TWO_KEYS.ORGANIZERS,
                organizers,
              )}
              disabledDarkMode
            />
          </FieldContainer>
        </RowContainer>
        <Spacer Height="24px" />
        <RowContainer>
          <FieldContainer>
            <FormDescription
              {...descriptionState[STEP_TWO_KEYS.EVENT_DESCRIPTION]}
              name={STEP_TWO_KEYS.EVENT_DESCRIPTION}
              value={formState[STEP_TWO_KEYS.EVENT_DESCRIPTION]}
              onChangeHandler={onChangeHandler(
                setDescriptionState,
                STEP_TWO_KEYS.EVENT_DESCRIPTION,
              )}
              onBlurHandler={onBlurHandler(
                setDescriptionState,
                STEP_TWO_KEYS.EVENT_DESCRIPTION,
              )}
              disabledDarkMode
            />
          </FieldContainer>
          <FieldContainer>
            <DragNDrop
              uploadedFile={formState[STEP_TWO_KEYS.EVENT_LOGO]}
              setUploadedFile={setUploadedFile}
              label="Poster/Logo"
              dndParagraph="Drag and drop or upload an event poster/logo"
            />
          </FieldContainer>
        </RowContainer>
        <ButtonsContainer>
          <SecondaryButton
            {...BUTTONS_CSS.secondary}
            noDark
            onClick={closeForm}
          >
            Cancel
          </SecondaryButton>
          <Spacer Width="16px" />
          <PrimaryButton {...BUTTONS_CSS.primary} onClick={onClickSubmitButton}>
            {`${update ? 'Update' : 'Submit'} event`}
          </PrimaryButton>
        </ButtonsContainer>
      </FormContainer>
    </Wrapper>
  );
};

StepTwo.defaultProps = {
  update: false,
};

StepTwo.propTypes = {
  backStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
  formState: eventFormStateProps().isRequired,
  audience: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      audienceName: PropTypes.string,
      length: PropTypes.number,
    }),
  ).isRequired,
  organizers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      organizerName: PropTypes.string,
      length: PropTypes.number,
    }),
  ).isRequired,
  update: PropTypes.bool,
};

export default StepTwo;
