import PropTypes from 'prop-types';
import FilterIcon from 'assets/icons/FilterIcon';
import { FilterButton } from './FilterControls.styled';

const ToggleFilters = ({ toggleFilters, isShowFilters }) => (
  <FilterButton onClick={toggleFilters}>
    <FilterIcon />
    {isShowFilters ? 'Hide ' : 'Show '}
    Filters
  </FilterButton>
);

ToggleFilters.propTypes = {
  toggleFilters: PropTypes.func.isRequired,
  isShowFilters: PropTypes.bool.isRequired,
};

export default ToggleFilters;
