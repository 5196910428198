import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import useClickOutside from 'hooks/useClickOutside';
import {
  ModalScreenContainer,
  ModalContentContainer,
  ModalProgress,
  ModalCloseButton,
  ModalFormContent,
  MobileBottomSpacer,
} from 'components/common/Modal/Modal.styled';
import ModalCloseButtonIcon from 'assets/icons/ModalCloseButtonIcon';
import useDisableScroll from 'hooks/useDisableScroll';
import closeModalOnKeyPress from 'utils/closeModalOnKeyPress';

const Modal = ({ progress, closeForm, children, closeOnEscape, showClose }) => {
  const contentRef = useRef(null);
  useClickOutside(contentRef, closeForm);

  useDisableScroll();

  useEffect(() => {
    if (contentRef) contentRef.current.focus();
  }, [contentRef]);

  return (
    <ModalScreenContainer>
      <ModalContentContainer
        tabIndex={0}
        onKeyDown={
          closeOnEscape ? closeModalOnKeyPress(closeForm, 'Escape') : null
        }
        ref={contentRef}
      >
        <ModalProgress progress={progress} />
        {showClose && (
          <ModalCloseButton onClick={closeForm}>
            <ModalCloseButtonIcon />
          </ModalCloseButton>
        )}
        <ModalFormContent>
          {children}
          <MobileBottomSpacer />
        </ModalFormContent>
      </ModalContentContainer>
    </ModalScreenContainer>
  );
};

Modal.propTypes = {
  progress: PropTypes.string,
  closeForm: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  closeOnEscape: PropTypes.bool,
  showClose: PropTypes.bool,
};

Modal.defaultProps = {
  progress: '0%',
  closeOnEscape: false,
  showClose: true,
};

export default Modal;
