export const BUTTONS_CSS = {
  Border: 'none',
  Height: '40px',
  Width: '220px',
  type: 'button',
};

export const STATIC_TEXT = {
  START_TITLE: 'Add an investor',
  START_DESCRIPTION: `HBSBuilds is the definitive platform to find companies with HBS students or alums on the founding team. Please check (http://hbsbuilds.com/) to make sure your company is not already listed before continuing.`,
  CONTACT_INFO: `For questions or assistance, please reach out to`,
};
