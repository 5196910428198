import PropTypes from 'prop-types';
import Spacer from 'components/common/Spacer/Spacer.styled';
import DatePicker from 'react-datepicker';
import DatePickerInput from 'components/common/HbsDatePicker/DatePickerInput';
import {
  DatePickerContainer,
  DatePickerLabel,
  ErrorMessage,
} from 'components/common/HbsDatePicker/HbsDatePicker.styled';
import SHOW_TIME_PROPS from 'components/common/HbsDatePicker/constants';

const HbsDatePicker = ({
  selectedDate,
  minDate,
  setSelectedDate,
  Width,
  isError,
  showTime,
  onBlurDateHandler,
  warnMsg,
  useMinDate,
}) => {
  const extendedProps = {
    minDate,
  };
  return (
    <DatePickerContainer Width={Width} showTime={showTime}>
      <DatePickerLabel>
        {!showTime ? 'Expiration date' : 'Event Date*'}
      </DatePickerLabel>
      <DatePicker
        autoFocus
        selected={selectedDate}
        onChange={(date) => setSelectedDate(date)}
        customInput={<DatePickerInput isError={isError} />}
        placeholderText="Click to select a date"
        wrapperClassName="datepicker"
        showPopperArrow={false}
        isClearable
        onCalendarClose={onBlurDateHandler}
        {...(showTime ? SHOW_TIME_PROPS : {})}
        {...(useMinDate && extendedProps)}
      />
      {isError && <ErrorMessage>{warnMsg}</ErrorMessage>}
      <Spacer Height="24px" />
    </DatePickerContainer>
  );
};

HbsDatePicker.defaultProps = {
  Width: '100%',
  selectedDate: null,
  isError: false,
  showTime: false,
  warnMsg: '',
  onBlurDateHandler: () => {},
  useMinDate: true,
};

HbsDatePicker.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date).isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  Width: PropTypes.string,
  isError: PropTypes.bool,
  showTime: PropTypes.bool,
  onBlurDateHandler: PropTypes.func,
  warnMsg: PropTypes.string,
  useMinDate: PropTypes.bool,
};

export default HbsDatePicker;
