import styled from 'styled-components';
import DEVICE from 'constants/deviceSizes';
import { CRIMSON, RAISIN_BLACK } from 'constants/colors';

export const Wrapper = styled.div`
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin: 0px 72px;
  width: 780px;

  @media ${DEVICE.tablet} {
    margin: 0px 20px;
    width: 450px;
  }

  @media ${DEVICE.formResponsive} {
    margin: 0px 20px;
    width: 335px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 85px;
  width: 100%;
`;

export const Step = styled.p`
  color: ${CRIMSON};
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;

  margin: 0;
`;

export const Title = styled.p`
  color: ${RAISIN_BLACK};
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  text-align: left;
  margin: 0;
`;

export const SelectContainer = styled.div`
  display: flex;
  height: 126px;
  margin-top: 32px;
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  height: 142px;
  justify-content: flex-end;
`;
