import DEVICE from 'constants/deviceSizes';
import styled from 'styled-components';

export const FilterControlsContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  @media ${DEVICE.formResponsive} {
    justify-content: space-between;
    gap: auto;
  }
`;

export const ClearResourcesFiltersButton = styled.button`
  height: 35px;
  padding: 0 12px;
  border: 1px solid var(--GRAY_80_OR_RAISIN_BLACK_ALPHA_03);
  border-radius: 4px;
  color: var(--GRAY_80_OR_DAVY_GRAY);
  font-size: 12px;
  font-weight: 600;
  background: transparent;
  cursor: pointer;
`;

export const ToggleResourcesFiltersButton = styled.button`
  display: flex;
  align-items: center;
  width: 120px;
  justify-content: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 600;
  background: transparent;
  border: 1px solid var(--GRAY_80_OR_RAISIN_BLACK_ALPHA_03);
  border-radius: 4px;
  color: var(--GRAY_80_OR_DAVY_GRAY);
  cursor: pointer;

  svg > path {
    fill: var(--GRAY_80_OR_DAVY_GRAY);
  }
`;
