import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PrimaryButton } from 'components/common/Button/Button.styled';
import { CRIMSON, DAVY_GRAY } from 'constants/colors';
import DEVICE from 'constants/deviceSizes';

export const FundingPageContainer = styled.div`
  padding: 20px 75px 0;

  @media ${DEVICE.laptop} {
    padding: 20px 50px 0;
  }
  @media ${DEVICE.formResponsive} {
    padding: 20px 25px 0;
  }
`;

export const LandingSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: space-between;
  width: 100%;

  @media ${DEVICE.laptop} {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    max-width: 450px;
  }
`;

export const LandingSection = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
`;

export const ActionSectionContainer = styled.div`
  border: 1px solid var(--DARK_CHARCOAL_OR_BRIGHT_GRAY);
  background-color: var(--RAISING_BLACK_OR_WHITE);
  width: 100%;
  max-width: 480px;
`;

export const ActionSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px 32px;
  gap: 15px;
`;

export const AddInvestorButton = styled(PrimaryButton)`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 11px 33px;
  border: none;
  background-color: ${(props) => (props.disabled ? DAVY_GRAY : CRIMSON)};
`;

export const AddInvestorDisabledNoticeText = styled.p`
  font-size: 12px;
  color: ${DAVY_GRAY};
  margin-top: 10px;
  text-align: center;
`;

export const FundingPigWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ReachFoundersLint = styled(Link)`
  font-size: 12px;
  color: var(--GRAY_80_OR_DAVY_GRAY);
  text-decoration: underline !important;
  font-weight: 600;
`;

export const UnavailableWrap = styled.span`
  color: ${CRIMSON};
  font-size: 12px;
  text-decoration: underline;
  width: 80px;
`;
