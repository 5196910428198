import PropTypes from 'prop-types';
import BinIcon from 'assets/icons/BinIcon';
import {
  SelectedForDeletionWrapper,
  TDSelectedForDeletion,
  TRSelectedForDeletion,
} from 'pages/AdminPage/components/AdminTable/AdditionalElements/DeleteSelected/DeleteSelected.styled';

const DeleteSelected = ({ colSpan, callBackFunc }) => (
  <TRSelectedForDeletion>
    <TDSelectedForDeletion colSpan={colSpan}>
      <SelectedForDeletionWrapper onClick={callBackFunc}>
        <BinIcon fillColor="var(--WHITE_OR_RAISING_BLACK)" />
        <p>Delete</p>
      </SelectedForDeletionWrapper>
    </TDSelectedForDeletion>
  </TRSelectedForDeletion>
);

DeleteSelected.propTypes = {
  colSpan: PropTypes.string.isRequired,
  callBackFunc: PropTypes.func.isRequired,
};

export default DeleteSelected;
