import { CRIMSON } from 'constants/colors';
import DEVICE from 'constants/deviceSizes';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

const AnchorPartnerSlideIn = keyframes`
    from {
      width: 60px;
    }

    to {
      width: 289px;
    }
`;

const AnchorPartnerContentFadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

export const FloatingAnchorPartnerContainer = styled.div`
  position: fixed;
  top: 30%;
  right: 0;
  border: 1px solid var(--DAVY_GRAY_OR_BRIGHT_GRAY);
  border-width: 1px 0 1px 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  padding: 5px;
  background-color: var(--RAISING_BLACK_OR_WHITE);
  transition: all 0.5s ease;

  @media ${DEVICE.tablet} {
    display: none;
  }

  :hover {
    width: 289px;
    animation: 0.5s ${AnchorPartnerSlideIn};
  }
`;

export const SymphonyLetterLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${FloatingAnchorPartnerContainer}:hover & {
    display: none;
  }
`;

export const LearnMoreLink = styled(Link)`
  font-size: 12px;
  color: ${CRIMSON};
  text-decoration: underline !important;
  font-weight: 600;
`;

export const AnchorPartnerContent = styled.div`
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: absolute;
  align-items: center;

  p {
    font-size: 12px;
  }

  ${FloatingAnchorPartnerContainer}:hover & {
    animation: 0.25s ${AnchorPartnerContentFadeIn} forwards;
    animation-delay: 0.2s;
  }
`;

export const AnchorPartnerDetails = styled.a`
  display: flex;
  align-items: center;
  gap: 5px;
  color: inherit;

  & > svg {
    height: 24px;
    width: 130px;
  }
`;
