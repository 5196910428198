const CalendarIcon = () => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8333 15.834H2.16667V6.66732H13.8333M11.3333 0.833984V2.50065H4.66667V0.833984H3V2.50065H2.16667C1.24167 2.50065 0.5 3.24232 0.5 4.16732V15.834C0.5 16.276 0.675595 16.6999 0.988155 17.0125C1.30072 17.3251 1.72464 17.5007 2.16667 17.5007H13.8333C14.2754 17.5007 14.6993 17.3251 15.0118 17.0125C15.3244 16.6999 15.5 16.276 15.5 15.834V4.16732C15.5 3.24232 14.75 2.50065 13.8333 2.50065H13V0.833984M12.1667 10.0007H8V14.1673H12.1667V10.0007Z"
      fill="#545556"
    />
  </svg>
);

export default CalendarIcon;
