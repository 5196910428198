import PropTypes from 'prop-types';
import { CRIMSON } from 'constants/colors';

const HBSBuildsLogo = ({ fill = CRIMSON }) => (
  <svg
    width="82"
    height="14"
    viewBox="0 0 82 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7202 1.76796V13H7.98419V8.37596H3.72819V13H0.992188V1.76796H3.72819V6.16796H7.98419V1.76796H10.7202Z"
      fill={fill}
    />
    <path
      d="M19.4953 7.23996C20.146 7.37863 20.6686 7.70396 21.0633 8.21596C21.458 8.71729 21.6553 9.29329 21.6553 9.94396C21.6553 10.8826 21.3246 11.6293 20.6633 12.184C20.0126 12.728 19.1006 13 17.9273 13H12.6953V1.76796H17.7513C18.8926 1.76796 19.7833 2.02929 20.4233 2.55196C21.074 3.07463 21.3993 3.78396 21.3993 4.67996C21.3993 5.34129 21.2233 5.89063 20.8713 6.32796C20.53 6.76529 20.0713 7.06929 19.4953 7.23996ZM15.4313 6.31196H17.2233C17.6713 6.31196 18.0126 6.21596 18.2473 6.02396C18.4926 5.82129 18.6153 5.52796 18.6153 5.14396C18.6153 4.75996 18.4926 4.46663 18.2473 4.26396C18.0126 4.06129 17.6713 3.95996 17.2233 3.95996H15.4313V6.31196ZM17.4473 10.792C17.906 10.792 18.258 10.6906 18.5033 10.488C18.7593 10.2746 18.8873 9.97063 18.8873 9.57596C18.8873 9.18129 18.754 8.87196 18.4873 8.64796C18.2313 8.42396 17.874 8.31196 17.4153 8.31196H15.4313V10.792H17.4473Z"
      fill={fill}
    />
    <path
      d="M27.3062 13.112C26.4849 13.112 25.7489 12.9786 25.0982 12.712C24.4475 12.4453 23.9249 12.0506 23.5302 11.528C23.1462 11.0053 22.9435 10.376 22.9222 9.63996H25.8342C25.8769 10.056 26.0209 10.376 26.2662 10.6C26.5115 10.8133 26.8315 10.92 27.2262 10.92C27.6315 10.92 27.9515 10.8293 28.1862 10.648C28.4209 10.456 28.5382 10.1946 28.5382 9.86396C28.5382 9.58663 28.4422 9.35729 28.2502 9.17596C28.0689 8.99463 27.8395 8.84529 27.5622 8.72796C27.2955 8.61063 26.9115 8.47729 26.4102 8.32796C25.6849 8.10396 25.0929 7.87996 24.6342 7.65596C24.1755 7.43196 23.7809 7.10129 23.4502 6.66396C23.1195 6.22663 22.9542 5.65596 22.9542 4.95196C22.9542 3.90663 23.3329 3.09063 24.0902 2.50396C24.8475 1.90663 25.8342 1.60796 27.0502 1.60796C28.2875 1.60796 29.2849 1.90663 30.0422 2.50396C30.7995 3.09063 31.2049 3.91196 31.2582 4.96796H28.2982C28.2769 4.60529 28.1435 4.32263 27.8982 4.11996C27.6529 3.90663 27.3382 3.79996 26.9542 3.79996C26.6235 3.79996 26.3569 3.89063 26.1542 4.07196C25.9515 4.24263 25.8502 4.49329 25.8502 4.82396C25.8502 5.18663 26.0209 5.46929 26.3622 5.67196C26.7035 5.87463 27.2369 6.09329 27.9622 6.32796C28.6875 6.57329 29.2742 6.80796 29.7222 7.03196C30.1809 7.25596 30.5755 7.58129 30.9062 8.00796C31.2369 8.43463 31.4022 8.98396 31.4022 9.65596C31.4022 10.296 31.2369 10.8773 30.9062 11.4C30.5862 11.9226 30.1169 12.3386 29.4982 12.648C28.8795 12.9573 28.1489 13.112 27.3062 13.112Z"
      fill={fill}
    />
    <path
      d="M39.8859 7.23996C40.5366 7.37863 41.0593 7.70396 41.4539 8.21596C41.8486 8.71729 42.0459 9.29329 42.0459 9.94396C42.0459 10.8826 41.7153 11.6293 41.0539 12.184C40.4033 12.728 39.4913 13 38.3179 13H33.0859V1.76796H38.1419C39.2833 1.76796 40.1739 2.02929 40.8139 2.55196C41.4646 3.07463 41.7899 3.78396 41.7899 4.67996C41.7899 5.34129 41.6139 5.89063 41.2619 6.32796C40.9206 6.76529 40.4619 7.06929 39.8859 7.23996ZM35.8219 6.31196H37.6139C38.0619 6.31196 38.4033 6.21596 38.6379 6.02396C38.8833 5.82129 39.0059 5.52796 39.0059 5.14396C39.0059 4.75996 38.8833 4.46663 38.6379 4.26396C38.4033 4.06129 38.0619 3.95996 37.6139 3.95996H35.8219V6.31196ZM37.8379 10.792C38.2966 10.792 38.6486 10.6906 38.8939 10.488C39.1499 10.2746 39.2779 9.97063 39.2779 9.57596C39.2779 9.18129 39.1446 8.87196 38.8779 8.64796C38.6219 8.42396 38.2646 8.31196 37.8059 8.31196H35.8219V10.792H37.8379Z"
      fill={fill}
    />
    <path
      d="M52.4488 4.07196V13H49.7128V11.784C49.4355 12.1786 49.0568 12.4986 48.5768 12.744C48.1075 12.9786 47.5848 13.096 47.0088 13.096C46.3261 13.096 45.7235 12.9466 45.2008 12.648C44.6781 12.3386 44.2728 11.896 43.9848 11.32C43.6968 10.744 43.5528 10.0666 43.5528 9.28796V4.07196H46.2728V8.91996C46.2728 9.51729 46.4275 9.98129 46.7368 10.312C47.0461 10.6426 47.4621 10.808 47.9848 10.808C48.5181 10.808 48.9395 10.6426 49.2488 10.312C49.5581 9.98129 49.7128 9.51729 49.7128 8.91996V4.07196H52.4488Z"
      fill={fill}
    />
    <path
      d="M55.7901 3.14396C55.3101 3.14396 54.9154 3.00529 54.6061 2.72796C54.3074 2.43996 54.1581 2.08796 54.1581 1.67196C54.1581 1.24529 54.3074 0.893294 54.6061 0.61596C54.9154 0.32796 55.3101 0.18396 55.7901 0.18396C56.2594 0.18396 56.6434 0.32796 56.9421 0.61596C57.2514 0.893294 57.4061 1.24529 57.4061 1.67196C57.4061 2.08796 57.2514 2.43996 56.9421 2.72796C56.6434 3.00529 56.2594 3.14396 55.7901 3.14396ZM57.1501 4.07196V13H54.4141V4.07196H57.1501Z"
      fill={fill}
    />
    <path d="M61.8688 1.15996V13H59.1328V1.15996H61.8688Z" fill={fill} />
    <path
      d="M63.3076 8.51996C63.3076 7.60263 63.4782 6.79729 63.8196 6.10396C64.1716 5.41063 64.6462 4.87729 65.2436 4.50396C65.8409 4.13063 66.5076 3.94396 67.2436 3.94396C67.8302 3.94396 68.3636 4.06663 68.8436 4.31196C69.3342 4.55729 69.7182 4.88796 69.9956 5.30396V1.15996H72.7316V13H69.9956V11.72C69.7396 12.1466 69.3716 12.488 68.8916 12.744C68.4222 13 67.8729 13.128 67.2436 13.128C66.5076 13.128 65.8409 12.9413 65.2436 12.568C64.6462 12.184 64.1716 11.6453 63.8196 10.952C63.4782 10.248 63.3076 9.43729 63.3076 8.51996ZM69.9956 8.53596C69.9956 7.85329 69.8036 7.31463 69.4196 6.91996C69.0462 6.52529 68.5876 6.32796 68.0436 6.32796C67.4996 6.32796 67.0356 6.52529 66.6516 6.91996C66.2782 7.30396 66.0916 7.83729 66.0916 8.51996C66.0916 9.20263 66.2782 9.74663 66.6516 10.152C67.0356 10.5466 67.4996 10.744 68.0436 10.744C68.5876 10.744 69.0462 10.5466 69.4196 10.152C69.8036 9.75729 69.9956 9.21863 69.9956 8.53596Z"
      fill={fill}
    />
    <path
      d="M78.3429 13.128C77.5643 13.128 76.8709 12.9946 76.2629 12.728C75.6549 12.4613 75.1749 12.0986 74.8229 11.64C74.4709 11.1706 74.2736 10.648 74.2309 10.072H76.9349C76.9669 10.3813 77.1109 10.632 77.3669 10.824C77.6229 11.016 77.9376 11.112 78.3109 11.112C78.6523 11.112 78.9136 11.048 79.0949 10.92C79.2869 10.7813 79.3829 10.6053 79.3829 10.392C79.3829 10.136 79.2496 9.94929 78.9829 9.83196C78.7163 9.70396 78.2843 9.56529 77.6869 9.41596C77.0469 9.26663 76.5136 9.11196 76.0869 8.95196C75.6603 8.78129 75.2923 8.51996 74.9829 8.16796C74.6736 7.80529 74.5189 7.31996 74.5189 6.71196C74.5189 6.19996 74.6576 5.73596 74.9349 5.31996C75.2229 4.89329 75.6389 4.55729 76.1829 4.31196C76.7376 4.06663 77.3936 3.94396 78.1509 3.94396C79.2709 3.94396 80.1509 4.22129 80.7909 4.77596C81.4416 5.33063 81.8149 6.06663 81.9109 6.98396H79.3829C79.3403 6.67463 79.2016 6.42929 78.9669 6.24796C78.7429 6.06663 78.4443 5.97596 78.0709 5.97596C77.7509 5.97596 77.5056 6.03996 77.3349 6.16796C77.1643 6.28529 77.0789 6.45063 77.0789 6.66396C77.0789 6.91996 77.2123 7.11196 77.4789 7.23996C77.7563 7.36796 78.1829 7.49596 78.7589 7.62396C79.4203 7.79463 79.9589 7.96529 80.3749 8.13596C80.7909 8.29596 81.1536 8.56263 81.4629 8.93596C81.7829 9.29863 81.9483 9.78929 81.9589 10.408C81.9589 10.9306 81.8096 11.4 81.5109 11.816C81.2229 12.2213 80.8016 12.5413 80.2469 12.776C79.7029 13.0106 79.0683 13.128 78.3429 13.128Z"
      fill={fill}
    />
  </svg>
);

HBSBuildsLogo.defaultProps = {
  fill: CRIMSON,
};

HBSBuildsLogo.propTypes = {
  fill: PropTypes.string,
};

export default HBSBuildsLogo;
