import { create } from 'zustand';

const useAdminCompanyStore = create((set) => ({
  companies: [],
  totalNumOfCompanies: 0,
  setCompanies: (companies, totalNumOfCompanies) =>
    set({ companies, totalNumOfCompanies }),
  updateCompany: (company) =>
    set((state) => ({
      companies: state.companies.map((c) =>
        c.id === company.id ? { ...c, ...company } : c,
      ),
      totalNumOfCompanies: state.totalNumOfCompanies,
    })),
  deleteMultipleCompanies: (ids) =>
    set((state) => ({
      companies: state.companies.map((c) =>
        ids.includes(c.id) ? { ...c, deletedAt: new Date().toISOString() } : c,
      ),
      totalNumOfCompanies: state.totalNumOfCompanies,
    })),
  restoreMultipleCompanies: (ids) =>
    set((state) => ({
      companies: state.companies.map((c) =>
        ids.includes(c.id) ? { ...c, deletedAt: null } : c,
      ),
      totalNumOfCompanies: state.totalNumOfCompanies,
    })),
}));

export default useAdminCompanyStore;
