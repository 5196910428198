import PropTypes from 'prop-types';
import SingleSelect from 'components/common/Dropdown/SingleSelect';
import { FilterWrapper } from '../Filters.styled';

const FilterSelect = ({
  options,
  label,
  placeHolder,
  maxHeight,
  value,
  setValue,
}) => (
  <FilterWrapper>
    <SingleSelect
      options={options}
      label={label}
      placeHolder={placeHolder}
      MaxHeight={maxHeight}
      selectedValue={value && options.find((object) => object.id === value)}
      onChangeHandler={(option) => setValue(option)}
    />
  </FilterWrapper>
);

FilterSelect.defaultProps = {
  placeHolder: '',
  maxHeight: '110px',
  value: null,
};

FilterSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeHolder: PropTypes.string,
  maxHeight: PropTypes.string,
};

export default FilterSelect;
