import PropTypes from 'prop-types';
import {
  BottomTooltipArrow,
  BottomTooltipBox,
  LeftTooltipArrow,
  LeftTooltipBox,
  RightTooltipArrow,
  RightTooltipBox,
  TooltipContainer,
  TopTooltipArrow,
  TopTooltipBox,
  TooltipParagraph,
} from 'components/common/Tooltip/Tooltip.styled';
import { useState } from 'react';
import TooltipIcon from 'assets/icons/TooltipIcon';

const Tooltip = ({
  text,
  Heigth,
  Width,
  Side,
  LeftOffSet,
  TopOffSet,
  ArrowLeftOffSet,
  ArrowTopOffSet,
  children,
}) => {
  const [show, setShow] = useState(false);

  return (
    <TooltipContainer
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {show && Side === 'Top' && (
        <TopTooltipBox Heigth={Heigth} Width={Width} LeftOffSet={LeftOffSet}>
          <TopTooltipArrow
            ArrowLeftOffSet={ArrowLeftOffSet}
            ArrowTopOffSet={ArrowTopOffSet}
          />
          <TooltipParagraph>{text}</TooltipParagraph>
        </TopTooltipBox>
      )}
      {show && Side === 'Right' && (
        <RightTooltipBox Heigth={Heigth} Width={Width} TopOffSet={TopOffSet}>
          <RightTooltipArrow
            ArrowLeftOffSet={ArrowLeftOffSet}
            ArrowTopOffSet={ArrowTopOffSet}
          />
          <TooltipParagraph>{text}</TooltipParagraph>
        </RightTooltipBox>
      )}
      {show && Side === 'Bottom' && (
        <BottomTooltipBox Heigth={Heigth} Width={Width} LeftOffSet={LeftOffSet}>
          <BottomTooltipArrow
            ArrowLeftOffSet={ArrowLeftOffSet}
            ArrowTopOffSet={ArrowTopOffSet}
          />
          <TooltipParagraph>{text}</TooltipParagraph>
        </BottomTooltipBox>
      )}
      {show && Side === 'Left' && (
        <LeftTooltipBox Heigth={Heigth} Width={Width} TopOffSet={TopOffSet}>
          <LeftTooltipArrow
            ArrowLeftOffSet={ArrowLeftOffSet}
            ArrowTopOffSet={ArrowTopOffSet}
          />
          <TooltipParagraph>{text}</TooltipParagraph>
        </LeftTooltipBox>
      )}
      {children || <TooltipIcon />}
    </TooltipContainer>
  );
};

Tooltip.defaultProps = {
  LeftOffSet: '0',
  TopOffSet: '0',
  ArrowLeftOffSet: '50%',
  ArrowTopOffSet: '50%',
  children: null,
};

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  Heigth: PropTypes.string.isRequired,
  Width: PropTypes.string.isRequired,
  Side: PropTypes.string.isRequired,
  LeftOffSet: PropTypes.string,
  TopOffSet: PropTypes.string,
  ArrowLeftOffSet: PropTypes.string,
  ArrowTopOffSet: PropTypes.string,
  children: PropTypes.node,
};

export default Tooltip;
