import {
  Container,
  RedLine,
  Title,
  Paragraph,
  IlabLink,
} from 'pages/ResourcePage/components/IlabInfoSection/IlabInfoSection.styled';

const IlabInfoSection = () => (
  <Container>
    <RedLine />
    <Title>Harvard i-lab Member Perks & Deals</Title>
    <Paragraph>
      The
      <IlabLink
        href="https://innovationlabs.harvard.edu/"
        target="_blank"
        rel="noreferrer"
      >
        <b> i-lab </b>
      </IlabLink>
      is open year round to all current Harvard students, and Alumni can apply
      to the Launch X GEO accelerator of the Life Lab once a year. To see the
      full list of offers from the i-lab, please log in to the i-lab portal
      <IlabLink
        href="https://my.innovationlabs.harvard.edu/login/"
        target="_blank"
        rel="noreferrer"
      >
        <b> here</b>
      </IlabLink>
      .
    </Paragraph>
  </Container>
);

export default IlabInfoSection;
