import PropTypes from 'prop-types';
import {
  Wrapper,
  Title,
  DescriptionParagraph,
  ButtonsContainer,
  BUTTONS_CSS,
} from 'pages/AdminPage/components/Forms/Company/DeleteCompany/DeleteCompany.styled';
import {
  PrimaryButton,
  SecondaryButton,
} from 'components/common/Button/Button.styled';

const ConfirmPrompt = ({ numberOfCompanies, closeForm, nextStep }) => (
  <Wrapper>
    <Title>Delete Company</Title>
    <DescriptionParagraph>{`Are you sure you want to delete ${numberOfCompanies} ${
      numberOfCompanies > 1 ? 'companies' : 'company'
    }?`}</DescriptionParagraph>
    <ButtonsContainer>
      <SecondaryButton $ {...BUTTONS_CSS.secondary} onClick={closeForm}>
        No, Keep
      </SecondaryButton>
      <PrimaryButton $ {...BUTTONS_CSS.primary} onClick={nextStep}>
        Yes, Delete
      </PrimaryButton>
    </ButtonsContainer>
  </Wrapper>
);

ConfirmPrompt.propTypes = {
  numberOfCompanies: PropTypes.number.isRequired,
  closeForm: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default ConfirmPrompt;
