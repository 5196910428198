import SingleSelect from 'components/common/Dropdown/SingleSelect';
import PropTypes from 'prop-types';
import { FilterWrapper } from '../Filters.styled';

const FilterSelect = ({
  options,
  label,
  placeHolder,
  maxHeight,
  // eslint-disable-next-line react/prop-types
  value,
  setValue,
  isFiltersCleared,
}) => (
  <FilterWrapper key={isFiltersCleared}>
    <SingleSelect
      options={options}
      label={label}
      placeHolder={placeHolder}
      key={`${isFiltersCleared}:${label}`}
      MaxHeight={maxHeight}
      selectedValue={value && options.find((object) => object.id === value)}
      onChangeHandler={(option) => setValue(option)}
    />
  </FilterWrapper>
);

FilterSelect.defaultProps = {
  placeHolder: '',
  maxHeight: '110px',
};

FilterSelect.propTypes = {
  isFiltersCleared: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  setValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeHolder: PropTypes.string,
  maxHeight: PropTypes.string,
};

export default FilterSelect;
