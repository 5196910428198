import SymphonyLogo from 'assets/icons/SymphonyLogo';
import { SYMPHONY_WEBSITE } from 'constants/other';
import gaSymphonyVisit from 'utils/gaSymphonyVisit';
import {
  AnchorPartnerContainer,
  AnchorTextWrapper,
  AnchorText,
  LearnMore,
} from './AnchorPartner.styled';

const AnchorPartner = () => (
  <AnchorPartnerContainer>
    <AnchorTextWrapper
      onClick={(e) => {
        e.stopPropagation();
        gaSymphonyVisit('anchor_partner_element');
      }}
      href={SYMPHONY_WEBSITE}
      target="_blank"
      rel="noopener noreferrer"
    >
      <AnchorText>Anchor partner</AnchorText>
      <SymphonyLogo />
    </AnchorTextWrapper>
    <LearnMore to="/about/#anchor-partner">Claim offer</LearnMore>
  </AnchorPartnerContainer>
);

export default AnchorPartner;
